export const makeStyles = ({ dataId, selectedBankId }) => ({
    bankCardWrapper: {
        border: (theme) =>
            `1px solid ${
                dataId == selectedBankId
                    ? '#0061DB'
                    : theme.palette.mode === 'dark'
                    ? 'transparent'
                    : '#E5E6EB'
            } `,
        backgroundColor: (theme) => (theme.palette.mode === 'dark' ? 'transparent' : '#F9FAFB'),
    },
    bankCardContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    },
    iconStyle: { fontSize: '18px', color: '#6C727F' },
    bankNameContainer: {
        flex: 1,
        minWidth: 0,
    },
});

export const balanceTextStyle = {
    fontSize: '14px',
    fontWeight: '400',
};

export const bankNameStyle = { fontSize: '16px', fontWeight: '600' };

export const bankNameOverflow = {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
};

export const balanceStyle = {
    fontSize: '20px',
    fontWeight: '700',
};

export const bankCarouselMakeStyles = ({ currentIndex, cardsPerPage, shouldShowArrows }) => ({
    carouselWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        overflow: 'hidden',
    },
    leftArrowButton: { position: 'absolute', left: 0, zIndex: 1 },
    rightArrowButton: { position: 'absolute', right: 0, zIndex: 1 },
    bankCardsWrapper: {
        display: 'flex',
        transform: `translateX(-${currentIndex * (100 / cardsPerPage)}%)`,
        transition: 'transform 0.3s ease-in-out',
        width: shouldShowArrows ? '90%' : '100%',
    },
    bankCardWrapper: {
        flex: `0 0 ${100 / cardsPerPage}%`,
        padding: '8px',
    },
});

export const syncBankAccountsMakeStyles = ({}) => ({
    iconStyle: { fontSize: '18px', color: '#6C727F' },
    loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '300px',
    },
    dropdown: {
        '& .MuiInputBase-root': {
            '& input': {
                fontSize: 13,
                padding: '16px 14px',
                color: (theme) => theme.textColor.primary,
                '&::placeholder': {
                    color: (theme) => theme.textColor.primary,
                    opacity: 1,
                    fontSize: 13,
                },
            },

            backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#394150' : 'none'),
            '& textarea': {
                '&::placeholder': {
                    color: (theme) => theme.textColor.primary,
                    opacity: 1,
                    fontSize: 13,
                },
            },
            '&:hover fieldset': {
                borderColor: '#6C727F',
                border: '1px solid #E5E6EB',
            },
            borderRadius: 2,
            '& fieldset': {
                borderColor: (theme) => (theme.palette.mode === 'dark' ? '#394150' : 'white'),
            },
            boxShadow: (theme) => theme.boxShadow,
            '& li': {
                color: (theme) => theme.textColor.inputField,
                fontSize: '16px',
                fontWeight: 400,
            },
            backgroundImage: 'unset',
            border: (theme) => `1px solid ${theme.borderColor.inputField}`,
            width: '100%',
        },
        marginRight: '15px',
        width: '300px',
    },
    updateButton: {
        paddingX: '20px',
        marginRight: '15px',
        borderRadius: '10px',
        background: (theme) => theme.backgroundColor.primary,
        color: (theme) => theme.primary.dark,
        border: (theme) =>
            theme.palette.mode === 'dark' ? '1px solid #394150' : '1px solid #E5E6EB',
        '&:hover': {
            backgroundColor: (theme) => theme.backgroundColor.primary,
        },
    },
    syncAllButton: {
        backgroundColor: '#0061DB',
        color: 'white',
        textTransform: 'none',
        borderRadius: '8px',
        paddingX: '20px',
        '&:hover': {
            backgroundColor: '#0056b3',
            '@media (hover: hover)': {
                backgroundColor: '#0056b3',
            },
        },
    },
});

export const bankTransactionsMakeStyles = ({}) => ({
    tabsStack: {
        background: (theme) => (theme.palette.mode === 'dark' ? '#2c3440' : '#F9FAFB'),
        minHeight: '60px',
        borderRadius: '50px',
        padding: '1rem 2rem',
        marginTop: '10px',
    },
});

export const makeTableNewStyles = () => ({
    smDownBoxWrapper: {
        p: 2,
        borderRadius: '10px',
        border: (theme) =>
            theme.palette.mode === 'dark' ? '2px solid #394150' : '1px solid #E5E6EB',
    },
    boldFont: {
        fontWeight: 'bold',
    },
    formControlStyle: {
        m: 1,
    },
    tableHeader: {
        textAlign: 'center',
        padding: '16px 10px',
    },
    tableBody: {
        borderBottom: 'none',
        textAlign: 'center',
        padding: '16px 10px',
    },
    companySelect: {
        '& .MuiSelect-select': {
            padding: 0,
            minHeight: 'unset',
            lineHeight: 'inherit',
            display: 'flex',
            alignItems: 'center',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        cursor: 'pointer',
        borderRadius: '12px',
        '&::before': {
            borderBottom: 'none',
        },
        '&::after': {
            borderBottom: 'none',
        },
        '& .MuiSelect-root': {
            borderBottom: 'none',
        },
        '&:hover:not(.Mui-disabled)::before': {
            borderBottom: 'none',
        },
    },
    importButton: {
        backgroundColor: '#0061DB',
        color: 'white',
        borderRadius: '8px',
        paddingX: '10px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#0056b3',
            '@media (hover: hover)': {
                backgroundColor: '#0056b3',
            },
        },
    },
    rootWrapper: {
        border: (theme) =>
            theme.palette.mode === 'dark' ? '1px solid #394150' : '1px solid #E5E6EB',
        mb: 2,
        p: '15px',
    },
    accordionExpandIcon: {
        borderRadius: '50%',
        backgroundColor: 'white',
        color: '#6C727F',
        width: '30px',
        height: '30px',
    },
    accordionSummary: {
        bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#394150' : '#E5E6EB'),
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
    },
    accountName: {
        fontWeight: 600,
        fontSize: '14px',
        color: (theme) => (theme.palette.mode === 'dark' ? '#9EA3AE' : '#4D5461'),
    },
    amountSelect: {
        '& .MuiSelect-select': {
            padding: 0,
            minHeight: 'unset',
            lineHeight: 'inherit',
            display: 'flex',
            alignItems: 'center',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        cursor: 'pointer',
        borderRadius: '12px',
    },
    attachImportButtonsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    attachButton: {
        border: (theme) =>
            theme.palette.mode === 'dark' ? '2px solid #394150' : '1px solid #E5E6EB',
        color: (theme) => (theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F'),
        borderRadius: '8px',
        textTransform: 'none',
        my: 1,
        flex: 1,
        mr: 0.5,
    },
    smDownImportButton: {
        border: (theme) =>
            theme.palette.mode === 'dark' ? '2px solid #394150' : '1px solid #E5E6EB',
        color: '#0061DB',
        borderRadius: '8px',
        textTransform: 'none',
        my: 1,
        flex: 1,
        ml: 0.5,
    },
    noBorder: {
        borderBottom: 'none',
    },
    accordionBackground: { backgroundColor: 'transparent' },
    rowAccordionWrapper: { border: 'none', padding: 0, margin: 0 },
});

export const rowAccordionStyles = () => ({
    root: {
        background: (theme) => theme.backgroundColor.primary,
        width: '100%',
        padding: '20px 0',
    },
    radioButtonsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 2,
    },
    radioGroup: { gap: 2, fontSize: '2px' },
    radioButtonLabel: {
        '& .MuiTypography-root': { fontSize: '14px' },
    },
    radioButtonIcon: {
        '& .MuiSvgIcon-root': {
            fontSize: '14px',
        },
    },
    crossIcon: {
        padding: '6px',
        '& .MuiSvgIcon-root': { fontSize: '20px' },
    },
    importButton: {
        backgroundColor: '#0061DB',
        color: 'white',
        borderRadius: '8px',
        paddingX: '10px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#0056b3',
            '@media (hover: hover)': {
                backgroundColor: '#0056b3',
            },
        },
    },
});

export const coaNestedDropdownStyles = () => ({
    loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '20px',
    },
    nestedDropdown: {
        fontSize: '13px',
        fontWeight: 400,
        padding: '14px 14px',
        color: (theme) => (theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F'),
        textAlign: 'left',
        backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#394150' : '#F4F4F6'),
        border: `1px solid ${(theme) => (theme.palette.mode === 'dark' ? '#394150' : '#E5E6EB')}`,
        borderRadius: '6px',
        textTransform: 'none',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '&:hover': {
            borderColor: '#6C727F',
        },
    },
});

export const matchTransactionInfoStyles = () => ({
    rootWrapper: {
        border: (theme) =>
            `1px solid ${theme.palette.mode === 'dark' ? 'rgba(81, 81, 81, 1)' : '#E5E6EB'} `,
        padding: '16px 8px',
        borderRadius: '15px',
        backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#394150' : '#F4F4F6'),
        padding: '20px',
    },
    category: { mt: 1 },
    unmatchButton: {
        backgroundColor: '#0061DB',
        color: 'white',
        borderRadius: '8px',
        paddingX: '10px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#0056b3',
            '@media (hover: hover)': {
                backgroundColor: '#0056b3',
            },
        },
    },
});

export const selectedTransactionsInfoStyles = () => ({
    rootWrapper: {
        border: (theme) =>
            `1px solid ${theme.palette.mode === 'dark' ? 'rgba(81, 81, 81, 1)' : '#E5E6EB'} `,
        padding: '15px',
        borderRadius: '15px',
        backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#394150' : '#F4F4F6'),
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 2,
    },
    category: { mt: 1 },
    button: {
        backgroundColor: '#0061DB',
        color: 'white',
        borderRadius: '8px',
        paddingX: '10px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#0056b3',
            '@media (hover: hover)': {
                backgroundColor: '#0056b3',
            },
        },
    },
});

export const filtersStyles = () => ({
    clearButton: {
        marginRight: '10px',
        background: (theme) => theme.backgroundColor.primary,
        color: (theme) => theme.primary.dark,
        border: '1px solid #E5E6EB',
        '&:hover': {
            backgroundColor: (theme) => theme.backgroundColor.secondary,
        },
    },
    inputField: {
        '& .MuiInputBase-root': {
            '& input': {
                fontSize: 13,
                padding: '16px 14px',
                color: (theme) => theme.textColor.primary,
                '&::placeholder': {
                    color: (theme) => theme.textColor.primary,
                    opacity: 1,
                    fontSize: 13,
                },
            },

            backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#394150' : 'rgb(244, 244, 246)',
            '& textarea': {
                '&::placeholder': {
                    color: (theme) => theme.textColor.primary,
                    opacity: 1,
                    fontSize: 13,
                },
            },
            '&:hover fieldset': {
                borderColor: '#6C727F',
                border: '1px solid #E5E6EB',
            },
            borderRadius: 2,
            '& fieldset': {
                borderColor: (theme) => (theme.palette.mode === 'dark' ? '#394150' : 'white'),
            },
        },
    },
    dropdown: {
        '& .MuiInputBase-root': {
            '& input': {
                fontSize: 13,
                padding: '16px 14px',
                color: (theme) => theme.textColor.primary,
                '&::placeholder': {
                    color: (theme) => theme.textColor.primary,
                    opacity: 1,
                    fontSize: 13,
                },
            },

            backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#394150' : 'rgb(244, 244, 246)',
            '& textarea': {
                '&::placeholder': {
                    color: (theme) => theme.textColor.primary,
                    opacity: 1,
                    fontSize: 13,
                },
            },
            '&:hover fieldset': {
                borderColor: '#6C727F',
                border: '1px solid #E5E6EB',
            },
            borderRadius: 2,
            '& fieldset': {
                borderColor: (theme) => (theme.palette.mode === 'dark' ? '#394150' : 'white'),
            },
            boxShadow: (theme) => theme.boxShadow,
            '& li': {
                color: (theme) => theme.textColor.inputField,
                fontSize: '16px',
                fontWeight: 400,
            },
            backgroundImage: 'unset',
            border: (theme) => `1px solid ${theme.borderColor.inputField}`,
        },
    },
    datePicker: {
        '& .MuiInputBase-root': {
            backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#394150' : 'rgb(244, 244, 246)',
            borderRadius: '8px',
            border: 'none',
            height: '48px',
            minHeight: '40px',
            fontSize: 13,
            color: (theme) => (theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F'),
            '&:hover .MuiOutlinedInput-notchedOutline': {
                border: (theme) =>
                    `1px solid ${theme.palette.mode === 'dark' ? '#E5E6EB' : '#E5E6EB'}`,
            },
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
            },
        },
        '& .MuiSvgIcon-root': {
            color: (theme) => (theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F'),
        },
        '& .MuiInputBase-input::placeholder': {
            color: (theme) => (theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F'),
            opacity: 1,
            fontSize: 13,
        },
        width: '100%',
        '& .MuiInputLabel-root': { fontSize: '13px' },
        '& .MuiOutlinedInput-input': { fontSize: '13px' },
    },
});

export const manualBankFormStyles = () => ({
    datePicker: {
        '& .MuiInputBase-root': {
            backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#394150' : 'rgb(244, 244, 246)',
            borderRadius: '8px',
            border: 'none',
            height: '48px',
            minHeight: '40px',
            fontSize: 13,
            color: (theme) => (theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F'),
            '&:hover .MuiOutlinedInput-notchedOutline': {
                border: (theme) =>
                    `1px solid ${theme.palette.mode === 'dark' ? '#E5E6EB' : '#E5E6EB'}`,
            },
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
            },
        },
        '& .MuiSvgIcon-root': {
            color: (theme) => (theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F'),
        },
        '& .MuiInputBase-input::placeholder': {
            color: (theme) => (theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F'),
            opacity: 1,
            fontSize: 13,
        },
        width: '100%',
        '& .MuiInputLabel-root': { fontSize: '13px' },
        '& .MuiOutlinedInput-input': { fontSize: '13px' },
    },
    dateError: {
        fontWeight: 600,
        marginLeft: 0,
    },
});

export const allBanksUpdateFormStyles = ({ isMobileScreen }) => ({
    dividerBackground: (theme) => (theme.palette.mode === 'dark' ? '#394150' : '#E5E6EB'),
    formWrapper: {
        maxHeight: isMobileScreen ? '55vh' : '70vh',
        overflowY: 'auto',
        paddingRight: 2,
        paddingTop: '5px',
    },
});

export const manageBankConnectionsStyles = () => ({
    accordion: {
        backgroundColor: (theme) => (theme.palette.mode === 'dark' ? 'rgb(57, 65, 80)' : 'white'),
        border: (theme) =>
            `1px solid ${theme.palette.mode === 'dark' ? 'transparent' : '#E5E6EB'} `,
    },

    AccordionWrapper: {
        maxWidth: 900,
        margin: '0 auto',
        p: 2,
        pb: 1,
    },
});
