import { useAxios } from '../../hooks/useAxios';

const baseUrl = '/payment-plan/';

export const usePaymentPlanService = () => {
    const { get, post, put, isLoading } = useAxios();

    const createPaymentPlan = (data) => post(`${baseUrl}create/`, data, '');

    const getPaymentPlans = (companyId, page, search) => {
        const searchQuery = search?.name ? `&name=${encodeURIComponent(search.name)}` : '';
        return get(`${baseUrl}get/?company_id=${companyId}&page=${page}${searchQuery}`);
    };

    const matchPlaidTransactionWithPaymentPlan = (data) => {
        return put(`${baseUrl}match-plaid-transaction/`, data);
    };

    return {
        isLoading,
        getPaymentPlans,
        createPaymentPlan,
        matchPlaidTransactionWithPaymentPlan,
    };
};
