import { useSelector } from 'react-redux';
import React, { Fragment, useEffect, useState } from 'react';

import { Box, Grid, TextField } from '@mui/material';

import { handleErrorAlert } from 'utils/handleErrorAlert';
import { Button, CircleLoading, SnackBarAlert } from 'shared';
import PaymentPlansTable from '../PaymentPlan/PaymentPlansTable';
import { usePaymentPlanService } from 'store/services/paymentPlan.service';
import { paymentPlanDetailsStyles } from 'components/Inventory/style/style';

const ModalPaymentPlans = ({
    setMatchButtonDisable,
    setSelectedPaymentPlans,
    selectedPaymentPlans,
}) => {
    const { isLoading, getPaymentPlans } = usePaymentPlanService();

    const [page, setPage] = useState(1);
    const [data, setData] = useState(null);
    const classes = paymentPlanDetailsStyles();
    const [search, setSearch] = useState({ name: '' });
    const companyData = useSelector((state) => state.company.data);
    const [alertData, setAlertData] = useState({
        severity: '',
        open: false,
        message: '',
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setSearch((prev) => ({ ...prev, [name]: value }));
    };

    const [showPaymentPlanForm, setShowPaymentPlanForm] = useState(false);

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
        handleGetPaymentPlans(pageNumber);
    };

    const handleGetPaymentPlans = async (page) => {
        try {
            const formattedSearch = search?.name ? { name: search.name } : {};
            const data = await getPaymentPlans(companyData?.id, page, formattedSearch);
            setData(data);
        } catch (error) {
            handleErrorAlert(setAlertData);
        }
    };

    const handleApplyClick = () => {
        handleGetPaymentPlans(1);
    };

    const handleClearClick = () => {
        setSearch({ name: '' });
        handleGetPaymentPlans(1);
    };

    const handlePaymentPlanSelection = (selectedPlans) => {
        setSelectedPaymentPlans(selectedPlans);
        setMatchButtonDisable(selectedPlans.length === 0);
    };

    useEffect(() => {
        if (companyData) {
            handleGetPaymentPlans(page);
        }
    }, [companyData]);

    return (
        <>
            <Box padding={2}>
                <Grid item xs={12} md={4} lg={4}>
                    <TextField
                        name="name"
                        value={search.name}
                        placeholder="Search for Payment Plans"
                        onChange={handleChange}
                        sx={classes.inputField}
                    />

                    <Button
                        text="Clear"
                        onClick={() => handleClearClick(handleClearClick)}
                        customStyles={classes.cancelButton}
                    />
                    <Button text="Apply" onClick={handleApplyClick} />
                </Grid>

                {data ? (
                    <Fragment>
                        {!showPaymentPlanForm && (
                            <Fragment>
                                <Box>
                                    <PaymentPlansTable
                                        isSelectable={true}
                                        page={page}
                                        data={data}
                                        loading={isLoading}
                                        handlePageChange={handlePageChange}
                                        selectedRows={selectedPaymentPlans}
                                        onSelectionChange={handlePaymentPlanSelection}
                                    />
                                </Box>
                            </Fragment>
                        )}
                    </Fragment>
                ) : (
                    <CircleLoading />
                )}

                <SnackBarAlert alertData={alertData} setAlertData={setAlertData} />
            </Box>
        </>
    );
};

export default ModalPaymentPlans;
