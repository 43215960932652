import dayjs from 'dayjs';
import { io } from 'socket.io-client';
import { Formik, Form } from 'formik';
import { useSelector } from 'react-redux';
import React, { Fragment, useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';

import ForReviewFilters from './ForReviewFilters';
import EmailSourceModal from './EmailSourceModal';

import AIMatchingView from './AIMatchingView';
import {
    forReviewSchema,
    forReviewInitialValues,
} from 'components/Inventory/utils/forReviewSchema';
import ForReviewAccordion from './ForReviewAccordion';
import { useCustomMediaQuery } from 'hooks/useMediaQuery';
import { handleErrorAlert } from 'utils/handleErrorAlert';
import { handleSuccessAlert } from 'utils/handleSuccessAlert';
import { getBankAccountsDropdown } from 'store/services/bank.Service';
import { useInventoryService } from 'store/services/inventory.service';
import { forReviewTableHeaders } from '../../../utils/forReviewTableHeaders';
import { SnackBarAlert, CircleLoading, TablePagination, DataTableWithAccordion } from 'shared';

const TOKEN = process.env.REACT_APP_SOCKET_TOKEN;
const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

const ForReview = ({}) => {
    const companyData = useSelector((state) => state.company.data);
    const isMobileScreen = useCustomMediaQuery('(max-width: 600px)');
    const { getInventoryForReview, getAutoMatchStatus, startAutoMatch } = useInventoryService();

    const [page, setPage] = useState(1);
    const [data, setData] = useState(null);
    const [banks, setBanks] = useState(null);
    const [alertData, setAlertData] = useState({
        severity: '',
        open: false,
        message: '',
    });
    const [loading, setLoading] = useState(true);
    const [sortOrder, setSortOrder] = useState('desc');
    const [transaction, setTransaction] = useState(null);
    const [tableLoading, setTableLoading] = useState(true);
    const [sorting, setSorting] = useState({ date: true });
    const [autoMatchLoading, setAutoMatchLoading] = useState(true);
    const [showOnlyAIMatchView, setShowOnlyAIMatchView] = useState(false);
    const [showEmailSourceModal, setShowEmailSourceModal] = useState(false);

    const handleGettingInventoryForReview = async (
        page,
        filters,
        column = null,
        sortOrder = null,
    ) => {
        try {
            setTableLoading(true);
            const response = await getInventoryForReview(
                page,
                companyData.id,
                filters?.description,
                filters?.startDate ? dayjs(filters.startDate).format('YYYY-MM-DD') : null,
                filters?.endDate ? dayjs(filters.endDate).format('YYYY-MM-DD') : null,
                filters?.amount,
                filters?.hasMatches?.value,
                filters?.bankAccount?.value,
                filters?.type?.value,
                column,
                sortOrder,
            );
            setData(response);
        } catch (error) {
            handleErrorAlert(setAlertData);
        } finally {
            setTableLoading(false);
        }
    };

    const handleStartAutoMatch = async () => {
        try {
            setAutoMatchLoading(true);
            const response = await startAutoMatch({ company_id: companyData?.id });
            handleSuccessAlert(setAlertData, response?.detail);
        } catch (error) {
            handleErrorAlert(setAlertData);
        }
    };

    const handleGetAutoMatchStatus = async () => {
        try {
            const response = await getAutoMatchStatus(companyData?.id);
            setAutoMatchLoading(response.sync_status == 'in-progress');
        } catch (error) {
            handleErrorAlert(setAlertData);
        }
    };

    const handleGetBankAccountsDropdown = async () => {
        try {
            const response = await getBankAccountsDropdown(companyData?.id, null, 'review');
            let total_count = 0;
            const bankValues = response.data.map((bank) => {
                total_count += bank.transactions_count;
                return { label: bank.name, value: bank.id, total: bank.transactions_count };
            });
            setBanks([{ value: 'all', label: 'All accounts', total: total_count }, ...bankValues]);
        } catch (error) {
            handleErrorAlert(setAlertData);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page, filters) => {
        setPage(page);
        handleGettingInventoryForReview(page, filters);
    };

    const gettingInitialInventoryForReview = async () => {
        handleGettingInventoryForReview(1, {});
        setPage(1);
    };

    const showAccordion = (row, onClose) => {
        return (
            <ForReviewAccordion
                rowData={row}
                onClose={onClose}
                gettingInitialInventoryForReview={gettingInitialInventoryForReview}
            />
        );
    };

    const handleClearClick = (handleReset) => {
        setPage(1);
        handleReset();
        gettingInitialInventoryForReview();
    };

    const handleSourceClick = (rowData) => {
        setTransaction(rowData);
        setShowEmailSourceModal(true);
    };

    const sortHandler = async (columnId, newSortOrder, filters) => {
        try {
            await handleGettingInventoryForReview(page, filters, columnId, newSortOrder);
            setSorting({ [columnId]: true });
            setSortOrder(newSortOrder);
        } catch (error) {
            handleErrorAlert(setAlertData);
        }
    };

    const handleAIMatch = () => {
        setShowOnlyAIMatchView(true);
    };

    useEffect(() => {
        if (companyData?.id) {
            Promise.all([
                handleGettingInventoryForReview(page, {}),
                handleGetBankAccountsDropdown(),
                handleGetAutoMatchStatus(),
            ]);
        }
    }, [companyData]);

    useEffect(() => {
        if (!companyData) return;

        const socket = io(SOCKET_URL, {
            transports: ['websocket'],
            path: '/api/socket/',
            query: {
                token: TOKEN,
            },
        });

        const eventName = `auto-match-status/${companyData.id}/`;

        socket.off(eventName);
        socket.on(eventName, (data) => {
            if (data.status == 'completed') {
                handleSuccessAlert(setAlertData, 'Auto matching completed successfully');
                setAutoMatchLoading(false);
                handleGettingInventoryForReview(page, {});
            }
        });

        socket.on('connect_error', (error) => {
            console.error('Connection error:', error);
        });

        return () => {
            socket.disconnect();
        };
    }, [companyData]);

    return (
        <Fragment>
            {showOnlyAIMatchView ? (
                <AIMatchingView onExit={() => setShowOnlyAIMatchView(false)} />
            ) : loading ? (
                <CircleLoading />
            ) : (
                <Formik
                    initialValues={forReviewInitialValues}
                    validationSchema={forReviewSchema}
                    onSubmit={(values) => handleGettingInventoryForReview(1, values)}
                >
                    {({ values, handleReset, handleChange, setFieldValue }) => {
                        return (
                            <Form>
                                <ForReviewFilters
                                    data={data}
                                    banks={banks}
                                    values={values}
                                    handleReset={handleReset}
                                    handleChange={handleChange}
                                    setFieldValue={setFieldValue}
                                    autoMatchLoading={autoMatchLoading}
                                    handleClearClick={handleClearClick}
                                    handleStartAutoMatch={handleStartAutoMatch}
                                    handleAIMatch={handleAIMatch}
                                />

                                {companyData?.id && data ? (
                                    <Fragment>
                                        {data?.count > 0 && (
                                            <Box
                                                display="flex"
                                                marginBottom="5px"
                                                justifyContent="flex-end"
                                            >
                                                <Typography fontSize="18px">{`TOTAL: ${data?.count} ROWS`}</Typography>
                                            </Box>
                                        )}
                                        <DataTableWithAccordion
                                            data={data}
                                            sorting={sorting}
                                            sortOrder={sortOrder}
                                            isLoading={tableLoading}
                                            showAccordion={showAccordion}
                                            sortHandler={(columnId, newSortOrder) =>
                                                sortHandler(columnId, newSortOrder, values)
                                            }
                                            headers={forReviewTableHeaders(handleSourceClick)}
                                        />
                                        <TablePagination
                                            page={page}
                                            count={data.count}
                                            isMobileScreen={isMobileScreen}
                                            onChange={(e, pageNumber) =>
                                                handlePageChange(pageNumber, values)
                                            }
                                        />
                                    </Fragment>
                                ) : (
                                    <CircleLoading />
                                )}
                            </Form>
                        );
                    }}
                </Formik>
            )}

            {showEmailSourceModal && (
                <EmailSourceModal
                    transaction={transaction}
                    showEmailSourceModal={showEmailSourceModal}
                    setShowEmailSourceModal={setShowEmailSourceModal}
                />
            )}

            <SnackBarAlert alertData={alertData} setAlertData={setAlertData} />
        </Fragment>
    );
};

export default ForReview;
