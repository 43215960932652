import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, Stack, Paper, TextField, IconButton } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { AIMatchingViewStyles } from 'components/Inventory/style/style';
import { useInventoryService } from 'store/services/inventory.service';
import { useSelector } from 'react-redux';
import { Typewriter } from './TypeWriter';
import { SnackBarAlert, Button } from 'shared';
import EmailSourceModal from '../../InventoryTab/components/EmailSourceModal';
import {
    formatInventory,
    formatTransaction,
    getGeneratedReasoning,
} from 'components/Inventory/utils/aiMatchingHelpers';

import SourceIcon from '@mui/icons-material/Source';

const AIMatchingView = ({ onExit }) => {
    const {
        getAgentMatchings,
        matchAggFileInventoryWithPlaidTransaction,
        submitAgentMatchingUser,
        SkipAgentMatching,
    } = useInventoryService();
    const { id: companyId } = useSelector((state) => state.company.data);
    const [data, setData] = useState({ matches: [], needs_user_input: [] });

    const [introVisible, setIntroVisible] = useState(true);
    const [currentCardIndex, setCurrentCardIndex] = useState(-1);
    const [currentSection, setCurrentSection] = useState('matches');
    const [renderingStage, setRenderingStage] = useState(0);
    const [completedCards, setCompletedCards] = useState([]);
    const [showEmailSourceModal, setShowEmailSourceModal] = useState(false);
    const [showFinalMessage, setShowFinalMessage] = useState(false);
    const [noDataFound, setNoDataFound] = useState(false);
    const [loadingSkip, setLoadingSkip] = useState(false);

    const [selectedEmailData, setSelectedEmailData] = useState(null);
    const [failedMatches, setFailedMatches] = useState([]);

    const [alertData, setAlertData] = useState({
        message: '',
        open: false,
        severity: '',
    });

    const classes = AIMatchingViewStyles();
    const messagesEndRef = useRef(null);

    const handleEmailSourceClick = (emailId) => {
        const emailData = emailId;
        console.log('this is emailID 23', emailData);
        if (!emailData.email_data) {
            setAlertData({
                message:
                    'There were no emails or payment method details on emails. Please provide last 4 digits of payment method for each inventory.',
                open: true,
                severity: 'error',
            });
            return;
        }

        console.log('this is emailID 23', emailData);

        setSelectedEmailData(emailData.email_data.email_id);

        setShowEmailSourceModal(true);
    };

    const startCardRendering = () => {
        if (data.matches && data.matches.length > 0 && currentSection === 'matches') {
            setCurrentCardIndex(0);
            setRenderingStage(1);
        } else if (
            data.needs_user_input &&
            data.needs_user_input.length > 0 &&
            currentSection === 'needs_user_input'
        ) {
            setCurrentCardIndex(0);
            setRenderingStage(1);
        } else if (data.matches && data.matches.length > 0) {
            setCurrentSection('matches');
            setCurrentCardIndex(0);
            setRenderingStage(1);
        } else if (data.needs_user_input && data.needs_user_input.length > 0) {
            setCurrentSection('needs_user_input');
            setCurrentCardIndex(0);
            setRenderingStage(1);
        }
    };
    const advanceRenderingStage = () => {
        if (currentSection === 'matches') {
            setRenderingStage((prev) => {
                if (prev === 3) {
                    setCompletedCards((prev) => [
                        ...prev,
                        { section: currentSection, index: currentCardIndex },
                    ]);

                    if (currentCardIndex < data.matches.length - 1) {
                        setTimeout(() => {
                            setCurrentCardIndex((prevIndex) => prevIndex + 1);
                            setRenderingStage(1);
                        }, 500);
                    } else if (data.needs_user_input && data.needs_user_input.length > 0) {
                        console.log('Transitioning to needs_user_input section');
                        setTimeout(() => {
                            setCurrentSection('needs_user_input');
                            setCurrentCardIndex(0);
                            setRenderingStage(1);
                        }, 500);
                    }
                    return 4;
                }
                return prev + 1;
            });
        } else if (currentSection === 'needs_user_input') {
            setRenderingStage((prev) => {
                if (prev === 2) {
                    setCompletedCards((prev) => [
                        ...prev,
                        { section: currentSection, index: currentCardIndex },
                    ]);

                    if (currentCardIndex < data.needs_user_input.length - 1) {
                        setTimeout(() => {
                            setCurrentCardIndex((prevIndex) => prevIndex + 1);
                            setRenderingStage(1);
                        }, 500);
                    }
                    return 3;
                }
                return prev + 1;
            });
        }
    };

    const skipMatch = async (cardData, source) => {
        let skipPlaidTransactionId = null;

        if (source === 'matchCard') {
            skipPlaidTransactionId = cardData?.transaction_id;
        } else {
            if (cardData.transaction?.plaid_transaction_id) {
                skipPlaidTransactionId = cardData.transaction.plaid_transaction_id;
            }

            if (Array.isArray(cardData.transactions)) {
                const ids = cardData.transactions
                    .map((t) => t.id)
                    .filter((id) => typeof id === 'number' && !isNaN(id));
                if (ids.length > 0) {
                    skipPlaidTransactionId = Math.min(...ids);
                }
            }
        }

        if (!skipPlaidTransactionId) {
            console.warn('Missing Plaid transaction ID for skip.');
            return;
        }

        try {
            setLoadingSkip(true);

            const response = await SkipAgentMatching(companyId, skipPlaidTransactionId);
            console.log('Skip response:', response);

            if (response?.matches || response?.needs_user_input) {
                const updatedData = {
                    matches: response.matches || [],
                    needs_user_input: response.needs_user_input || [],
                };

                setIntroVisible(true);

                setData(updatedData);

                setCurrentCardIndex(-1);
                setRenderingStage(0);
                setCompletedCards([]);

                const hasMatches = updatedData.matches && updatedData.matches.length > 0;
                const hasUserInput =
                    updatedData.needs_user_input && updatedData.needs_user_input.length > 0;

                if (hasMatches) {
                    setCurrentSection('matches');
                } else if (hasUserInput) {
                    setCurrentSection('needs_user_input');
                }

                const noMoreCards = !hasMatches && !hasUserInput;

                if (noMoreCards) {
                    setNoDataFound(true);
                    setAlertData({
                        message: '🎉 All transactions have been processed!',
                        open: true,
                        severity: 'success',
                    });
                    setShowFinalMessage(true);
                    setTimeout(() => {
                        onExit();
                    }, 3000);
                } else {
                    setTimeout(() => {
                        setIntroVisible(false);
                    }, 1000);

                    setTimeout(() => {
                        startCardRendering();
                    }, 1500);
                }

                setLoadingSkip(false);
            } else if (response?.message === 'There are no more bank transactions to match.') {
                setNoDataFound(true);
                setAlertData({
                    message: response.message,
                    open: true,
                    severity: 'success',
                });
                setShowFinalMessage(true);
                setTimeout(() => {
                    onExit();
                }, 3000);
                setLoadingSkip(false);
            } else {
                setLoadingSkip(false);
                setAlertData({
                    message: 'No more transactions to process.',
                    open: true,
                    severity: 'info',
                });
                setNoDataFound(true);
                setShowFinalMessage(true);
                setTimeout(() => {
                    onExit();
                }, 3000);
            }
        } catch (error) {
            setLoadingSkip(false);
            console.error('Error skipping match:', error);
            setAlertData({
                message: 'Failed to skip. Please try again.',
                open: true,
                severity: 'error',
            });
        }
    };

    const handleMatchClick = async (values, cardData, source) => {
        console.log('handle match clicked');

        if (source === 'matchCard') {
            const plaidTransactionId = cardData?.transaction_id;
            const aggregatedFileInventoryId = cardData?.matched_inventory?.id;

            if (!plaidTransactionId || !aggregatedFileInventoryId) {
                console.warn('Missing required IDs for matchCard');
                return;
            }

            try {
                const response = await matchAggFileInventoryWithPlaidTransaction({
                    companyId,
                    plaidTransactionId,
                    aggregatedFileInventoryIds: [aggregatedFileInventoryId],
                });

                // Always refresh data from backend after a match
                const matchingsResponse = await getAgentMatchings(companyId);

                if (response.failed_matches && response.failed_matches.length > 0) {
                    const errorMessages = response.failed_matches
                        .map((fail) => `Inventory ID ${fail.inventory_id}: ${fail.error}`)
                        .join('\n');

                    setAlertData({
                        message: `Error matching the inventory:\n${errorMessages}`,
                        open: true,
                        severity: 'error',
                    });
                    return;
                }

                setAlertData({
                    severity: 'success',
                    message: response.message || 'Matched successfully!',
                    open: true,
                });

                setData(matchingsResponse);
                setCurrentCardIndex(-1);
                setRenderingStage(0);

                const hasMatches =
                    matchingsResponse.matches && matchingsResponse.matches.length > 0;
                const hasUserInput =
                    matchingsResponse.needs_user_input &&
                    matchingsResponse.needs_user_input.length > 0;

                if (!hasMatches && !hasUserInput) {
                    setNoDataFound(true);
                    setAlertData({
                        message: '🎉 All transactions have been matched!',
                        open: true,
                        severity: 'success',
                    });
                    setShowFinalMessage(true);
                    setTimeout(() => {
                        onExit();
                    }, 3000);
                } else {
                    setIntroVisible(true);
                    setTimeout(() => {
                        setIntroVisible(false);
                    }, 800);
                    setTimeout(() => {
                        startCardRendering();
                    }, 1200);
                }
            } catch (err) {
                console.error('Error in matchCard:', err);
                setAlertData({
                    message: 'Error matching the card. Please try again.',
                    open: true,
                    severity: 'error',
                });
            }
        } else if (source === 'userInputCard') {
            const matches = Object.entries(values)
                .filter(([_, val]) => val.trim() !== '')
                .map(([fieldKey, card_last4]) => {
                    const inventoryId = fieldKey.replace('inventory_', '');
                    return {
                        inventory_id: parseInt(inventoryId, 10),
                        card_last4,
                    };
                });

            if (matches.length === 0) {
                console.warn('No valid last 4 digits entered.');
                return;
            }

            const payload = { matches };
            try {
                setIntroVisible(true);

                const res = await submitAgentMatchingUser(companyId, payload);

                const allMatched = !res?.failed_matches || res.failed_matches.length === 0;

                if (!allMatched) {
                    // Preserve values entered and failed state
                    const failedIds = res.failed_matches.map((f) => f.inventory_id);
                    const failedMap = new Map();
                    res.failed_matches.forEach((fail) => {
                        failedMap.set(fail.inventory_id, fail.error);
                    });

                    const updatedInventories = cardData.matched_inventories.map((inv) => {
                        return {
                            ...inv,
                            match_error: failedMap.get(inv.id) || null,
                        };
                    });

                    const updatedCard = {
                        ...cardData,
                        matched_inventories: updatedInventories,
                        // Store the current values to preserve them
                        preserved_values: values,
                    };

                    const updatedNeedsUserInput = [...data.needs_user_input];
                    updatedNeedsUserInput[currentCardIndex] = updatedCard;

                    setData((prev) => ({
                        ...prev,
                        needs_user_input: updatedNeedsUserInput,
                    }));

                    setFailedMatches(res.failed_matches);
                    setAlertData({
                        message:
                            'Some inventory items could not be matched. Please review and fix the issues.',
                        open: true,
                        severity: 'warning',
                    });

                    setIntroVisible(false);
                    return;
                }
                setFailedMatches([]);
                setCurrentCardIndex(-1);
                setRenderingStage(0);

                const plaidTransactionIds = cardData.transactions
                    .map((t) => t.plaid_transaction_id || t.id)
                    .filter(Boolean);

                const matchPromises = matches.map(async (match) => {
                    const plaidTransactionId = plaidTransactionIds[0];

                    if (!plaidTransactionId) {
                        console.warn('Missing Plaid transaction ID for match');
                        return null;
                    }

                    return await matchAggFileInventoryWithPlaidTransaction({
                        companyId,
                        plaidTransactionId,
                        aggregatedFileInventoryIds: [match.inventory_id],
                    });
                });

                // Wait for all matches to complete
                await Promise.all(matchPromises);

                // Get updated matchings data
                const matchingsResponse = await getAgentMatchings(companyId);
                setData(matchingsResponse);
                setFailedMatches([]);
                setCurrentCardIndex(-1);
                setRenderingStage(0);

                const hasMatches = matchingsResponse.matches?.length > 0;
                const hasUserInput = matchingsResponse.needs_user_input?.length > 0;

                if (!hasMatches && !hasUserInput) {
                    setNoDataFound(true);
                    setAlertData({
                        message: '🎉 All transactions have been matched!',
                        open: true,
                        severity: 'success',
                    });

                    setShowFinalMessage(true);
                    setTimeout(() => {
                        onExit();
                    }, 3000);
                } else {
                    setTimeout(() => setIntroVisible(false), 800);
                    setTimeout(() => startCardRendering(), 1200);
                }
            } catch (err) {
                console.error('Error submitting match:', err);
                setAlertData({
                    message: 'Unexpected error while submitting the match.',
                    open: true,
                    severity: 'error',
                });
                setIntroVisible(false);
            }
        }
    };

    const renderCompletedMatchCard = (index) => {
        const cardData = data.matches[index];
        if (!cardData) return null;
        console.log('this is date to see render renderCompletedMatchCard', cardData);

        const transactionObj = cardData.transaction || {
            id: cardData.transaction_id,
            amount: cardData.amount,
            date: cardData.date,
            description: cardData.description,
            vendor: cardData.description,
            card_last4: cardData.card_last4,
        };

        const transactionStr = formatTransaction(transactionObj);
        console.log('this is matching invent 001', cardData.matched_inventory);

        const inventoryStr = formatInventory(cardData.matched_inventory);

        return (
            <Paper key={`complete-match-${index}`} elevation={3} sx={classes.completeCard}>
                <Box>
                    <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                        Transaction
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        {transactionStr}
                    </Typography>

                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mt={3}
                        mb={1}
                    >
                        <Typography variant="subtitle1" fontWeight="bold">
                            Inventories
                        </Typography>
                        <IconButton
                            onClick={() => handleEmailSourceClick(cardData)}
                            sx={{ padding: 0 }}
                        >
                            <SourceIcon fontSize="small" />
                        </IconButton>
                    </Box>

                    <Box display="flex" alignItems="center" gap={1} mb={2}>
                        <Typography variant="body1" sx={{ flexGrow: 1 }}>
                            {inventoryStr}
                        </Typography>
                    </Box>

                    <Stack direction="row" spacing={2} justifyContent="flex-end" mt={2}>
                        <Button
                            sx={classes.cancelButton}
                            text="Skip"
                            onClick={() => {
                                // const hasUserInput =
                                //     cardData?.matched_inventories?.some((inv) => {
                                //         const fieldName = `inventory_${inv.id}`;
                                //         return document
                                //             .querySelector(`input[name="${fieldName}"]`)
                                //             ?.value.trim();
                                //     }) ?? false;

                                // if (hasUserInput) {
                                //     setAlertData({
                                //         message:
                                //             'Please clear all last 4 digit inputs before skipping.',
                                //         open: true,
                                //         severity: 'warning',
                                //     });
                                //     return;
                                // }

                                skipMatch(cardData, 'matchCard');
                            }}
                        />

                        <Button
                            text="Match"
                            variant="contained"
                            onClick={() => handleMatchClick({}, cardData, 'matchCard')}
                        ></Button>
                    </Stack>
                </Box>
            </Paper>
        );
    };

    const renderCompletedUserInputCard = (index) => {
        const cardData = data.needs_user_input[index];
        if (!cardData) return null;
        const reasoning = cardData ? getGeneratedReasoning(cardData) : '';
        return (
            <Paper key={`complete-input-${index}`} elevation={3} sx={classes.completeInputCard}>
                <Formik
                    initialValues={
                        cardData.preserved_values ||
                        cardData.matched_inventories.reduce((acc, inv) => {
                            acc[`inventory_${inv.id}`] = '';
                            return acc;
                        }, {})
                    }
                    validationSchema={Yup.object(
                        cardData.matched_inventories.reduce((acc, inv, i) => {
                            acc[`inventory_${inv.id}`] = Yup.string()
                                .matches(/^[0-9]{4}$/, 'Must be 4 digits')
                                .required('Required');
                            return acc;
                        }, {}),
                    )}
                    onSubmit={(values) => handleMatchClick(values, cardData, 'userInputCard')}
                >
                    {({ errors, touched, isSubmitting }) => (
                        <Form>
                            <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                                Transactions
                            </Typography>

                            {cardData.transactions.map((transaction, i) => (
                                <Typography key={i} variant="body1" sx={{ mb: 1 }}>
                                    {formatTransaction(transaction)}
                                </Typography>
                            ))}

                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                mt={3}
                                mb={1}
                            >
                                <Typography variant="subtitle1" fontWeight="bold">
                                    Inventories
                                </Typography>
                                <IconButton
                                    onClick={() => handleEmailSourceClick(cardData)}
                                    sx={{ padding: 0 }}
                                >
                                    <SourceIcon fontSize="small" />
                                </IconButton>
                            </Box>

                            {cardData.matched_inventories.map((inventory, i) => (
                                <>
                                    <Box
                                        key={i}
                                        display="flex"
                                        flexDirection={{ xs: 'column', md: 'row' }}
                                        alignItems={{ xs: 'start', md: 'center' }}
                                        mb={2}
                                        gap={2}
                                    >
                                        <Typography variant="body1" sx={{ flexGrow: 1 }}>
                                            {formatInventory(inventory)}
                                        </Typography>

                                        <Field
                                            name={`inventory_${inventory.id}`}
                                            as={TextField}
                                            placeholder="Last 4 digits"
                                            variant="outlined"
                                            size="small"
                                            sx={{ width: '120px' }}
                                            error={
                                                touched[`inventory_${inventory.id}`] &&
                                                Boolean(errors[`inventory_${inventory.id}`])
                                            }
                                            helperText={
                                                touched[`inventory_${inventory.id}`]
                                                    ? errors[`inventory_${inventory.id}`]
                                                    : ''
                                            }
                                        />
                                    </Box>
                                    {failedMatches.find((f) => f.inventory_id === inventory.id) && (
                                        <Typography
                                            variant="body2"
                                            sx={{ color: '#b00020', mt: 0.5 }}
                                        >
                                            {
                                                failedMatches.find(
                                                    (f) => f.inventory_id === inventory.id,
                                                )?.error
                                            }
                                        </Typography>
                                    )}
                                </>
                            ))}

                            {cardData.matched_inventories.some((inv) => !inv.purchase_email) && (
                                <Typography
                                    variant="body2"
                                    sx={{ fontWeight: 'bold', color: 'black', mt: 1 }}
                                >
                                    There were no emails or payment method details on emails. Please
                                    provide last 4 digits of payment method for each inventory.
                                </Typography>
                            )}
                            <Typography
                                variant="body2"
                                sx={{ fontStyle: 'italic', color: '#666', mt: 2 }}
                            >
                                {reasoning}
                            </Typography>

                            <Stack direction="row" spacing={2} justifyContent="flex-end" mt={3}>
                                <Button
                                    text="Skip"
                                    sx={classes.cancelButton}
                                    onClick={() => {
                                        // const hasUserInput =
                                        //     cardData?.matched_inventories?.some((inv) => {
                                        //         const fieldName = `inventory_${inv.id}`;
                                        //         return document
                                        //             .querySelector(`input[name="${fieldName}"]`)
                                        //             ?.value.trim();
                                        //     }) ?? false;

                                        // if (hasUserInput) {
                                        //     setAlertData({
                                        //         message:
                                        //             'Please clear all last 4 digit inputs before skipping.',
                                        //         open: true,
                                        //         severity: 'warning',
                                        //     });
                                        //     return;
                                        // }

                                        skipMatch(cardData, 'userInputCard');
                                    }}
                                />

                                <Button
                                    text="Match"
                                    variant="contained"
                                    type="submit"
                                    disabled={isSubmitting}
                                />
                            </Stack>
                        </Form>
                    )}
                </Formik>
            </Paper>
        );
    };

    const renderCurrentMatchCard = () => {
        if (
            currentSection !== 'matches' ||
            currentCardIndex === -1 ||
            currentCardIndex >= data.matches.length
        )
            return null;

        const cardData = data.matches[currentCardIndex];
        console.log('this is date to see render renderCurrentMatchCard', cardData);

        const transactionObj = cardData.transaction || {
            id: cardData.transaction_id,
            amount: cardData.amount,
            date: cardData.date,
            description: cardData.description,
            vendor: cardData.description,
            card_last4: cardData.card_last4,
        };
        const transactionStr = formatTransaction(transactionObj);
        console.log('this is matching invent 1', cardData.matched_inventory);
        const inventoryStr = formatInventory(cardData.matched_inventory);

        return (
            <Paper key={`current-match-${currentCardIndex}`} elevation={3} sx={classes.matchCard}>
                <Box>
                    <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                        Transaction
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        {renderingStage >= 1 &&
                            (renderingStage === 1 ? (
                                <Typewriter
                                    text={transactionStr}
                                    onComplete={() => advanceRenderingStage()}
                                />
                            ) : (
                                transactionStr
                            ))}
                    </Typography>

                    {renderingStage >= 2 && (
                        <>
                            <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                                Inventory
                            </Typography>
                            <Box display="flex" alignItems="center" gap={1} mb={2}>
                                <Typography variant="body1" sx={{ flexGrow: 1 }}>
                                    {renderingStage === 2 ? (
                                        <Typewriter
                                            text={inventoryStr}
                                            onComplete={() => advanceRenderingStage()}
                                        />
                                    ) : (
                                        inventoryStr
                                    )}
                                </Typography>
                                <IconButton
                                    onClick={() => handleEmailSourceClick(cardData)}
                                    sx={{ padding: 0 }}
                                >
                                    <SourceIcon fontSize="small" />
                                </IconButton>
                            </Box>
                        </>
                    )}

                    {renderingStage >= 3 && (
                        <Typography
                            variant="body2"
                            sx={{
                                fontStyle: 'italic',
                                color: '#666',
                                mt: 2,
                            }}
                        >
                            ${cardData.reasoning}
                        </Typography>
                    )}

                    {renderingStage >= 3 && (
                        <Stack direction="row" spacing={2} justifyContent="flex-end" mt={2}>
                            <Button
                                text="Skip"
                                sx={classes.cancelButton}
                                onClick={() => {
                                    // const hasUserInput =
                                    //     cardData?.matched_inventories?.some((inv) => {
                                    //         const fieldName = `inventory_${inv.id}`;
                                    //         return document
                                    //             .querySelector(`input[name="${fieldName}"]`)
                                    //             ?.value.trim();
                                    //     }) ?? false;

                                    // if (hasUserInput) {
                                    //     setAlertData({
                                    //         message:
                                    //             'Please clear all last 4 digit inputs before skipping.',
                                    //         open: true,
                                    //         severity: 'warning',
                                    //     });
                                    //     return;
                                    // }

                                    skipMatch(cardData, 'matchCard');
                                }}
                            />

                            <Button
                                text="Match"
                                variant="contained"
                                onClick={() => handleMatchClick({}, cardData, 'matchCard')}
                            />
                        </Stack>
                    )}
                </Box>
            </Paper>
        );
    };

    const renderCurrentUserInputCard = () => {
        if (
            currentSection !== 'needs_user_input' ||
            currentCardIndex === -1 ||
            currentCardIndex >= data.needs_user_input.length
        )
            return null;

        const cardData = data.needs_user_input[currentCardIndex];
        const reasoning = cardData ? getGeneratedReasoning(cardData) : '';

        return (
            <Paper
                key={`current-input-${currentCardIndex}`}
                elevation={3}
                sx={{
                    mb: 3,
                    p: 3,
                    borderRadius: 2,
                    width: '100%',
                    maxWidth: 700,
                    backgroundColor: '#fff',
                    animation: 'fadeIn 0.5s ease forwards',
                }}
            >
                <Box>
                    <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                        Transactions
                    </Typography>

                    {renderingStage >= 1 &&
                        (renderingStage === 1 ? (
                            <Typewriter
                                text={cardData.transactions.map(formatTransaction).join('\n')}
                                onComplete={() => advanceRenderingStage()}
                            />
                        ) : (
                            cardData.transactions.map((transaction, i) => (
                                <Typography key={i} variant="body1" sx={{ mb: 1 }}>
                                    {formatTransaction(transaction)}
                                </Typography>
                            ))
                        ))}

                    {renderingStage >= 2 && (
                        <>
                            <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1, mt: 3 }}>
                                Inventories
                            </Typography>

                            {renderingStage === 2 ? (
                                <Typewriter
                                    text={cardData.matched_inventories
                                        .map(formatInventory)
                                        .join('\n')}
                                    onComplete={() => advanceRenderingStage()}
                                />
                            ) : (
                                <Formik
                                    initialValues={
                                        cardData.preserved_values ||
                                        cardData.matched_inventories.reduce((acc, inv) => {
                                            acc[`inventory_${inv.id}`] = '';
                                            return acc;
                                        }, {})
                                    }
                                    validationSchema={Yup.object(
                                        cardData.matched_inventories.reduce((acc, inv, i) => {
                                            acc[`inventory_${inv.id}`] = Yup.string()
                                                .matches(/^[0-9]{4}$/, 'Must be 4 digits')
                                                .required('Required');
                                            return acc;
                                        }, {}),
                                    )}
                                    onSubmit={(values) =>
                                        handleMatchClick(values, cardData, 'userInputCard')
                                    }
                                >
                                    {({ errors, touched, isSubmitting }) => (
                                        <Form>
                                            {cardData.matched_inventories.map((inventory, i) => (
                                                <Box
                                                    key={i}
                                                    display="flex"
                                                    flexDirection={{ xs: 'column', md: 'row' }}
                                                    alignItems={{ xs: 'start', md: 'center' }}
                                                    mb={2}
                                                    gap={2}
                                                >
                                                    <Typography
                                                        variant="body1"
                                                        sx={{ flexGrow: 1 }}
                                                    >
                                                        {formatInventory(inventory)}
                                                    </Typography>
                                                    <Field
                                                        name={`inventory_${inventory.id}`}
                                                        as={TextField}
                                                        placeholder="Last 4 digits"
                                                        variant="outlined"
                                                        size="small"
                                                        sx={{ width: '120px' }}
                                                        error={
                                                            touched[`inventory_${inventory.id}`] &&
                                                            Boolean(
                                                                errors[`inventory_${inventory.id}`],
                                                            )
                                                        }
                                                        helperText={
                                                            touched[`inventory_${inventory.id}`]
                                                                ? errors[
                                                                      `inventory_${inventory.id}`
                                                                  ]
                                                                : ''
                                                        }
                                                    />
                                                </Box>
                                            ))}

                                            <Typography
                                                variant="body2"
                                                sx={{ fontStyle: 'italic', color: '#666', mt: 2 }}
                                            >
                                                {reasoning}
                                            </Typography>

                                            <Stack
                                                direction="row"
                                                spacing={2}
                                                justifyContent="flex-end"
                                                mt={3}
                                            >
                                                <Button
                                                    text="Skip"
                                                    sx={classes.cancelButton}
                                                    onClick={() => {
                                                        // const hasUserInput =
                                                        //     cardData?.matched_inventories?.some(
                                                        //         (inv) => {
                                                        //             const fieldName = `inventory_${inv.id}`;
                                                        //             return document
                                                        //                 .querySelector(
                                                        //                     `input[name="${fieldName}"]`,
                                                        //                 )
                                                        //                 ?.value.trim();
                                                        //         },
                                                        //     ) ?? false;

                                                        // if (hasUserInput) {
                                                        //     setAlertData({
                                                        //         message:
                                                        //             'Please clear all last 4 digit inputs before skipping.',
                                                        //         open: true,
                                                        //         severity: 'warning',
                                                        //     });
                                                        //     return;
                                                        // }

                                                        skipMatch(cardData, 'userInputCard');
                                                    }}
                                                />

                                                <Button
                                                    variant="contained"
                                                    type="submit"
                                                    text="Match"
                                                    disabled={isSubmitting}
                                                />
                                            </Stack>
                                        </Form>
                                    )}
                                </Formik>
                            )}
                        </>
                    )}
                </Box>
            </Paper>
        );
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIntroVisible(false);
        }, 2500);
        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        if (!introVisible && (data?.matches?.length > 0 || data?.needs_user_input?.length > 0)) {
            startCardRendering();
        }
    }, [introVisible, data]);

    useEffect(() => {
        if (companyId) {
            getAgentMatchings(companyId)
                .then((res) => {
                    setData(res);

                    const hasMatches = res?.matches?.length > 0;
                    const needsInput = res?.needs_user_input?.length > 0;

                    if (!hasMatches && !needsInput) {
                        setNoDataFound(true);
                    } else {
                        setNoDataFound(false);
                    }
                })
                .catch((err) => {
                    console.error('Error fetching agent matchings:', err);
                    setNoDataFound(true);
                });
        }
    }, [companyId]);

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [renderingStage, currentCardIndex, completedCards.length]);

    return (
        <Box
            height="100vh"
            width="100%"
            bgcolor="#f5f8fa"
            display="flex"
            flexDirection="column"
            alignItems="center"
            position="relative"
            px={2}
            py={4}
            sx={{ overflowY: 'auto' }}
        >
            <Box position="absolute" top={20} right={20}>
                <Button text={'exit'} onClick={onExit} />
            </Box>

            {introVisible || loadingSkip ? (
                <Paper
                    elevation={3}
                    sx={{
                        width: '100%',
                        maxWidth: 700,
                        textAlign: 'center',
                        bgcolor: '#fff',
                        borderRadius: 2,
                        p: 4,
                        mt: 8,
                        animation: 'fadeIn 0.8s ease forwards',
                    }}
                >
                    <Typography variant="h5" fontWeight="bold" mb={1}>
                        AI Matching in Progress
                    </Typography>
                    <Typography variant="body1" mb={3}>
                        Please wait while we intelligently pair transactions with inventory...
                    </Typography>
                    <Typography variant="body1" mb={3}>
                        AI is thinking...
                    </Typography>
                </Paper>
            ) : noDataFound ? (
                <Paper
                    elevation={3}
                    sx={{
                        width: '100%',
                        maxWidth: 800,
                        textAlign: 'center',
                        bgcolor: '#fff',
                        borderRadius: 2,
                        p: 4,
                        mt: 8,
                    }}
                >
                    <Typography variant="h6" fontWeight="bold" mb={2}>
                        No Data Found
                    </Typography>
                    <Typography variant="body1">
                        There are no transactions or inventory items to match.
                    </Typography>
                </Paper>
            ) : (
                <Box display="flex" flexDirection="column" alignItems="center" width="100%" mt={8}>
                    <Typography variant="h5" fontWeight="bold" mb={4}>
                        AI Matching Results
                    </Typography>

                    {!showFinalMessage &&
                        completedCards.map((card) => {
                            if (card.section === 'matches') {
                                return renderCompletedMatchCard(card.index);
                            } else if (card.section === 'needs_user_input') {
                                return renderCompletedUserInputCard(card.index);
                            }
                            return null;
                        })}

                    {showFinalMessage ? (
                        <Paper
                            elevation={3}
                            sx={{
                                width: '100%',
                                maxWidth: 800,
                                textAlign: 'center',
                                bgcolor: '#fff',
                                borderRadius: 2,
                                p: 4,
                                mt: 4,
                                animation: 'fadeIn 0.8s ease forwards',
                            }}
                        >
                            <Typography variant="h6" fontWeight="bold" mb={2}>
                                🎉 All transactions have been matched!
                            </Typography>
                            <Typography variant="body1" className="pulse">
                                Exiting...
                            </Typography>
                        </Paper>
                    ) : (
                        !completedCards.some(
                            (c) => c.section === currentSection && c.index === currentCardIndex,
                        ) &&
                        (currentSection === 'matches'
                            ? renderCurrentMatchCard()
                            : renderCurrentUserInputCard())
                    )}

                    <div ref={messagesEndRef} />

                    {showEmailSourceModal && selectedEmailData && (
                        <EmailSourceModal
                            emailId={selectedEmailData}
                            showEmailSourceModal={showEmailSourceModal}
                            setShowEmailSourceModal={setShowEmailSourceModal}
                        />
                    )}

                    <SnackBarAlert alertData={alertData} setAlertData={setAlertData} />
                </Box>
            )}

            <style>
                {`
                @keyframes fadeIn {
                    from { opacity: 0; transform: translateY(20px); }
                    to { opacity: 1; transform: translateY(0); }
                }

                @keyframes pulse {
                    0% { opacity: 1; }
                    50% { opacity: 0.5; }
                    100% { opacity: 1; }
                }
                
                .pulse {
                    animation: pulse 1.5s infinite ease-in-out;
                }
                `}
            </style>
        </Box>
    );
};

export default AIMatchingView;
