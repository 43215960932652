import { useSelector } from 'react-redux';
import React, { Fragment, useEffect, useState } from 'react';

import { Box, Grid, TextField } from '@mui/material';

import InventoryList from './components/InventoryList';
import { useCompanyService } from 'store/services/company.service';
import EmailDetailsAccordion from './components/EmailDetailsAccordion';
import { inventoryFiltersStyles } from 'components/Inventory/style/style';
import { TablePagination, CircleLoading, Button, SnackBarAlert } from 'shared';

const InventoryEmails = () => {
    const companyData = useSelector((state) => state.company.data);
    const { isLoading, getCompanyEmailsWithInventory } = useCompanyService();

    const [page, setPage] = useState(1);
    const [data, setData] = useState(null);
    const [values, setValues] = useState({ description: '' });
    const [alertData, setAlertData] = useState({
        message: '',
        open: false,
        severity: '',
    });

    const classes = inventoryFiltersStyles();

    const handleGetCompanyEmailsWithInventory = async (pageNumber, searchText = '') => {
        try {
            const response = await getCompanyEmailsWithInventory(
                companyData?.id,
                pageNumber,
                searchText,
            );

            setData(response);
        } catch (error) {
            console.error(error);
        }
    };

    const refreshData = async () => {
        try {
            const updatedData = await getCompanyEmailsWithInventory(companyData?.id, 1, '');
            setData(updatedData);
        } catch (error) {
            console.error('Error fetching updated inventory data:', error);
        }
    };

    const handlePageChange = async (pageNumber) => {
        setPage(pageNumber);

        await handleGetCompanyEmailsWithInventory(pageNumber, values.description);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setValues((prev) => ({ ...prev, [name]: value }));
    };

    const handleApplyClick = () => {
        setPage(1);
        handleGetCompanyEmailsWithInventory(1, values.description);
    };

    const handleClearClick = () => {
        setValues({ description: '' });
        setPage(1);
        handleGetCompanyEmailsWithInventory(1, '');
    };

    useEffect(() => {
        if (companyData) {
            handleGetCompanyEmailsWithInventory(page, values.description);
        }
    }, [companyData]);

    return (
        <Box width="100%">
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={3}>
                    <TextField
                        fullWidth
                        name="description"
                        value={values.description}
                        placeholder="Search for keywords"
                        onChange={handleChange}
                        sx={classes.inputField}
                    />
                </Grid>

                <Grid item>
                    {' '}
                    <Button
                        text="Clear"
                        onClick={handleClearClick}
                        customStyles={classes.cancelButton}
                    />
                    <Button text="Apply" onClick={handleApplyClick} />
                </Grid>

                {!isLoading && companyData && data ? (
                    <Box width="100%">
                        {data?.results?.map((email) => (
                            <Fragment key={email.id}>
                                <Grid item xs={12}>
                                    <EmailDetailsAccordion email={email} />
                                </Grid>
                                <Grid item xs={12}>
                                    <InventoryList
                                        data={email?.inventories}
                                        company_email_id={email.id}
                                        refreshData={refreshData}
                                        setAlertData={setAlertData}
                                    />
                                </Grid>
                            </Fragment>
                        ))}
                        <Grid item xs={12} p={'10px 0'}>
                            <TablePagination
                                rowsPerPage={30}
                                page={page}
                                count={data.count}
                                onChange={(e, pageNumber) => handlePageChange(pageNumber)}
                            />
                        </Grid>
                    </Box>
                ) : (
                    <CircleLoading />
                )}
            </Grid>
            <SnackBarAlert alertData={alertData} setAlertData={setAlertData} />
        </Box>
    );
};

export default InventoryEmails;
