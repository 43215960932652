export const bankAccountTypes = [
    {
        label: 'Depository',
        value: 'depository',
    },
    {
        label: 'Credit',
        value: 'credit',
    },
];

export const bankAccountSubtypes = {
    depository: [
        { label: 'Checking', value: 'checking' },
        { label: 'Savings', value: 'savings' },
    ],
    credit: [{ label: 'Credit Card', value: 'credit card' }],
};
