import { Skeleton } from '@mui/material';
import React from 'react';

function RecieptLoader() {
    return (
        <>
            <Skeleton variant="text" width="30%" height={25} />
            <Skeleton variant="rectangular" width="100%" height={40} />

            <Skeleton variant="rectangular" width="100%" height={40} />

            <Skeleton
                variant="text"
                width="40%"
                height={25}
                style={{ marginTop: 10 }}
            />
            <Skeleton variant="rectangular" width="100%" height={40} />

            <Skeleton variant="rectangular" width="100%" height={40} />

            <Skeleton
                variant="text"
                width="40%"
                height={25}
                style={{ marginTop: 10 }}
            />
            <Skeleton variant="rectangular" width="100%" height={40} />

            <Skeleton variant="rectangular" width="100%" height={40} />

            <Skeleton
                variant="text"
                width="40%"
                height={25}
                style={{ marginTop: 10 }}
            />
            <Skeleton variant="rectangular" width="100%" height={40} />
        </>
    );
}

export default RecieptLoader;
