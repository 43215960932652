import Papa from 'papaparse';
import { io } from 'socket.io-client';
import { useSelector } from 'react-redux';
import React, { useCallback, Fragment, useState, useEffect } from 'react';

import {
    Button,
    DataTable,
    Animation,
    Autocomplete,
    SnackBarAlert,
    CircleLoading,
    TablePagination,
} from 'shared';
import {
    Box,
    List,
    Grid,
    Select,
    ListItem,
    MenuItem,
    Typography,
    FormControl,
    ListItemText,
    CircularProgress,
} from '@mui/material';

import {
    getTransactionFiles,
    getBankAccountsDropdown,
    importManualBankTransactions,
} from 'store/services/bank.Service';
import { Upload } from 'components/upload';
import { handleErrorAlert } from 'utils/handleErrorAlert';
import { useCustomMediaQuery } from 'hooks/useMediaQuery';
import { uploadTransactionsStyles } from './style/styles';
import { handleSuccessAlert } from 'utils/handleSuccessAlert';
import { uploadTransactionsHeaders } from './utils/uploadTransactionsHeaders';

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
const TOKEN = process.env.REACT_APP_SOCKET_TOKEN;

const UploadTransactions = () => {
    const classes = uploadTransactionsStyles();
    const companyData = useSelector((state) => state.company.data);
    const isMobileScreen = useCustomMediaQuery('(max-width: 600px)');

    const [page, setPage] = useState(1);
    const [rows, setRows] = useState([]);
    const [files, setFiles] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [alertData, setAlertData] = useState({
        severity: '',
        message: '',
        open: false,
    });
    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [fileName, setFileName] = useState(null);
    const [selectedValues, setSelectedValues] = useState({});
    const [importLoading, setImportLoading] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [processingFiles, setProcessingFiles] = useState(null);

    const handleFileDrop = useCallback(
        (acceptedFiles) => {
            const newFiles = acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                }),
            );

            setFiles([newFiles[0]]);
            if (newFiles) {
                const uploadedFile = newFiles[0];
                setFileName(uploadedFile.name);
                Papa.parse(newFiles[0], {
                    header: true,
                    skipEmptyLines: true,
                    complete: (results) => {
                        if (results.data.length > 0) {
                            const firstRow = results.data[0];
                            const formatted = Object.keys(firstRow).map((header) => ({
                                id: header,
                                label: header,
                                sort: false,
                                verticalAlign: 'top',
                                changed: false,
                                labelForSelect: header,
                            }));
                            setHeaders(formatted);
                            setRows(results.data);
                        }
                    },
                    error: (error) => {
                        console.error('Error parsing CSV:', error);
                    },
                });
            }
        },
        [files],
    );

    const handleHeaderChange = (event, ch) => {
        const selectedValue = event.target.value;

        setHeaders((prevHeaders) =>
            prevHeaders.map((header) => {
                if (header.id === selectedValues[ch]) {
                    return {
                        ...header,
                        changed: false,
                        label: header.label.replace(` (${ch})`, ''),
                    };
                }
                if (header.id === selectedValue) {
                    return {
                        ...header,
                        changed: true,
                        label: `${header.label} (${ch})`,
                    };
                }
                return header;
            }),
        );

        setSelectedValues((prevSelectedValues) => ({
            ...prevSelectedValues,
            [ch]: selectedValue,
        }));
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleClear = () => {
        setFiles([]);
        setHeaders([]);
        setRows([]);
        setSelectedValues({});
        setPage(1);
        setFileName(null);
        setSelectedAccount(null);
    };

    const formatData = () => {
        const camelCaseKey = (str) => {
            return str
                .replace(/\([^)]*\)/g, '') // Remove anything inside parentheses, including the parentheses
                .replace(/\*/g, '') // Remove asterisks
                .replace(/(?:^|\s)(\w)/g, (_, c) => (c ? c.toUpperCase() : '')) // Capitalize letters after spaces
                .replace(/\s+/g, '') // Remove spaces
                .replace(/^(\w)/, (_, c) => c.toLowerCase()); // Make the first letter lowercase
        };

        return rows.map((row) => {
            const formattedRow = {};

            Object.keys(selectedValues).forEach((key) => {
                const mappedKey = camelCaseKey(key);
                const mappedValue = selectedValues[key];

                formattedRow[mappedKey] = row[mappedValue] || null;
            });

            return formattedRow;
        });
    };

    const handleImport = async () => {
        try {
            setImportLoading(true);
            const formattedData = formatData();

            const response = await importManualBankTransactions({
                data: formattedData,
                companyId: companyData.id,
                bankAccountId: selectedAccount.value,
                fileName,
            });
            handleSuccessAlert(setAlertData, response.message);
            handleClear();
            handleGetTransactionFiles();
        } catch (error) {
            handleErrorAlert(setAlertData, 'Import failed.');
        } finally {
            setImportLoading(false);
        }
    };

    const handleGetAccounts = async () => {
        const response = await getBankAccountsDropdown(companyData?.id, 'manual', 'all');
        setAccounts(
            response?.data?.map((account) => ({
                label: account.name,
                value: account.id,
                total: account.transactions_count,
            })),
        );
    };

    const handleGetTransactionFiles = async () => {
        try {
            setLoading(true);
            const response = await getTransactionFiles(companyData?.id, 'processing');
            setProcessingFiles(response?.data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!companyData || processingFiles?.length === 0) return;

        const socket = io(SOCKET_URL, {
            transports: ['websocket'],
            path: '/api/socket/',
            query: {
                token: TOKEN,
            },
        });

        const eventName = `file/${companyData.id}/`;

        socket.off(eventName);
        socket.on(eventName, (data) => {
            handleSuccessAlert(setAlertData, `${data?.file_name} processed successfully`);

            setProcessingFiles((prev) => prev.filter((f) => f.id !== data.id));
        });

        socket.on('connect_error', (error) => {
            console.error('Connection error:', error);
        });

        return () => {
            socket.disconnect();
        };
    }, [companyData, processingFiles]);

    useEffect(() => {
        if (companyData) {
            handleGetTransactionFiles();
            handleGetAccounts();
        }
    }, [companyData]);

    if (!companyData) {
        return <CircleLoading />;
    }

    return (
        <Fragment>
            <Grid container sx={classes.inventoryReconciliationWrapper}>
                {loading ? (
                    <CircleLoading />
                ) : (
                    <Fragment>
                        {processingFiles && processingFiles?.length > 0 && (
                            <Grid item sx={12} width="100%" marginBottom={2}>
                                <Box margin="auto" padding={2} borderRadius={2} boxShadow={3}>
                                    <Typography variant="h6" marginBottom={2}>
                                        Processing Files
                                    </Typography>
                                    <List>
                                        {processingFiles.map((file) => (
                                            <ListItem
                                                justifyContent="space-between"
                                                display="flex"
                                                key={file.id}
                                            >
                                                <ListItemText primary={file.file_name} />
                                                <CircularProgress size={24} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                            </Grid>
                        )}
                        <Grid item xs={12} width={'100%'}>
                            <Upload
                                multiple
                                accept={{
                                    'text/csv': [],
                                    'application/vnd.ms-excel': [],
                                }}
                                placeholder={'Drag and drop your files here (CSV)'}
                                files={files}
                                onDrop={handleFileDrop}
                                onRemove={handleClear}
                            />
                            {headers.length > 0 && (
                                <Box sx={classes.buttonsWrapper}>
                                    <Button
                                        text="Import"
                                        variant="outlined"
                                        disabled={
                                            !selectedValues['Date *'] ||
                                            !selectedValues['Description *'] ||
                                            !selectedValues['Amount *'] ||
                                            !selectedAccount
                                        }
                                        customStyles={classes.button}
                                        onClick={handleImport}
                                        loading={importLoading}
                                    />
                                    <Button
                                        text="Clear"
                                        onClick={handleClear}
                                        customStyles={classes.clearButton}
                                    />
                                </Box>
                            )}
                        </Grid>
                        {files.length > 0 && (
                            <Grid container marginTop={5}>
                                <Grid item sm={12} md={6} padding={2}>
                                    <Typography>Total Rows: {rows.length}</Typography>
                                    <Autocomplete
                                        options={accounts}
                                        labelKey="label"
                                        valueKey="value"
                                        placeholder="Select account"
                                        value={selectedAccount}
                                        onChange={(e, value) => {
                                            setSelectedAccount(value);
                                        }}
                                        showTotal={true}
                                    />
                                </Grid>
                                {headers.length > 0 && (
                                    <Grid item sm={12} md={6}>
                                        {uploadTransactionsHeaders.map((ch, i) => (
                                            <Box key={i} sx={classes.dropdownsWrapper}>
                                                <Typography sx={classes.flex}>{ch}</Typography>
                                                <FormControl sx={classes.flex}>
                                                    <Select
                                                        placeholder="Select Column"
                                                        value={selectedValues[ch] || ''}
                                                        onChange={(e) => handleHeaderChange(e, ch)}
                                                    >
                                                        {headers
                                                            .filter(
                                                                (h) =>
                                                                    !h.changed ||
                                                                    selectedValues[ch] === h.id,
                                                            )
                                                            .map((h) => (
                                                                <MenuItem
                                                                    disabled={h.changed}
                                                                    key={h.id}
                                                                    value={h.id}
                                                                >
                                                                    {h.labelForSelect}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        ))}
                                    </Grid>
                                )}
                            </Grid>
                        )}
                    </Fragment>
                )}
            </Grid>

            {headers.length > 0 && (
                <Grid container>
                    <Grid item xs={12}>
                        <DataTable
                            headCells={headers}
                            data={rows.slice((page - 1) * 10, page * 10)}
                        ></DataTable>
                    </Grid>
                    <Grid item xs={12} p={'10px 0'}>
                        <TablePagination
                            page={page}
                            count={rows.length}
                            isMobileScreen={isMobileScreen}
                            onChange={handleChangePage}
                        />
                    </Grid>
                </Grid>
            )}

            {importLoading && <Animation title="Uploading your bank activities" />}

            <SnackBarAlert alertData={alertData} setAlertData={setAlertData} />
        </Fragment>
    );
};

export default UploadTransactions;
