import React, { Fragment } from 'react';

import { Box, Paper, Typography, Checkbox } from '@mui/material';

import { Autocomplete } from 'shared';
import { transactionSummaryStyles } from 'components/Inventory/style/style';

const TransactionSummary = ({
    checked,
    numSelected,
    transaction,
    selectedRows,
    selectedOption,
    remainingAmount,
    totalAmountSelected,
    handleCheckedChange,
    handleSelectedOptionChange,
}) => {
    const classes = transactionSummaryStyles();

    return (
        <Paper sx={classes.root}>
            <Box sx={classes.summaryWrapper}>
                <Box sx={classes.textBox}>
                    <Typography fontSize="12px" fontWeight="500">
                        NUM. SELECTED
                    </Typography>
                    <Typography fontSize="14px" fontWeight="600">
                        {numSelected}
                    </Typography>
                </Box>

                <Box sx={classes.textBox}>
                    <Typography fontSize="12px" fontWeight="500">
                        TOTAL SELECTED
                    </Typography>
                    <Typography fontSize="14px" fontWeight="600">
                        {transaction.currency_code} {totalAmountSelected.toFixed(2)}
                    </Typography>
                </Box>
            </Box>

            {numSelected ? (
                <Fragment>
                    <Box sx={classes.remainingWrapper}>
                        <Typography fontSize="12px" fontWeight="500">
                            Remaining: {transaction.currency_code} {remainingAmount}
                        </Typography>
                    </Box>
                    {remainingAmount != 0 ? (
                        <Fragment>
                            <Box
                                sx={classes.checkboxWrapper}
                                checked={checked}
                                onChange={() => handleCheckedChange(!checked)}
                            >
                                <Checkbox sx={classes.checkbox} size="small" />
                                <Typography fontSize="12px">
                                    {`Create an adjustment of ${remainingAmount} to match bank
                                total`}
                                </Typography>
                            </Box>
                            {checked && selectedRows?.length > 1 && (
                                <Autocomplete
                                    options={selectedRows.map((row) => ({
                                        label: row?.invoice_id || row.purchase_id,
                                        value: row.id,
                                    }))}
                                    labelKey="label"
                                    valueKey="value"
                                    placeholder="Select a purchase"
                                    value={selectedOption}
                                    onChange={(e, value) => handleSelectedOptionChange(value)}
                                    isSearchField={false}
                                    disableClearable
                                />
                            )}
                        </Fragment>
                    ) : (
                        <></>
                    )}
                </Fragment>
            ) : (
                <></>
            )}
        </Paper>
    );
};

export default TransactionSummary;
