/* eslint-disable react-hooks/exhaustive-deps */

import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import useNotification from './useNotification';
import { store } from '../store';
import { CIRCLER_API_URL } from '../utils/constants';
import { useNavigate } from 'react-router-dom';

const api = axios.create();

api.interceptors.request.use(
    (config) => {
        if (config.baseURL === CIRCLER_API_URL) {
            const { token } = store.getState().auth;
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

api.interceptors.response.use(
    (response) => {
        return response?.data || response;
    },
    (error) => {
        const errorMessage =
            error.response.data?.error ||
            error.response.data?.detail || // Some APIs use "detail"
            error.response.data?.message || // Some APIs use "message"
            'An error occurred';
        const redirectPath = error.response?.data?.redirect;
        const statusCode =
            error.response?.status || error.response?.status || 400;
        if (redirectPath) {
            // Attach the redirect path to the error object
            error.redirectPath = redirectPath;
        }
        return Promise.reject({
            ...error,
            errorMessage,
            statusCode,
            redirectPath,
        });
    },
);

export const useAxios = (baseURL = CIRCLER_API_URL) => {
    const [sendNotification] = useNotification();
    const [isLoading, setIsLoading] = useState(false);
    // This state is used in the context of initial loading of the component.
    // The reason for using this initial state was that the above state "isLoading" was
    // causing the component to not show the loader because it was initialized with false.
    // Hence there was a sudden jerk of NoData component when the component was loading for the first time.
    // This state is initialized with true and is exported as "true" so to show the Loader component until the
    // data fetch is not completed.
    const [isInitialLoading, setIsInitialLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        setIsInitialLoading(false);
    }, []);

    const handleApiRequest = useCallback(
        (request, notificationSuccess = null, showError = true) => {
            setIsLoading(true);
            return request()
                .then((data) => {
                    if (notificationSuccess) {
                        sendNotification({
                            msg: notificationSuccess,
                            variant: 'success',
                        });
                    }
                    return data;
                })
                .catch((error) => {
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.redirect
                    ) {
                        // Use navigate to redirect to the specified path
                        navigate(error.response.data.redirect);
                    } else if (showError) {
                        sendNotification({
                            msg: error.errorMessage,
                            variant: 'error',
                        });
                    }
                    throw error; // Rethrow the error after handling
                })
                .finally(() => {
                    setIsLoading(false);
                });
        },
        [sendNotification, navigate],
    );

    const get = useCallback(
        (url, params) => {
            return handleApiRequest(() => api.get(url, { params, baseURL }));
        },
        [handleApiRequest, baseURL],
    );

    const post = useCallback(
        (url, data, msg = 'Successfully Created', showError) => {
            return handleApiRequest(
                () =>
                    api.post(url, data, {
                        baseURL,
                    }),
                msg,
                showError,
            );
        },
        [handleApiRequest, baseURL],
    );

    const put = useCallback(
        (url, data, msg = 'Successfully Updated') => {
            return handleApiRequest(
                () =>
                    api.put(url, data, {
                        baseURL,
                    }),
                msg,
            );
        },
        [handleApiRequest, baseURL],
    );

    const patch = useCallback(
        (url, data, msg = 'Successfully Updated') => {
            return handleApiRequest(
                () =>
                    api.patch(url, data, {
                        baseURL,
                    }),
                msg,
            );
        },
        [handleApiRequest, baseURL],
    );

    const remove = useCallback(
        (url, data, msg = 'Successfully Deleted') => {
            return handleApiRequest(
                () =>
                    api.delete(url, {
                        baseURL,

                        data,
                    }),
                msg,
            );
        },
        [handleApiRequest, baseURL],
    );

    return {
        get,
        post,
        put,
        patch,
        remove,
        isLoading: isInitialLoading || isLoading,
    };
};
