import { useSelector } from 'react-redux';
import React, { Fragment, useEffect, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import MatchedRow from './MatchedRow';
import { handleErrorAlert } from 'utils/handleErrorAlert';
import { forReviewAccordionStyles } from '../../../style/style';
import { useInventoryService } from 'store/services/inventory.service';
import { CircleLoading, Typography, Button, SnackBarAlert } from 'shared';
import MatchWithAggregatedFileInventory from './MatchWithAggregatedFileInventory';

const ForReviewAccordion = ({
    rowData,
    onClose,
    gettingInitialInventoryForReview,
}) => {
    const classes = forReviewAccordionStyles();
    const {
        excludePlaidTransaction,
        getFileInventoryByPlaidTransaction,
        matchAggFileInventoryWithPlaidTransaction,
    } = useInventoryService();
    const { id: companyId } = useSelector((state) => state.company.data);

    const [data, setData] = useState(null);
    const [alertData, setAlertData] = useState({
        severity: '',
        open: false,
        message: '',
    });
    const [selectedRow, setSelectedRow] = useState(null);
    const [showMatchingModal, setShowMatchingModal] = useState(false);
    const [matchButtonLoading, setMatchButtonLoading] = useState(false);
    const [excludeButtonLoading, setExcludeButtonLoading] = useState(false);

    const handleFileInventoryByPlaidTransaction = async () => {
        try {
            const response = await getFileInventoryByPlaidTransaction(
                companyId,
                rowData.id,
            );
            setData(response.data);
        } catch (error) {
            handleErrorAlert(setAlertData);
        }
    };

    const handleMatchClick = async () => {
        try {
            setMatchButtonLoading(true);
            const response = await matchAggFileInventoryWithPlaidTransaction({
                companyId,
                plaidTransactionId: rowData.id,
                aggregatedFileInventoryIds: [selectedRow],
            });
            setAlertData({
                severity: 'success',
                message: response.message,
                open: true,
            });
            gettingInitialInventoryForReview();
        } catch (error) {
            handleErrorAlert(setAlertData);
        } finally {
            setMatchButtonLoading(false);
        }
    };

    const handleExcludeButtonClick = async () => {
        try {
            setExcludeButtonLoading(true);
            const response = await excludePlaidTransaction({
                company_id: companyId,
                plaid_transaction_ids: [rowData?.id],
            });
            setAlertData({
                severity: 'success',
                message: response.message,
                open: true,
            });
            gettingInitialInventoryForReview();
        } catch (error) {
            handleErrorAlert(setAlertData);
        } finally {
            setExcludeButtonLoading(false);
        }
    };

    useEffect(() => {
        if (rowData.file_inventory_count) {
            handleFileInventoryByPlaidTransaction();
        }
    }, []);

    return (
        <Box sx={classes.root}>
            {rowData.file_inventory_count == 0 || data ? (
                <Fragment>
                    <Box sx={classes.radioButtonsWrapper}>
                        <Typography text={'Select Match'} />
                        <IconButton sx={classes.crossIcon} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box sx={classes.buttonWrapper}>
                        <Button
                            text="Exclude"
                            size="small"
                            customStyles={classes.button}
                            loading={excludeButtonLoading}
                            onClick={handleExcludeButtonClick}
                        />
                        <Button
                            text="Find Match"
                            size="small"
                            customStyles={classes.button}
                            onClick={() => setShowMatchingModal(true)}
                        />
                        <Button
                            disabled={!selectedRow}
                            text="Match"
                            size="small"
                            onClick={handleMatchClick}
                            loading={matchButtonLoading}
                            customStyles={classes.button}
                        />
                    </Box>
                    {data &&
                        data.map((entry) => (
                            <MatchedRow
                                data={entry}
                                key={entry.id}
                                isSelected={selectedRow === entry.id}
                                onSelect={(rowId) => setSelectedRow(rowId)}
                            />
                        ))}
                </Fragment>
            ) : (
                <CircleLoading />
            )}

            <SnackBarAlert alertData={alertData} setAlertData={setAlertData} />

            {showMatchingModal && (
                <MatchWithAggregatedFileInventory
                    transaction={rowData}
                    showMatchingModal={showMatchingModal}
                    setShowMatchingModal={setShowMatchingModal}
                    gettingInitialInventoryForReview={
                        gettingInitialInventoryForReview
                    }
                />
            )}
        </Box>
    );
};

export default ForReviewAccordion;
