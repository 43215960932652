import { useSelector } from 'react-redux';
import React, { useState, Fragment, useEffect } from 'react';

import { Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import PaymentPlansForm from './PaymentPlanForm';
import PaymentPlansTable from './PaymentPlansTable';
import { handleErrorAlert } from 'utils/handleErrorAlert';
import { useCustomMediaQuery } from 'hooks/useMediaQuery';
import { handleSuccessAlert } from 'utils/handleSuccessAlert';
import { paymentPlansStyles } from 'components/Inventory/style/style';
import { CircleLoading, Typography, Button, SnackBarAlert } from 'shared';
import { usePaymentPlanService } from 'store/services/paymentPlan.service';

const PaymentPlan = () => {
    const { isLoading, getPaymentPlans } = usePaymentPlanService();
    const companyData = useSelector((state) => state.company.data);
    const isMobileScreen = useCustomMediaQuery('(max-width: 600px)');

    const classes = paymentPlansStyles({ isMobileScreen });

    const [page, setPage] = useState(1);
    const [data, setData] = useState(null);
    const [alertData, setAlertData] = useState({
        severity: '',
        open: false,
        message: '',
    });
    const [showPaymentPlanForm, setShowPaymentPlanForm] = useState(false);

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
        handleGetPaymentPlans(pageNumber);
    };

    const handlePaymentPlanSuccess = (message) => {
        try {
            setShowPaymentPlanForm(false);
            handleSuccessAlert(setAlertData, message);
            handleGetPaymentPlans(page, 1);
        } catch (error) {
            handleErrorAlert(setAlertData);
        }
    };

    const handleGetPaymentPlans = async (page) => {
        try {
            const data = await getPaymentPlans(companyData?.id, page);
            setData(data);
        } catch (error) {
            handleErrorAlert(setAlertData);
        }
    };

    const handlePaymentPlanFormCancel = () => {
        setShowPaymentPlanForm(false);
        handleGetPaymentPlans(page);
    };

    useEffect(() => {
        if (companyData) {
            handleGetPaymentPlans(page);
        }
    }, [companyData]);

    return (
        <Box sx={classes.root}>
            {companyData ? (
                <Fragment>
                    {!showPaymentPlanForm && (
                        <Fragment>
                            <Box sx={classes.buttonWrapper}>
                                <Button
                                    text={
                                        <Typography
                                            text={'Add Payment Plan'}
                                            customStyles={classes.addButtonText}
                                        />
                                    }
                                    startIcon={<AddIcon htmlColor={'#FFF'} />}
                                    customStyles={classes.exclusionRuleButton}
                                    onClick={() => setShowPaymentPlanForm(true)}
                                />
                            </Box>
                            <Box>
                                <PaymentPlansTable
                                    page={page}
                                    data={data}
                                    loading={isLoading}
                                    handlePageChange={handlePageChange}
                                />
                            </Box>
                        </Fragment>
                    )}
                    {showPaymentPlanForm && (
                        <PaymentPlansForm
                            type="Create"
                            setAlertData={setAlertData}
                            handlePaymentPlanSuccess={handlePaymentPlanSuccess}
                            handlePaymentPlanFormCancel={handlePaymentPlanFormCancel}
                        />
                    )}
                </Fragment>
            ) : (
                <CircleLoading />
            )}

            <SnackBarAlert alertData={alertData} setAlertData={setAlertData} />
        </Box>
    );
};

export default PaymentPlan;
