import React, { useState } from 'react';

import BankForm from './BankForm';
import { Typography } from 'shared';
import { Card, CardContent, Grid, Box, IconButton } from '@mui/material';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';

import {
    makeStyles,
    balanceStyle,
    bankNameStyle,
    bankNameOverflow,
    balanceTextStyle,
} from '../style/style';

const BankCard = ({ data, selectedBank }) => {
    const classes = makeStyles({
        dataId: data?.id,
        selectedBankId: selectedBank?.id,
    });

    const [openBankForm, setOpenBankForm] = useState(false);

    const formatDate = (isoString) => {
        const date = new Date(isoString);
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        };
        return date.toLocaleDateString('en-US', options);
    };

    return (
        <Card sx={classes.bankCardWrapper}>
            <CardContent sx={classes.bankCardContentWrapper}>
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box sx={classes.bankNameContainer}>
                        <Typography
                            customStyles={{
                                ...bankNameStyle,
                                ...bankNameOverflow,
                            }}
                            text={data?.name}
                        />
                    </Box>
                    <Box>
                        <IconButton size="small" disabled="true">
                            <AccountBalanceOutlinedIcon
                                sx={classes.iconStyle}
                            />
                        </IconButton>
                        <IconButton
                            size="small"
                            onClick={() => setOpenBankForm(true)}
                        >
                            <BorderColorOutlinedIcon sx={classes.iconStyle} />
                        </IconButton>
                    </Box>
                </Grid>

                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box>
                        <Typography
                            customStyles={balanceStyle}
                            text={`$${data?.current_balance}`}
                        />
                        <Typography
                            customStyles={balanceTextStyle}
                            text="Balance"
                        />
                    </Box>
                    <Box>
                        <Typography
                            customStyles={balanceTextStyle}
                            text="Last Synced"
                        />
                        <Typography
                            customStyles={balanceTextStyle}
                            text={formatDate(data?.last_synced)}
                        />
                    </Box>
                </Grid>

                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box>
                        <Typography
                            customStyles={balanceStyle}
                            text={`$${Number(
                                data?.balance_in_circler,
                                0,
                            ).toFixed(2)}`}
                        ></Typography>
                        <Typography
                            customStyles={balanceTextStyle}
                            text="In Circler"
                        ></Typography>
                    </Box>
                    <Box>
                        <Typography
                            customStyles={balanceStyle}
                            text={data.non_imported_transaction_count}
                        ></Typography>
                        <Typography
                            customStyles={balanceTextStyle}
                            text="In Review"
                        ></Typography>
                    </Box>
                </Grid>
            </CardContent>

            {openBankForm && (
                <BankForm
                    type="Edit"
                    open={openBankForm}
                    initialData={selectedBank}
                    handleCloseModal={() => {
                        setOpenBankForm(false);
                    }}
                />
            )}
        </Card>
    );
};

export default BankCard;
