import { Box } from '@mui/material';
import { Upload } from 'components/upload';

export default function FileNewFolderDialog({
    title = 'Upload Files',
    files,
    handleDrop,
    handleRemoveFile,
    isUploadLoading,
    accept = {
        'image/jpeg': [],
        'image/png': [],
        'application/pdf': [],
    },
    placeholder,
    ...other
}) {
    return (
        <Box width="100%" {...other} marginTop={4}>
            <Upload
                multiple
                accept={accept}
                placeholder={placeholder}
                files={files}
                onDrop={handleDrop}
                onRemove={handleRemoveFile}
                disabled={isUploadLoading}
            />
        </Box>
    );
}
