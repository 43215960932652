import * as Yup from 'yup';

export const unimportedTransactionsInitialValues = {
    description: '',
    date: null,
};

export const unimportedTransactionsSchema = Yup.object({
    date: Yup.date().nullable(),
    description: Yup.string().nullable(),
});
