import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import {
    unimportTransactions,
    updateTransactionCoa,
    fetchBankTransactions,
} from 'store/services/bank.Service';
import { handleErrorAlert } from 'utils/handleErrorAlert';
import { useCustomMediaQuery } from 'hooks/useMediaQuery';
import { handleSuccessAlert } from 'utils/handleSuccessAlert';
import { SnackBarAlert, TablePagination, DataTable } from 'shared';
import { importedTransactionsTableHeaders } from '../utils/importedTransactionsTableHeaders';

const ImportedTransactions = ({ selectedBank, coaCategoryData }) => {
    const companyData = useSelector((state) => state.company.data);
    const isMobileScreen = useCustomMediaQuery('(max-width: 600px)');

    const [page, setPage] = useState(1);
    const [data, setData] = useState(null);
    const [alertData, setAlertData] = useState({
        severity: '',
        open: false,
        message: '',
    });
    const [loading, setLoading] = useState(true);
    const [selectedOption, setSelectedOption] = useState(null);

    const handleGetTransactions = async (page) => {
        try {
            setLoading(true);
            const response = await fetchBankTransactions(selectedBank?.id, page, 'imported');
            setData(response);
        } catch (error) {
            handleErrorAlert(setAlertData);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page) => {
        setPage(page);
        handleGetTransactions(page);
    };

    const handleUnimportClick = async (data) => {
        try {
            setLoading(true);
            setSelectedOption(data);
            const response = await unimportTransactions(companyData?.id, [data?.id]);
            handleSuccessAlert(setAlertData, response?.detail);
            await handleGetTransactions(page);
        } catch (error) {
            handleErrorAlert(setAlertData);
        } finally {
            setLoading(false);
        }
    };

    const handleCoaUpdate = async (coa, plaidTransaction) => {
        try {
            setLoading(true);
            await updateTransactionCoa(plaidTransaction?.id, coa?.id);
            await handleGetTransactions(page);
        } catch (error) {
            handleErrorAlert(setAlertData);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        handleGetTransactions(1);
    }, [selectedBank]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <DataTable
                    data={data?.plaid_transactions}
                    isLoading={loading}
                    headCells={importedTransactionsTableHeaders(
                        selectedOption,
                        coaCategoryData,
                        handleCoaUpdate,
                        handleUnimportClick,
                    )}
                />
            </Grid>
            <Grid item xs={12} marginTop="10px">
                <TablePagination
                    page={page}
                    count={data?.total_count || 0}
                    isMobileScreen={isMobileScreen}
                    onChange={(e, pageNumber) => handlePageChange(pageNumber)}
                />
            </Grid>

            <SnackBarAlert alertData={alertData} setAlertData={setAlertData} />
        </Grid>
    );
};

export default ImportedTransactions;
