import React, { useState } from 'react';

import {
    Box,
    Grid,
    Accordion,
    Typography,
    AccordionDetails,
    AccordionSummary,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { extractPlainText } from 'components/Inventory/utils/emailUtils';
import { emailDetailsAccordionStyles } from 'components/Inventory/style/style';

const EmailDetailsAccordion = ({ email }) => {
    const classes = emailDetailsAccordionStyles();

    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = (event) => {
        event.stopPropagation();
        setExpanded(!expanded);
    };

    const handleSummaryClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    return (
        <Box sx={classes.accordionWrapper}>
            <Accordion
                key={email?.id}
                sx={classes.accordion}
                // onChange={() => {}}
                // expanded={expanded}
            >
                <AccordionSummary
                    expandIcon={
                        <ExpandMoreIcon
                        // onClick={handleExpandClick}
                        />
                    }
                    // onClick={handleSummaryClick}
                    // aria-controls="panel-content"
                    // id="panel-header"
                    sx={classes.summary}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <Typography variant="body1" fontWeight="bold">
                                Sender:
                            </Typography>
                            <Typography variant="body2" sx={classes.addToNewLine}>
                                {email?.sender}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="body1" fontWeight="bold">
                                Subject:
                            </Typography>
                            <Typography variant="body2">{email?.subject}</Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography variant="body1" fontWeight="bold">
                                Received At:
                            </Typography>
                            <Typography variant="body2">{email?.timeReceived}</Typography>
                        </Grid>
                    </Grid>
                </AccordionSummary>

                <AccordionDetails sx={classes.accordionDetails}>
                    {email?.stripped_html ? (
                        <Typography
                            component="div"
                            dangerouslySetInnerHTML={{
                                __html: email?.stripped_html,
                            }}
                        />
                    ) : (
                        <Typography variant="body2" sx={classes.noBodyText}>
                            {extractPlainText(email?.body)}
                        </Typography>
                    )}
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default EmailDetailsAccordion;
