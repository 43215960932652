import React from 'react';
import { Formik, Form } from 'formik';

import { Grid } from '@mui/material';

import AggInventoryFilters from './AggInventoryFilters';
import {
    salesActivityDateFields,
    SalesActivitySearchByFields,
} from 'components/Inventory/utils/SelectActivityFilterFields';
import { CircleLoading, DataTable, TablePagination } from 'shared';
import { aggregatedSalesInventoryHeaders } from 'components/Inventory/utils/aggregatedSalesInventoryHeaders';

function ModalSalesActivity({
    initialValues,
    validationSchema,
    unmatchedInventories,
    selectedRows,
    handleCheckboxChange,
    tableLoading,
    page,
    handlePageChange,
    handleGetAggregatedFileInventories,
    isMobileScreen,
    handleClearClick,
}) {
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => handleGetAggregatedFileInventories(1, values, 'sales')}
        >
            {({ values, handleReset, handleChange, setFieldValue }) => (
                <Form>
                    <AggInventoryFilters
                        values={values}
                        handleReset={handleReset}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        handleClearClick={handleClearClick}
                        searchByFields={SalesActivitySearchByFields}
                        dateFields={salesActivityDateFields}
                    />

                    {unmatchedInventories ? (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <DataTable
                                    headCells={aggregatedSalesInventoryHeaders(
                                        selectedRows,
                                        handleCheckboxChange,
                                    )}
                                    isLoading={tableLoading}
                                    data={unmatchedInventories.results}
                                />
                            </Grid>
                            <Grid item xs={12} p={'10px 0'}>
                                <TablePagination
                                    isMobileScreen={isMobileScreen}
                                    page={page}
                                    count={unmatchedInventories?.count}
                                    onChange={(e, pageNumber) =>
                                        handlePageChange(pageNumber, values)
                                    }
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        <CircleLoading />
                    )}
                </Form>
            )}
        </Formik>
    );
}

export default ModalSalesActivity;
