import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import { DataTable, TablePagination } from 'shared';
import { paymentPlanTableHeaders } from 'components/Inventory/utils/paymentPlanTableHeaders';

const PaymentPlansTable = ({
    page,
    data,
    loading,
    handlePageChange,
    isSelectable = false,
    selectedRows = [],
    onSelectionChange = () => {},
}) => {
    const [selectedRow, setSelectedRow] = useState(null);

    const handleRowSelection = (newSelection) => {
        const newSelectedRowId = Object.keys(newSelection)[0];
        if (newSelectedRowId === selectedRow) {
            setSelectedRow(null);
        } else {
            setSelectedRow(newSelectedRowId);
        }
    };

    useEffect(() => {
        if (data?.results) {
            if (selectedRow) {
                const selectedRowData = data.results.find((row) => row.id == selectedRow);
                if (selectedRowData) {
                    onSelectionChange([selectedRowData]);
                }
            } else {
                onSelectionChange([]);
            }
        }
    }, [selectedRow, data?.results]);

    useEffect(() => {
        if (selectedRows.length === 0 && selectedRow !== null) {
            setSelectedRow(null);
        } else if (selectedRows.length === 1 && selectedRows[0]?.id !== selectedRow) {
            setSelectedRow(selectedRows[0].id);
        }
    }, [selectedRows]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <DataTable
                    isSelectable={isSelectable}
                    isLoading={loading}
                    data={data?.results}
                    headCells={paymentPlanTableHeaders.filter((cell) => cell.id !== 'select')}
                    selected={selectedRow ? { [selectedRow]: true } : {}}
                    setSelected={handleRowSelection}
                    showHeaderCheckBox={false}
                />
            </Grid>
            <Grid item xs={12} p={'10px 0'}>
                <TablePagination
                    page={page}
                    count={data?.count}
                    onChange={(e, pageNumber) => handlePageChange(pageNumber)}
                />
            </Grid>
        </Grid>
    );
};

export default PaymentPlansTable;
