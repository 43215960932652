import React, { Fragment, useState } from 'react';

import { NestedMenuItem } from 'mui-nested-menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, Button, CircularProgress, Menu, MenuItem } from '@mui/material';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';

const makeStyles = () => ({
    loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '20px',
    },
    nestedDropdown: {
        fontSize: '13px',
        fontWeight: 400,
        padding: '14px 14px',
        color: (theme) =>
            theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F',
        textAlign: 'left',
        backgroundColor: (theme) =>
            theme.palette.mode === 'dark' ? '#394150' : '#F4F4F6',
        border: `1px solid ${(theme) =>
            theme.palette.mode === 'dark' ? '#394150' : '#E5E6EB'}`,
        borderRadius: '6px',
        textTransform: 'none',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '&:hover': {
            borderColor: '#6C727F',
        },
    },
});

export const CoaNestedDropdown = ({
    selectedOption,
    coaCategoryData,
    setSelectedOption,
}) => {
    const classes = makeStyles();

    const [menuPosition, setMenuPosition] = useState(null);

    const handleOpenMenu = (event) => {
        event.preventDefault();
        setMenuPosition({
            top: event.pageY,
            left: event.pageX,
        });
    };

    const handleItemClick = (item) => {
        setMenuPosition(null);
        setSelectedOption(item);
    };

    const renderMenu = (items) => (
        <Fragment>
            {items.map((item, index) => {
                if (item.children && item.children.length > 0) {
                    return (
                        <NestedMenuItem
                            key={index}
                            label={item.title}
                            parentMenuOpen={!!menuPosition}
                        >
                            {renderMenu(item.children)}
                        </NestedMenuItem>
                    );
                }

                return (
                    <MenuItem key={index} onClick={() => handleItemClick(item)}>
                        {item.isParent && (
                            <SubdirectoryArrowRightIcon fontSize="small" />
                        )}{' '}
                        {item.title}
                    </MenuItem>
                );
            })}
        </Fragment>
    );

    return (
        <Fragment>
            <Button
                onClick={handleOpenMenu}
                sx={classes.nestedDropdown}
                endIcon={
                    <ArrowDropDownIcon
                        style={{
                            fontWeight: 400,
                            color: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '#9EA3AE'
                                    : '#6C727F',
                            transform: menuPosition
                                ? 'rotate(180deg)'
                                : 'rotate(0deg)',
                            transition: 'transform 0.3s ease',
                        }}
                    />
                }
            >
                {selectedOption.title}
            </Button>

            <Menu
                open={!!menuPosition}
                onClose={() => setMenuPosition(null)}
                anchorReference="anchorPosition"
                anchorPosition={menuPosition}
            >
                {coaCategoryData.loading ? (
                    <Box sx={classes.loader}>
                        <CircularProgress />
                    </Box>
                ) : (
                    renderMenu(coaCategoryData.children)
                )}
            </Menu>
        </Fragment>
    );
};
