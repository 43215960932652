import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';

import { useCompanyService } from 'store/services/company.service';
import { Modal, Button, CircleLoading, EmailAccordion } from 'shared';

const EmailSourceModal = ({ transaction, showEmailSourceModal, setShowEmailSourceModal }) => {
    const companyData = useSelector((state) => state.company.data);
    const { isLoading, getCompanyEmails } = useCompanyService();

    const [emails, setEmails] = useState(null);

    const handleGetInventorySource = async () => {
        try {
            const response = await getCompanyEmails(
                companyData?.id,
                transaction?.amount,
                transaction?.date,
            );
            setEmails(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (companyData) {
            handleGetInventorySource();
        }
    }, [companyData]);

    return (
        <Modal
            fullWidth={true}
            open={showEmailSourceModal}
            title="Inventory Source"
            handleClose={() => setShowEmailSourceModal(false)}
            customWidth="75%"
            showFooter
            footer={
                <Box>
                    <Button text="Cancel" onClick={() => setShowEmailSourceModal(false)} />
                </Box>
            }
        >
            {isLoading && !emails ? (
                <CircleLoading />
            ) : !emails?.length ? (
                <Box display="flex" alignItems="center" marginBottom="10px" justifyContent="center">
                    <Typography>No source email found</Typography>
                </Box>
            ) : (
                <EmailAccordion emails={emails} />
            )}
        </Modal>
    );
};

export default EmailSourceModal;
