import * as Yup from 'yup';

import { ACCOUNT_TYPES_1, ACCOUNT_TYPES_2 } from 'utils/helpers';

export const nonQbAccountsInitialValues = (accounts) => ({
    accounts: accounts.map((account) => ({
        id: account.id,
        name: account.name,
        account_number: account.customer_chart_of_account?.account_number || '',
        parent_account: account.customer_chart_of_account?.parent_account || null,
        account_type_1: account.customer_chart_of_account?.account_type_1 || '',
        account_type_2: account.customer_chart_of_account?.account_type_2 || '',
    })),
});

const account_type_1_values = ACCOUNT_TYPES_1.map((option) => option.value);
const account_type_2_values = Object.values(ACCOUNT_TYPES_2)
    .flat()
    .map((option) => option.value);

export const nonQbAccountsSchema = Yup.object().shape({
    accounts: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required('Account name is required'),
            account_number: Yup.string().required('Account number is required'),
            parent_account: Yup.string().required(),
            account_type_1: Yup.string()
                .nullable()
                .when('parent_account', {
                    is: (val) => !!val,
                    then: (schema) =>
                        schema
                            .required('Account type 1 is required')
                            .oneOf(account_type_1_values, 'Invalid Account type 1'),
                    otherwise: (schema) => schema.nullable(),
                }),
            account_type_2: Yup.string()
                .nullable()
                .when('parent_account', {
                    is: (val) => !!val,
                    then: (schema) =>
                        schema
                            .required('Account type 2 is required')
                            .oneOf(account_type_2_values, 'Invalid Account type 2'),
                    otherwise: (schema) => schema.nullable(),
                }),
        }),
    ),
});
