import React, { useState } from 'react';

import {
    Box,
    Grid,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Switch,
    Divider,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { manageBankConnectionsStyles } from '../style/style';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

const LinkedAccountsAccordion = ({ data }) => {
    const [expanded, setExpanded] = useState(true);
    const [toggles, setToggles] = useState(
        data.accounts.reduce((acc, _, idx) => ({ ...acc, [idx]: true }), {}),
    );

    const classes = manageBankConnectionsStyles();

    const handleToggle = (index) => {
        setToggles((prev) => ({ ...prev, [index]: !prev[index] }));
    };

    return (
        <Box sx={classes.accordionWrapper}>
            <Box mt={2}>
                <Accordion
                    expanded={expanded}
                    sx={classes.accordion}
                    onChange={() => setExpanded(!expanded)}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item>
                                <AccountBalanceIcon fontSize="large" />
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle1" fontWeight="bold">
                                    {data.bankName}
                                </Typography>
                                <Typography variant="caption">Added on {data.addedOn}</Typography>
                            </Grid>
                        </Grid>
                    </AccordionSummary>

                    <AccordionDetails>
                        {data.accounts.map((account, index) => (
                            <React.Fragment key={index}>
                                <Grid
                                    container
                                    justifyContent="space-between"
                                    alignItems="center"
                                    py={1}
                                >
                                    <Grid item xs={6}>
                                        <Typography variant="body1">{account.name}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography
                                            variant="body2"
                                            color={account.balance < 0 ? 'error' : 'text.primary'}
                                        >
                                            $
                                            {account.balance.toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Switch
                                            checked={toggles[index]}
                                            onChange={() => handleToggle(index)}
                                        />
                                    </Grid>
                                </Grid>
                                {index !== data.accounts.length - 1 && <Divider />}
                            </React.Fragment>
                        ))}
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Box>
    );
};

export default LinkedAccountsAccordion;
