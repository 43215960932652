export const extractChildren = (data) => {
    if (!data || typeof data !== 'object') return [];

    return Object.keys(data).map((key) => ({
        name: key,
        total: data[key].amount,
        children: extractChildrenFromContent(data[key].content),
    }));
};

export const extractChildrenFromContent = (content) => {
    if (!Array.isArray(content) || content.length === 0) return [];

    return content.map((item) => ({
        name: item.name,
        total: item.amount,
        children: extractChildrenFromContent(item.content),
    }));
};

export const formatLabel = (label) => {
    if (!label || typeof label !== 'string') return '';

    // Mapping for known cases
    const labelMappings = {
        asset: 'Assets',
        equity: 'Equity',
        expense: 'Expenses',
        gain_loss: 'Gain Loss',
        revenue: 'Revenue',
        liability: 'Liabilities',
    };

    // Return mapped label if it exists
    if (labelMappings[label.toLowerCase()]) {
        return labelMappings[label.toLowerCase()];
    }

    // Otherwise, format normally (replace underscores and capitalize)
    return label
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

export const formatApiResponse = (responseData) => {
    if (!responseData || !responseData.data) return [];

    return Object.entries(responseData.data)
        .map(([key, value]) => {
            if (Array.isArray(value)) {
                // ✅ Flat List: Equity, Revenue, Gain/Loss (No Nested Categories)
                return {
                    name: formatLabel(key), // "Equity" → "Equity"
                    children: value.map((account) => ({
                        name: account.account_name,
                        total: account.amount || 0,
                        account_id: account.account_id,
                        children: [],
                    })),
                };
            } else if (typeof value === 'object' && value !== null) {
                // ✅ Nested Structure: Assets, Liabilities, Expenses (Has Categories)
                const hasNestedCategories = Object.values(value).some(
                    Array.isArray,
                );

                if (hasNestedCategories) {
                    // ✅ Case: Normal Assets/Liabilities with categories (Current/Non-Current)
                    return {
                        name: formatLabel(key), // "Assets", "Liabilities"
                        children: Object.entries(value).map(
                            ([category, accounts]) => ({
                                name: formatLabel(category), // "Current", "Non-Current"
                                children: (Array.isArray(accounts)
                                    ? accounts
                                    : []
                                ).map((account) => ({
                                    name: account.account_name,
                                    total: account.amount || 0,
                                    account_id: account.account_id,
                                    children: [],
                                })),
                            }),
                        ),
                    };
                } else {
                    // ✅ Case: Unexpected object structure (fallback to treating it as flat)
                    return {
                        name: formatLabel(key),
                        children: Object.entries(value).map(
                            ([category, amount]) => ({
                                name: formatLabel(category),
                                total: amount || 0,
                                children: [],
                            }),
                        ),
                    };
                }
            }
        })
        .filter(Boolean); // ✅ Remove undefined values (if any unexpected nulls exist)
};
