import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';

import { Box, Grid } from '@mui/material';

import PurchaseFilters from './PurchaseFilters';
import { Modal, Button, DataTable, TablePagination } from 'shared';
import { useInventoryService } from 'store/services/inventory.service';
import { paymentPlanFormStyles } from 'components/Inventory/style/style';
import { purchaseTableModalHeaders } from 'components/Inventory/utils/purchaseTableHeaders';

const PurchaseModal = ({
    purchaseData,
    showPurchaseModal,
    setShowPurchaseModal,
    handlePurchaseModalSubmit,
}) => {
    const classes = paymentPlanFormStyles();
    const { id: companyId } = useSelector((state) => state.company.data);
    const { isLoading, getAggregatedFileInventories } = useInventoryService();

    const [page, setPage] = useState(1);
    const [data, setData] = useState(null);
    const [filterValues, setFilterValues] = useState({});
    const [selectedRows, setSelectedRows] = useState(purchaseData);

    const handleGetAggregatedFileInventories = async (page, filters = {}) => {
        try {
            const response = await getAggregatedFileInventories({
                companyId,
                page,
                isReviewed: 'False',
                startDate: filters.startDate,
                endDate: filters.endDate,
                purchaseId: filters.purchaseId,
                activityType: 'purchase',
            });
            setData(response);
        } catch (error) {
            console.error(error);
        }
    };

    const handlePageChange = (page) => {
        setPage(page);
        handleGetAggregatedFileInventories(page, filterValues);
    };

    const handleGetInitialData = async (values) => {
        try {
            setPage(1);
            setFilterValues(values);
            handleGetAggregatedFileInventories(1);
        } catch (error) {
            console.error(error);
        }
    };

    const handleFiltersSubmit = async (values) => {
        try {
            setPage(1);
            setFilterValues(values);
            handleGetAggregatedFileInventories(1, values);
        } catch (error) {
            console.error(error);
        }
    };

    const handleCheckboxChange = (purchaseActivity) => {
        setSelectedRows((prevSelected) => {
            const isSelected = prevSelected?.some((item) => item.id === purchaseActivity.id);
            let updatedSelectedRows;

            if (isSelected) {
                updatedSelectedRows = prevSelected?.filter(
                    (item) => item.id !== purchaseActivity.id,
                );
            } else {
                updatedSelectedRows = [...prevSelected, purchaseActivity];
            }

            return updatedSelectedRows;
        });
    };

    useEffect(() => {
        handleGetAggregatedFileInventories(page);
    }, []);

    return (
        <Modal
            fullWidth={true}
            open={showPurchaseModal}
            title="Select Purchase Activity"
            handleClose={() => setShowPurchaseModal(false)}
            customWidth="90%"
            showFooter={true}
            footer={
                <Box display="flex" justifyContent="flex-end" gap={1}>
                    <Button
                        text="Cancel"
                        customStyles={classes.cancelButton}
                        onClick={() => setShowPurchaseModal(false)}
                    />
                    <Button text="Add" onClick={() => handlePurchaseModalSubmit(selectedRows)} />
                </Box>
            }
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <PurchaseFilters
                        handleFiltersSubmit={handleFiltersSubmit}
                        handleGetInitialData={handleGetInitialData}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DataTable
                        data={data?.results}
                        headCells={purchaseTableModalHeaders(selectedRows, handleCheckboxChange)}
                        isLoading={isLoading}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TablePagination
                        page={page}
                        count={data?.count}
                        onChange={(event, pageNumber) => handlePageChange(pageNumber)}
                    />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default PurchaseModal;
