import * as Yup from 'yup';

export const purchaseActivityFiltersInitialValues = {
    startDate: null,
    endDate: null,
    amount: '',
    status: { value: 'all', label: 'All' },
};

export const purchaseActivityFiltersSchema = Yup.object({
    endDate: Yup.date().nullable(),
    amount: Yup.number().nullable(),
    startDate: Yup.date().nullable(),
    status: Yup.object()
        .shape({
            value: Yup.string().required('Type value is required'),
            label: Yup.string().required('Type label is required'),
        })
        .nullable(),
});
