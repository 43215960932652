import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';

import { useAuth } from 'hooks/useAuth';
import { Modal, CircleLoading } from 'shared';
import QbAccountsForm from './QbBankAccountsForm';
import NonQbAccountsForm from './NonQbAccountsForm';
import { useCoreService } from 'store/services/core.service';
import { updateBulkBankAccounts } from 'store/services/bank.Service';

const AllBanksUpdateForm = ({
    accounts,
    setShowAllBanksUpdateForm,
    handleBulkAccountUpdateSuccess,
}) => {
    const { user } = useAuth();
    const companyData = useSelector((state) => state.company.data);
    const { isLoading, getCompanyChartOfAccountsList } = useCoreService();

    const [coas, setCoas] = useState(null);
    const [buttonLoading, setButtonLoading] = useState(false);

    const handleCompanyCoas = async () => {
        try {
            const response = await getCompanyChartOfAccountsList(companyData?.id);
            setCoas(response);
        } catch (error) {
            console.error(error);
        }
    };

    const handleBlukAccountsUpdate = async (accounts_data) => {
        try {
            setButtonLoading(true);
            if (accounts_data?.length) {
                const data = { companyId: companyData?.id, data: accounts_data };
                const response = await updateBulkBankAccounts(data);
                handleBulkAccountUpdateSuccess(response?.message);
            } else {
                setShowAllBanksUpdateForm(false);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setButtonLoading(false);
        }
    };

    useEffect(() => {
        if (companyData) {
            handleCompanyCoas();
        }
    }, [companyData]);

    return (
        <Modal
            fullWidth
            open={true}
            customWidth="90%"
            title="Update Bank Accounts"
            handleClose={() => setShowAllBanksUpdateForm(false)}
        >
            {isLoading ? (
                <CircleLoading />
            ) : user.data.qb_connected ? (
                <QbAccountsForm
                    coas={coas.filter((coa) => coa.source == 'quickbooks')}
                    accounts={accounts}
                    loading={buttonLoading}
                    handleBlukAccountsUpdate={handleBlukAccountsUpdate}
                />
            ) : (
                <NonQbAccountsForm
                    coas={[{ id: null, label: 'No Parent Account' }, ...coas]}
                    accounts={accounts}
                    loading={buttonLoading}
                    handleBlukAccountsUpdate={handleBlukAccountsUpdate}
                />
            )}
        </Modal>
    );
};

export default AllBanksUpdateForm;
