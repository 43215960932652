import * as Yup from 'yup';

export const payemntPlanInitialValues = (data) => {
    return {
        name: data?.name || null,
        notes: data?.notes || null,
        scheduledPayments: data?.scheduledPayments || [],
    };
};

export const payemntPlanSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    notes: Yup.string().nullable().optional(),
    scheduledPayments: Yup.array().of(
        Yup.object().shape({
            date: Yup.date().required(),
            amount: Yup.number().required(),
        }),
    ),
});
