import { useAxios } from '../../hooks/useAxios';

export const useDivvyService = () => {
    const { post, isLoading, get, put } = useAxios();

    const addDivvyToken = (data) => post('/divvy/add-token/', data, '', false);

    const addDivvyCards = (data) => post('/divvy/store-cards/', data, '', false);

    const addDivvyCardTransactions = (data) =>
        post('/divvy/store-card-transactions/', data, '', false);

    const getDivvyToken = (company_id) => get(`/divvy/get-divvy-token/?company_id=${company_id}`);

    const updateDivvyToken = (data) => put('/divvy/update-divvy-token/', data, '', false);
    return {
        isLoading,
        addDivvyToken,
        addDivvyCards,
        addDivvyCardTransactions,
        getDivvyToken,
        updateDivvyToken,
    };
};
