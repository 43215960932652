import { Formik, Form } from 'formik';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import { WarningAmberRounded } from '@mui/icons-material';
import { Box, Grid, Button, Checkbox, FormControlLabel } from '@mui/material';

import { useAuth } from 'hooks/useAuth';
import { handleErrorAlert } from 'utils/handleErrorAlert';
import { updateAccount } from 'store/services/bank.Service';
import { useCoreService } from 'store/services/core.service';
import { handleSuccessAlert } from 'utils/handleSuccessAlert';
import { bankFormSchema, bankFormInitialValues } from '../utils/bankFormSchema';
import { Modal, TextField, Typography, Autocomplete, CircleLoading, SnackBarAlert } from 'shared';

const BankForm = ({ type, open, initialData, handleCloseModal }) => {
    const { user } = useAuth();
    const { getCompanyChartOfAccountsList } = useCoreService();
    const companyData = useSelector((state) => state.company.data);

    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [alertData, setAlertData] = useState({
        severity: '',
        open: false,
        message: '',
    });
    const [updateLoading, setUpdateLoading] = useState(false);

    const handleSubmit = async (values) => {
        const {
            connectToQB,
            accountName,
            description,
            isSubAccount,
            parentAccount,
            accountNumber,
            quickBooksAccount,
        } = values;
        try {
            setUpdateLoading(true);
            const data = {
                accountName,
                description,
                isSubAccount,
                parentAccount,
                accountNumber,
                id: initialData?.id,
                company_id: companyData.id,
                connectToQB,
                quickBooksAccount,
            };
            const response = await updateAccount(data);
            handleSuccessAlert(setAlertData, response?.message);
            handleCloseModal();
        } catch (error) {
            handleErrorAlert(setAlertData);
        }
    };

    const handleCompanyCoas = async () => {
        try {
            const response = await getCompanyChartOfAccountsList(companyData?.id);
            setAccounts(response);
        } catch (error) {
            handleErrorAlert(setAlertData);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        handleCompanyCoas();
    }, []);

    return (
        <Modal
            fullWidth
            open={open}
            title={`${type} Bank Account`}
            actionBtnTitle={<Typography text={type === 'Add' ? 'Create' : 'Update'} />}
            handleClose={handleCloseModal}
        >
            {loading ? (
                <CircleLoading />
            ) : (
                <Formik
                    initialValues={bankFormInitialValues(initialData)}
                    validationSchema={bankFormSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, setFieldValue, errors, touched }) => (
                        <Form>
                            <Box width="100%" gap={2} mt={1} border="none">
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            name="accountName"
                                            placeholder="Account Name"
                                            value={values.accountName}
                                            onChange={(e) =>
                                                setFieldValue('accountName', e.target.value)
                                            }
                                            error={touched.accountName && !!errors.accountName}
                                            helperText={touched.accountName && errors.accountName}
                                            disabled={values.connectToQB}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            name="accountNumber"
                                            placeholder="Account Number"
                                            value={values.accountNumber}
                                            onChange={(e) =>
                                                setFieldValue('accountNumber', e.target.value)
                                            }
                                            error={touched.accountNumber && !!errors.accountNumber}
                                            helperText={
                                                touched.accountNumber && errors.accountNumber
                                            }
                                            disabled={values.connectToQB}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            name="description"
                                            placeholder="Description"
                                            value={values.description}
                                            onChange={(e) =>
                                                setFieldValue('description', e.target.value)
                                            }
                                            error={touched.description && !!errors.description}
                                            helperText={touched.description && errors.description}
                                            disabled={values.connectToQB}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values.isSubAccount}
                                                    onChange={(e) =>
                                                        setFieldValue(
                                                            'isSubAccount',
                                                            e.target.checked,
                                                        )
                                                    }
                                                />
                                            }
                                            label="Is Sub Account"
                                            disabled={values.connectToQB}
                                        />
                                    </Grid>

                                    {values.isSubAccount && (
                                        <Grid item xs={12}>
                                            <Autocomplete
                                                options={accounts}
                                                labelKey="label"
                                                valueKey="id"
                                                placeholder="Select Parent Account"
                                                name="parentAccount"
                                                value={accounts?.find(
                                                    (acc) => acc.id === values.parentAccount,
                                                )}
                                                onChange={(e, value) =>
                                                    setFieldValue(
                                                        'parentAccount',
                                                        value ? value.id : null,
                                                    )
                                                }
                                                isSearchField
                                                disableClearable
                                                error={
                                                    touched.parentAccount && !!errors.parentAccount
                                                }
                                                helperText={
                                                    touched.parentAccount && errors.parentAccount
                                                }
                                                disabled={values.connectToQB}
                                            />
                                        </Grid>
                                    )}

                                    {user.data.qb_connected && (
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="connectToQB"
                                                        checked={values.connectToQB}
                                                        onChange={(e) => {
                                                            setFieldValue(
                                                                'connectToQB',
                                                                e.target.checked,
                                                            );
                                                        }}
                                                    />
                                                }
                                                label="Connect to QuickBooks Account"
                                            />
                                        </Grid>
                                    )}
                                </Grid>

                                {values.connectToQB && (
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            options={accounts?.filter(
                                                (account) => account.source == 'quickbooks',
                                            )}
                                            labelKey="label"
                                            valueKey="id"
                                            placeholder="Select Quickbooks Account"
                                            value={
                                                accounts.find(
                                                    (acc) => acc.id === values.quickBooksAccount,
                                                ) || null
                                            }
                                            isSearchField
                                            disableClearable
                                            onChange={(_, value) =>
                                                setFieldValue(
                                                    'quickBooksAccount',
                                                    value ? value.id : null,
                                                )
                                            }
                                            error={
                                                touched.quickBooksAccount &&
                                                !!errors.quickBooksAccount
                                            }
                                            helperText={
                                                touched.quickBooksAccount &&
                                                errors.quickBooksAccount
                                            }
                                        />
                                    </Grid>
                                )}

                                {values.connectToQB && (
                                    <Grid container alignItems="center" sx={{ mt: 2 }}>
                                        <WarningAmberRounded
                                            sx={{
                                                color: 'orange',
                                                mr: 1,
                                                fontSize: '20px',
                                            }}
                                        />
                                        <Typography
                                            variant="body2"
                                            sx={{ color: 'orange' }}
                                            text={`This will delete the current account
                                            and all activities will be linked to
                                            the account selected.`}
                                        />
                                    </Grid>
                                )}
                            </Box>
                            <Box display="flex" justifyContent="flex-end" marginTop={3} gap={2}>
                                <Button onClick={handleCloseModal}>Cancel</Button>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    loading={updateLoading}
                                >
                                    {type === 'Add' ? 'Create' : 'Update'}
                                </LoadingButton>
                            </Box>
                        </Form>
                    )}
                </Formik>
            )}
            <SnackBarAlert alertData={alertData} setAlertData={setAlertData} />
        </Modal>
    );
};

export default BankForm;
