import dayjs from 'dayjs';
import React from 'react';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import {
    Box,
    Button,
    Dialog,
    DialogContent,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

dayjs.extend(isSameOrAfter);

const AddBankStartDateModal = ({
    open,
    onClose,
    selectedDate,
    handleDateChange,
    isCreateLinkLoading,
    createPlaidLinkToken,
}) => {
    const theme = useTheme();
    const lastYearStart = dayjs().subtract(1, 'year').startOf('year');
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="import-dialog-title"
            maxWidth="md"
            fullWidth
            sx={{
                '& .MuiDialog-paper': {
                    minHeight: '400px',
                    borderRadius: '16px',
                },
            }}
        >
            <DialogContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    minHeight: '400px',
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                }}
            >
                <Box sx={{ padding: 1 }}>
                    <Typography
                        sx={{
                            color: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? 'white'
                                    : '#121826',
                            fontWeight: '700',
                            fontSize: '18px',
                        }}
                    >
                        Add New Bank Account
                    </Typography>

                    <Box sx={{ width: { xs: '100%', md: '100%' } }} mt={3}>
                        <Typography
                            sx={{
                                color: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'white'
                                        : '#121826',
                                fontWeight: '700',
                                fontSize: '32px',
                                lineHeight: '38px',
                            }}
                        >
                            How far back should we import your transactions from
                            the new bank account?
                        </Typography>

                        <Typography
                            sx={{
                                fontSize: '13px',
                                fontWeight: '500',
                                mb: '5px',
                                color: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#D3D5DA'
                                        : '#121826',
                            }}
                            mt={2}
                        >
                            Import starting from
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker
                                    disableFuture
                                    shouldDisableDate={(date) => {
                                        return (
                                            dayjs(date).isBefore(
                                                lastYearStart,
                                                'day',
                                            ) || // Disable dates older than 365 days
                                            dayjs(date).isSameOrAfter(
                                                dayjs(),
                                                'day',
                                            ) // Disable future dates
                                        );
                                    }}
                                    disableHighlightToday
                                    l="Select Date"
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    renderInput={(params) => (
                                        <TextField {...params} size="small" />
                                    )}
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="end"
                        gap={2}
                        mt={5}
                        width="100%"
                    >
                        <Button
                            sx={{
                                border: '1px solid #394150',
                                color: '#6C727F',
                                borderRadius: '8px',
                                outline: 'none',
                                textTransform: 'none',
                            }}
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            loading={isCreateLinkLoading}
                            fullWidth={isSmallScreen}
                            sx={{
                                backgroundColor: '#0061DB',
                                color: 'white',
                                textTransform: 'none',
                                borderRadius: '8px',
                                paddingX: '20px',
                                '&:hover': {
                                    backgroundColor: '#0056b3',
                                    '@media (hover: hover)': {
                                        backgroundColor: '#0056b3',
                                    },
                                },
                            }}
                            onClick={() => createPlaidLinkToken(selectedDate)}
                        >
                            Add Bank Account
                        </LoadingButton>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default AddBankStartDateModal;
