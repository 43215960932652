import { Button, IconButton } from '@mui/material';
import SourceIcon from '@mui/icons-material/Source';

export const forReviewTableHeaders = (handleSourceClick) => {
    return [
        {
            id: 'date',
            label: 'Date',
            sort: true,
            verticalAlign: 'top',
        },
        {
            id: 'bank_account__name',
            label: 'Account',
            sort: false,
            verticalAlign: 'top',
        },
        {
            id: 'description',
            label: 'Description',
            sort: false,
            verticalAlign: 'top',
            maxWidth: '500px',
            render: (row) =>
                `${row.description}${
                    row.card_data?.card_number ? ` (x${row.card_data?.card_number})` : ''
                }`,
        },
        {
            id: 'amount',
            label: 'Total',
            sort: false,
            verticalAlign: 'top',
            render: (row) => `${row.currency_code} ${row.amount}`,
        },
        {
            id: 'action',
            label: 'Action',
            sort: false,
            verticalAlign: 'top',
            render: (row) => (
                <Button>
                    {row?.file_inventory_count
                        ? `${row?.file_inventory_count} Match${
                              row?.file_inventory_count > 1 ? 'es' : ''
                          }`
                        : `Find Match`}
                </Button>
            ),
        },
        {
            id: 'source',
            label: 'Source',
            sort: false,
            render: (row) => (
                <IconButton onClick={() => handleSourceClick(row)} sx={{ padding: 0 }}>
                    <SourceIcon fontSize="small" />
                </IconButton>
            ),
        },
    ];
};
