import dayjs from 'dayjs';

import { CircularProgress, IconButton } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export const exclusionRuleTableHeaders = (handleDeleteClick, loadingRowId) => {
    return [
        {
            id: 'account',
            label: 'Account Name',
            sort: false,
            verticalAlign: 'top',
            render: (row) => `${row.bank_account_name || 'All accounts'} `,
        },
        {
            id: 'field',
            label: 'Field',
            sort: false,
            verticalAlign: 'top',
        },
        {
            id: 'operator',
            label: 'Operator',
            sort: false,
            verticalAlign: 'top',
        },
        {
            id: 'text',
            label: 'Value',
            sort: false,
            verticalAlign: 'top',
        },
        {
            id: 'created',
            label: 'Created',
            sort: false,
            verticalAlign: 'top',
            render: (row) => `${dayjs(row.created_at).format('YYYY-MM-DD')} `,
        },
        {
            id: 'action',
            label: '',
            sort: false,
            verticalAlign: 'top',
            render: (row) => (
                <IconButton
                    onClick={() => handleDeleteClick(row?.id)}
                    disabled={loadingRowId === row?.id}
                    sx={{ padding: 0 }}
                >
                    {loadingRowId === row?.id ? (
                        <CircularProgress size={24} color="inherit" />
                    ) : (
                        <DeleteForeverIcon fontSize="small" />
                    )}
                </IconButton>
            ),
        },
    ];
};
