import * as Yup from 'yup';

export const InventoryRowSchema = Yup.object().shape({
    purchase_id: Yup.string().optional().nullable(),
    reference_id: Yup.string().optional().nullable(),
    sale_reference_id: Yup.string().optional().nullable(),
    description: Yup.string().required('Description is required'),
    class_1: Yup.string().optional().nullable(),
    class_2: Yup.string().optional().nullable(),
    class_3: Yup.string().optional().nullable(),
    purchase_price: Yup.number().typeError('Must be a number').required('Required'),
    status: Yup.string().oneOf(['active', 'sold'], 'Status must be Active or Sold'),
    expires_at: Yup.date().required('Expiration date is required').typeError('Invalid date'),
    purchased_at: Yup.date(),
    payment_last_digits: Yup.string().optional().nullable(),
});
