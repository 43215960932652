import { Box } from '@mui/material';
import { ChatHeader } from './ChatHeader';
import { ChatInput } from './ChatInput';
import FileNewFolderDialog from './FileNewFolderDialog';

const styles = {
    chatPanel: {
        display: 'flex',
        padding: '24px',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '12px',
        background: (theme) => theme.backgroundColor.primary,
    },
};

const Chat = ({
    files,
    handleDrop,
    handleRemoveFile,
    handleRemoveAllFiles,
    handleUpload,
    isUploadLoading,
    themeMode,
    accept,
    placeholder,
}) => {
    return (
        <Box sx={styles.chatPanel}>
            <ChatHeader isDarkMode={themeMode === 'dark'} />

            <FileNewFolderDialog
                files={files}
                handleDrop={handleDrop}
                handleRemoveFile={handleRemoveFile}
                isUploadLoading={isUploadLoading}
                placeholder={placeholder}
                accept={accept}
            />
            <ChatInput
                files={files}
                handleRemoveAllFiles={handleRemoveAllFiles}
                handleUpload={handleUpload}
                isUploadLoading={isUploadLoading}
            />
        </Box>
    );
};

export default Chat;
