import { LoadingButton } from '@mui/lab';
import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { CURRENCIES } from 'utils/data';
import { formatAccountTitleRow } from 'utils/helpers';
import NestedDropDown from '../ImportBankTransactionOld/Components/ImportTransactionSelect/NestedDropDown';

const AddNewTransactionModal = ({
    open,
    onClose,
    onSave,
    description,
    handleDescriptionChange,
    panelData,
    selectedAccount,
    companyId,
    onPanelListItemClick,
    openDropDownIndex,
    handleDropDownToggle,
    isPanelDataLoading,
    handleDropDownClose,
    transactionDate,
    setTransactionDate,
    selectedCurrency,
    handleCurrencyChange,
    selectedPaymentStatus,
    handlePaymentStatusChange,
    handleDropdownOpen,
    amount,
    handleAmountChange,
    isPaymentLoading,
    paymentStatuses,
    errors,
    isSubmitLoading,
}) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            sx={{
                '& .MuiDialog-paper': {
                    borderRadius: '15px',
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                },
            }}
        >
            <DialogTitle
                sx={{
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                    color: (theme) =>
                        theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
                }}
            >
                Add New Transaction
            </DialogTitle>
            <DialogContent
                sx={{
                    minHeight: '270px',
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': {
                        width: '8px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'dark' ? '#555' : '#888',
                        borderRadius: '8px',
                        border: '2px solid transparent',
                        backgroundClip: 'content-box',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'dark' ? '#777' : '#555',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#2D3B4E'
                                : '#f1f1f1',
                        borderRadius: '8px',
                    },
                }}
            >
                <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    gap={2}
                    mt={1}
                    border="none"
                    borderRadius="8px"
                    position="relative"
                    sx={{
                        border: 'none',
                    }}
                >
                    <Grid container spacing={3} alignItems="end">
                        <Grid item xs={12}>
                            <TextField
                                error={errors?.description}
                                label="Description"
                                type="text"
                                value={description}
                                onChange={handleDescriptionChange}
                                fullWidth
                                variant="outlined"
                                size="small"
                                sx={{
                                    position: 'relative',
                                    bgcolor: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '#394150'
                                            : '#F4F4F6',
                                    borderRadius: '6px',
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? 'none'
                                                    : '#E5E6EB',
                                            borderRadius: '8px',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? 'none'
                                                    : '#E5E6EB',
                                            borderRadius: '8px',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? 'none'
                                                    : '#E5E6EB',
                                            borderRadius: '8px',
                                        },
                                    },
                                }}
                            />
                            {errors?.description && (
                                <FormHelperText
                                    error
                                    sx={{ position: 'absolute' }}
                                >
                                    {errors?.description}
                                </FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    position: 'relative',
                                    bgcolor: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '#394150'
                                            : '#F4F4F6',
                                    border: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '1px solid #394150'
                                            : '1px solid #E5E6EB',
                                    borderRadius: '6px',
                                }}
                            >
                                <NestedDropDown
                                    item={panelData}
                                    title={formatAccountTitleRow(
                                        selectedAccount,
                                    )}
                                    plaidId={0}
                                    companyId={companyId}
                                    onItemClick={onPanelListItemClick}
                                    rowIndex={1}
                                    transactionId={1}
                                    changeDropDownLength={() => {}}
                                    isDropDownOpen={1 === openDropDownIndex}
                                    handleDropDownToggle={handleDropDownToggle}
                                    isPanelDataLoading={isPanelDataLoading}
                                    handleDropDownClose={handleDropDownClose}
                                    arrowDown={false}
                                    onRight
                                />
                            </Box>
                            {errors?.account && (
                                <FormHelperText
                                    error
                                    sx={{ position: 'absolute' }}
                                >
                                    {errors?.account}
                                </FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker
                                        label="Transaction Date"
                                        value={transactionDate}
                                        onChange={(newValue) =>
                                            setTransactionDate(newValue)
                                        }
                                        disableFuture
                                        disableHighlightToday
                                        sx={{
                                            '& .MuiInputBase-root': {
                                                backgroundColor: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? '#394150'
                                                        : '#F4F4F6',
                                                height: '40px',
                                                minHeight: '40px',
                                                '& input': {
                                                    padding: '8px 12px',
                                                },
                                            },
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                size="small"
                                                variant="outlined"
                                                sx={{
                                                    bgcolor: (theme) =>
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? '#394150'
                                                            : '#F4F4F6',
                                                    borderRadius: '16px',
                                                    '& .MuiOutlinedInput-root':
                                                        {
                                                            '& fieldset': {
                                                                border: (
                                                                    theme,
                                                                ) =>
                                                                    theme
                                                                        .palette
                                                                        .mode ===
                                                                    'dark'
                                                                        ? 'none'
                                                                        : 'none',
                                                                borderRadius:
                                                                    '16px',
                                                            },
                                                            '&:hover fieldset':
                                                                {
                                                                    border: (
                                                                        theme,
                                                                    ) =>
                                                                        theme
                                                                            .palette
                                                                            .mode ===
                                                                        'dark'
                                                                            ? 'none'
                                                                            : 'none',
                                                                    borderRadius:
                                                                        '16px',
                                                                },
                                                            '&.Mui-focused fieldset':
                                                                {
                                                                    border: (
                                                                        theme,
                                                                    ) =>
                                                                        theme
                                                                            .palette
                                                                            .mode ===
                                                                        'dark'
                                                                            ? 'none'
                                                                            : 'none',
                                                                    borderRadius:
                                                                        '16px',
                                                                },
                                                        },
                                                }}
                                            />
                                        )}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Autocomplete
                                blurOnSelect
                                options={CURRENCIES}
                                getOptionLabel={(option) => option.value}
                                renderOption={(props, option) => (
                                    <li {...props}>
                                        {`${option.value} - ${option.label}`}{' '}
                                        {/* Display full option in dropdown */}
                                    </li>
                                )}
                                value={
                                    CURRENCIES.find(
                                        (option) =>
                                            option.value === selectedCurrency,
                                    ) || null
                                }
                                onChange={handleCurrencyChange}
                                sx={{ position: 'relative' }}
                                disableClearable
                                renderInput={(params) => (
                                    <TextField
                                        error={errors?.currency}
                                        {...params}
                                        label="Currency"
                                        variant="outlined"
                                        size="small"
                                        sx={{
                                            bgcolor: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#394150'
                                                    : '#F4F4F6',
                                            borderRadius: '6px',
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    border: (theme) =>
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? 'none'
                                                            : '1px solid #E5E6EB',
                                                    borderRadius: '8px',
                                                },
                                                '&:hover fieldset': {
                                                    border: (theme) =>
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? 'none'
                                                            : '1px solid #E5E6EB',
                                                    borderRadius: '8px',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    border: (theme) =>
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? 'none'
                                                            : '1px solid #E5E6EB',
                                                    borderRadius: '8px',
                                                },
                                            },
                                        }}
                                    />
                                )}
                            />
                            {errors?.currency && (
                                <FormHelperText
                                    error
                                    sx={{ position: 'absolute' }}
                                >
                                    {errors?.currency}
                                </FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl
                                fullWidth
                                variant="outlined"
                                size="small"
                                sx={{ position: 'relative' }}
                            >
                                <InputLabel error={errors?.paymentStatus}>
                                    Payment Status
                                </InputLabel>
                                <Select
                                    error={errors?.paymentStatus}
                                    value={selectedPaymentStatus}
                                    onChange={handlePaymentStatusChange}
                                    onOpen={handleDropdownOpen}
                                    label="Payment Status"
                                    sx={{
                                        bgcolor: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#394150'
                                                : '#F4F4F6', // Match the background color
                                        borderRadius: '6px', // Match border radius
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? 'none'
                                                    : '#E5E6EB', // Match border color
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline':
                                            {
                                                borderColor: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? 'none'
                                                        : '#E5E6EB', // Hover effect
                                            },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline':
                                            {
                                                borderColor: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? 'none'
                                                        : '#E5E6EB', // Focus effect
                                            },
                                    }}
                                >
                                    {isPaymentLoading ? (
                                        <MenuItem
                                            disabled
                                            sx={{
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <CircularProgress size={24} />
                                        </MenuItem>
                                    ) : (
                                        paymentStatuses.map((status) => (
                                            <MenuItem
                                                key={status.id}
                                                value={status.id}
                                            >
                                                {status.label}
                                            </MenuItem>
                                        ))
                                    )}
                                </Select>
                            </FormControl>
                            {errors?.paymentStatus && (
                                <FormHelperText
                                    error
                                    sx={{ position: 'absolute' }}
                                >
                                    {errors?.paymentStatus}
                                </FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                error={errors?.amount}
                                label="Amount"
                                value={amount}
                                onChange={handleAmountChange}
                                fullWidth
                                variant="outlined"
                                size="small"
                                sx={{
                                    position: 'relative',
                                    bgcolor: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '#394150'
                                            : '#F4F4F6',
                                    borderRadius: '6px',
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? 'none'
                                                    : '#E5E6EB',
                                            borderRadius: '8px',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? 'none'
                                                    : '#E5E6EB',
                                            borderRadius: '8px',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? 'none'
                                                    : '#E5E6EB',
                                            borderRadius: '8px',
                                        },
                                    },
                                }}
                            />
                            {errors?.amount && (
                                <FormHelperText
                                    error
                                    sx={{ position: 'absolute' }}
                                >
                                    {errors?.amount}
                                </FormHelperText>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>

            <DialogActions
                sx={{
                    padding: 2,
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                }}
            >
                <Button
                    sx={{
                        fontWeight: '500',
                        fontSize: '14px',
                        color: '#6C727F',
                        border: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '1px solid #394150'
                                : '1px solid #E5E6EB',
                        textTransform: 'none',
                        marginRight: '10px',
                    }}
                    size="small"
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <LoadingButton
                    sx={{
                        backgroundColor: '#0061DB',
                        color: 'white',
                        borderRadius: '8px',
                        textTransform: 'none',
                        paddingX: '20px',
                        paddingY: '8px',
                        '&:hover': {
                            backgroundColor: '#004BB5',
                        },
                        width: {
                            xs: '100%',
                            sm: 'auto',
                        },
                    }}
                    loading={isSubmitLoading}
                    size="small"
                    onClick={() =>
                        onSave(
                            description,
                            selectedAccount,
                            transactionDate,
                            selectedCurrency,
                            selectedPaymentStatus,
                            amount,
                        )
                    }
                >
                    Save
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default AddNewTransactionModal;
