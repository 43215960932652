import * as Yup from 'yup';

export const scheduledPaymentSchema = Yup.object().shape({
    payments: Yup.number().min(1).required('Payments is required'),
    frequency: Yup.string().oneOf(['daily', 'weekly', 'monthly']).required('Frequency is required'),
    startDate: Yup.date().required('Start date is required'),
    amount: Yup.number().min(1).optional().nullable(),
});

export const scheduledPaymentInitialValues = {
    payments: 1,
    frequency: null,
    startDate: null,
    amount: null,
};
