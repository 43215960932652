import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Fragment, useCallback, useEffect, useState } from 'react';

import {
    Box,
    Grid,
    Divider,
    Button as MUIButton,
    Typography as MUITypography,
} from '@mui/material';
import { Save } from '@mui/icons-material';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';

import {
    UPDATE_COMPANY_ERROR,
    UPDATE_COMPANY_PENDING,
    UPDATE_COMPANY_SUCCESS,
} from '../../store/slices/company.slice';
import { ENTITY_TYPES } from '../../utils/constants';
import useTimezoneList from '../../hooks/useTimezoneList';
import { useThemeToggle } from '../../hooks/useThemeToggle';
import { useCoreService } from '../../store/services/core.service';
import { ReactComponent as EditIcon } from '../../assets/icons/Edit.svg';
import { ReactComponent as Help } from '../../assets/icons/HelpIcon.svg';
import { useCompanyService } from '../../store/services/company.service';
import { Autocomplete, Button, TextField, Typography } from '../../shared';
import { ReactComponent as EditIconDarkMode } from '../../assets/icons/EditDarkMode.svg';
import {
    CompanyInformationSchema,
    StepOneCompnayInformationInitialValues,
} from './utils/CompanyInfoFormSchema';

const makeStyles = ({ isOnboardingFlow }) => ({
    companyInformationContainer: {
        //TODO: Need to check in Industry page from My Profile flow
        p: isOnboardingFlow ? '0px' : '32px',
        borderRadius: '12px',
        backgroundColor: !isOnboardingFlow ? (theme) => theme.backgroundColor.primary : 'unset',
    },
});

export const CompanyInformation = ({
    showIndustry = true,
    companyLabel,
    timezoneLabel,
    industryLabel,
    typeOfEntityLabel,
    isOnboardingFlow = false,
    showCompanyHeader = false,
    onCompanyChange = undefined,
    companyData = null,
    callback,
}) => {
    const { data } = useSelector((state) => state.company);

    const dispatch = useDispatch();
    const { themeMode } = useThemeToggle();
    const { getIndustries, isLoading } = useCoreService();
    const { updateCompany } = useCompanyService();
    const [companies, setCompanies] = useState([]);
    const styles = makeStyles({ isOnboardingFlow });
    const [isEdit, setIsEdit] = useState(isOnboardingFlow);
    const timezoneList = useTimezoneList();
    const [company, setCompany] = useState({
        id: data?.id,
        name: data?.name ? data?.name : companyData?.name,
        timezone: data?.timezone || {
            label: 'New York (Eastern Time - EST/EDT)',
            value: 'America/New_York',
        },
        industry: data?.industry,
        entity: data?.entity,
        line1: data?.address?.line1 ? data?.address?.line1 : companyData?.line1,
        line2: data?.address?.line2 ? data?.address?.line2 : companyData?.line2,
        line3: data?.address?.line3 ? data?.address?.line3 : companyData?.line3,
        line4: data?.address?.line4 ? data?.address?.line4 : companyData?.line4,
        line5: data?.address?.line5 ? data?.address?.line5 : companyData?.line5,
        city: data?.address?.city ? data?.address?.city : companyData?.city,
        state: data?.address?.state ? data?.address?.state : companyData?.state,
        country: data?.address?.country ? data?.address?.country : companyData?.country,
        postalCode: data?.address?.postal_code
            ? data?.address?.postal_code
            : companyData?.postal_code,
        email: data?.email ? data?.email : companyData?.email,
    });
    const { createStripeSession } = useCompanyService();

    const handleCreateStripeSession = useCallback(async () => {
        const response = await createStripeSession(data?.id);
        if (response?.url) {
            window.location.href = response?.url;
        }
    }, [data?.id, createStripeSession]);

    const handleCompanyChange = useCallback(
        (e, selectedItem) => {
            if (selectedItem) {
                const changeProperty = { ...company, [e]: selectedItem };
                setCompany(changeProperty);
                onCompanyChange?.(changeProperty);
            } else {
                const { name, value } = e.target;
                const changeProperty = { ...company, [name]: value };
                setCompany(changeProperty);
                onCompanyChange?.(changeProperty);
            }
        },
        [company, onCompanyChange],
    );

    const handleUpdateCompany = (values) => {
        setIsEdit(!isEdit);
        dispatch(UPDATE_COMPANY_PENDING({ loading: true }));
        updateCompany(company.id, {
            name: values.name,
            industry: values.industry.value,
            timezone: values.timezone.value,
            entity: values.entity.value,
            address: {
                line1: values.line1,
                line2: values.line2,
                ...(values.line3 && {
                    line3: values.line3,
                }),
                ...(values.line4 && {
                    line4: values.line4,
                }),
                ...(values.line5 && {
                    line5: values.line5,
                }),
                city: values.city,
                state: values.state,
                postal_code: values.postalCode,
                country: values.country,
            },
        })
            .then((res) => {
                dispatch(
                    UPDATE_COMPANY_SUCCESS({
                        data: {
                            ...res,
                            timezone: {
                                label: res.timezone,
                                value: res.timezone,
                            },
                            industry: {
                                label: values.industry.value,
                                value: values.industry.value,
                            },
                            entity: {
                                label: res.entity,
                                value: res.entity,
                            },
                            address: {
                                line1: values.line1,
                                line2: values.line2,
                                line3: values.line3,
                                line4: values.line4,
                                line5: values.line5,
                                city: values.city,
                                state: values.state,
                                postal_code: values.postalCode,
                                country: values.country,
                            },
                            stripeTrialExpiry: data.stripe_trial_expiry,
                            subscriptionActive: data.subscription_active,
                            stripeStatus: data.stripe_status,
                        },
                    }),
                );
            })
            .catch((err) => {
                dispatch(UPDATE_COMPANY_ERROR({ err }));
            });
    };

    useEffect(() => {
        getIndustries()
            .then((res) => setCompanies(res))
            .catch((err) => console.error(err));
    }, [getIndustries]);

    return (
        <Grid
            item
            container
            spacing={2}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={styles.companyInformationContainer}
        >
            <Grid container item alignItems={'center'} spacing={2}>
                <Formik
                    initialValues={StepOneCompnayInformationInitialValues(data, companyData)}
                    enableReinitialize={true}
                    validationSchema={CompanyInformationSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                        setSubmitting(true);
                        if (isOnboardingFlow) {
                            callback(values);

                            setIsEdit(false);
                        } else {
                            await handleUpdateCompany(values);
                        }

                        setSubmitting(false);
                    }}
                >
                    {({ values, setFieldValue, handleChange, touched, errors, handleSubmit }) => (
                        <Form>
                            {showCompanyHeader && (
                                <>
                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        display={'flex'}
                                        alignItems={'center'}
                                        justifyContent={'space-between'}
                                        sx={styles.companyInformationContainer}
                                    >
                                        <Grid item>
                                            <Typography
                                                text={'Company Information'}
                                                customStyles={{
                                                    fontWeight: 700,
                                                    fontSize: 18,
                                                }}
                                            />
                                            <Typography
                                                text={
                                                    'Here you can update your company related information.'
                                                }
                                                customStyles={{
                                                    fontWeight: 500,
                                                    fontSize: 14,
                                                    color: (theme) => theme.primary.dark,
                                                }}
                                            />
                                        </Grid>

                                        <Grid item>
                                            {!isEdit ? (
                                                <Button
                                                    text="Edit"
                                                    startIcon={
                                                        themeMode === 'dark' ? (
                                                            <EditIconDarkMode fill={'#FFF'} />
                                                        ) : (
                                                            <EditIcon fill={'#FFF'} />
                                                        )
                                                    }
                                                    customStyles={{
                                                        background: (theme) =>
                                                            theme.backgroundColor.primary,
                                                        color: (theme) => theme.primary.dark,
                                                        border: '1px solid #E5E6EB',
                                                        padding: '10px 16px',
                                                        '&:hover': {
                                                            backgroundColor: (theme) =>
                                                                theme.backgroundColor.primary,
                                                        },
                                                    }}
                                                    onClick={() => setIsEdit(!isEdit)}
                                                />
                                            ) : (
                                                <Button
                                                    text="Save Changes"
                                                    startIcon={<Save stroke={'#FFF'} />}
                                                    customStyles={{
                                                        background: (theme) =>
                                                            theme.backgroundColor.light,
                                                        color: (theme) => theme.primary.light,
                                                        border: '1px solid #E5E6EB',
                                                        padding: '10px 16px',
                                                        '&:hover': {
                                                            backgroundColor: (theme) =>
                                                                theme.backgroundColor.light,
                                                        },
                                                    }}
                                                    onClick={handleSubmit}
                                                    loading={isLoading}
                                                />
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box width={'100%'}>
                                                <Divider />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                            <Grid container spacing={2} sx={styles.companyInformationContainer}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        name="name"
                                        label={companyLabel}
                                        value={values.name}
                                        placeholder="Company Name"
                                        onChange={handleChange}
                                        disabled={!isEdit}
                                        error={touched.name && !!errors.name}
                                        helperText={touched.name && errors.name}
                                        customLabelStyles={{
                                            fontSize: 16,
                                            fontWeight: 500,
                                            color: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#D3D5DA'
                                                    : '#4D5461',
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Autocomplete
                                        options={timezoneList}
                                        labelKey="label"
                                        valueKey="value"
                                        placeholder="Continent/City"
                                        name="timezone"
                                        isSearchField={false}
                                        label={timezoneLabel}
                                        disabled={!isEdit}
                                        value={values.timezone}
                                        onChange={(e, value) => setFieldValue('timezone', value)}
                                        error={touched.timezone && !!errors.timezone}
                                        helperText={touched.timezone && errors.timezone}
                                        customLabelStyles={{
                                            fontSize: 16,
                                            fontWeight: 500,
                                            color: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#D3D5DA'
                                                    : '#4D5461',
                                        }}
                                        disableClearable
                                        blurOnSelect
                                    />
                                </Grid>

                                {showIndustry && (
                                    <Grid item xs={12} md={6}>
                                        <Autocomplete
                                            options={companies.map((item) => ({
                                                label: item.name,
                                                value: item.id,
                                            }))}
                                            labelKey="label"
                                            valueKey="value"
                                            placeholder="Select Industry"
                                            name="industry"
                                            isSearchField={false}
                                            label={industryLabel}
                                            labelIcon={<Help />}
                                            labelIconText={
                                                'You will not be able to modify this later, but you can add custom accounts to your default COA.'
                                            }
                                            customLabelStyles={{
                                                fontSize: 16,
                                                fontWeight: 500,
                                                color: (theme) =>
                                                    theme.palette.mode === 'dark'
                                                        ? '#D3D5DA'
                                                        : '#4D5461',
                                            }}
                                            customStyle={{
                                                color: (theme) => theme.textColor.primary,
                                            }}
                                            value={values.industry}
                                            onChange={(e, value) =>
                                                setFieldValue('industry', value)
                                            }
                                            error={touched.industry && !!errors.industry}
                                            helperText={touched.industry && errors.industry}
                                        />
                                        <Typography
                                            text={'This helps determine the appropriate COA'}
                                            customStyles={{
                                                mt: 1,
                                                fontWeight: 400,
                                                fontSize: 14,
                                                color: '#6C727F',
                                            }}
                                        />
                                    </Grid>
                                )}

                                <Grid item xs={12} md={6}>
                                    <Autocomplete
                                        options={ENTITY_TYPES}
                                        labelKey="label"
                                        valueKey="value"
                                        placeholder="Select Entity"
                                        name="entity"
                                        value={values.entity}
                                        isSearchField={false}
                                        label={typeOfEntityLabel}
                                        labelIcon={<Help />}
                                        labelIconText={
                                            "You will not be able to modify this later, but don't worry. This doesn't affect COA."
                                        }
                                        disabled={!isOnboardingFlow}
                                        customLabelStyles={{
                                            fontSize: 16,
                                            fontWeight: 500,
                                            color: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#D3D5DA'
                                                    : '#4D5461',
                                        }}
                                        customStyle={{
                                            color: (theme) => theme.textColor.primary,
                                        }}
                                        onChange={(e, value) => setFieldValue('entity', value)}
                                        error={touched.entity && !!errors.entity}
                                        helperText={touched.entity && errors.entity}
                                    />
                                    {isOnboardingFlow && (
                                        <Typography
                                            text={
                                                "None if it's not a US entity or not incorporated"
                                            }
                                            customStyles={{
                                                mt: 1,
                                                fontWeight: 400,
                                                fontSize: 14,
                                                color: '#6C727F',
                                            }}
                                        />
                                    )}
                                </Grid>

                                {/* figure out what it is?         */}

                                {!isEdit && (
                                    <Grid item xs={12} md={6} lg={6}>
                                        <TextField
                                            fullWidth
                                            name={'email'}
                                            label="Start Sending Receipts to:"
                                            value={company.email}
                                            placeholder="Start Sending Receipts to"
                                            disabled={!isEdit}
                                            customLabelStyles={{
                                                fontSize: 16,
                                                fontWeight: 500,
                                                color: (theme) =>
                                                    theme.palette.mode === 'dark'
                                                        ? '#D3D5DA'
                                                        : '#4D5461',
                                            }}
                                        />
                                    </Grid>
                                )}
                                {!!!isOnboardingFlow && (
                                    <Grid item xs={12} md={6} lg={6} alignSelf="end">
                                        <MUIButton
                                            fullWidth
                                            variant="outlined"
                                            startIcon={
                                                themeMode === 'dark' ? (
                                                    <PaymentsOutlinedIcon />
                                                ) : (
                                                    <PaymentsOutlinedIcon />
                                                )
                                            }
                                            sx={{
                                                paddingY: '14px',
                                                background: (theme) =>
                                                    theme.backgroundColor.primary,
                                                color: (theme) => theme.primary.dark,
                                                border: '1px solid #E5E6EB',
                                                '&:hover': {
                                                    border: '1px solid #E5E6EB',
                                                },
                                            }}
                                            onClick={handleCreateStripeSession}
                                        >
                                            <MUITypography
                                                variant="subtitle2"
                                                textTransform="capitalize"
                                            >
                                                Manage Billing
                                            </MUITypography>
                                        </MUIButton>
                                    </Grid>
                                )}
                                {!isEdit && <Grid item xs={12} md={6} lg={6}></Grid>}

                                {/* end of figure out  */}

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        name="line1"
                                        label="Address Line 1"
                                        value={values.line1}
                                        placeholder="Line 1"
                                        disabled={!isEdit}
                                        onChange={handleChange}
                                        error={touched.line1 && !!errors.line1}
                                        helperText={touched.line1 && errors.line1}
                                        customLabelStyles={{
                                            fontSize: 16,
                                            fontWeight: 500,
                                            color: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#D3D5DA'
                                                    : '#4D5461',
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        name="line2"
                                        label="Address Line 2 (Optional)"
                                        value={values.line2}
                                        placeholder="Line 2"
                                        onChange={handleChange}
                                        disabled={!isEdit}
                                        customLabelStyles={{
                                            fontSize: 16,
                                            fontWeight: 500,
                                            color: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#D3D5DA'
                                                    : '#4D5461',
                                        }}
                                    />
                                </Grid>

                                {/* company lines more */}

                                {(values.line3 || values.line4 || values.line5) && (
                                    <Fragment>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                name="line3"
                                                label="Address Line 3"
                                                placeholder="Line 3"
                                                value={values.line3}
                                                onChange={handleChange}
                                                disabled={!isEdit}
                                                error={touched.line3 && !!errors.line3}
                                                helperText={touched.line3 && errors.line3}
                                                customLabelStyles={{
                                                    fontSize: 16,
                                                    fontWeight: 500,
                                                    color: (theme) =>
                                                        theme.palette.mode === 'dark'
                                                            ? '#D3D5DA'
                                                            : '#4D5461',
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                name="line4"
                                                label="Address Line 4"
                                                placeholder="Line 4"
                                                value={values.line4}
                                                onChange={handleChange}
                                                disabled={!isEdit}
                                                error={touched.line4 && !!errors.line4}
                                                helperText={touched.line4 && errors.line4}
                                                customLabelStyles={{
                                                    fontSize: 16,
                                                    fontWeight: 500,
                                                    color: (theme) =>
                                                        theme.palette.mode === 'dark'
                                                            ? '#D3D5DA'
                                                            : '#4D5461',
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                name="line5"
                                                label="Address Line 5"
                                                placeholder="Line 5"
                                                value={values.line5}
                                                onChange={handleChange}
                                                disabled={!isEdit}
                                                error={touched.line5 && !!errors.line5}
                                                helperText={touched.line5 && errors.line5}
                                                customLabelStyles={{
                                                    fontSize: 16,
                                                    fontWeight: 500,
                                                    color: (theme) =>
                                                        theme.palette.mode === 'dark'
                                                            ? '#D3D5DA'
                                                            : '#4D5461',
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={6}></Grid>
                                    </Fragment>
                                )}

                                {/* compnay lines more end */}

                                {/* more address in onboarding */}
                                {/* City */}
                                <Grid item xs={12} md={6} lg={6}>
                                    <TextField
                                        fullWidth
                                        name="city"
                                        label="City"
                                        placeholder="City"
                                        value={values.city}
                                        onChange={handleChange}
                                        error={touched.city && !!errors.city}
                                        helperText={touched.city && errors.city}
                                        disabled={!isEdit}
                                        customLabelStyles={{
                                            fontSize: 16,
                                            fontWeight: 500,
                                            color: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#D3D5DA'
                                                    : '#4D5461',
                                        }}
                                    />
                                </Grid>

                                {/* State */}
                                <Grid item xs={12} md={6} lg={6}>
                                    <TextField
                                        fullWidth
                                        name="state"
                                        label="State"
                                        placeholder="State"
                                        value={values.state}
                                        onChange={handleChange}
                                        error={touched.state && !!errors.state}
                                        helperText={touched.state && errors.state}
                                        disabled={!isEdit}
                                        customLabelStyles={{
                                            fontSize: 16,
                                            fontWeight: 500,
                                            color: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#D3D5DA'
                                                    : '#4D5461',
                                        }}
                                    />
                                </Grid>

                                {/* Postal Code */}
                                <Grid item xs={12} md={6} lg={6}>
                                    <TextField
                                        fullWidth
                                        name="postalCode"
                                        label="Postal Code"
                                        placeholder="Postal Code"
                                        value={values.postalCode}
                                        onChange={handleChange}
                                        error={touched.postalCode && !!errors.postalCode}
                                        helperText={touched.postalCode && errors.postalCode}
                                        disabled={!isEdit}
                                        customLabelStyles={{
                                            fontSize: 16,
                                            fontWeight: 500,
                                            color: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#D3D5DA'
                                                    : '#4D5461',
                                        }}
                                    />
                                </Grid>

                                {/* Country */}
                                <Grid item xs={12} md={6} lg={6}>
                                    <TextField
                                        fullWidth
                                        name="country"
                                        label="Country"
                                        placeholder="Country"
                                        value={values.country}
                                        onChange={handleChange}
                                        error={touched.country && !!errors.country}
                                        helperText={touched.country && errors.country}
                                        disabled={!isEdit}
                                        customLabelStyles={{
                                            fontSize: 16,
                                            fontWeight: 500,
                                            color: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#D3D5DA'
                                                    : '#4D5461',
                                        }}
                                    />
                                </Grid>

                                {/* end on boarding  */}
                                {!showCompanyHeader ? (
                                    <Grid item xs={12}>
                                        <Button
                                            text={
                                                <Typography
                                                    text="Finish"
                                                    customStyles={{
                                                        color: '#FFFFFF',
                                                        fontSize: '18px',
                                                        fontWeight: 500,
                                                    }}
                                                />
                                            }
                                            variant="contained"
                                            color="primary"
                                            onClick={handleSubmit}
                                            fullWidth
                                        />
                                    </Grid>
                                ) : null}
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Grid>
        </Grid>
    );
};
