import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';

import { Box, CircularProgress } from '@mui/material';

import InventoryTable from './components/InventoryTable';
import { inventoryTabStyles } from '../../style/style';
import { useInventoryService } from 'store/services/inventory.service';
import { inventoryTableHeaders } from '../../utils/inventoryTableHeaders';

const InventoryTab = () => {
    const classes = inventoryTabStyles({});
    const { getCompanyInventory } = useInventoryService();
    const companyData = useSelector((state) => state.company.data);

    const [page, setPage] = useState(1);
    const [sortOrder, setSortOrder] = useState('desc');
    const [inventoryData, setInventoryData] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [sorting, setSorting] = useState({ purchased_at: true });
    const [downloadLoading, setDownloadLoading] = useState(false);

    const handleGetInventory = async (page, filters = {}, columnId = null, newSortOrder = null) => {
        try {
            setTableLoading(true);
            const response = await getCompanyInventory(
                companyData.id,
                page,
                100,
                filters?.expiryDate,
                filters?.purchaseDate,
                filters?.description,
                columnId,
                newSortOrder,
            );
            setInventoryData(response);
        } catch (error) {
            console.error(error);
        } finally {
            setTableLoading(false);
        }
    };

    const downloadInventoryCsv = (inventoryData) => {
        const filteredHeaders = inventoryTableHeaders(() => {}).filter(
            (col) => col.label !== 'Source',
        );

        const csvHeaders = filteredHeaders.map((col) => col.label);
        const csvRows = [csvHeaders.join(',')];

        inventoryData.forEach((item) => {
            const rowValues = filteredHeaders.map((col) => {
                switch (col.id) {
                    case 'purchase_payment':
                        return `${item.payment_method || '-'} ${item.payment_last_digits || '-'}`;

                    case 'expires_at':
                        return item.expires_at ? dayjs(item.expires_at).format('YYYY-MM-DD') : '';

                    case 'purchased_at':
                        return item.purchased_at
                            ? dayjs(item.purchased_at).format('YYYY-MM-DD')
                            : '';

                    case 'status':
                        return item.status || '';

                    default:
                        return item[col.id] ?? '';
                }
            });

            const csvLine = rowValues.map((val) => `"${val}"`).join(',');

            csvRows.push(csvLine);
        });

        const csvString = csvRows.join('\n');

        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `inventory_data_${new Date()}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleDownloadInventory = async (filters = {}) => {
        try {
            setDownloadLoading(true);
            const response = await getCompanyInventory(
                companyData.id,
                null,
                null,
                filters?.expiryDate,
                filters?.purchaseDate,
                filters?.description,
            );
            downloadInventoryCsv(response);
        } catch (error) {
            console.error(error);
        } finally {
            setDownloadLoading(false);
        }
    };

    const handlePageChange = (pageNumber, filters) => {
        setPage(pageNumber);
        handleGetInventory(pageNumber, filters);
    };

    useEffect(() => {
        if (companyData) {
            handleGetInventory(page);
        }
    }, [companyData]);

    const handleSort = async (filters, columnId, newSortOrder) => {
        try {
            await handleGetInventory(page, filters, columnId, newSortOrder);
            setSorting({ [columnId]: true });
            setSortOrder(newSortOrder);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Box sx={classes.root}>
            {companyData ? (
                <Box>
                    <InventoryTable
                        page={page}
                        sorting={sorting}
                        setPage={setPage}
                        data={inventoryData}
                        sortOrder={sortOrder}
                        loading={tableLoading}
                        handleSort={handleSort}
                        downloadLoading={downloadLoading}
                        handlePageChange={handlePageChange}
                        handleGetInventory={handleGetInventory}
                        handleDownloadInventory={handleDownloadInventory}
                    />
                </Box>
            ) : (
                <Box sx={classes.loader}>
                    <CircularProgress />
                </Box>
            )}
        </Box>
    );
};

export default InventoryTab;
