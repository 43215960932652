import React from 'react';
import dayjs from 'dayjs';
import { Formik, Form } from 'formik';

import { Box, Grid } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { Button, TextField } from 'shared';
import {
    purchaseFiltersSchema,
    purchaseFiltersInitialValues,
} from 'components/Inventory/utils/purchaseFiltersSchema';
import { paymentPlanFormStyles } from 'components/Inventory/style/style';

const PurchaseFilters = ({ handleFiltersSubmit, handleGetInitialData }) => {
    const classes = paymentPlanFormStyles();

    const handleClearClick = (handleReset) => {
        handleReset();
        handleGetInitialData(purchaseFiltersInitialValues);
    };

    const handleFormSubmit = (values) => {
        const filters = {
            ...values,
            purchaseId: values.purchaseId,
            endDate: values.endDate ? dayjs(values.endDate).format('YYYY-MM-DD') : null,
            startDate: values.startDate ? dayjs(values.startDate).format('YYYY-MM-DD') : null,
        };
        handleFiltersSubmit(filters);
    };

    return (
        <Formik
            initialValues={purchaseFiltersInitialValues}
            validationSchema={purchaseFiltersSchema}
            onSubmit={handleFormSubmit}
        >
            {({ values, handleReset, handleChange, setFieldValue }) => {
                return (
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} lg={3}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        value={values.startDate && dayjs(values.startDate)}
                                        onChange={(date) => setFieldValue('startDate', date)}
                                        label="Start Date"
                                        placeholder="Start Date"
                                        format="YYYY-MM-DD"
                                        sx={classes.datePicker}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        value={values.endDate && dayjs(values.endDate)}
                                        onChange={(date) => setFieldValue('endDate', date)}
                                        label="End Date"
                                        placeholder="End Date"
                                        format="YYYY-MM-DD"
                                        sx={classes.datePicker}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <TextField
                                    fullWidth
                                    name="purchaseId"
                                    inlineLabel="Purchase"
                                    value={values.purchaseId}
                                    placeholder="Search by purchase"
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="flex-end" gap={1}>
                                    <Button
                                        text="Clear"
                                        onClick={() => handleClearClick(handleReset)}
                                        customStyles={classes.cancelButton}
                                    />
                                    <Button text="Apply" type="submit" />
                                </Box>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default PurchaseFilters;
