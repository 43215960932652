import * as Yup from 'yup';

export const inventoryFiltersInitialValues = {
    description: '',
    expiryDate: null,
    purchaseDate: null,
};

export const inventoryFiltersSchema = Yup.object({
    expiryDate: Yup.date().nullable(),
    purchaseDate: Yup.date().nullable(),
    description: Yup.string().nullable(),
});
