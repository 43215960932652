export const inventoryTabStyles = ({ isScreenLowerThan1024 }) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: isScreenLowerThan1024 ? '16px' : '32px',
        width: '100%',
    },
    loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '300px',
    },
});

export const uploadFileTabStyles = () => ({
    inventoryReconciliationWrapper: {
        background: (theme) => (theme.palette.mode == 'dark' ? '#212936' : 'white'),
        padding: '24px',
        borderRadius: '12px',
    },
    buttonsWrapper: {
        display: 'flex',
        gap: '20px',
        marginTop: '20px',
        justifyContent: 'flex-end',
    },
    button: {
        color: 'white',
    },
    dropdownsWrapper: {
        display: 'flex',
        gap: '20px',
        width: '100%',
        marginBlock: '10px',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    flex: {
        flex: 1,
    },
    clearButton: {
        color: 'white',
        background: (theme) => theme.backgroundColor.primary,
        color: (theme) => theme.primary.dark,
        border: '1px solid #E5E6EB',
        '&:hover': {
            backgroundColor: (theme) => theme.backgroundColor.secondary,
        },
    },
});

export const inventoryReconciliationStyles = ({}) => ({
    menuWrapper: {
        width: '100%',
        background: (theme) => (theme.palette.mode === 'dark' ? '#212936' : 'white'),
    },
    stack: {
        background: (theme) => (theme.palette.mode === 'dark' ? '#212936' : 'rgb(244, 244, 246)'),
        minHeight: '60px',
        borderRadius: '5px',
        padding: '1rem 2rem',
    },
    tabContentWrapper: {
        p: '10px 0px',
    },
    activeTab: {
        textTransform: 'none',
        fontSize: '1rem',
        fontWeight: '600',
        backgroundColor: '#CEEFDF',
        borderRadius: '3px',
        color: '#0A8C4D',
        '&:hover': {
            backgroundColor: '#0A8C4D',
            color: '#CEEFDF',
        },
    },
    inactiveTab: {
        textTransform: 'none',
        fontSize: '1rem',
        fontWeight: '500',
        backgroundColor: 'transparent',
        borderRadius: 'none',
        color: '#6C727F',
    },
});

export const inventoryStyles = ({ isMobileScreen }) => ({
    menuWrapper: {
        width: '100%',
        background: (theme) => (theme.palette.mode === 'dark' ? '#212936' : 'white'),
        borderBottom: (theme) =>
            `1px solid ${theme.palette.mode === 'dark' ? '#394150' : '#E5E6EB'}`,
    },
    stack: {
        background: (theme) => (theme.palette.mode === 'dark' ? '#212936' : 'rgb(244, 244, 246)'),
        minHeight: '60px',
        borderRadius: '5px',
        padding: '1rem 2rem',
    },
    tabContentWrapper: {
        p: isMobileScreen ? '20px' : '40px',
    },
});

export const forReviewAccordionStyles = () => ({
    root: {
        background: (theme) => theme.backgroundColor.primary,
        width: '100%',
        padding: '20px 0',
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: '10px',
        marginBottom: '5px',
    },
    button: {
        color: 'white',
        padding: '14px',
    },
    radioButtonsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 2,
    },
    radioGroup: { gap: 2, fontSize: '2px' },
    radioButtonLabel: {
        '& .MuiTypography-root': { fontSize: '14px' },
    },
    radioButtonIcon: {
        '& .MuiSvgIcon-root': {
            fontSize: '14px',
        },
    },
    crossIcon: {
        padding: '6px',
        '& .MuiSvgIcon-root': { fontSize: '20px' },
    },
    importButton: {
        backgroundColor: '#0061DB',
        color: 'white',
        borderRadius: '8px',
        paddingX: '10px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#0056b3',
            '@media (hover: hover)': {
                backgroundColor: '#0056b3',
            },
        },
    },
});

export const matchRowStyles = () => ({
    rootWrapper: {
        border: (theme) =>
            `1px solid ${theme.palette.mode === 'dark' ? 'rgba(81, 81, 81, 1)' : '#E5E6EB'} `,
        padding: '8px 16px',
        borderRadius: '15px',
        backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#394150' : '#F4F4F6'),
        marginTop: '5px',
    },
});

export const exclusionRulesStyles = ({ isMobileScreen }) => ({
    root: {
        p: '10px 0px',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    addButtonText: {
        fontWeight: 500,
        color: '#FFF',
    },
    exclusionRuleButton: {
        padding: '12px 20px',
        height: '48px',
        '& .MuiButton-startIcon': {
            mr: isMobileScreen ? '0px' : '8px',
        },
    },
});

export const exclusionRulesFormStyles = ({}) => ({
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'center',
    },
    button: {
        color: 'white',
        padding: '12px 20px',
        height: '48px',
        background: '#0061DB',
    },
});

export const inventorySettingsFormStyles = () => ({
    root: { width: '100%' },
    formWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    cancelButton: {
        fontWeight: '500',
        fontSize: '14px',
        color: '#6C727F',
        border: (theme) =>
            theme.palette.mode === 'dark' ? '1px solid #394150' : '1px solid #E5E6EB',
        textTransform: 'none',
        marginRight: '20px',
    },
});

export const transactionCardStyles = () => ({
    root: {
        p: 4,
        borderRadius: '8px',
        border: (theme) =>
            `1px solid ${theme.palette.mode === 'dark' ? 'transparent' : '#E5E6EB'} `,
        backgroundColor: (theme) => (theme.palette.mode === 'dark' ? 'transparent' : '#F9FAFB'),
    },
    textBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
});

export const transactionSummaryStyles = () => ({
    root: {
        padding: '25px 15px',
        borderRadius: '8px',
        border: (theme) =>
            `1px solid ${theme.palette.mode === 'dark' ? 'transparent' : '#E5E6EB'} `,
        backgroundColor: (theme) => (theme.palette.mode === 'dark' ? 'transparent' : '#F9FAFB'),
    },
    summaryWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    textBox: { display: 'flex', flexDirection: 'column', gap: '10px' },
    remainingWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: '10px 0',
    },
    checkboxWrapper: {
        display: 'flex',
        alignItems: 'flex-start',
        gap: '5px',
        marginBottom: '10px',
    },
    checkbox: { padding: 0 },
});

export const circlerInventoryAccordionStyles = () => ({
    root: {
        background: (theme) => theme.backgroundColor.primary,
        width: '100%',
        padding: '20px 0',
    },
    radioButtonsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 2,
    },
    crossIcon: {
        padding: '6px',
        '& .MuiSvgIcon-root': { fontSize: '20px' },
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: '10px',
        marginBottom: '5px',
    },
    button: {
        color: 'white',
        padding: '14px',
    },
});

export const matchWithAggregatedFileInventoryStyles = () => ({
    menuWrapper: {
        padding: '10px 5px',
        borderRadius: '8px',

        backgroundColor: (theme) => (theme.palette.mode === 'dark' ? 'transparent' : '#F9FAFB'),
    },
    stack: {
        background: (theme) => (theme.palette.mode === 'dark' ? '#1E252F' : '#F9FAFB'),
        minHeight: '60px',
        borderRadius: '5px',
        padding: '1rem 2rem',
    },

    cancelButton: {
        marginRight: '10px',
        background: (theme) => theme.backgroundColor.primary,
        color: (theme) => theme.primary.dark,
        border: '1px solid #E5E6EB',
        '&:hover': {
            backgroundColor: (theme) => theme.backgroundColor.secondary,
        },
    },
    inputField: {
        fontSize: 16,
        fontWeight: 500,
        color: (theme) => (theme.palette.mode === 'dark' ? '#D3D5DA' : '#4D5461'),
        background: (theme) => (theme.palette.mode === 'dark' ? '#1E252F' : '#4D5461'),
    },
    datePicker: {
        '& .MuiInputBase-root': {
            backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#394150' : '#F4F4F6'),
            borderRadius: '8px',
            border: 'none',
            height: '48px',
            minHeight: '40px',
            fontSize: 13,
            color: (theme) => (theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F'),
            '&:hover .MuiOutlinedInput-notchedOutline': {
                border: (theme) =>
                    `1px solid ${theme.palette.mode === 'dark' ? '#E5E6EB' : '#E5E6EB'}`,
            },
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
            },
        },
        '& .MuiSvgIcon-root': {
            color: (theme) => (theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F'),
        },
        '& .MuiInputBase-input::placeholder': {
            color: (theme) => (theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F'),
            opacity: 1,
            fontSize: 13,
        },
        width: '100%',
        '& .MuiInputLabel-root': { fontSize: '13px' },
        '& .MuiOutlinedInput-input': { fontSize: '13px' },
    },

    tabButton: (selected) => ({
        textTransform: 'none',
        fontSize: '1rem',
        fontWeight: selected ? '600' : '500',
        backgroundColor: selected ? '#CEEFDF' : 'transparent',

        borderRadius: selected ? '3px' : 'none',
        color: selected ? '#0A8C4D' : '#A1A8B3',
        '&:hover': {
            backgroundColor: selected ? '#0A8C4D26' : 'transparent',
            color: selected ? '#0A8C4D' : '#A1A8B3',
        },
    }),
});

export const forReviewFiltersStyles = () => ({
    cancelButton: {
        marginRight: '10px',
        background: (theme) => theme.backgroundColor.primary,
        color: (theme) => theme.primary.dark,
        border: '1px solid #E5E6EB',
        '&:hover': {
            backgroundColor: (theme) => theme.backgroundColor.secondary,
        },
    },
    inputField: {
        '& .MuiInputBase-root': {
            '& input': {
                fontSize: 13,
                padding: '16px 14px',
                color: (theme) => theme.textColor.primary,
                '&::placeholder': {
                    color: (theme) => theme.textColor.primary,
                    opacity: 1,
                    fontSize: 13,
                },
            },

            backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#394150' : 'white'),
            '& textarea': {
                '&::placeholder': {
                    color: (theme) => theme.textColor.primary,
                    opacity: 1,
                    fontSize: 13,
                },
            },
            '&:hover fieldset': {
                borderColor: '#6C727F',
                border: '1px solid #E5E6EB',
            },
            borderRadius: 2,
            '& fieldset': {
                borderColor: (theme) => (theme.palette.mode === 'dark' ? '#394150' : 'white'),
            },
        },
    },
    dropdown: {
        '& .MuiInputBase-root': {
            '& input': {
                fontSize: 13,
                padding: '16px 14px',
                color: (theme) => theme.textColor.primary,
                '&::placeholder': {
                    color: (theme) => theme.textColor.primary,
                    opacity: 1,
                    fontSize: 13,
                },
            },

            backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#394150' : 'none'),
            '& textarea': {
                '&::placeholder': {
                    color: (theme) => theme.textColor.primary,
                    opacity: 1,
                    fontSize: 13,
                },
            },
            '&:hover fieldset': {
                borderColor: '#6C727F',
                border: '1px solid #E5E6EB',
            },
            borderRadius: 2,
            '& fieldset': {
                borderColor: (theme) => (theme.palette.mode === 'dark' ? '#394150' : 'white'),
            },
            boxShadow: (theme) => theme.boxShadow,
            '& li': {
                color: (theme) => theme.textColor.inputField,
                fontSize: '16px',
                fontWeight: 400,
            },
            backgroundImage: 'unset',
            border: (theme) => `1px solid ${theme.borderColor.inputField}`,
        },
    },
    datePicker: {
        '& .MuiInputBase-root': {
            backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#394150' : 'white'),
            borderRadius: '8px',
            border: 'none',
            height: '48px',
            minHeight: '40px',
            fontSize: 13,
            color: (theme) => (theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F'),
            '&:hover .MuiOutlinedInput-notchedOutline': {
                border: (theme) =>
                    `1px solid ${theme.palette.mode === 'dark' ? '#E5E6EB' : '#E5E6EB'}`,
            },
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
            },
        },
        '& .MuiSvgIcon-root': {
            color: (theme) => (theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F'),
        },
        '& .MuiInputBase-input::placeholder': {
            color: (theme) => (theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F'),
            opacity: 1,
            fontSize: 13,
        },
        width: '100%',
        '& .MuiInputLabel-root': { fontSize: '13px' },
        '& .MuiOutlinedInput-input': { fontSize: '13px' },
    },
});

export const inventoryFiltersStyles = () => ({
    cancelButton: {
        marginRight: '10px',
        background: (theme) => theme.backgroundColor.primary,
        color: (theme) => theme.primary.dark,
        border: '1px solid #E5E6EB',
        '&:hover': {
            backgroundColor: (theme) => theme.backgroundColor.secondary,
        },
    },
    clearButton: {
        marginRight: '10px',
        background: (theme) => theme.backgroundColor.primary,
        color: (theme) => theme.primary.dark,
        border: '1px solid #E5E6EB',
        '&:hover': {
            backgroundColor: (theme) => theme.backgroundColor.secondary,
        },
    },
    inputField: {
        '& .MuiInputBase-root': {
            '& input': {
                fontSize: 13,
                padding: '16px 14px',
                color: (theme) => theme.textColor.primary,
                '&::placeholder': {
                    color: (theme) => theme.textColor.primary,
                    opacity: 1,
                    fontSize: 13,
                },
            },

            backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#394150' : 'white'),
            '& textarea': {
                '&::placeholder': {
                    color: (theme) => theme.textColor.primary,
                    opacity: 1,
                    fontSize: 13,
                },
            },
            '&:hover fieldset': {
                borderColor: '#6C727F',
                border: '1px solid #E5E6EB',
            },
            borderRadius: 2,
            '& fieldset': {
                borderColor: (theme) => (theme.palette.mode === 'dark' ? '#394150' : 'white'),
            },
        },
    },
    dropdown: {
        '& .MuiInputBase-root': {
            '& input': {
                fontSize: 13,
                padding: '16px 14px',
                color: (theme) => theme.textColor.primary,
                '&::placeholder': {
                    color: (theme) => theme.textColor.primary,
                    opacity: 1,
                    fontSize: 13,
                },
            },

            backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#394150' : 'white'),
            '& textarea': {
                '&::placeholder': {
                    color: (theme) => theme.textColor.primary,
                    opacity: 1,
                    fontSize: 13,
                },
            },
            '&:hover fieldset': {
                borderColor: '#6C727F',
                border: '1px solid #E5E6EB',
            },
            borderRadius: 2,
            '& fieldset': {
                borderColor: (theme) => (theme.palette.mode === 'dark' ? '#394150' : 'white'),
            },
            boxShadow: (theme) => theme.boxShadow,
            '& li': {
                color: (theme) => theme.textColor.inputField,
                fontSize: '16px',
                fontWeight: 400,
            },
            backgroundImage: 'unset',
            border: (theme) => `1px solid ${theme.borderColor.inputField}`,
        },
    },
    datePicker: {
        '& .MuiInputBase-root': {
            backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#394150' : 'white'),
            borderRadius: '8px',
            border: 'none',
            height: '48px',
            minHeight: '40px',
            fontSize: 13,
            color: (theme) => (theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F'),
            '&:hover .MuiOutlinedInput-notchedOutline': {
                border: (theme) =>
                    `1px solid ${theme.palette.mode === 'dark' ? '#E5E6EB' : '#E5E6EB'}`,
            },
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
            },
        },
        '& .MuiSvgIcon-root': {
            color: (theme) => (theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F'),
        },
        '& .MuiInputBase-input::placeholder': {
            color: (theme) => (theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F'),
            opacity: 1,
            fontSize: 13,
        },
        width: '100%',
        '& .MuiInputLabel-root': { fontSize: '13px' },
        '& .MuiOutlinedInput-input': { fontSize: '13px' },
    },
});

export const emailDetailsAccordionStyles = () => ({
    accordionWrapper: { mt: 2 },
    accordion: {
        backgroundColor: (theme) => (theme.palette.mode === 'dark' ? 'rgb(57, 65, 80)' : 'white'),
        border: (theme) =>
            `1px solid ${theme.palette.mode === 'dark' ? 'transparent' : '#E5E6EB'} `,
    },
    noBodyText: { whiteSpace: 'pre-wrap' },
    accordionDetails: {
        maxHeight: '400px',
        overflowY: 'auto',
    },
    highlightColor: {
        backgroundColor: 'yellow',
    },
    addToNewLine: {
        wordBreak: 'break-all',
    },
    summary: {
        userSelect: 'text',
    },
});

export const filteredInventoryInfo = () => ({
    rootWrapper: {
        border: (theme) =>
            `1px solid ${theme.palette.mode === 'dark' ? 'rgba(81, 81, 81, 1)' : '#E5E6EB'} `,
        padding: '15px',
        borderRadius: '15px',
        backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#394150' : 'white'),
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 2,
        marginBottom: 2,
    },
});

export const BulkEditForm = () => ({
    datePicker: {
        '& .MuiInputBase-root': {
            backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#394150' : '#F4F4F6;'),
            borderRadius: '8px',
            border: 'none',
            height: '52px',
            minHeight: '40px',
            fontSize: 13,
            color: (theme) => (theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F'),
            '&:hover .MuiOutlinedInput-notchedOutline': {
                border: (theme) =>
                    `1px solid ${theme.palette.mode === 'dark' ? '#E5E6EB' : '#E5E6EB'}`,
            },
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
            },
        },
        '& .MuiSvgIcon-root': {
            color: (theme) => (theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F'),
        },
        '& .MuiInputBase-input::placeholder': {
            color: (theme) => (theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F'),
            opacity: 1,
            fontSize: 13,
        },
        width: '100%',
        '& .MuiInputLabel-root': { fontSize: '13px' },
        '& .MuiOutlinedInput-input': { fontSize: '13px' },
    },
    formContainer: { paddingLeft: '10px', marginTop: '20px' },
    statusText: {
        fontSize: '13px !important',
        fontWeight: '526 !important', // Slightly bold
        marginBottom: '5px',
    },

    modalBack: { mb: 3, p: 2, border: '1px solid #ccc', borderRadius: 2 },
    firstDivider: { mt: 1, mb: 2 },
    newRowIcon: {
        border: '2px dashed #0061DB',
        borderRadius: '50%',
        padding: 1.5,
    },
    addIconColor: { color: '#0061DB' },
    footerSetup: {
        backgroundColor: 'transparent',
        border: '1px solid #6C727F',
        color: '#6C727F',
    },
});

export const activityFiltersStyles = () => ({
    clearButton: {
        marginRight: '10px',
        background: (theme) => theme.backgroundColor.primary,
        color: (theme) => theme.primary.dark,
        border: '1px solid #E5E6EB',
        '&:hover': {
            backgroundColor: (theme) => theme.backgroundColor.secondary,
        },
    },
    inputField: {
        '& .MuiInputBase-root': {
            '& input': {
                fontSize: 13,
                padding: '16px 14px',
                color: (theme) => theme.textColor.primary,
                '&::placeholder': {
                    color: (theme) => theme.textColor.primary,
                    opacity: 1,
                    fontSize: 13,
                },
            },

            backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#394150' : 'white'),
            '& textarea': {
                '&::placeholder': {
                    color: (theme) => theme.textColor.primary,
                    opacity: 1,
                    fontSize: 13,
                },
            },
            '&:hover fieldset': {
                borderColor: '#6C727F',
                border: '1px solid #E5E6EB',
            },
            borderRadius: 2,
            '& fieldset': {
                borderColor: (theme) => (theme.palette.mode === 'dark' ? '#394150' : 'white'),
            },
        },
    },
    dropdown: {
        '& .MuiInputBase-root': {
            '& input': {
                fontSize: 13,
                padding: '16px 14px',
                color: (theme) => theme.textColor.primary,
                '&::placeholder': {
                    color: (theme) => theme.textColor.primary,
                    opacity: 1,
                    fontSize: 13,
                },
            },

            backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#394150' : 'none'),
            '& textarea': {
                '&::placeholder': {
                    color: (theme) => theme.textColor.primary,
                    opacity: 1,
                    fontSize: 13,
                },
            },
            '&:hover fieldset': {
                borderColor: '#6C727F',
                border: '1px solid #E5E6EB',
            },
            borderRadius: 2,
            '& fieldset': {
                borderColor: (theme) => (theme.palette.mode === 'dark' ? '#394150' : 'white'),
            },
            boxShadow: (theme) => theme.boxShadow,
            '& li': {
                color: (theme) => theme.textColor.inputField,
                fontSize: '16px',
                fontWeight: 400,
            },
            backgroundImage: 'unset',
            border: (theme) => `1px solid ${theme.borderColor.inputField}`,
        },
    },
    datePicker: {
        '& .MuiInputBase-root': {
            backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#394150' : 'white'),
            borderRadius: '8px',
            border: 'none',
            height: '48px',
            minHeight: '40px',
            fontSize: 13,
            color: (theme) => (theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F'),
            '&:hover .MuiOutlinedInput-notchedOutline': {
                border: (theme) =>
                    `1px solid ${theme.palette.mode === 'dark' ? '#E5E6EB' : '#E5E6EB'}`,
            },
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
            },
        },
        '& .MuiSvgIcon-root': {
            color: (theme) => (theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F'),
        },
        '& .MuiInputBase-input::placeholder': {
            color: (theme) => (theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F'),
            opacity: 1,
            fontSize: 13,
        },
        width: '100%',
        '& .MuiInputLabel-root': { fontSize: '13px' },
        '& .MuiOutlinedInput-input': { fontSize: '13px' },
    },
});

export const paymentPlansStyles = ({ isMobileScreen }) => ({
    root: {
        p: '10px 0px',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    addButtonText: {
        fontWeight: 500,
        color: '#FFF',
    },
    exclusionRuleButton: {
        padding: '12px 20px',
        height: '48px',
        '& .MuiButton-startIcon': {
            mr: isMobileScreen ? '0px' : '8px',
        },
    },
});

export const notesText = (theme) => {
    return { color: theme === 'dark' ? '#9EA3AE' : '#4D5461', fontSize: '13px' };
};

export const paymentPlanFormStyles = () => ({
    rootWrapper: {
        background: (theme) => (theme.palette.mode == 'dark' ? '#212936' : 'white'),
        padding: '24px',
        borderRadius: '12px',
    },
    headingWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '15px',
        justifyContent: 'space-between',
    },
    datePicker: {
        '& .MuiInputBase-root': {
            backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#394150' : 'rgb(244, 244, 246)',
            borderRadius: '8px',
            border: 'none',
            height: '48px',
            minHeight: '40px',
            fontSize: 13,
            color: (theme) => (theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F'),
            '&:hover .MuiOutlinedInput-notchedOutline': {
                border: (theme) =>
                    `1px solid ${theme.palette.mode === 'dark' ? '#E5E6EB' : '#E5E6EB'}`,
            },
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
            },
        },
        '& .MuiSvgIcon-root': {
            color: (theme) => (theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F'),
        },
        '& .MuiInputBase-input::placeholder': {
            color: (theme) => (theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F'),
            opacity: 1,
            fontSize: 13,
        },
        width: '100%',
        '& .MuiInputLabel-root': { fontSize: '13px' },
        '& .MuiOutlinedInput-input': { fontSize: '13px' },
    },
    dateError: {
        fontWeight: 600,
        marginLeft: 0,
    },
    cancelButton: {
        color: 'white',
        background: (theme) => theme.backgroundColor.primary,
        color: (theme) => theme.primary.dark,
        border: '1px solid #E5E6EB',
        '&:hover': {
            backgroundColor: (theme) => theme.backgroundColor.secondary,
        },
        borderRadius: '10px',
        textTransform: 'none',
    },
});

export const paymentPlanDetailsStyles = () => ({
    cancelButton: {
        marginRight: '10px',
        marginLeft: '10px',
        background: (theme) => theme.backgroundColor.primary,
        color: (theme) => theme.primary.dark,
        border: '1px solid #E5E6EB',
        '&:hover': {
            backgroundColor: (theme) => theme.backgroundColor.secondary,
        },
    },

    inputField: {
        '& .MuiInputBase-root': {
            '& input': {
                fontSize: 13,
                padding: '16px 14px',
                color: (theme) => theme.textColor.primary,
                '&::placeholder': {
                    color: (theme) => theme.textColor.primary,
                    opacity: 1,
                    fontSize: 13,
                },
            },

            backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#394150' : '#F4F4F6'),
            '& textarea': {
                '&::placeholder': {
                    color: (theme) => theme.textColor.primary,
                    opacity: 1,
                    fontSize: 13,
                },
            },
            '&:hover fieldset': {
                borderColor: '#6C727F',
                border: '1px solid #E5E6EB',
            },
            borderRadius: 2,
            '& fieldset': {
                borderColor: (theme) => (theme.palette.mode === 'dark' ? '#394150' : 'white'),
            },
        },
    },
});

export const AIMatchingViewStyles = () => ({
    cancelButton: {
        fontWeight: '500',
        fontSize: '14px',
        color: '#6C727F',
        border: (theme) =>
            theme.palette.mode === 'dark' ? '1px solid #394150' : '1px solid #E5E6EB',
        textTransform: 'none',
        marginRight: '20px',
        backgroundColor: (theme) => theme.backgroundColor.primary,
    },

    completeCard: {
        background: (theme) => (theme.palette.mode === 'dark' ? '#212936' : 'rgb(244, 244, 246)'),

        mb: 3,
        p: 3,
        borderRadius: 2,
        width: '100%',
        maxWidth: 1100,
        // backgroundColor: '#fff',
        backgroundColor: (theme) => theme.backgroundColor.primary,

        // color: '#000',
    },
    completeInputCard: {
        mb: 3,
        p: 3,
        borderRadius: 2,
        width: '100%',
        maxWidth: 1100,
        backgroundColor: '#fff',
    },
    matchCard: {
        mb: 3,
        p: 3,
        borderRadius: 2,
        width: '100%',
        maxWidth: 1100,
        backgroundColor: '#fff',
        animation: 'fadeIn 0.5s ease forwards',
    },
});
