import React from 'react';
import { Bar } from 'react-chartjs-2';

export const BarChart = ({ options, data }) => {
    //this is sample data for double bar chart -- testing
    // const data2 = {
    //     labels: ['Dec 24', 'Jan 25', 'Feb 25', 'Mar 25'],
    //     datasets: [
    //         {
    //             label: 'Positive Values',
    //             data: [0, 92, 42, 92], // Extract only positive values
    //             backgroundColor: '#00C791', // Green color for positive values
    //             barThickness: 12,
    //             borderRadius: 5,
    //             maxBarThickness: 12,
    //         },
    //         {
    //             label: 'Negative Values',
    //             data: [0, -2, -24, -2], // Extract only negative values
    //             backgroundColor: '#FF4D4D', // Red color for negative values
    //             barThickness: 12,
    //             borderRadius: 5,
    //             maxBarThickness: 12,
    //         },
    //     ],
    // };

    return <Bar options={options} data={data} width={200} height={120} />;
};
