import React, { useState, useEffect } from 'react';
import { List, Paper, Typography } from '@mui/material';

import { balanceSheetStyles } from '../style/syles';

import RecieptLoader from './RecieptLoader';
import RecieptTableLevelOne from './RecieptTableLevelOne';
import { useCustomMediaQuery } from 'hooks/useMediaQuery';
import { REPORT_TYPES } from '../../../constants/menu.constants';

const ProfitLoss = ({ data, handleAccordionChange, isLoading }) => {
    const isMobileScreen = useCustomMediaQuery('(max-width: 600px)');
    const classes = balanceSheetStyles({ isMobileScreen });

    return (
        <Paper sx={classes.paperContainer}>
            <Typography variant="h6" sx={classes.title}>
                {REPORT_TYPES.PROFIT_LOSS.label}
            </Typography>

            {isLoading ? (
                <RecieptLoader />
            ) : (
                <List>
                    {data.map((item) => (
                        <RecieptTableLevelOne
                            key={item.name}
                            item={item}
                            handleAccordionChange={handleAccordionChange}
                        />
                    ))}
                </List>
            )}
        </Paper>
    );
};

export default ProfitLoss;
