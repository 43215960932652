import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import { Box, Grid, Stack, Button, CircularProgress } from '@mui/material';

import { Modal } from 'shared';
import TransactionCard from './TransactionCard';
import ModalPaymentPlans from './ModalPaymentPlans';
import TransactionSummary from './TransactionSummary';
import ModalSalesActivity from './ModalSalesActivity';
import {
    aggInventorySchema,
    aggInventoryInitialValues,
} from 'components/Inventory/utils/aggInventorySchema';
import { useCustomMediaQuery } from 'hooks/useMediaQuery';
import ModalPurchaseActivity from './ModalPurchaseActivity';
import { useInventoryService } from 'store/services/inventory.service';
import { usePaymentPlanService } from 'store/services/paymentPlan.service';
import { matchWithAggregatedFileInventoryStyles } from 'components/Inventory/style/style';

const MatchWithAggregatedFileInventory = ({
    transaction,
    showMatchingModal,
    setShowMatchingModal,
    gettingInitialInventoryForReview,
}) => {
    const classes = matchWithAggregatedFileInventoryStyles();
    const isMobileScreen = useCustomMediaQuery('(max-width: 600px)');
    const { id: companyId } = useSelector((state) => state.company.data);
    const { getAggregatedFileInventories, matchAggFileInventoryWithPlaidTransaction } =
        useInventoryService();
    const { matchPlaidTransactionWithPaymentPlan } = usePaymentPlanService();
    const [page, setPage] = useState(1);

    const [checked, setChecked] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedTabId, setSelectedTabId] = useState('purchase');
    const [remainingAmount, setRemainingAmount] = useState(0.0);
    const [matchButtonDisable, setMatchButtonDisable] = useState(true);
    const [totalAmountSelected, setTotalAmountSelected] = useState(0.0);
    const [matchButtonLoading, setMatchButtonLoading] = useState(false);
    const [unmatchedInventories, setUnmatchedInventories] = useState(null);
    const [selectedPaymentPlans, setSelectedPaymentPlans] = useState([]);

    const changeTab = (tab) => {
        setSelectedTabId(tab.id);

        setSelectedRows([]);
        setRemainingAmount(0.0);
        setMatchButtonDisable(true);
        setSelectedOption(null);
        setTotalAmountSelected(0.0);
        setPage(1);
        setSelectedPaymentPlans([]);
        if (tab.id === 'purchase') {
            handleGetAggregatedFileInventories(1, {}, tab.id);
        } else if (tab.id === 'payment_plans') {
        } else if (tab.id === 'sales') {
            handleGetAggregatedFileInventories(1, {}, tab.id);
        }
    };

    useEffect(() => {
        handleGetAggregatedFileInventories(page, {}, selectedTabId);
    }, []);

    const handleGetAggregatedFileInventories = async (page, filters, activityType) => {
        try {
            setTableLoading(true);
            const response = await getAggregatedFileInventories({
                companyId,
                page,
                isReviewed: 'False',
                description: filters.description,
                ...(activityType === 'sales'
                    ? {
                          saleStartDate: filters.saleStartDate
                              ? dayjs(filters.saleStartDate).format('YYYY-MM-DD')
                              : undefined,
                          saleEndDate: filters.saleEndDate
                              ? dayjs(filters.saleEndDate).format('YYYY-MM-DD')
                              : undefined,
                      }
                    : {
                          startDate: filters.startDate
                              ? dayjs(filters.startDate).format('YYYY-MM-DD')
                              : undefined,
                          endDate: filters.endDate
                              ? dayjs(filters.endDate).format('YYYY-MM-DD')
                              : undefined,
                      }),

                amount: filters.amount,
                purchaseId: filters.purchaseId,
                activityType: activityType,
            });
            setUnmatchedInventories(response);
        } catch (error) {
            console.error(error);
        } finally {
            setTableLoading(false);
        }
    };

    const handlePageChange = (page, filters) => {
        setPage(page);
        handleGetAggregatedFileInventories(page, filters, selectedTabId);
    };

    const handleCheckboxChange = (inventory) => {
        setSelectedRows((prevSelected) => {
            const isSelected = prevSelected.some((item) => item.id === inventory.id);
            let updatedSelectedRows;

            if (isSelected) {
                updatedSelectedRows = prevSelected.filter((item) => item.id !== inventory.id);
            } else {
                updatedSelectedRows = [...prevSelected, inventory];
            }

            const selectedRowsSum = updatedSelectedRows.reduce(
                (sum, row) =>
                    sum +
                    parseFloat(
                        row?.activity_type == 'purchase' ? row.purchase_price : row.amount_due,
                    ),
                0,
            );

            const amount = (Math.abs(transaction.amount) - Math.abs(selectedRowsSum)).toFixed(2);

            setTotalAmountSelected(selectedRowsSum);
            setRemainingAmount(amount);
            setSelectedOption(null);

            if (updatedSelectedRows.length === 1 && checked) {
                setMatchButtonDisable(false);
            } else {
                setMatchButtonDisable(amount != 0);
            }

            return updatedSelectedRows;
        });
    };

    const handleSelectedOptionChange = (value) => {
        setSelectedOption(value);
        setMatchButtonDisable(false);
    };

    const handleCheckedChange = (value) => {
        setChecked(value);
        if (value && selectedRows.length === 1) {
            setMatchButtonDisable(false);
        }
        if (!value) {
            setMatchButtonDisable(true);
        }
    };

    const handleMatchClick = async (modalType) => {
        try {
            setMatchButtonLoading(true);

            if (modalType === 'Payment Plans') {
                const data = {
                    plaid_transaction_id: transaction.id,
                    payment_plan_id: selectedPaymentPlans[0].id,
                    company_id: companyId,
                };

                await matchPlaidTransactionWithPaymentPlan(data);
            } else {
                const data = {
                    plaidTransactionId: transaction.id,
                    adjustedPrice: remainingAmount != 0 ? remainingAmount : null,
                    aggregatedFileInventoryIds: selectedRows.map((row) => row.id),
                    adjustedAggregatedFileInventoryId:
                        remainingAmount != 0
                            ? selectedRows.length == 1
                                ? selectedRows[0]?.id
                                : selectedOption.value
                            : null,
                    companyId,
                };
                await matchAggFileInventoryWithPlaidTransaction(data);
                gettingInitialInventoryForReview();
            }

            setShowMatchingModal(false);
        } catch (error) {
            console.error(error);
        } finally {
            setMatchButtonLoading(false);
        }
    };

    const handleClearClick = (handleReset) => {
        setPage(1);
        handleReset();
        handleGetAggregatedFileInventories(1, {}, selectedTabId);
    };

    useEffect(() => {
        handleGetAggregatedFileInventories(page, {}, selectedTabId);
    }, []);

    const modalTabsData = [
        {
            id: 'purchase',
            label: 'Purchase Activity',
            component: (
                <ModalPurchaseActivity
                    initialValues={aggInventoryInitialValues}
                    validationSchema={aggInventorySchema}
                    unmatchedInventories={unmatchedInventories}
                    selectedRows={selectedRows}
                    handleCheckboxChange={handleCheckboxChange}
                    tableLoading={tableLoading}
                    page={page}
                    handlePageChange={handlePageChange}
                    handleGetAggregatedFileInventories={handleGetAggregatedFileInventories}
                    isMobileScreen={isMobileScreen}
                    handleClearClick={handleClearClick}
                />
            ),
        },
        {
            id: 'payment_plans',
            label: 'Payment Plans',
            component: (
                <ModalPaymentPlans
                    setMatchButtonDisable={setMatchButtonDisable}
                    setSelectedPaymentPlans={setSelectedPaymentPlans}
                    selectedPaymentPlans={selectedPaymentPlans}
                />
            ),
        },
        // {
        //     id: 'sales',
        //     label: 'Sales Activity',
        //     component: (
        //         <ModalSalesActivity
        //             initialValues={aggInventoryInitialValues}
        //             validationSchema={aggInventorySchema}
        //             unmatchedInventories={unmatchedInventories}
        //             selectedRows={selectedRows}
        //             handleCheckboxChange={handleCheckboxChange}
        //             tableLoading={tableLoading}
        //             page={page}
        //             handlePageChange={handlePageChange}
        //             handleGetAggregatedFileInventories={handleGetAggregatedFileInventories}
        //             isMobileScreen={isMobileScreen}
        //             handleClearClick={handleClearClick}
        //         />
        //     ),
        // },
    ];

    return (
        <Modal
            fullWidth={true}
            open={showMatchingModal}
            title="Select Activity"
            handleClose={() => setShowMatchingModal(false)}
            customWidth="95%"
            showFooter
            footer={
                <Box display="flex" justifyContent="flex-end" gap={2}>
                    <Button
                        variant="outlined"
                        className={classes.cancelButton}
                        onClick={() => setShowMatchingModal(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        disabled={matchButtonDisable || matchButtonLoading}
                        onClick={() => handleMatchClick(selectedTabId)}
                        className={matchButtonLoading ? classes.loadingButton : ''}
                        startIcon={
                            matchButtonLoading && <CircularProgress size={20} color="inherit" />
                        }
                    >
                        Match
                    </Button>
                </Box>
            }
        >
            <Grid container spacing={2} alignItems="center" marginBottom="10px">
                <Grid item xs={12} sm={4} md={3}>
                    <TransactionSummary
                        checked={checked}
                        transaction={transaction}
                        selectedRows={selectedRows}
                        selectedOption={selectedOption}
                        remainingAmount={remainingAmount}
                        numSelected={selectedRows.length}
                        setSelectedOption={setSelectedOption}
                        totalAmountSelected={totalAmountSelected}
                        handleCheckedChange={handleCheckedChange}
                        setMatchButtonDisable={setMatchButtonDisable}
                        handleSelectedOptionChange={handleSelectedOptionChange}
                    />
                </Grid>

                <Grid item xs={12} sm={8} md={9}>
                    <TransactionCard transaction={transaction} />
                </Grid>
            </Grid>

            <Box padding={1} sx={classes.menuWrapper}>
                <Stack
                    display="flex"
                    flexDirection={{ xs: 'column', md: 'row' }}
                    gap={1}
                    sx={classes.stack}
                    alignItems="center"
                >
                    {modalTabsData.map((tab) => (
                        <Grid item key={tab.id} borderRadius={'5px'}>
                            <Button
                                onClick={() => changeTab(tab)}
                                sx={classes.tabButton(selectedTabId === tab.id)}
                                variant={selectedTabId === tab.id ? 'contained' : 'text'}
                            >
                                {tab.label}
                            </Button>
                        </Grid>
                    ))}
                </Stack>
            </Box>

            <Box padding={2}>
                {modalTabsData.find((tab) => tab.id === selectedTabId)?.component}
            </Box>
        </Modal>
    );
};

export default MatchWithAggregatedFileInventory;
