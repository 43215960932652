import React, { useEffect, useState } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, IconButton, useTheme } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import BankCard from './BankCard';
import { bankCarouselMakeStyles } from '../style/style';

const BankCardCarousel = ({ bankCardsData, selectedBank, setSelectedBank }) => {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));

    const [currentIndex, setCurrentIndex] = useState(0);
    const [cardsPerPage, setCardsPerPage] = useState(3);

    const shouldShowArrows = bankCardsData.length > cardsPerPage;
    const classes = bankCarouselMakeStyles({
        currentIndex,
        cardsPerPage,
        shouldShowArrows,
    });

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            Math.min(prevIndex + 1, bankCardsData.length - cardsPerPage),
        );
    };

    useEffect(() => {
        if (isLargeScreen) {
            setCardsPerPage(3);
        } else if (isMediumScreen) {
            setCardsPerPage(2);
        } else if (isSmallScreen) {
            setCardsPerPage(1);
        }
    }, [isLargeScreen, isMediumScreen, isSmallScreen]);

    return (
        <Box sx={classes.carouselWrapper}>
            {shouldShowArrows && (
                <IconButton
                    onClick={handlePrev}
                    disabled={currentIndex === 0}
                    sx={classes.leftArrowButton}
                >
                    <ArrowBackIosIcon />
                </IconButton>
            )}

            <Box sx={classes.bankCardsWrapper}>
                {bankCardsData.map((data, index) => (
                    <Box
                        key={index}
                        sx={classes.bankCardWrapper}
                        onClick={() => setSelectedBank(data)}
                    >
                        <BankCard data={data} selectedBank={selectedBank} />
                    </Box>
                ))}
            </Box>

            {shouldShowArrows && (
                <IconButton
                    onClick={handleNext}
                    disabled={
                        currentIndex === bankCardsData.length - cardsPerPage
                    }
                    sx={classes.rightArrowButton}
                >
                    <ArrowForwardIosIcon />
                </IconButton>
            )}
        </Box>
    );
};

export default BankCardCarousel;
