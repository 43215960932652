import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Grid, Stack, Box, Paper } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { reportStyles } from './style/syles';

import { SnackBarAlert } from 'shared';
import AJAX from 'services/api.service';
import { tabsData } from './utils/tabsData';
import { SET_SELECTED_ITEM } from 'store/actions';
import ProfitLoss from './components/ProfitLoss';
import CashActivity from './components/CashActivity';
import BalanceSheet from './components/BalanceSheet';
import { useCustomMediaQuery } from 'hooks/useMediaQuery';
import { downloadFile, formateDate } from 'utils/helpers';
import { handleSuccessAlert } from 'utils/handleSuccessAlert';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { REPORT_TYPES } from '../../constants/menu.constants';
import { useReportService } from 'store/services/report.service';

const Reports = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const isMobileScreen = useCustomMediaQuery('(max-width: 600px)');
    const classes = reportStyles({ isMobileScreen });

    const [balanceSheetData, setBalanceSheetData] = useState([]);
    const [profitLossData, setProfitLossData] = useState([]);
    const [cashActivityData, setCashActivityData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const selectedTab = useSelector((state) => state.me.selectedItems);
    const { data: company } = useSelector((state) => state.company);
    const [currency, setCurrency] = useState('USD');
    const [dateRange, setDateRange] = useState({
        startDate: '',
        endDate: '',
    });
    const [alertData, setAlertData] = useState({
        severity: '',
        open: false,
        message: '',
    });
    const [tempDateRange, setTempDateRange] = useState({
        startDate: '',
        endDate: '',
    });

    const onChangeDateRange = (key, value) => {
        setTempDateRange((prev) => ({
            ...prev,
            [key]: value ? formateDate(value, 'yyyy-MM-dd') : '',
        }));
    };

    const {
        downloadCashReportCSV,
        downloadProfitLossCSV,
        downloadBSReportCSV,
    } = useReportService();

    const changeTab = (tab) => {
        const defaultDateRange = {
            startDate: '',
            endDate: '',
        };
        setDateRange(defaultDateRange);
        setTempDateRange(defaultDateRange);

        dispatch(SET_SELECTED_ITEM(tab.label));
        navigate(tab.path);
    };

    const getSummaryReport = async () => {
        if (!company) return;
        setIsLoading(true);
        let result;
        switch (selectedTab) {
            case REPORT_TYPES.BALANCE_SHEET.label:
                result = await AJAX.request(
                    'GET',
                    'api/dashboard/balance_sheet_report',
                    {
                        company_id: company.id,
                        currency,
                        start_date: dateRange.startDate,
                        end_date: dateRange.endDate,
                    },
                    true,
                );
                const processedData = Object.entries(result.data)
                    .filter(([key]) => !key.startsWith('total_'))
                    .map(([section, categories]) => ({
                        name:
                            section.charAt(0).toUpperCase() + section.slice(1),
                        total: parseFloat(result.data[`total_${section}`]) || 0,
                        children: Object.entries(categories).map(
                            ([category, accounts]) => ({
                                name: category,
                                children: accounts.map((account) => ({
                                    name: account.account_name,
                                    total: account.amount || 0,
                                    account_id: account.account_id,
                                    children: [],
                                })),
                            }),
                        ),
                    }));

                setBalanceSheetData([...processedData]);

                break;

            case REPORT_TYPES.PROFIT_LOSS.label:
                result = await AJAX.request(
                    'GET',
                    'api/dashboard/profit_loss_report',
                    {
                        company_id: company.id,
                        currency,
                        end_date: dateRange.endDate,
                        start_date: dateRange.startDate,
                    },
                    true,
                );
                if (result.error_code === 0) {
                    setProfitLossData(
                        Object.keys(result.data)
                            .filter((key) => !key.startsWith('total_'))
                            .map((section) => ({
                                name: section.replace(/_/g, ' '),
                                children: result.data[section].map(
                                    (account) => ({
                                        name: account.account_name,
                                        total: parseFloat(account.amount) || 0,
                                        account_id: account.account_id,
                                        children: [],
                                    }),
                                ),
                                total:
                                    parseFloat(
                                        result.data[`total_${section}`],
                                    ) || 0,
                            })),
                    );
                }

                break;

            case REPORT_TYPES.CASH_ACTIVITY.label:
                result = await AJAX.request(
                    'GET',
                    'api/dashboard/cash_flow_report',
                    {
                        company_id: company.id,
                        currency,
                        end_date: dateRange.endDate,
                        start_date: dateRange.startDate,
                    },
                    true,
                );
                if (result.error_code === 0) {
                    setCashActivityData(
                        Object.keys(result.data)
                            .filter((key) => !key.startsWith('total_'))
                            .map((section) => ({
                                name: section.replace(/_/g, ' '),
                                children: result.data[section].map(
                                    (account) => ({
                                        name: account.account_name,
                                        total: parseFloat(account.amount) || 0,
                                        account_id: account.account_id,
                                        children: [],
                                    }),
                                ),
                                total:
                                    parseFloat(
                                        result.data[`total_${section}`],
                                    ) || 0,
                            })),
                    );
                }

                break;

            default:
                break;
        }

        setIsLoading(false);
    };

    const handleDownloadCSV = useCallback(() => {
        if (!company) return;

        const downloadMap = {
            [REPORT_TYPES.CASH_ACTIVITY.label]: downloadCashReportCSV,
            [REPORT_TYPES.PROFIT_LOSS.label]: downloadProfitLossCSV,
            [REPORT_TYPES.BALANCE_SHEET.label]: downloadBSReportCSV,
        };

        const downloadFn = downloadMap[selectedTab];

        if (downloadFn) {
            downloadFn(
                company.id,
                currency,
                dateRange.startDate,
                dateRange.endDate,
            ).then((res) => {
                downloadFile(res, `${selectedTab}Report`);
                handleSuccessAlert(
                    setAlertData,
                    'File Successfully Downloaded',
                );
            });
        }
    }, [
        company?.id,
        selectedTab,
        currency,
        dateRange.startDate,
        dateRange.endDate,
    ]);

    const handleApplyClick = () => {
        setDateRange(tempDateRange);
    };

    const handleClearClick = () => {
        const defaultDateRange = {
            startDate: '',
            endDate: '',
        };
        setDateRange(defaultDateRange);
        setTempDateRange(defaultDateRange);
        getSummaryReport();
    };
    useEffect(() => {
        getSummaryReport();
    }, [dateRange, selectedTab, company, currency]);

    useEffect(() => {
        const currentTab = tabsData.find(
            (tab) => tab.path === location.pathname,
        );
        if (currentTab && currentTab.label !== selectedTab) {
            dispatch(SET_SELECTED_ITEM(currentTab.label));
        }
    }, [location.pathname, dispatch, selectedTab]);

    return (
        <>
            <Box>
                <Box padding={1} sx={classes.menuWrapper}>
                    <Stack
                        display="flex"
                        flexDirection={{ xs: 'column', md: 'row' }}
                        gap={1}
                        style={classes.stack}
                        alignItems="center"
                    >
                        {tabsData?.map((tab) => (
                            <Grid item key={tab.label} borderRadius={'5px'}>
                                <Button
                                    onClick={() => changeTab(tab)}
                                    sx={classes.tabButton(
                                        selectedTab === tab.label,
                                    )}
                                    variant={
                                        selectedTab === tab.label
                                            ? 'contained'
                                            : 'text'
                                    }
                                >
                                    {tab.label}
                                </Button>
                            </Grid>
                        ))}
                    </Stack>
                </Box>

                <Paper sx={classes.paperContainer}>
                    <Box sx={classes.filterBox}>
                        <Grid container sx={classes.datePickerGrid.container}>
                            <Grid item sx={classes.datePickerGrid.item}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        value={
                                            tempDateRange.startDate
                                                ? dayjs(tempDateRange.startDate)
                                                : null
                                        }
                                        onChange={(date) =>
                                            onChangeDateRange('startDate', date)
                                        }
                                        label="Start Date"
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item sx={classes.datePickerGrid.item}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        value={
                                            tempDateRange.endDate
                                                ? dayjs(tempDateRange.endDate)
                                                : null
                                        }
                                        onChange={(date) =>
                                            onChangeDateRange('endDate', date)
                                        }
                                        label="End Date"
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item sx={classes.buttonGroup}>
                                <Button
                                    size="small"
                                    variant="contained"
                                    sx={classes.smallButton}
                                    onClick={handleClearClick}
                                >
                                    Clear
                                </Button>

                                <Button
                                    size="small"
                                    variant="contained"
                                    sx={classes.exportButton}
                                    type="button"
                                    onClick={handleApplyClick}
                                >
                                    Apply
                                </Button>
                            </Grid>
                        </Grid>

                        <Button
                            onClick={handleDownloadCSV}
                            size="small"
                            variant="contained"
                            sx={classes.exportButton}
                        >
                            Export CSV
                        </Button>
                    </Box>
                </Paper>
                <Grid sx={{ marginTop: 2 }}>
                    {selectedTab === REPORT_TYPES.BALANCE_SHEET.label && (
                        <Grid sx={classes.tabContentWrapper}>
                            <BalanceSheet
                                data={balanceSheetData}
                                selectedTab={REPORT_TYPES.BALANCE_SHEET.value}
                                isLoading={isLoading}
                            />
                        </Grid>
                    )}
                    {selectedTab === REPORT_TYPES.PROFIT_LOSS.label && (
                        <Grid sx={classes.tabContentWrapper}>
                            <ProfitLoss
                                data={profitLossData}
                                selectedTab={REPORT_TYPES.PROFIT_LOSS.value}
                                isLoading={isLoading}
                            />
                        </Grid>
                    )}
                    {selectedTab === REPORT_TYPES.CASH_ACTIVITY.label && (
                        <Grid sx={classes.tabContentWrapper}>
                            <CashActivity
                                data={cashActivityData}
                                selectedTab={REPORT_TYPES.CASH_ACTIVITY.value}
                                isLoading={isLoading}
                            />
                        </Grid>
                    )}
                </Grid>
            </Box>
            <SnackBarAlert alertData={alertData} setAlertData={setAlertData} />
        </>
    );
};

export default Reports;
