import { useSelector } from 'react-redux';
import React, { Fragment, useEffect, useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { useCustomMediaQuery } from 'hooks/useMediaQuery';
import { handleErrorAlert } from 'utils/handleErrorAlert';
import { useInventoryService } from 'store/services/inventory.service';
import { matchedTableHeaders } from '../../../utils/matchedReviewHeaders';
import { DataTable, CircleLoading, SnackBarAlert, TablePagination } from 'shared';

const Matched = () => {
    const { getInventoryForMatched, unmatchAggFileInventoryAndPlaidTransaction } =
        useInventoryService();
    const companyData = useSelector((state) => state.company.data);
    const isMobileScreen = useCustomMediaQuery('(max-width: 600px)');

    const [page, setPage] = useState(1);
    const [data, setData] = useState(null);
    const [alertData, setAlertData] = useState({
        severity: '',
        open: false,
        message: '',
    });
    const [tableLoading, setTableLoading] = useState(true);
    const [loadingRowId, setLoadingRowId] = useState(null);

    const handleGetInventoryForMatched = async (page) => {
        try {
            setTableLoading(true);
            const response = await getInventoryForMatched(companyData.id, page);
            setData(response);
        } catch (error) {
            handleErrorAlert(setAlertData);
        } finally {
            setTableLoading(false);
        }
    };

    const handleUnmatchAggFileInventoryAndPlaidTransaction = async (rowData) => {
        try {
            setLoadingRowId(rowData?.id);
            const response = await unmatchAggFileInventoryAndPlaidTransaction({
                companyId: companyData?.id,
                plaidTransactionId: rowData?.id,
                aggregatedFileInventoryId: rowData?.aggregated_file_inventories?.[0]?.id,
            });
            setAlertData({
                severity: 'success',
                message: response.message,
                open: true,
            });
            handleGetInventoryForMatched(page);
        } catch (error) {
            handleErrorAlert(setAlertData);
        }
    };

    const handlePageChange = async (page) => {
        setPage(page);
        await handleGetInventoryForMatched(page);
    };

    useEffect(() => {
        handleGetInventoryForMatched(page);
    }, []);

    return (
        <Fragment>
            {companyData?.id && data ? (
                <Grid container>
                    {data?.count > 0 && (
                        <Grid
                            item
                            xs={12}
                            display="flex"
                            marginBottom="5px"
                            justifyContent="flex-end"
                        >
                            <Typography fontSize="18px">{`TOTAL: ${data?.count} ROW${
                                data?.count > 1 ? 'S' : ''
                            }`}</Typography>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <DataTable
                            headCells={matchedTableHeaders(loadingRowId, (rowData) =>
                                handleUnmatchAggFileInventoryAndPlaidTransaction(rowData),
                            )}
                            isLoading={tableLoading}
                            data={data.results}
                        />
                    </Grid>
                    <Grid item xs={12} p={'10px 0'}>
                        <TablePagination
                            isMobileScreen={isMobileScreen}
                            page={page}
                            count={data?.count}
                            onChange={(e, pageNumber) => handlePageChange(pageNumber)}
                        />
                    </Grid>
                </Grid>
            ) : (
                <CircleLoading />
            )}

            <SnackBarAlert alertData={alertData} setAlertData={setAlertData} />
        </Fragment>
    );
};

export default Matched;
