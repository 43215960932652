import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
    Grid,
    Link,
    Fade,
    Typography,
    IconButton,
    FormControlLabel,
    InputAdornment,
} from '@mui/material';
import Image from 'mui-image';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

import {
    SAVE_TOKEN,
    SIGN_UP_ERROR,
    SIGN_UP_PENDING,
    SIGN_UP_SUCCESS,
} from '../store/actions';
import { useThemeToggle } from '../hooks/useThemeToggle';
import CirclerImage from '../assets/icons/Content-Left.svg';
import { useCustomMediaQuery } from '../hooks/useMediaQuery';
import { useAuthService } from '../store/services/auth.service';
import { TextField, Button, CheckboxSelection } from '../shared';
import { ReactComponent as CirclerLogo } from '../assets/icons/circler-logo.svg';
import { ReactComponent as DarkCirclerLogo } from '../assets/icons/DarkLogo.svg';

const styles = {
    container: {
        height: '100vh',
        overflow: 'hidden',
    },
    mainHeading: {
        fontSize: '32px',
        fontWeight: '700',
        color: (theme) => theme.textColor.dark,
        lineHeight: '38px',
    },
    subHeading: {
        fontSize: '18px',
        fontWeight: '500',
        color: '#6C727F',
        lineHeight: '24px',
        mt: 0.5,
    },
    loginBtnStyle: {
        borderRadius: '8px',
        fontSize: '18px',
        fontWeight: 500,
        height: '48px',
    },
    signUpOption: {
        color: '#FFF',
        fontSize: '16px',
        fontWeight: '500',
    },
    link: {
        textDecoration: 'none',
        color: (theme) =>
            theme.palette.mode === 'dark' ? '#3381E2' : '#0061DB',
        cursor: 'pointer',
        fontWeight: 600,
        fontSize: '16px',
    },
    termsAndCondition: {
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '20px',
        color: (theme) => theme.textColor.dark,
    },
    otherSignUpOption: {
        color: (theme) => theme.textColor.dark,
        fontSize: '16px',
        fontWeight: '400',
    },
};

const RegisterSchema = Yup.object().shape({
    email: Yup.string()
        .required('Email is required')
        .email('Email must be a valid email address'),
    confirmEmail: Yup.string()
        .required('Confirm your email')
        .oneOf([Yup.ref('email'), null], 'Emails must match'),
    password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('Password is required'),
    confirmPassword: Yup.string()
        .required('Confirm your password')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    acceptedTerms: Yup.boolean()
        .oneOf([true], 'You must accept the Terms and Conditions')
        .required('You must accept the Terms and Conditions'),
});

const initialValues = {
    email: '',
    confirmEmail: '',
    password: '',
    confirmPassword: '',
    acceptedTerms: false,
};

export const SignUp = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { themeMode } = useThemeToggle();
    const { signup, isLoading } = useAuthService();
    const isScreenLowerThan1024 = useCustomMediaQuery('(max-width: 1025px)');

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [passwordVisible2, setPasswordVisible2] = useState(false);

    const handleSubmit = async (values, { setSubmitting, setStatus }) => {
        setStatus({ serverError: '' });

        try {
            dispatch(SIGN_UP_PENDING());
            const res = await signup(
                values.email.toLowerCase(),
                values.password,
            );
            dispatch(SIGN_UP_SUCCESS());
            dispatch(SAVE_TOKEN({ token: res.token }));
            navigate('/onboarding');
        } catch (err) {
            dispatch(SIGN_UP_ERROR({ error: err.errorMessage }));
            setStatus({ serverError: err.errorMessage });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Grid
            flex={{ xs: '1', sm: '2', md: '3' }}
            container
            sx={{
                ...styles.container,
                overflow: isScreenLowerThan1024 ? 'auto' : 'hidden',
            }}
        >
            <Grid
                item
                xs={12}
                md={6}
                lg={6}
                position="relative"
                maxWidth="700px"
                display={{ xs: 'none', md: 'block' }}
                overflow="hidden"
            >
                <Image alt="Left Content" src={CirclerImage} />
            </Grid>
            <Grid
                container
                item
                xs={12}
                md={6}
                lg={6}
                flex="1"
                alignContent="flex-start"
                justifyContent="center"
                px={{ xs: 2, sm: 4, md: 8 }}
                pt="50px"
                sx={{
                    overflow: 'auto',
                    backgroundColor: (theme) => theme.backgroundColor.primary,
                }}
            >
                <Grid
                    item
                    container
                    direction="column"
                    maxWidth="700px"
                    padding={isScreenLowerThan1024 ? '24px 0' : '0 10%'}
                    gap={2}
                    textAlign={'start'}
                >
                    <Grid item>
                        {themeMode === 'dark' ? (
                            <DarkCirclerLogo
                                width="180.995px"
                                height="45.22px"
                            />
                        ) : (
                            <CirclerLogo width="180.995px" height="45.22px" />
                        )}
                    </Grid>

                    {showConfirmation ? (
                        <>
                            <Grid item>
                                <Typography
                                    sx={{
                                        ...styles.mainHeading,
                                        marginBottom: '10px',
                                    }}
                                >
                                    Confirm your account
                                </Typography>
                                <Typography sx={styles.subHeading}>
                                    A confirmation email has been sent to your
                                    email address. Please activate your account.
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button
                                    text={
                                        <Typography sx={styles.signUpOption}>
                                            Sign In
                                        </Typography>
                                    }
                                    fullWidth
                                    customStyles={styles.loginBtnStyle}
                                    onClick={() => navigate('/login')}
                                />
                            </Grid>
                        </>
                    ) : (
                        <Formik
                            initialValues={initialValues}
                            validationSchema={RegisterSchema}
                            onSubmit={handleSubmit}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                submitForm,
                                isSubmitting,
                                status,
                            }) => (
                                <Form>
                                    <Grid
                                        container
                                        spacing={2}
                                        direction="column"
                                    >
                                        <Grid item>
                                            <Typography sx={styles.mainHeading}>
                                                Create an account
                                            </Typography>
                                            <Typography sx={styles.subHeading}>
                                                Please enter your details to
                                                sign up.
                                            </Typography>
                                        </Grid>

                                        <Grid item>
                                            <TextField
                                                name="email"
                                                placeholder="Email *"
                                                type="text"
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={
                                                    touched.email &&
                                                    Boolean(errors.email)
                                                }
                                                helperText={
                                                    touched.email &&
                                                    errors.email
                                                }
                                            />
                                        </Grid>

                                        <Fade in={!!values.email} unmountOnExit>
                                            <Grid item>
                                                <TextField
                                                    name="confirmEmail"
                                                    placeholder="Confirm Email *"
                                                    type="text"
                                                    value={values.confirmEmail}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        touched.confirmEmail &&
                                                        Boolean(
                                                            errors.confirmEmail,
                                                        )
                                                    }
                                                    helperText={
                                                        touched.confirmEmail &&
                                                        errors.confirmEmail
                                                    }
                                                />
                                            </Grid>
                                        </Fade>

                                        <Grid item>
                                            <TextField
                                                name="password"
                                                placeholder="Password *"
                                                type={
                                                    passwordVisible
                                                        ? 'text'
                                                        : 'password'
                                                }
                                                value={values.password}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={
                                                    touched.password &&
                                                    Boolean(errors.password)
                                                }
                                                helperText={
                                                    touched.password &&
                                                    errors.password
                                                }
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={() =>
                                                                    setPasswordVisible(
                                                                        (
                                                                            prev,
                                                                        ) =>
                                                                            !prev,
                                                                    )
                                                                }
                                                                edge="end"
                                                            >
                                                                {passwordVisible ? (
                                                                    <VisibilityOutlinedIcon fontSize="small" />
                                                                ) : (
                                                                    <VisibilityOffOutlinedIcon fontSize="small" />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>

                                        <Fade
                                            in={!!values.password}
                                            unmountOnExit
                                        >
                                            <Grid item>
                                                <TextField
                                                    name="confirmPassword"
                                                    placeholder="Confirm Password *"
                                                    type={
                                                        passwordVisible2
                                                            ? 'text'
                                                            : 'password'
                                                    }
                                                    value={
                                                        values.confirmPassword
                                                    }
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        touched.confirmPassword &&
                                                        Boolean(
                                                            errors.confirmPassword,
                                                        )
                                                    }
                                                    helperText={
                                                        touched.confirmPassword &&
                                                        errors.confirmPassword
                                                    }
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle confirm password visibility"
                                                                    onClick={() =>
                                                                        setPasswordVisible2(
                                                                            (
                                                                                prev,
                                                                            ) =>
                                                                                !prev,
                                                                        )
                                                                    }
                                                                    edge="end"
                                                                >
                                                                    {passwordVisible2 ? (
                                                                        <VisibilityOutlinedIcon fontSize="small" />
                                                                    ) : (
                                                                        <VisibilityOffOutlinedIcon fontSize="small" />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                        </Fade>

                                        <Grid container item>
                                            <FormControlLabel
                                                sx={{ padding: 0 }}
                                                control={
                                                    <CheckboxSelection
                                                        color="primary"
                                                        name="acceptedTerms"
                                                        checked={
                                                            values.acceptedTerms
                                                        }
                                                        onChange={handleChange}
                                                    />
                                                }
                                                label={
                                                    <Typography
                                                        sx={
                                                            styles.termsAndCondition
                                                        }
                                                    >
                                                        Agree to the&nbsp;
                                                        <Link
                                                            href="https://www.termsfeed.com/live/2c8418f9-830b-4917-95c9-1a30f68a8c7a"
                                                            target="_blank"
                                                            sx={styles.link}
                                                        >
                                                            Terms and Conditions
                                                        </Link>{' '}
                                                        and{' '}
                                                        <Link
                                                            href="https://www.termsfeed.com/live/c453ce2a-a7f6-4fd4-8956-efc53c6f2510"
                                                            target="_blank"
                                                            sx={styles.link}
                                                        >
                                                            Privacy Policy
                                                        </Link>
                                                        .
                                                    </Typography>
                                                }
                                            />
                                        </Grid>

                                        {touched.acceptedTerms &&
                                            errors.acceptedTerms && (
                                                <Grid item>
                                                    <Typography
                                                        sx={{
                                                            color: 'red',
                                                            fontSize: 14,
                                                            fontWeight: 500,
                                                        }}
                                                    >
                                                        {errors.acceptedTerms}
                                                    </Typography>
                                                </Grid>
                                            )}

                                        {status?.serverError && (
                                            <Grid item>
                                                <Typography
                                                    sx={{
                                                        color: 'red',
                                                        fontSize: 16,
                                                        fontWeight: 500,
                                                    }}
                                                >
                                                    {status.serverError}
                                                </Typography>
                                            </Grid>
                                        )}

                                        <Grid item>
                                            <Button
                                                text={
                                                    <Typography
                                                        sx={styles.signUpOption}
                                                    >
                                                        Sign Up
                                                    </Typography>
                                                }
                                                fullWidth
                                                loading={
                                                    isLoading || isSubmitting
                                                }
                                                customStyles={
                                                    styles.loginBtnStyle
                                                }
                                                onClick={submitForm}
                                            />
                                        </Grid>

                                        <Grid item textAlign="center">
                                            <Typography
                                                sx={styles.otherSignUpOption}
                                            >
                                                Already have an account?&nbsp;
                                                <Link
                                                    href="/login"
                                                    sx={styles.link}
                                                >
                                                    Sign in
                                                </Link>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};
