import { useCallback } from 'react';
import { useAxios } from '../../hooks/useAxios';

export const useBookkeeperService = () => {
    const { get, post, put, isLoading } = useAxios();

    // Add new manual journal entry
    const addManualJournalEntry = (data) =>
        post('bookkeeper/manual-journal-entry/', data, '');

    // Get manual journal entries by company ID (Paginated)
    const getManualJournalEntry = useCallback(
        (companyId, page) =>
            get(`bookkeeper/manual-journal-entry/${companyId}/?page=${page}`),
        [get],
    );

    const getManualJournalEntryById = useCallback(
        (companyId, journalId) =>
            get(
                `bookkeeper/manual-journal-entry-detail/${companyId}/${journalId}`,
            ),
        [get],
    );

    // ✅ Update an existing manual journal entry (PUT)
    const updateManualJournalEntry = (companyId, journalId, data) =>
        put(
            `bookkeeper/manual-journal-entry/${companyId}/${journalId}`,
            data,
            '',
        );

    return {
        isLoading,
        addManualJournalEntry,
        getManualJournalEntry,
        getManualJournalEntryById, // New get by ID
        updateManualJournalEntry, // New PUT request
    };
};
