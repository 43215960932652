import { useCallback } from 'react';

import { useAxios } from '../../hooks/useAxios';

export const useInventoryService = () => {
    const { get, post, put, remove, isLoading } = useAxios();

    const getCompanyInventory = (
        companyId,
        page,
        rowsPerPage,
        expiryDate = null,
        purchaseDate = null,
        description = null,
        sortColumn = null,
        sortOrder = null,
    ) => {
        const params = new URLSearchParams({});
        if (purchaseDate) params.append('purchaseDate', purchaseDate);
        if (expiryDate) params.append('expiryDate', expiryDate);
        if (page) params.append('page', page.toString());
        if (description) params.append('description', description);
        if (rowsPerPage) params.append('rowsPerPage', rowsPerPage);
        if (sortColumn) params.append('sortColumn', sortColumn);
        if (sortOrder) params.append('sortOrder', sortOrder);

        return get(`/inventory/${companyId}/inventory/?${params.toString()}`);
    };

    const addFileInventory = (data) => post(`inventory/add-file-inventory/`, data, '');

    const getInventoryForReview = (
        page,
        companyId,
        description = null,
        startDate = null,
        endDate = null,
        amount = null,
        matches = null,
        bankAccount = null,
        type = null,
        sortColumn = null,
        sortOrder = null,
    ) => {
        const params = new URLSearchParams({
            company_id: companyId.toString(),
            page: page.toString(),
        });

        if (type) params.append('type', type);
        if (amount) params.append('amount', amount);
        if (matches) params.append('matches', matches);
        if (endDate) params.append('end_date', endDate);
        if (sortOrder) params.append('sortOrder', sortOrder);
        if (startDate) params.append('start_date', startDate);
        if (sortColumn) params.append('sortColumn', sortColumn);
        if (description) params.append('description', description);
        if (bankAccount) params.append('bankAccount', bankAccount);

        return get(`inventory/file-inventory-for-review/?${params.toString()}`);
    };

    const getInventoryForMatched = useCallback(
        (companyId, page) =>
            get(`/inventory/file-inventory-for-matched/?company_id=${companyId}&page=${page}`),
        [get],
    );

    const getFileInventoryByPlaidTransaction = useCallback(
        (companyId, plaidTransactionId) =>
            get(
                `/inventory/file-inventory-by-plaid-transaction/?company_id=${companyId}&plaid_transaction_id=${plaidTransactionId}`,
            ),
        [get],
    );

    const matchAggFileInventoryWithPlaidTransaction = (data) =>
        put(`inventory/plaid-transaction/match/`, data, '');

    const unmatchAggFileInventoryAndPlaidTransaction = (data) =>
        put(`inventory/plaid-transaction/unmatch/`, data, '');

    const createExecutionRule = (data) => post(`inventory/create-exclusion-rule/`, data, '');

    const getExecutionRules = useCallback(
        (companyId, page) =>
            get(`/inventory/get-exclusion-rules/?company_id=${companyId}&page=${page}`),
        [get],
    );

    const deleteExecutionRule = (rule_id, companyId) =>
        remove(`inventory/delete-exclusion-rule/${rule_id}/?company_id=${companyId}`, {}, '');

    const getExcludedPlaidTransactions = useCallback(
        (companyId, page) =>
            get(`/inventory/get-excluded-plaid-transactions/?company_id=${companyId}&page=${page}`),
        [get],
    );

    const undoExcludedPlaidTransaction = (data) =>
        put(`inventory/undo-excluded-plaid-transactions/`, data, '');

    const getInventorySettings = (companyId, page) =>
        get(`inventory/settings/${companyId}/?page=${page}`);

    const updateInventorySettings = (id, data) => put(`inventory/settings/${id}/update/`, data, '');

    const createInventorySettings = (data) => post(`inventory/settings/create/`, data, '');

    const getAggregatedFileInventories = ({
        companyId,
        page = null,
        status = null,
        isReviewed,
        description = null,
        startDate = null,
        endDate = null,
        amountDue = null,
        saleStartDate = null,
        saleEndDate = null,
        amount = null,
        purchaseId = null,
        activityType = null,
    }) => {
        const params = new URLSearchParams({
            company_id: companyId,
            is_reviewed: isReviewed,
        });

        if (description) params.append('description', description);
        if (startDate) params.append('start_date', startDate);
        if (endDate) params.append('end_date', endDate);
        if (saleStartDate) params.append('sale_start_date', saleStartDate);
        if (saleEndDate) params.append('sale_end_date', saleEndDate);
        if (amountDue) params.append('amount_due', amountDue);
        if (amount) params.append('amount', amount);
        if (purchaseId) params.append('purchase_id', purchaseId);
        if (page) params.append('page', page);
        if (status) params.append('status', status);
        if (activityType) params.append('activity_type', activityType);

        return get(`inventory/get-aggregated-file-inventory/?${params.toString()}`);
    };

    const getFileInventories = (companyId, page) =>
        get(`inventory/get-file-inventory/?company_id=${companyId}&page=${page}`);

    const excludePlaidTransaction = (data) =>
        put(`inventory/exclude-plaid-transactions/`, data, '');

    const matchFileInventoryWithInventory = (data) =>
        put(`inventory/file-inventory/match/`, data, '');

    const unmatchFileInventoryAndInventory = (data) =>
        put(`inventory/file-inventory/unmatch/`, data, '');

    const getCompanyInventoryByStatus = (companyId, page, matched) =>
        get(
            `inventory/get-company-inventory/?company_id=${companyId}&page=${page}&matched=${matched}`,
        );

    const importAggInventoryInCircler = (data) => post(`inventory/import-agg-inventory/`, data, '');

    const getInventorySource = (companyId, inventoryId) =>
        get(`inventory/source/${companyId}/${inventoryId}/`);

    const getAutoMatchStatus = (companyId) =>
        get(`inventory/auto-match-status/?company_id=${companyId}`);

    const startAutoMatch = (data) => post(`inventory/auto-match/`, data, ``);

    const getAgentMatchings = (data) => get(`inventory/agent_matching/${data}`);

    const SkipAgentMatching = (companyId, skipPlaidTransactionId) =>
        get(`inventory/agent_matching/skip/${companyId}/${skipPlaidTransactionId}/`);

    const submitAgentMatchingUser = (companyId, data) =>
        post(`inventory/agent_matching/submit-user-matches/${companyId}/`, data, ``);

    const getEmailById = async (companyId, emailId) => {
        return await get(`/inventory/email-by-id/?company_id=${companyId}&email_id=${emailId}`);
    };

    return {
        isLoading,
        getEmailById,
        startAutoMatch,
        addFileInventory,
        getExecutionRules,
        getAgentMatchings,
        SkipAgentMatching,
        getAutoMatchStatus,
        getFileInventories,
        getInventorySource,
        submitAgentMatchingUser,
        getCompanyInventory,
        deleteExecutionRule,
        createExecutionRule,
        getInventorySettings,
        getInventoryForReview,
        getInventoryForMatched,
        updateInventorySettings,
        createInventorySettings,
        excludePlaidTransaction,
        importAggInventoryInCircler,
        getCompanyInventoryByStatus,
        getExcludedPlaidTransactions,
        undoExcludedPlaidTransaction,
        getAggregatedFileInventories,
        matchFileInventoryWithInventory,
        unmatchFileInventoryAndInventory,
        getFileInventoryByPlaidTransaction,
        matchAggFileInventoryWithPlaidTransaction,
        unmatchAggFileInventoryAndPlaidTransaction,
    };
};
