import React from 'react';
import { Box } from '@mui/material';
import { Typography } from '../shared';
import { getLang } from 'utils/language/language';

const TopExpensesChart = ({ topExpensesData }) => {
    // const values = HORIZONTAL_STACK_BAR_LABELS.map(() =>
    //     faker.datatype.number({ min: 0, max: 100000 }),
    // );

    const normalize = (value, minValue, maxValue) => {
        const normalizeValue = (value - minValue) / (maxValue - minValue);
        return normalizeValue;
    };

    // const topExpensesData = HORIZONTAL_STACK_BAR_LABELS.map(
    //     (property, index) => ({
    //         property,
    //         value: values[index],
    //     }),
    // );

    const mappedData = topExpensesData
        ? topExpensesData.map((expense) => ({
              property: expense?.account_name,
              value: expense?.total_amount,
          }))
        : [];

    // Sort data by values in descending order
    const sortedData = mappedData.sort((a, b) => b.value - a.value);

    // Safeguard against empty arrays to prevent accessing properties of undefined
    const minValue =
        sortedData.length > 0 ? sortedData[sortedData.length - 1].value : 0;
    const maxValue = sortedData.length > 0 ? sortedData[0].value : 0;
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                height: '100%',
            }}
        >
            {sortedData.length > 0 ? (
                sortedData.map((item) => (
                    <Box
                        key={item.property}
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            height: '33px',
                            marginBottom: 1,
                        }}
                    >
                        <Box
                            sx={{
                                width: `${
                                    normalize(item.value, minValue, maxValue) *
                                    100
                                }%`,
                                backgroundColor: '#0CAF6026',
                                textAlign: 'left',
                                paddingLeft: 1,
                                paddingRight: 1,
                                display: 'flex',
                                alignItems: 'center',
                                overflow: 'unset',
                                borderRadius: '4px',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            <Typography
                                text={item.property}
                                customStyles={{
                                    fontSize: 14,
                                    fontWeight: 500,
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                width: 'auto',
                                display: 'flex',
                                alignItems: 'center',
                                textAlign: 'right',
                                marginLeft: '10px',
                            }}
                        >
                            <Typography
                                text={`$${item.value}`}
                                customStyles={{
                                    fontSize: 14,
                                    fontWeight: 500,
                                }}
                            />
                        </Box>
                    </Box>
                ))
            ) : (
                <Typography
                    text={getLang('no_data_available')}
                    align="center"
                    fontWeight={500}
                />
            )}
        </Box>
    );
};

export default React.memo(TopExpensesChart);
