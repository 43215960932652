import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

export const ProtectedRoute = ({ route = true, children }) => {
    const { user } = useAuth();

    if (!user?.data) {
        return <Navigate to="/login" />;
    } else if (
        !user?.data?.subscription_active &&
        user?.data?.stripe_status !== 'active' &&
        route !== 'subscriptions'
    ) {
        return <Navigate to="/subscriptions" />;
    } else if (
        route !== 'onboarding' &&
        !user?.data?.is_onboarded &&
        user?.data?.subscription_active &&
        user?.data?.stripe_status == 'active'
    ) {
        return <Navigate to="/onboarding" />;
    }
    return children;
};
