import dayjs from 'dayjs';
import { Fragment } from 'react';

import { Grid } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { Button, TextField } from 'shared';
import { matchWithAggregatedFileInventoryStyles } from 'components/Inventory/style/style';

const AggInventoryFilters = ({
    values,
    handleReset,
    handleChange,
    setFieldValue,
    handleClearClick,
    searchByFields = [],
    dateFields = [],
}) => {
    const classes = matchWithAggregatedFileInventoryStyles();

    return (
        <Fragment>
            <Grid container spacing={2}>
                {dateFields.map((field) => (
                    <Grid key={field.name} item {...field.gridSize}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                value={values[field.name] ? dayjs(values[field.name]) : null}
                                onChange={(date) => setFieldValue(field.name, date)}
                                label={field.label}
                                placeholder={field.placeholder}
                                format={field.format || 'YYYY-MM-DD'}
                                sx={classes.datePicker}
                            />
                        </LocalizationProvider>
                    </Grid>
                ))}

                {searchByFields.map((field) => (
                    <Grid key={field.name} item {...field.gridSize}>
                        <TextField
                            fullWidth
                            name={field.name}
                            value={values[field.name]}
                            placeholder={field.placeholder}
                            type={field.type}
                            onChange={handleChange}
                        />
                    </Grid>
                ))}
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={24} marginTop="10px">
                    <Button
                        text="Clear"
                        onClick={() => handleClearClick(handleReset)}
                        customStyles={classes.cancelButton}
                    />
                    <Button text="Apply" type="submit" />
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default AggInventoryFilters;
