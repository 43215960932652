import dayjs from 'dayjs';
import { FieldArray } from 'formik';
import React, { Fragment } from 'react';

import { Grid, IconButton } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { TextField } from 'shared';
import { paymentPlanFormStyles } from 'components/Inventory/style/style';

const ScheduledPaymentsList = ({ values, setFieldValue, touched, errors }) => {
    const classes = paymentPlanFormStyles();

    return (
        <FieldArray name="scheduledPayments">
            {({ remove }) => (
                <Grid container spacing={2} mt={2}>
                    {values.scheduledPayments.map((payment, index) => (
                        <Fragment>
                            <Grid item xs={5} key={`amount_${index}`}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        value={payment.date ? dayjs(payment.date) : null}
                                        onChange={(date) =>
                                            setFieldValue(`scheduledPayments[${index}].date`, date)
                                        }
                                        label="Date"
                                        placeholder="Date"
                                        format="YYYY-MM-DD"
                                        sx={classes.datePicker}
                                        slotProps={{
                                            textField: {
                                                error:
                                                    touched.scheduledPayments?.[index]?.date &&
                                                    !!errors.scheduledPayments?.[index]?.date,
                                                helperText:
                                                    touched.scheduledPayments?.[index]?.date &&
                                                    errors.scheduledPayments?.[index]?.date,
                                                FormHelperTextProps: {
                                                    sx: classes.dateError,
                                                },
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={5} key={`date_${index}`}>
                                <TextField
                                    fullWidth
                                    name={`scheduledPayments[${index}].amount`}
                                    inlineLabel="Amount"
                                    type="number"
                                    value={payment.amount}
                                    onChange={(e) =>
                                        setFieldValue(
                                            `scheduledPayments[${index}].amount`,
                                            e.target.value,
                                        )
                                    }
                                    error={
                                        touched.scheduledPayments?.[index]?.amount &&
                                        !!errors.scheduledPayments?.[index]?.amount
                                    }
                                    helperText={
                                        touched.scheduledPayments?.[index]?.amount &&
                                        errors.scheduledPayments?.[index]?.amount
                                    }
                                />
                            </Grid>
                            <Grid item xs={2} key={`delete_${index}`}>
                                <IconButton>
                                    <DeleteForeverIcon
                                        color="error"
                                        onClick={() => remove(index)}
                                    />
                                </IconButton>
                            </Grid>
                        </Fragment>
                    ))}
                </Grid>
            )}
        </FieldArray>
    );
};

export default ScheduledPaymentsList;
