import React, { Fragment } from 'react';
import { Grid, IconButton } from '@mui/material';

import { Autocomplete, TextField } from 'shared';
import DeleteIcon from '@mui/icons-material/Delete';

import { customLabelStyle, customSelectStyle, customSelectLabelStyle } from '../style/style';

const JournalEntryRow = ({
    index,
    entry,
    errors,
    touched,
    accounts,
    onRemove,
    canRemove,
    setFieldValue,
}) => {
    return (
        <Fragment key={index}>
            <Grid item xs={12} md={5} lg={5}>
                <Autocomplete
                    options={accounts}
                    labelKey="label"
                    valueKey="id"
                    placeholder="Account"
                    name={`journalEntries[${index}].account`}
                    value={entry.account}
                    onChange={(e, value) =>
                        setFieldValue(`journalEntries[${index}].account`, value)
                    }
                    isSearchField={false}
                    customLabelStyles={customSelectLabelStyle}
                    customStyle={customSelectStyle}
                    error={
                        touched.journalEntries?.[index]?.account &&
                        !!errors.journalEntries?.[index]?.account
                    }
                    helperText={
                        touched.journalEntries?.[index]?.account &&
                        errors.journalEntries?.[index]?.account
                    }
                />
            </Grid>
            <Grid item xs={5} md={3} lg={3}>
                <TextField
                    type="number"
                    fullWidth
                    name={`journalEntries[${index}].debit`}
                    placeholder="Debit"
                    value={entry.debit}
                    onChange={(e) => {
                        setFieldValue(`journalEntries[${index}].debit`, e.target.value);
                        setFieldValue(`journalEntries[${index}].credit`, 0);
                    }}
                    customLabelStyles={customLabelStyle}
                    disabled={!!entry.credit}
                    onKeyPress={(event) => {
                        if (['+', '-', 'e', 'E'].includes(event?.key)) {
                            event.preventDefault();
                        }
                    }}
                />
            </Grid>
            <Grid item xs={5} md={3} lg={3}>
                <TextField
                    fullWidth
                    type="number"
                    name={`journalEntries[${index}].credit`}
                    placeholder="Credit"
                    value={entry.credit}
                    onChange={(e) => {
                        setFieldValue(`journalEntries[${index}].credit`, e.target.value);
                        setFieldValue(`journalEntries[${index}].debit`, 0);
                    }}
                    customLabelStyles={customLabelStyle}
                    disabled={!!entry.debit}
                    onKeyPress={(event) => {
                        if (['+', '-', 'e', 'E'].includes(event?.key)) {
                            event.preventDefault();
                        }
                    }}
                />
            </Grid>
            {canRemove && (
                <Grid item xs={2} md={1} lg={1}>
                    <IconButton color="error" onClick={() => onRemove(index)}>
                        <DeleteIcon />
                    </IconButton>
                </Grid>
            )}
        </Fragment>
    );
};

export default JournalEntryRow;
