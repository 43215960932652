export const inventoryReconciliationTabsData = [
    { label: 'For Review', value: 'forReview' },
    { label: 'Matched', value: 'matched' },
    { label: 'Excluded', value: 'excluded' },
    { label: 'File Upload', value: 'fileUpload' },
    { label: 'Purchase Activity', value: 'purchaseActivity' },
    // { label: 'Sales Activity', value: 'salesActivity' },
    { label: 'Exclusion Rules', value: 'exclusionRules' },
    { label: 'Payment Plan', value: 'paymentPlan' },
];
