import * as Yup from 'yup';

export const purchaseFiltersInitialValues = {
    startDate: null,
    endDate: null,
    purchaseId: '',
};

export const purchaseFiltersSchema = Yup.object({
    endDate: Yup.date().nullable(),
    purchaseId: Yup.string().nullable(),
    startDate: Yup.date().nullable(),
});
