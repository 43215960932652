import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    Collapse,
    IconButton,
    Paper,
    useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

import { recieptTableLevelOneStyles } from '../style/syles';
import { formatLabel } from '../utils/utils';

const RecieptTableLevelOne = ({ item, level = 0 }) => {
    const [isOpen, setIsOpen] = useState(true);

    const navigate = useNavigate();
    const classes = recieptTableLevelOneStyles(level);

    const hasChildren = item.children && item.children.length > 0;

    const handleClick = (e) => {
        e.stopPropagation();
        setIsOpen(!isOpen);
    };

    const handleNavigate = (e, path) => {
        e.stopPropagation();
        navigate(path);
    };

    return (
        <TableContainer component={Paper} sx={classes.tableContainer}>
            <Table>
                <TableBody>
                    <TableRow
                        onClick={level === 0 ? handleClick : undefined}
                        sx={classes.tableRow}
                    >
                        <TableCell sx={classes.tableCell}>
                            {level === 0 && (
                                <IconButton size="small">
                                    {isOpen ? <ExpandLess /> : <ExpandMore />}
                                </IconButton>
                            )}
                            {level === 1 && item.account_id ? (
                                <Typography
                                    component="a"
                                    onClick={(e) =>
                                        handleNavigate(
                                            e,
                                            `/reports/balance-sheet/journalEntry/${item.account_id}`,
                                        )
                                    }
                                    sx={classes.linkText}
                                >
                                    {formatLabel(item.name)}
                                </Typography>
                            ) : (
                                <Typography
                                    variant="body1"
                                    sx={{ flexGrow: 1 }}
                                >
                                    {formatLabel(item.name)}
                                </Typography>
                            )}
                        </TableCell>

                        <TableCell align="right" sx={classes.totalCell}>
                            <Typography variant="body2" fontWeight="bold">
                                {level === 0 || level === 1
                                    ? item.total !== undefined
                                        ? Number(item.total).toFixed(2)
                                        : '0.00'
                                    : item.total !== undefined
                                    ? Number(item.total).toFixed(2)
                                    : ''}
                            </Typography>
                        </TableCell>
                    </TableRow>

                    {level === 0 && (
                        <TableRow>
                            <TableCell colSpan={2} sx={classes.collapseCell}>
                                <Collapse
                                    in={isOpen}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    {hasChildren ? (
                                        item.children.map((child) => (
                                            <RecieptTableLevelOne
                                                key={`${
                                                    child.account_id ||
                                                    child.name
                                                }-${level}`}
                                                item={child}
                                                level={level + 1}
                                            />
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell
                                                colSpan={2}
                                                align="center"
                                                sx={classes.noDataCell}
                                            >
                                                No data available
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </Collapse>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default RecieptTableLevelOne;
