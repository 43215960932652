import React, { useState, Fragment } from 'react';

import {
    Table,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
    TableContainer,
    TableSortLabel,
} from '@mui/material';

import { Typography } from './Typography';
import TableSkeleton from './table-skeleton';

const dataTableWithAccordionStyles = () => ({
    tableContainer: {
        width: '100%',
        background: (theme) => theme.backgroundColor.primary,
        overflow: 'auto',
        padding: '4px 24px 0px 24px',
        marginBottom: '10px',
        borderRadius: '12px',
        '& .MuiTableCell-root': {
            borderColor: (theme) => (theme.palette.mode === 'dark' ? '#394150' : '#E5E6EB'),
            fontSize: '12px',
        },
        '&::-webkit-scrollbar': {
            width: '0.4rem',
            height: '0.4rem',
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            margin: 1,
            borderRadius: '8px',
            backgroundColor: (theme) => theme.primary.dark,
        },
    },
    headerTableCell: {
        p: '12px',
        background: (theme) => theme.backgroundColor.primary,
        borderBottom: (theme) =>
            theme.palette.mode === 'dark' ? '1px solid #394150' : '1px solid #E5E6EB',
        fontWeight: 500,
        fontSize: 14,
        color: '#6C727F',
    },
    tableRow: {
        cursor: 'pointer',
    },
    accordionCell: {
        paddingBottom: 0,
        paddingTop: 0,
        background: (theme) => theme.backgroundColor.primary,
    },
    headerCellLabel: {
        whiteSpace: 'nowrap',
    },
});

export const DataTableWithAccordion = ({
    data = [],
    sorting = {},
    headers = [],
    sortOrder = 'asc',
    isLoading = false,
    showAccordion = false,
    sortHandler = () => {},
}) => {
    const classes = dataTableWithAccordionStyles();

    const [expandedRow, setExpandedRow] = useState(null);

    const toggleRowExpansion = (rowId) => {
        setExpandedRow(expandedRow === rowId ? null : rowId);
    };

    const handleSort = (columnId) => {
        const isAsc = sorting[columnId] && sortOrder === 'asc';
        const newSortOrder = isAsc ? 'desc' : 'asc';
        sortHandler(columnId, newSortOrder);
    };

    return (
        <Fragment>
            <TableContainer sx={classes.tableContainer}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {headers.map((header) => (
                                <TableCell
                                    key={header.id}
                                    align={header.align || 'left'}
                                    sx={{
                                        ...classes.headerTableCell,
                                        verticalAlign: header.verticalAlign || 'middle',
                                        width: header.maxWidth || 'unset',
                                    }}
                                >
                                    <TableSortLabel
                                        active={sorting[header.id]}
                                        direction={sorting[header.id] ? sortOrder : 'asc'}
                                        onClick={() => handleSort(header.id)}
                                        sx={classes.headerCellLabel}
                                        hideSortIcon={!header.sort}
                                        disabled={!header.sort || isLoading}
                                    >
                                        <Typography
                                            text={header.label}
                                            customStyles={{
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: (theme) =>
                                                    header?.changed
                                                        ? 'white'
                                                        : theme.palette.mode === 'dark'
                                                        ? '#6C727F'
                                                        : '#4D5461',
                                            }}
                                        />
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {isLoading ? (
                        <TableBody>
                            {[...Array(8)].map((i, index) => (
                                <TableSkeleton key={index} numberOfColumns={8} />
                            ))}
                        </TableBody>
                    ) : (
                        <TableBody>
                            {data?.results?.length == 0 ? (
                                <TableRow>
                                    <TableCell colSpan={headers.length} align="center">
                                        <Typography variant="body1" text="No data available" />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                data?.results?.map((row) => (
                                    <React.Fragment key={row.id}>
                                        <TableRow hover sx={classes.tableRow}>
                                            {headers.map((header) => (
                                                <TableCell
                                                    key={header.id}
                                                    align={header.align || 'left'}
                                                    sx={{
                                                        verticalAlign:
                                                            header.verticalAlign || 'middle',
                                                    }}
                                                    onClick={() =>
                                                        header.id === 'action' &&
                                                        toggleRowExpansion(row.id)
                                                    }
                                                >
                                                    {header.render
                                                        ? header.render(row)
                                                        : row[header.id] || '-'}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                        {expandedRow == row.id && (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={headers.length}
                                                    sx={classes.accordionCell}
                                                >
                                                    {showAccordion(row, () => setExpandedRow(null))}
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </React.Fragment>
                                ))
                            )}
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
        </Fragment>
    );
};
