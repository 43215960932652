import { Chip } from '@mui/material';

export const salesActivityTableHeaders = [
    {
        id: 'sold_at',
        label: 'Date',
        sort: false,
        verticalAlign: 'top',
    },
    {
        id: 'invoice_id',
        label: 'Invoice',
        sort: false,
        verticalAlign: 'top',
    },
    {
        id: 'description',
        label: 'Description',
        sort: false,
        verticalAlign: 'top',
        render: (row) =>
            `${row.expires_at}, Section: ${row.class_1}, Row: ${row.class_2}, Seats: ${row.seats}`,
    },
    {
        id: 'reference_id',
        label: 'Reference',
        sort: false,
        verticalAlign: 'top',
    },
    {
        id: 'amount',
        label: 'Total',
        sort: false,
        verticalAlign: 'top',
        render: (row) => `$${row.amount_due}`,
    },
    {
        id: 'plaidTransactionStatus',
        label: 'Status',
        sort: false,
        verticalAlign: 'top',
        render: (row) => (
            <Chip
                label={row.is_reviewed ? 'Matched' : 'Not matched'}
                color={row.is_reviewed ? 'success' : 'warning'}
            />
        ),
    },
];
