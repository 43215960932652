import axios from 'axios';
// import { toast } from "react-toastify";
// import { store } from "../store"; // Import store to get the token
import { store } from 'store';

class AjaxService {
    constructor() {
        this.base_url = process.env.REACT_APP_CIRCLER_API_URL;
        this.memcache = {};
        this.memcache_ttl = 4 * 60 * 1000; // 4 minutes
        this.requests = [];

        this.apiClient = axios.create({
            baseURL: this.base_url,
            headers: { 'Content-Type': 'application/json' },
        });

        this.apiClient.interceptors.request.use(
            (config) => {
                const { token } = store.getState().auth;
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`;
                }
                return config;
            },
            (error) => Promise.reject(error),
        );
    }

    request(type, api, params = {}, isCache = false, isLoader = false) {
        return new Promise((resolve, reject) => {
            if (isCache) {
                const cacheKey = api + '|' + JSON.stringify(params);
                const cachedData = this.getCache(cacheKey);
                if (cachedData) {
                    resolve(cachedData);
                    return;
                }
            }

            if (isLoader) document.body.classList.add('loading');
            this.apiClient({
                method: type.toLowerCase(),
                url: api,
                params: type.toLowerCase() === 'get' ? params : {},
                data: type.toLowerCase() !== 'get' ? params : {},
                cancelToken: new axios.CancelToken((c) =>
                    this.requests.push(c),
                ),
            })
                .then((response) => {
                    if (isLoader) document.body.classList.remove('loading');
                    if (isCache && response.data.error_code === 0) {
                        this.setCache(
                            api + '|' + JSON.stringify(params),
                            response.data,
                        );
                    }
                    resolve(response.data);
                })
                .catch((error) => {
                    if (isLoader) document.body.classList.remove('loading');
                    //   toast.error(
                    //     error.response
                    //       ? "The site can't be reached. Please try again."
                    //       : "Unable to connect to the server. Please check your internet connection.",
                    //     { autoClose: 4000 }
                    //   );
                    reject(error);
                });
        });
    }

    getCache(key) {
        if (!this.memcache[key]) return undefined;
        const cache = this.memcache[key];
        if (this.memcache_ttl < new Date().getTime() - cache.time) {
            delete this.memcache[key];
            return undefined;
        }
        return cache.value;
    }

    setCache(key, value) {
        if (JSON.stringify(value).length > 5000) return;
        this.memcache[key] = { time: new Date().getTime(), value };
        if (Object.keys(this.memcache).length > 300) {
            delete this.memcache[Object.keys(this.memcache)[0]];
        }
    }

    cancelRequests() {
        this.requests.forEach((cancel) => cancel());
        this.requests = [];
    }
}

const AJAX = new AjaxService();
export default AJAX;
