import { useSelector } from 'react-redux';
import React, { Fragment, useEffect, useState } from 'react';

import {
    Box,
    Radio,
    Alert,
    Snackbar,
    IconButton,
    RadioGroup,
    FormControlLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import {
    updateTransactions,
    unmatchTransaction,
    excludeTransactions,
    getMatchedTransaction,
} from 'store/services/bank.Service';
import { CircleLoading } from 'shared';
import TransferForm from './TransferForm';
import CategorizeForm from './CategorizeForm';
import { rowAccordionStyles } from '../style/style';
import { handleErrorAlert } from 'utils/handleErrorAlert';
import CreditCardPaymentForm from './CreditCardPaymentForm';
import { handleSuccessAlert } from 'utils/handleSuccessAlert';

const radioGroupOptions = [
    { value: 'categorize', label: 'Categorize' },
    { value: 'transfer', label: 'Transfer' },
    { value: 'creditCardPayment', label: 'Credit Card Payment' },
];

const RowAccordion = ({ rowData, onClose, coaCategoryData, handleBankTransactions }) => {
    const classes = rowAccordionStyles();
    const token = useSelector((state) => state.auth.token);
    const { id: companyId } = useSelector((state) => state.company.data);

    const [alertData, setAlertData] = useState({
        severity: '',
        open: false,
        message: '',
    });
    const [selectedOption, setSelectedOption] = useState(
        rowData?.transaction_type == 'transaction'
            ? 'categorize'
            : rowData?.transaction_type == 'transfer'
            ? 'transfer'
            : 'creditCardPayment',
    );
    const [matchedTransaction, setMatchedTransaction] = useState(null);

    const handleUpdateTransaction = async (data) => {
        try {
            const response = await updateTransactions(token, {
                plaid_transaction_id: rowData.id,
                coa_account_id: data.coaAccountId,
                merchant_name: data.merchantName,
                transaction_type:
                    selectedOption == 'categorize'
                        ? 'transaction'
                        : selectedOption == 'transfer'
                        ? 'transfer'
                        : 'credit_card_payment',
            });
            handleBankTransactions(response.message);
        } catch (error) {
            handleErrorAlert(setAlertData);
        }
    };

    const handleExcludeTransactions = async () => {
        try {
            const response = await excludeTransactions(companyId, [rowData.id]);
            handleSuccessAlert(setAlertData, response?.message);
            handleBankTransactions();
        } catch (error) {
            handleErrorAlert(setAlertData);
        }
    };

    const handleGetMatchedTransaction = async () => {
        try {
            const response = await getMatchedTransaction({
                plaidTransactionId: rowData?.id,
                companyId,
            });
            setMatchedTransaction(response.data);
        } catch (error) {
            handleErrorAlert(setAlertData);
        }
    };

    const handleUnmatchTransaction = async () => {
        try {
            const response = await unmatchTransaction({
                plaidTransactionId: rowData?.id,
                companyId,
            });
            setAlertData({
                severity: 'success',
                message: response.message,
                open: true,
            });
            handleBankTransactions();
        } catch (error) {
            handleErrorAlert(setAlertData);
        }
    };

    const handleAlertClose = () => {
        setAlertData((prevState) => ({
            ...prevState,
            open: false,
        }));
    };

    useEffect(() => {
        if (rowData?.status === 'matched') {
            handleGetMatchedTransaction();
        }
    }, []);

    return (
        <Box sx={classes.root}>
            {coaCategoryData.loading || (rowData?.status === 'matched' && !matchedTransaction) ? (
                <CircleLoading />
            ) : (
                <Fragment>
                    <Box sx={classes.radioButtonsWrapper}>
                        <RadioGroup
                            row
                            value={selectedOption}
                            onChange={(event) => setSelectedOption(event.target.value)}
                            sx={classes.radioGroup}
                        >
                            {radioGroupOptions.map((option) => (
                                <FormControlLabel
                                    sx={classes.radioButtonLabel}
                                    value={option.value}
                                    control={<Radio sx={classes.radioButtonIcon} />}
                                    label={option.label}
                                />
                            ))}
                        </RadioGroup>
                        <IconButton sx={classes.crossIcon} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box>
                        {selectedOption === 'categorize' && (
                            <CategorizeForm
                                data={rowData}
                                coaCategoryData={coaCategoryData}
                                handleUpdateTransaction={handleUpdateTransaction}
                                handleExcludeTransactions={handleExcludeTransactions}
                            />
                        )}
                        {selectedOption === 'transfer' && (
                            <TransferForm
                                data={rowData}
                                coaCategoryData={coaCategoryData}
                                matchedTransaction={matchedTransaction}
                                handleUpdateTransaction={handleUpdateTransaction}
                                handleUnmatchTransaction={handleUnmatchTransaction}
                                handleExcludeTransactions={handleExcludeTransactions}
                            />
                        )}
                        {selectedOption === 'creditCardPayment' && (
                            <CreditCardPaymentForm
                                data={rowData}
                                coaCategoryData={coaCategoryData}
                                matchedTransaction={matchedTransaction}
                                handleUpdateTransaction={handleUpdateTransaction}
                                handleUnmatchTransaction={handleUnmatchTransaction}
                                handleExcludeTransactions={handleExcludeTransactions}
                            />
                        )}
                    </Box>
                </Fragment>
            )}

            <Snackbar
                open={alertData.open}
                autoHideDuration={5000}
                onClose={handleAlertClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleAlertClose}
                    severity={alertData.severity}
                    sx={{ width: '100%' }}
                >
                    {alertData.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default RowAccordion;
