import * as Yup from 'yup';

export const CompanyInformationSchema = Yup.object().shape({
    name: Yup.string().required('Company Name is required'),
    timezone: Yup.object().required('Timezone is required'),
    industry: Yup.object().required('Industry is required'),
    entity: Yup.object().required('Entity type is required'),
    line1: Yup.string().required('Address Line 1 is required'),
    line2: Yup.string(),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    postalCode: Yup.string().required('Postal Code is required'),
    country: Yup.string().required('Country is required'),
});

export const StepOneCompnayInformationInitialValues = (data, companyData) => {
    return {
        name: data?.name || companyData?.name || '',
        timezone: data?.timezone || {
            label: 'New York (Eastern Time - EST/EDT)',
            value: 'America/New_York',
        },
        industry: data?.industry || null,
        entity: data?.entity || null,
        line1: data?.address?.line1 || companyData?.line1 || '',
        line2: data?.address?.line2 || companyData?.line2 || '',
        city: data?.address?.city || companyData?.city || '',
        state: data?.address?.state || companyData?.state || '',
        postalCode:
            data?.address?.postal_code || companyData?.postal_code || '',
        country: data?.address?.country || companyData?.country || '',
    };
};
