import { Formik, Form } from 'formik';
import React, { useEffect, useState } from 'react';

import { Box, Grid } from '@mui/material';

import { getBankAccountsDropdown } from 'store/services/bank.Service';
import { useInventoryService } from 'store/services/inventory.service';
import { exclusionRulesFormStyles } from 'components/Inventory/style/style';
import { exclusionFields } from 'components/Inventory/utils/exclusionFields';
import { exclusionOperators } from 'components/Inventory/utils/exclusionOperators';
import { exclusionRuleSchema } from 'components/Inventory/utils/exclusionRuleSchema';
import { Modal, Button, TextField, Typography, Autocomplete, CircleLoading } from 'shared';

const initialValues = {
    text: null,
    account: null,
    field: null,
    operator: null,
};

const ExclusionRulesForm = ({
    open,
    companyId,
    handleCloseModal,
    handleExclusionRuleCreationSuccess,
}) => {
    const classes = exclusionRulesFormStyles({});
    const { isLoading, createExecutionRule } = useInventoryService();

    const [banks, setBanks] = useState([]);
    const [loader, setLoader] = useState(true);

    const handleGetBankAccountsDropdown = async () => {
        try {
            const response = await getBankAccountsDropdown(companyId, null);
            setBanks([{ id: 'all', name: 'All accounts' }, ...response.data]);
        } catch (error) {
            console.error(error);
        } finally {
            setLoader(false);
        }
    };

    const handleSubmit = async (values) => {
        const { account, field, operator, text } = values;

        try {
            await createExecutionRule({
                companyId,
                bankAccountId: account.value === 'all' ? null : account.value,
                field: field.value,
                operator: operator.value,
                text,
                isAppliedToAll: account === 'all',
            });
            handleExclusionRuleCreationSuccess();
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        handleGetBankAccountsDropdown();
    }, []);

    return (
        <Modal
            fullWidth
            open={open}
            title={`Create Exclusion Rule`}
            handleClose={handleCloseModal}
            actionBtnTitle={<Typography text={'Create'} />}
        >
            {loader ? (
                <CircleLoading />
            ) : (
                <Formik
                    initialValues={initialValues}
                    validationSchema={exclusionRuleSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, setFieldValue, errors, touched }) => (
                        <Form>
                            <Box width="100%" gap={2} mt={1} border="none">
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            name="bank"
                                            options={banks.map((bank) => ({
                                                label: bank.name,
                                                value: bank.id,
                                            }))}
                                            labelKey="label"
                                            valueKey="value"
                                            placeholder="Select bank"
                                            value={values.account}
                                            onChange={(e, value) => setFieldValue('account', value)}
                                            isSearchField={false}
                                            disableClearable
                                            error={touched.account && !!errors.account}
                                            helperText={touched.account && errors.account}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Autocomplete
                                            name="field"
                                            options={exclusionFields}
                                            labelKey="label"
                                            valueKey="value"
                                            placeholder="Select Field"
                                            value={values.field}
                                            onChange={(e, value) => setFieldValue('field', value)}
                                            isSearchField={false}
                                            disableClearable
                                            error={touched.field && !!errors.field}
                                            helperText={touched.field && errors.field}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Autocomplete
                                            name="operator"
                                            options={exclusionOperators}
                                            labelKey="label"
                                            valueKey="value"
                                            placeholder="Select Operator"
                                            value={values.operator}
                                            onChange={(e, value) => {
                                                console.log(e);
                                                setFieldValue('operator', value);
                                            }}
                                            isSearchField={false}
                                            disableClearable
                                            error={touched.operator && !!errors.operator}
                                            helperText={touched.operator && errors.operator}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            name="text"
                                            placeholder="Text"
                                            value={values.text}
                                            onChange={(e) => setFieldValue('text', e.target.value)}
                                            error={touched.text && !!errors.text}
                                            helperText={touched.text && errors.text}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Box sx={classes.buttonWrapper}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                sx={classes.button}
                                                loading={isLoading}
                                                text="SAVE"
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Form>
                    )}
                </Formik>
            )}
        </Modal>
    );
};

export default ExclusionRulesForm;
