import React from 'react';

import { Typography, Box } from '@mui/material';

import { filteredInventoryInfo } from '../../../style/style';

const FilteredInventoryInfo = ({ count, amount }) => {
    const classes = filteredInventoryInfo();

    return (
        <Box sx={classes.rootWrapper}>
            <Typography variant="body2">
                {count} Inventor{count === 1 ? 'y is' : 'ies are'} filtered. Total purchase price:{' '}
                {amount} USD
            </Typography>
        </Box>
    );
};

export default FilteredInventoryInfo;
