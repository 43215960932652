import { Formik, Form } from 'formik';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, IconButton } from '@mui/material';

import PurchaseTable from './PurchaseTable';
import PurchaseModal from './PurchasesModal';
import {
    payemntPlanSchema,
    payemntPlanInitialValues,
} from 'components/Inventory/utils/paymentPlanSchema';
import { useThemeToggle } from 'hooks/useThemeToggle';
import { Button, TextField, Typography } from 'shared';
import { handleErrorAlert } from 'utils/handleErrorAlert';
import ScheduledPaymentModal from './ScheduledPaymentModal';
import ScheduledPaymentsList from './ScheduledPaymentsList';
import { notesText } from 'components/Inventory/style/style';
import { paymentPlanFormStyles } from 'components/Inventory/style/style';
import { usePaymentPlanService } from 'store/services/paymentPlan.service';

const PaymentPlansForm = ({
    type,
    setAlertData,
    handlePaymentPlanSuccess,
    handlePaymentPlanFormCancel,
}) => {
    const { themeMode } = useThemeToggle();
    const classes = paymentPlanFormStyles();
    const companyData = useSelector((state) => state.company.data);
    const { isLoading, createPaymentPlan } = usePaymentPlanService();

    const [purchaseData, setPurchaseData] = useState([]);
    const [showPurchaseModal, setShowPurchaseModal] = useState(false);
    const [showScheduledPaymentForm, setShowScheduledPaymentForm] = useState(false);

    const handleSubmit = async (values) => {
        try {
            const data = {
                ...values,
                purchaseData: purchaseData?.map((purchase) => purchase.id),
                companyId: companyData?.id,
            };
            const response = await createPaymentPlan(data);
            handlePaymentPlanSuccess(response.message);
        } catch (error) {
            handleErrorAlert(setAlertData);
        }
    };

    const handlePurchaseModalSubmit = (data) => {
        setPurchaseData(data);
        setShowPurchaseModal(false);
    };

    return (
        <Box sx={classes.rootWrapper}>
            <Box sx={classes.headingWrapper}>
                <Typography fontWeight="bold" fontSize="20px" text={`${type} Payment Plan`} />
                <IconButton>
                    <CloseIcon onClick={handlePaymentPlanFormCancel} />
                </IconButton>
            </Box>
            <Formik
                initialValues={payemntPlanInitialValues({})}
                validationSchema={payemntPlanSchema}
                onSubmit={handleSubmit}
            >
                {({ values, setFieldValue, errors, touched }) => (
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            name="name"
                                            placeholder="Name"
                                            inlineLabel="Name"
                                            value={values.text}
                                            onChange={(e) => setFieldValue('name', e.target.value)}
                                            error={touched.name && !!errors.name}
                                            helperText={touched.name && errors.name}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            name="notes"
                                            placeholder="Notes"
                                            inlineLabel="Notes"
                                            value={values.notes}
                                            onChange={(e) => setFieldValue('notes', e.target.value)}
                                            error={touched.notes && !!errors.notes}
                                            helperText={touched.notes && errors.notes}
                                            multiline
                                            minRows={2}
                                            InputProps={{
                                                style: notesText(themeMode),
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}></Grid>
                            <Grid item xs={12} md={6}>
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Typography text="Scheduled Payments" />
                                    <Button
                                        text="Add Scheduled Payment"
                                        startIcon={<AddIcon />}
                                        customStyles={{ padding: '10px' }}
                                        onClick={() => setShowScheduledPaymentForm(true)}
                                    />
                                </Box>

                                <ScheduledPaymentsList
                                    errors={errors}
                                    values={values}
                                    touched={touched}
                                    setFieldValue={setFieldValue}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box marginTop="10px">
                                    <Typography text="MATCHED PAYMENTS" />
                                </Box>
                                <Box marginTop="25px">
                                    <Typography text="There are no bank transactions linked to this payment plan." />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}></Grid>
                            <Grid item xs={12} md={6}>
                                <Box display="flex" justifyContent="flex-end">
                                    <Button
                                        text="Add Purchases"
                                        startIcon={<AddIcon />}
                                        customStyles={{ padding: '10px' }}
                                        onClick={() => setShowPurchaseModal(true)}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <PurchaseTable data={purchaseData} />
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="flex-end">
                                    <Button loading={isLoading} text={type} type="submit" />
                                </Box>
                            </Grid>
                        </Grid>

                        {showScheduledPaymentForm && (
                            <ScheduledPaymentModal
                                open={showScheduledPaymentForm}
                                onClose={() => setShowScheduledPaymentForm(false)}
                                onAddPayments={(payments) =>
                                    setFieldValue('scheduledPayments', [
                                        ...values.scheduledPayments,
                                        ...payments,
                                    ])
                                }
                            />
                        )}

                        {showPurchaseModal && (
                            <PurchaseModal
                                purchaseData={purchaseData}
                                showPurchaseModal={showPurchaseModal}
                                setShowPurchaseModal={setShowPurchaseModal}
                                handlePurchaseModalSubmit={handlePurchaseModalSubmit}
                            />
                        )}
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default PaymentPlansForm;
