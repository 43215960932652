import * as Yup from 'yup';

export const salesActivityFiltersInitialValues = {
    saleStartDate: null,
    saleEndDate: null,
    amountDue: '',
    status: { value: 'all', label: 'All' },
};

export const salesActivityFiltersSchema = Yup.object({
    saleEndDate: Yup.date().nullable(),
    amountDue: Yup.number().nullable(),
    saleStartDate: Yup.date().nullable(),
    status: Yup.object()
        .shape({
            value: Yup.string().required('Type value is required'),
            label: Yup.string().required('Type label is required'),
        })
        .nullable(),
});
