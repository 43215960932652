import { Checkbox } from '@mui/material';

export const aggregatedFileInventoryHeaders = (selectedRows, handleCheckboxChange) => {
    return [
        {
            id: 'select',
            label: '',
            sort: false,
            verticalAlign: 'top',
            render: (row) => (
                <Checkbox
                    checked={selectedRows.some((item) => item.id === row.id)}
                    onChange={() => handleCheckboxChange(row)}
                />
            ),
        },
        {
            id: 'purchased_at',
            label: 'Date',
            sort: false,
            verticalAlign: 'top',
        },
        {
            id: 'purchase_id',
            label: 'Purchase',
            sort: false,
            verticalAlign: 'top',
        },
        {
            id: 'reference_id',
            label: 'Reference',
            sort: false,
            verticalAlign: 'top',
        },
        {
            id: 'description',
            label: 'Description',
            sort: false,
            verticalAlign: 'top',
            render: (row) =>
                `${row.description}${row.venue ? `,  ${row.venue}` : ''} - ${
                    row.expires_at
                }, Section: ${row.class_1}, Row: ${row.class_2}, Seats: ${row.seats} `,
        },
        {
            id: 'amount',
            label: 'Total',
            sort: false,
            verticalAlign: 'top',
            render: (row) => (row.purchase_price != null ? `$${row.purchase_price}` : '-'),
        },
        {
            id: 'purchaser_email',
            label: 'Purchaser Email',
            sort: false,
            verticalAlign: 'top',
        },
    ];
};
