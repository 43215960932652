import { LoadingButton } from '@mui/lab';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Tooltip,
    Typography,
} from '@mui/material';
import Match from 'assets/icons/match.svg';
import Trash from 'assets/icons/Trash.svg';
import axios from 'axios';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { store } from 'store';
import { fetchUnmatchedTransactionsRequest, fetchmatchedTransactionsRequest } from 'store/actions';
import { BREAK_MATCH, COA_FETCH_WITH_CATEGORY } from 'store/services/bank.Service';
import { useCoreService } from 'store/services/core.service';
import { useTransactionService } from 'store/services/transactions.service';
import {
    formatAccountCategoriesData,
    formatCurrencyAmount,
    getCreateTransactionPayload,
} from 'utils/helpers';
import AddNewTransactionModal from './AddNewTransactionModal';

function UnmatchedReconciliation({ accountId }) {
    const { createNewTransaction } = useTransactionService();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch();
    const { unmatchedTransactions, matchedTransactions } = useSelector((state) => state.bank);
    const transactionService = useTransactionService();
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [selectedItemId2, setSelectedItemId2] = useState(null);
    const [match, setMatch] = useState(false);
    const [errors, setErrors] = useState({});
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const reconcileBalanceSummary = useSelector((state) => state.bank.reconcileBalance);

    const closeModal = useCallback(() => {
        setIsModalOpen(false);
    }, []);
    const [hoveredRow, setHoveredRow] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [deleteRowId, setDeleteRowId] = useState(null);

    useEffect(() => {
        if (!showPopup) {
            setDeleteRowId(null);
        }
    }, [showPopup]);

    useEffect(() => {
        if (deleteRowId) {
            setShowPopup(true);
        }
    }, [deleteRowId]);

    const fetchUnmatchedTransactions = useCallback(
        async (setIsDeleteLoading, setModal, setSelectedItemId2) => {
            const { token } = store.getState().auth;
            try {
                const page = 1;
                const pageSize = 50;
                dispatch(
                    fetchUnmatchedTransactionsRequest({
                        reconciliationId: reconcileBalanceSummary?.bank_reconciliation_id,
                        page,
                        pageSize,
                        token,
                        ...(typeof setIsDeleteLoading === 'function' ? { setIsDeleteLoading } : {}),
                        ...(typeof setModal === 'function' ? { setModal } : {}),
                        ...(typeof setSelectedItemId2 === 'function' ? { setSelectedItemId2 } : {}),
                    }),
                );
            } catch (error) {
                console.error('Error fetching user company data:', error);
            }
        },
        [dispatch, reconcileBalanceSummary?.bank_reconciliation_id],
    );

    useEffect(() => {
        if (reconcileBalanceSummary?.bank_reconciliation_id) {
            fetchUnmatchedTransactions();
        }
    }, [reconcileBalanceSummary?.bank_reconciliation_id, dispatch, fetchUnmatchedTransactions]);

    const handleMatch = useCallback(() => {
        const { token } = store.getState().auth;
        const fetchData = async () => {
            try {
                if (match) {
                    const plaid_transaction_id = selectedItemId.id;
                    await axios.post(
                        BREAK_MATCH,
                        {
                            plaid_transaction_id,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            },
                        },
                    );

                    setMatch(false);
                    setSelectedItemId(null);
                    setSelectedItemId2(null);
                } else {
                    const plaid_transaction_id = selectedItemId.id;
                    const transaction_id = selectedItemId2.id;

                    dispatch(
                        fetchmatchedTransactionsRequest({
                            plaid_transaction_id,
                            transaction_id,
                            token,
                        }),
                    );

                    setMatch(true);
                }
            } catch (error) {
                console.error('Error handling match:', error);
            }
        };

        fetchData();
    }, [dispatch, match, selectedItemId?.id, selectedItemId2?.id]);
    const handleChange = useCallback(
        (item) => () => {
            if (selectedItemId?.id === item.id) {
                setSelectedItemId(null);
            } else {
                setSelectedItemId(item);
            }
        },
        [selectedItemId?.id],
    );
    const isChecked = useCallback((id) => selectedItemId?.id === id, [selectedItemId?.id]);

    const handleChange2 = useCallback(
        (item) => () => {
            if (selectedItemId2?.id === item.id) {
                setSelectedItemId2(null);
            } else {
                setSelectedItemId2(item);
            }
        },
        [selectedItemId2?.id],
    );
    const isChecked2 = useCallback((id) => selectedItemId2?.id === id, [selectedItemId2?.id]);

    const [transactionDate, setTransactionDate] = useState(dayjs(new Date()));
    const [selectedCurrency, setSelectedCurrency] = useState('USD');
    const { data: company } = useSelector((state) => state.company);
    const [selectedPaymentStatus, setSelectedPaymentStatus] = useState('');
    const [paymentStatuses, setPaymentStatuses] = useState([]);
    const [amount, setAmount] = useState('');
    const [panelData, setPanelData] = useState({
        title: 'Select Category',
        children: [],
    });
    const [openDropDownIndex, setOpenDropDownIndex] = useState(null);
    const [originalPanelData, setOriginalPanelData] = useState([]);
    const [isPanelDataLoading, setIsPanelDataLoading] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const { getTransactionStatusOptions, isLoading: isPaymentLoading } = useCoreService();
    const [description, setDescription] = useState('');

    useEffect(() => {
        if (isModalOpen) return;
        setTransactionDate(dayjs(new Date()));
        setSelectedCurrency('USD');
        setSelectedPaymentStatus('');
        setPaymentStatuses([]);
        setAmount('');
        setPanelData({
            title: 'Select Category',
            children: [],
        });
        setOriginalPanelData([]);
        setIsPanelDataLoading(false);
        setSelectedAccount(null);
        setOpenDropDownIndex(null);
        setDescription('');
        setErrors({});
        setIsSubmitLoading(false);
    }, [isModalOpen]);

    const fetchCompanyAccountsCategoryData = useCallback(async (companyId) => {
        setIsPanelDataLoading(true);
        setPanelData({
            title: 'Select',
            children: [],
        });
        setOriginalPanelData([]);
        try {
            const { token } = store.getState().auth;
            const response = await fetch(COA_FETCH_WITH_CATEGORY(companyId), {
                method: 'GET',
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = await response.json();
            setPanelData(formatAccountCategoriesData(data, true));
            setOriginalPanelData(data?.active_accounts || []);
        } catch (error) {
            console.error('Error fetching dropdown options:', error);
            setPanelData({
                title: 'Select',
                children: [],
            });
        } finally {
            setIsPanelDataLoading(false);
        }
    }, []);

    const importAccountCetegories = useCallback(
        (companyId) => {
            fetchCompanyAccountsCategoryData(companyId);
        },
        [fetchCompanyAccountsCategoryData],
    );

    const onPanelListItemClick = useCallback(
        (accountNumber, plaidId, transactionId) => {
            const account = originalPanelData?.find((item) => item?.id === accountNumber);
            setSelectedAccount(account);
            setOpenDropDownIndex(null);
            setErrors((previousErrors) => ({
                ...previousErrors,
                account: null,
            }));
        },
        [originalPanelData],
    );

    const handleDropDownClose = useCallback(() => {
        setOpenDropDownIndex(null);
    }, []);

    const handleDropDownToggle = useCallback(
        (index, depth, companyId) => {
            if (index !== openDropDownIndex && depth === 1 && companyId) {
                importAccountCetegories(companyId);
            } else if (index !== openDropDownIndex && depth === 1) {
            }
            setOpenDropDownIndex((prev) => (prev === index ? null : index));
        },
        [importAccountCetegories, openDropDownIndex],
    );

    const handleDescriptionChange = useCallback((event) => {
        setErrors((previousErrors) => ({
            ...previousErrors,
            description: null,
        }));
        setDescription(event.target.value);
    }, []);

    const handleAmountChange = useCallback((event) => {
        const value = event.target.value;
        if (!isNaN(value)) {
            setAmount(value);
            setErrors((previousErrors) => ({
                ...previousErrors,
                amount: null,
            }));
        } else {
            setErrors((previousErrors) => ({
                ...previousErrors,
                amount: 'Amount must be a number',
            }));
        }
    }, []);

    const handleCurrencyChange = useCallback((event, newValue) => {
        setSelectedCurrency(newValue ? newValue?.value : null);
        setErrors((previousErrors) => ({
            ...previousErrors,
            currency: null,
        }));
    }, []);

    const handleDropdownOpen = useCallback(async () => {
        try {
            const response = await getTransactionStatusOptions(company.id, true);
            setPaymentStatuses([
                {
                    id: -1000,
                    label: 'Not Paid',
                },
                ...response?.map((item) => ({
                    id: item?.id,
                    label: `Paid With: ${item?.account_type_3}`,
                })),
            ]);
        } catch (error) {
            console.error('Error fetching options:', error);
        } finally {
        }
    }, [company.id, getTransactionStatusOptions]);

    const handlePaymentStatusChange = useCallback((event) => {
        setSelectedPaymentStatus(event.target.value);
        setErrors((previousErrors) => ({
            ...previousErrors,
            paymentStatus: null,
        }));
    }, []);

    const submit = useCallback(
        async (
            description,
            selectedAccount,
            transactionDate,
            selectedCurrency,
            selectedPaymentStatus,
            amount,
        ) => {
            let isError = false;
            setErrors({});
            if (!description) {
                setErrors((previousErrors) => ({
                    ...previousErrors,
                    description: 'Please enter description',
                }));
                isError = true;
            }
            if (!selectedAccount) {
                setErrors((previousErrors) => ({
                    ...previousErrors,
                    account: 'Please select account category',
                }));
                isError = true;
            }
            if (!transactionDate) {
                setErrors((previousErrors) => ({
                    ...previousErrors,
                    transactionDate: 'Please select transaction date',
                }));
                isError = true;
            }
            if (!selectedCurrency) {
                setErrors((previousErrors) => ({
                    ...previousErrors,
                    currency: 'Please select currency',
                }));
                isError = true;
            }
            if (!selectedPaymentStatus) {
                setErrors((previousErrors) => ({
                    ...previousErrors,
                    paymentStatus: 'Please select payment status',
                }));
                isError = true;
            }
            if (!amount) {
                setErrors((previousErrors) => ({
                    ...previousErrors,
                    amount: 'Please enter amount',
                }));
                isError = true;
            }
            if (isError) return;
            setIsSubmitLoading(true);
            try {
                await createNewTransaction(
                    company?.id,
                    getCreateTransactionPayload(
                        transactionDate,
                        description,
                        selectedAccount,
                        amount,
                        store?.getState()?.me?.data?.email,
                        selectedCurrency,
                        company?.id,
                        selectedPaymentStatus,
                    ),
                );
            } catch (error) {
            } finally {
                closeModal();
            }
        },
        [closeModal, company?.id, createNewTransaction],
    );

    const onDeleteTransaction = useCallback(
        async (id) => {
            try {
                setIsDeleteLoading(true);
                const data = await transactionService?.deleteTransaction(id);
                if (data?.status === 204) {
                    fetchUnmatchedTransactions(
                        setIsDeleteLoading,
                        setShowPopup,
                        setSelectedItemId2,
                    );
                }
            } catch (error) {
            } finally {
                setIsDeleteLoading(false);
            }
        },
        [fetchUnmatchedTransactions, transactionService],
    );

    return (
        <>
            <Box width="100%">
                <Box
                    mt={3}
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'dark' ? '#212936' : 'white',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-around',
                        flexDirection: { xs: 'column', sm: 'row' },
                        alignItems: 'center',
                        borderRadius: '12px',
                        padding: '24px',
                        gap: { xs: 2, sm: 0 },
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                color: selectedItemId?.amount?.startsWith('-')
                                    ? '#F75555'
                                    : '#0A8C4D',
                                fontWeight: '600',
                                fontSize: '16px',
                            }}
                        >
                            +$ {formatCurrencyAmount(selectedItemId?.amount || 0)}
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: '400',
                                fontSize: '16px',
                                color: (theme) =>
                                    theme.palette.mode === 'dark' ? '#D3D5DA' : '#121826',
                            }}
                        >
                            Selected in your bank
                        </Typography>
                    </Box>
                    <Box>
                        <Tooltip
                            title={
                                !selectedItemId || !selectedItemId2
                                    ? 'Select both items to enable match'
                                    : ''
                            }
                            arrow
                            placement="top"
                        >
                            <span>
                                <Button
                                    sx={{
                                        backgroundColor: '#00C791',
                                        color: 'white',
                                        textTransform: 'none',
                                        borderRadius: '8px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '8px',
                                        '&:hover': {
                                            backgroundColor: '#00A675',
                                        },
                                        '&.Mui-disabled': {
                                            backgroundColor: '#73bf93',
                                            color: 'white',
                                        },
                                    }}
                                    onClick={handleMatch}
                                    disabled={
                                        !selectedItemId ||
                                        !selectedItemId2 ||
                                        !(
                                            selectedItemId2?.transaction_total ===
                                            selectedItemId?.amount
                                        )
                                    }
                                >
                                    <img
                                        src={Match}
                                        alt="Icon"
                                        style={{
                                            width: 18,
                                            height: 18,
                                        }}
                                    />
                                    {!match ? 'Match' : 'Break Match'}
                                </Button>
                            </span>
                        </Tooltip>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                color: selectedItemId2?.transaction_total?.startsWith('-')
                                    ? '#F75555'
                                    : '#0A8C4D',
                                fontWeight: '600',
                                fontSize: '16px',
                            }}
                        >
                            +$ {formatCurrencyAmount(selectedItemId2?.transaction_total || 0)}
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: '400',
                                fontSize: '16px',
                                color: (theme) =>
                                    theme.palette.mode === 'dark' ? '#D3D5DA' : '#121826',
                            }}
                        >
                            Selected in Circler
                        </Typography>
                    </Box>
                </Box>

                <Grid
                    width="100%"
                    container
                    mt={4}
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'dark' ? '#212936' : 'white',
                        marginLeft: '1px',
                        borderRadius: '12px',
                    }}
                >
                    <Grid item xs={12} md={6} p={2}>
                        <Typography
                            sx={{
                                fontWeight: '700',
                                fontSize: '18px',
                                color: (theme) =>
                                    theme.palette.mode === 'dark' ? '#F9FAFB' : '#121826',
                                marginBottom: '15px',
                            }}
                        >
                            Unreconciled bank Transactions
                        </Typography>

                        <Box
                            sx={{
                                maxHeight: '400px',
                                overflowY: 'auto',
                                '&::-webkit-scrollbar': {
                                    width: '5px',
                                },
                                '&::-webkit-scrollbar-track': {
                                    background: '#f1f1f1',
                                    borderRadius: '10px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    background: '#888',
                                    borderRadius: '10px',
                                },
                                '&::-webkit-scrollbar-thumb:hover': {
                                    background: '#555',
                                },
                            }}
                        >
                            {!match ? (
                                unmatchedTransactions?.plaid_transactions?.transactions?.map(
                                    (item) => (
                                        <Box
                                            key={item.id}
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            width="100%"
                                            sx={{
                                                border: (theme) =>
                                                    theme.palette.mode === 'dark'
                                                        ? '1px solid #394150'
                                                        : '1px solid #E5E6EB',
                                            }}
                                            borderRadius="8px"
                                            padding={2}
                                            marginBottom={2}
                                        >
                                            <Box display="flex" alignItems="center" gap={2}>
                                                <Checkbox
                                                    checked={isChecked(item.id)}
                                                    onChange={handleChange(item)}
                                                />
                                                <Box>
                                                    <Typography
                                                        sx={{
                                                            fontWeight: '400',
                                                            fontSize: '16px',
                                                            color: (theme) =>
                                                                theme.palette.mode === 'dark'
                                                                    ? '#D3D5DA'
                                                                    : '#121826',
                                                        }}
                                                    >
                                                        {item.date}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontWeight: '400',
                                                            fontSize: '16px',
                                                            color: (theme) =>
                                                                theme.palette.mode === 'dark'
                                                                    ? '#D3D5DA'
                                                                    : '#121826',
                                                        }}
                                                    >
                                                        {item.merchant_name}
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            <Typography
                                                sx={{
                                                    color: item?.amount?.startsWith('-')
                                                        ? '#F75555'
                                                        : '#0A8C4D',
                                                    fontWeight: '600',
                                                    fontSize: '16px',
                                                }}
                                            >
                                                $ {formatCurrencyAmount(item.amount || 0)}
                                            </Typography>
                                        </Box>
                                    ),
                                )
                            ) : (
                                <Box
                                    key={matchedTransactions.id}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    width="100%"
                                    sx={{
                                        border: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '1px solid #394150'
                                                : '1px solid #E5E6EB',
                                    }}
                                    borderRadius="8px"
                                    padding={2}
                                    marginBottom={2}
                                >
                                    <Box display="flex" alignItems="center" gap={2}>
                                        <Box>
                                            <Typography
                                                sx={{
                                                    fontWeight: '400',
                                                    fontSize: '16px',
                                                    color: (theme) =>
                                                        theme.palette.mode === 'dark'
                                                            ? '#D3D5DA'
                                                            : '#121826',
                                                }}
                                            >
                                                {matchedTransactions?.matched_transaction?.date}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontWeight: '400',
                                                    fontSize: '16px',
                                                    color: (theme) =>
                                                        theme.palette.mode === 'dark'
                                                            ? '#D3D5DA'
                                                            : '#121826',
                                                }}
                                            >
                                                {
                                                    matchedTransactions?.matched_transaction
                                                        ?.description
                                                }
                                            </Typography>
                                        </Box>
                                    </Box>

                                    <Typography
                                        sx={{
                                            color: matchedTransactions?.matched_transaction?.transaction_total?.startsWith(
                                                '-',
                                            )
                                                ? '#F75555'
                                                : '#0A8C4D',
                                            fontWeight: '600',
                                            fontSize: '16px',
                                        }}
                                    >
                                        ${' '}
                                        {formatCurrencyAmount(
                                            matchedTransactions?.matched_transaction
                                                ?.transaction_total || 0,
                                        )}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6} p={2}>
                        <Grid
                            container
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid item xs={12} md={9}>
                                <Typography
                                    sx={{
                                        fontWeight: '700',
                                        fontSize: '18px',
                                        color: (theme) =>
                                            theme.palette.mode === 'dark' ? '#F9FAFB' : '#121826',
                                    }}
                                >
                                    Circler Transactions
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={3}
                                display="flex"
                                flexDirection="row"
                                gap={1}
                                justifyContent="flex-end"
                                alignItems="center"
                            >
                                {selectedItemId && (
                                    <Button
                                        onClick={(e) => {}}
                                        sx={{
                                            border: '1px solid #FA9999',
                                            borderRadius: '20%',
                                            color: '#F75555',
                                            textTransform: 'none',
                                            fontSize: {
                                                xs: '10px',
                                                sm: '16px',
                                            },
                                            width: '30px',
                                            height: '30px',
                                            minWidth: 'unset',
                                            padding: '5px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <img
                                            src={Trash}
                                            alt="Icon"
                                            style={{
                                                width: 20,
                                                height: 20,
                                            }}
                                        />
                                    </Button>
                                )}
                                <Button
                                    sx={{
                                        border: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '1px solid #394150'
                                                : '1px solid #E5E6EB',
                                        color: (theme) =>
                                            theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F',
                                        borderRadius: '8px',
                                        outline: 'none',
                                        textTransform: 'none',
                                    }}
                                    onClick={() => setIsModalOpen(true)}
                                >
                                    + New Transaction
                                </Button>
                            </Grid>
                        </Grid>

                        <Box
                            sx={{
                                maxHeight: '400px',
                                overflowY: 'auto',
                                '&::-webkit-scrollbar': {
                                    width: '5px',
                                },
                                '&::-webkit-scrollbar-track': {
                                    background: '#f1f1f1',
                                    borderRadius: '10px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    background: '#888',
                                    borderRadius: '10px',
                                },
                                '&::-webkit-scrollbar-thumb:hover': {
                                    background: '#555',
                                },
                            }}
                        >
                            {!match ? (
                                unmatchedTransactions?.bookkeeper_transactions?.transactions?.map(
                                    (item) => (
                                        <Box
                                            key={item.id}
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            width="100%"
                                            sx={{
                                                border: (theme) =>
                                                    theme.palette.mode === 'dark'
                                                        ? '1px solid #394150'
                                                        : '1px solid #E5E6EB',
                                            }}
                                            borderRadius="8px"
                                            padding={2}
                                            marginBottom={2}
                                            onMouseEnter={() => setHoveredRow(item?.id)}
                                            onMouseLeave={() => setHoveredRow(null)}
                                        >
                                            <Box display="flex" alignItems="center" gap={2}>
                                                <Checkbox
                                                    checked={isChecked2(item.id)}
                                                    onChange={handleChange2(item)}
                                                />
                                                <Box>
                                                    <Typography
                                                        sx={{
                                                            fontWeight: '400',
                                                            fontSize: '16px',
                                                            color: (theme) =>
                                                                theme.palette.mode === 'dark'
                                                                    ? '#D3D5DA'
                                                                    : '#121826',
                                                        }}
                                                    >
                                                        {item.date}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontWeight: '400',
                                                            fontSize: '16px',
                                                            color: (theme) =>
                                                                theme.palette.mode === 'dark'
                                                                    ? '#D3D5DA'
                                                                    : '#121826',
                                                        }}
                                                    >
                                                        {item?.counterparty_name ||
                                                            item?.description}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box flexGrow={1} />

                                            <Typography
                                                sx={{
                                                    color: item?.transaction_total?.startsWith('-')
                                                        ? '#F75555'
                                                        : '#0A8C4D',
                                                    fontWeight: '600',
                                                    fontSize: '16px',
                                                }}
                                            >
                                                ${' '}
                                                {formatCurrencyAmount(item?.transaction_total || 0)}
                                            </Typography>
                                            {hoveredRow === item?.id &&
                                                selectedItemId2?.id !== hoveredRow && (
                                                    <Button
                                                        onClick={() => setDeleteRowId(item?.id)}
                                                        sx={{
                                                            border: '1px solid #FA9999',
                                                            borderRadius: '20%',
                                                            color: '#F75555',
                                                            bgcolor: 'white',
                                                            textTransform: 'none',
                                                            fontSize: {
                                                                xs: '10px',
                                                                sm: '16px',
                                                            },
                                                            width: '30px',
                                                            height: '30px',
                                                            minWidth: 'unset',
                                                            padding: '5px',
                                                            display: 'flex',
                                                            marginLeft: '8px',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}
                                                    >
                                                        <img
                                                            src={Trash}
                                                            alt="Icon"
                                                            style={{
                                                                width: 20,
                                                                height: 20,
                                                            }}
                                                        />
                                                    </Button>
                                                )}
                                            {selectedItemId2?.id === item?.id && (
                                                <Button
                                                    onClick={() => setDeleteRowId(item?.id)}
                                                    sx={{
                                                        border: '1px solid #FA9999',
                                                        borderRadius: '20%',
                                                        color: '#F75555',
                                                        bgcolor: 'white',
                                                        textTransform: 'none',
                                                        fontSize: {
                                                            xs: '10px',
                                                            sm: '16px',
                                                        },
                                                        width: '30px',
                                                        height: '30px',
                                                        minWidth: 'unset',
                                                        padding: '5px',
                                                        display: 'flex',
                                                        marginLeft: '8px',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <img
                                                        src={Trash}
                                                        alt="Icon"
                                                        style={{
                                                            width: 20,
                                                            height: 20,
                                                        }}
                                                    />
                                                </Button>
                                            )}
                                        </Box>
                                    ),
                                )
                            ) : (
                                <Box
                                    key={matchedTransactions.id}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    width="100%"
                                    sx={{
                                        border: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '1px solid #394150'
                                                : '1px solid #E5E6EB',
                                    }}
                                    borderRadius="8px"
                                    padding={2}
                                    marginBottom={2}
                                >
                                    <Box display="flex" alignItems="center" gap={2}>
                                        <Box>
                                            <Typography
                                                sx={{
                                                    fontWeight: '400',
                                                    fontSize: '16px',
                                                    color: (theme) =>
                                                        theme.palette.mode === 'dark'
                                                            ? '#D3D5DA'
                                                            : '#121826',
                                                }}
                                            >
                                                {matchedTransactions.date}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontWeight: '400',
                                                    fontSize: '16px',
                                                    color: (theme) =>
                                                        theme.palette.mode === 'dark'
                                                            ? '#D3D5DA'
                                                            : '#121826',
                                                }}
                                            >
                                                {matchedTransactions?.merchant_name}
                                            </Typography>
                                        </Box>
                                    </Box>

                                    <Typography
                                        sx={{
                                            color: matchedTransactions?.amount?.startsWith('-')
                                                ? '#F75555'
                                                : '#0A8C4D',
                                            fontWeight: '600',
                                            fontSize: '16px',
                                        }}
                                    >
                                        $ {formatCurrencyAmount(matchedTransactions?.amount || 0)}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <AddNewTransactionModal
                open={isModalOpen}
                onClose={() => closeModal()}
                onSave={submit}
                loading={false}
                description={description}
                handleDescriptionChange={handleDescriptionChange}
                panelData={panelData}
                selectedAccount={selectedAccount}
                companyId={company?.id}
                onPanelListItemClick={onPanelListItemClick}
                openDropDownIndex={openDropDownIndex}
                handleDropDownToggle={handleDropDownToggle}
                isPanelDataLoading={isPanelDataLoading}
                handleDropDownClose={handleDropDownClose}
                transactionDate={transactionDate}
                setTransactionDate={setTransactionDate}
                selectedCurrency={selectedCurrency}
                handleCurrencyChange={handleCurrencyChange}
                selectedPaymentStatus={selectedPaymentStatus}
                handlePaymentStatusChange={handlePaymentStatusChange}
                handleDropdownOpen={handleDropdownOpen}
                amount={amount}
                handleAmountChange={handleAmountChange}
                isPaymentLoading={isPaymentLoading}
                paymentStatuses={paymentStatuses}
                errors={errors}
                isSubmitLoading={isSubmitLoading}
            />

            <Dialog
                open={showPopup}
                onClose={() => setShowPopup(false)}
                fullWidth
                sx={{
                    '& .MuiDialog-paper': {
                        borderRadius: '15px',
                        bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#212936' : 'white'),
                    },
                }}
            >
                <DialogTitle
                    sx={{
                        bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#212936' : 'white'),
                        color: (theme) => (theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000'),
                    }}
                >
                    Delete Transaction
                </DialogTitle>
                <DialogContent
                    sx={{
                        bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#212936' : 'white'),
                        overflowY: 'auto',
                        '&::-webkit-scrollbar': {
                            width: '8px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'dark' ? '#555' : '#888',
                            borderRadius: '8px',
                            border: '2px solid transparent',
                            backgroundClip: 'content-box',
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'dark' ? '#777' : '#555',
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'dark' ? '#2D3B4E' : '#f1f1f1',
                            borderRadius: '8px',
                        },
                    }}
                >
                    <Box
                        width="100%"
                        display="flex"
                        flexDirection="column"
                        gap={2}
                        mt={1}
                        border="none"
                        borderRadius="8px"
                        position="relative"
                        sx={{
                            border: 'none',
                        }}
                    >
                        Are you sure you want to delete transaction?
                    </Box>
                </DialogContent>

                <DialogActions
                    sx={{
                        padding: 2,
                        bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#212936' : 'white'),
                    }}
                >
                    <Button
                        sx={{
                            fontWeight: '500',
                            fontSize: '14px',
                            color: '#6C727F',
                            border: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '1px solid #394150'
                                    : '1px solid #E5E6EB',
                            textTransform: 'none',
                            marginRight: '10px',
                        }}
                        size="small"
                        onClick={() => setShowPopup(false)}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        sx={{
                            backgroundColor: '#F75555',
                            color: 'white',
                            borderRadius: '8px',
                            textTransform: 'none',
                            paddingX: '20px',
                            paddingY: '8px',
                            '&:hover': {
                                backgroundColor: '#D64545',
                            },
                            width: {
                                xs: '100%',
                                sm: 'auto',
                            },
                        }}
                        loading={isDeleteLoading}
                        size="small"
                        onClick={() => onDeleteTransaction(deleteRowId)}
                    >
                        Delete
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default UnmatchedReconciliation;
