import * as Yup from 'yup';

export const exclusionRuleSchema = Yup.object({
    account: Yup.object()
        .shape({
            value: Yup.string().required('Account value is required'),
            label: Yup.string().required('Account label is required'),
        })
        .nullable()
        .required('Account is required'),
    field: Yup.object()
        .shape({
            value: Yup.string().required('Field value is required'),
            label: Yup.string().required('Field label is required'),
        })
        .nullable()
        .required('Field is required'),
    operator: Yup.object()
        .shape({
            value: Yup.string().required('Operator value is required'),
            label: Yup.string().required('Operator label is required'),
        })
        .nullable()
        .required('Operator is required'),
    text: Yup.string().required('Text is required'),
});
