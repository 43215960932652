import { Typography, Link, Checkbox } from '@mui/material';

import { Button, CoaNestedDropdown } from 'shared';

export const unimportedTransactionsTableHeaders = (
    selectedRows,
    selectedOption,
    setSelectedRows,
    coaCategoryData,
    handleCoaUpdate,
    handleSelectAll,
    handleImportClick,
    totalTransactions,
) => {
    const allChecked =
        totalTransactions > 0 && selectedRows.length === totalTransactions;
    return [
        {
            id: 'selected',
            label: (
                <Checkbox
                    size="small"
                    padding={0}
                    onChange={handleSelectAll}
                    checked={allChecked}
                    indeterminate={
                        selectedRows.length > 0 &&
                        selectedRows.length < totalTransactions
                    }
                />
            ),
            sort: false,
            render: (row) => (
                <Checkbox
                    size="small"
                    checked={selectedRows.includes(row.id)}
                    onChange={(event) =>
                        setSelectedRows((prevRows) =>
                            event.target.checked
                                ? [...prevRows, row.id]
                                : prevRows.filter((id) => id !== row.id),
                        )
                    }
                />
            ),
        },
        {
            id: 'date',
            label: 'Date',
            sort: false,
        },
        {
            id: 'description',
            label: 'Description',
            sort: false,
        },
        {
            id: 'amount',
            label: 'Amount',
            sort: false,
            render: (row) => `${row.currency_code} ${row.amount}`,
        },
        {
            id: 'category',
            label: 'Category',
            sort: false,
            render: (row) => (
                <CoaNestedDropdown
                    coaCategoryData={coaCategoryData}
                    selectedOption={{
                        title: `${row?.coa_account?.account_number}, ${row?.coa_account?.account_name}`,
                    }}
                    setSelectedOption={(data) => handleCoaUpdate(data, row)}
                />
            ),
        },
        {
            id: 'action',
            label: 'Type',
            sort: false,
            render: (row) => (
                <Typography fontSize="12px">
                    {row?.status.replace(/^\w/, (c) => c.toUpperCase())} to{' '}
                    <Link href="#">
                        {row?.transaction_type
                            .replace(/_/g, ' ')
                            .replace(/\b\w/g, (char) => char.toUpperCase())}
                    </Link>
                </Typography>
            ),
        },
        {
            id: 'import',
            label: 'Action',
            sort: false,
            render: (row) => (
                <Button
                    sx={{ padding: '5px', margin: '0', fontSize: '12px' }}
                    loading={selectedOption?.id == row.id}
                    size="small"
                    text="Import"
                    onClick={() => handleImportClick(row)}
                />
            ),
        },
    ];
};
