import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Button,
    Box,
    Paper,
    Skeleton,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useReportService } from 'store/services/report.service';
import { journalEntryStyles } from '../style/syles';

const JournalEntry = () => {
    const { account_id } = useParams();
    const navigate = useNavigate();
    const { getJournalEntries } = useReportService();
    const [isLoading, setIsLoading] = useState(true);
    const [journalData, setJournalData] = useState([]);

    const classes = journalEntryStyles();

    useEffect(() => {
        const fetchJournalEntries = async () => {
            try {
                setIsLoading(true);
                const data = await getJournalEntries(account_id);
                setJournalData(data);
            } catch (error) {
                setJournalData([]);
            } finally {
                setIsLoading(false);
            }
        };

        if (account_id) {
            fetchJournalEntries();
        }
    }, [account_id, getJournalEntries]);

    return (
        <Paper sx={classes.paperContainer}>
            <Button
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate(-1)}
                sx={classes.backButton}
            >
                Back
            </Button>

            <Box sx={classes.titleBox}>
                <Typography variant="h5" fontWeight="bold">
                    Journal Entry for Account ID: {account_id}
                </Typography>
            </Box>

            <TableContainer component={Paper} sx={classes.tableContainer}>
                <Table sx={classes.table}>
                    <TableHead>
                        <TableRow sx={classes.tableHeadRow}>
                            <TableCell sx={classes.tableHeadCell}>
                                Description
                            </TableCell>
                            <TableCell sx={classes.tableHeadCell} align="right">
                                Debit
                            </TableCell>
                            <TableCell sx={classes.tableHeadCell} align="right">
                                Credit
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading
                            ? [...Array(4)].map((_, index) => (
                                  <TableRow key={index}>
                                      <TableCell>
                                          <Skeleton
                                              variant="text"
                                              width="90%"
                                              height={24}
                                          />
                                      </TableCell>
                                      <TableCell align="right">
                                          <Skeleton
                                              variant="text"
                                              width="80%"
                                              height={24}
                                          />
                                      </TableCell>
                                      <TableCell align="right">
                                          <Skeleton
                                              variant="text"
                                              width="80%"
                                              height={24}
                                          />
                                      </TableCell>
                                  </TableRow>
                              ))
                            : journalData.map((entry, index) => (
                                  <TableRow key={index}>
                                      <TableCell>{entry.description}</TableCell>
                                      <TableCell align="right">
                                          {entry.debit.toLocaleString()}
                                      </TableCell>
                                      <TableCell align="right">
                                          {entry.credit.toLocaleString()}
                                      </TableCell>
                                  </TableRow>
                              ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {!isLoading && journalData.length === 0 && (
                <Typography sx={classes.noDataText}>
                    No journal entries found.
                </Typography>
            )}
        </Paper>
    );
};

export default JournalEntry;
