import { Button, Stack } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { SET_SELECTED_ITEM } from 'store/actions';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';

const tabsData = [
    { label: 'Transactions', path: '/transaction' },
    // {
    //     label: 'Email transactions',
    //     path: '/email-transactions',
    // },
    {
        label: 'Import bank transactions',
        path: '/import-bank-transactions',
    },
    // { label: 'Scan Receipt', path: '/scan-receipt' },
    {
        label: 'Upload Transactions',
        path: '/upload-transactions',
    },
    // { label: 'Bank Reconciliation', path: '/bank-reconciliation' },
];

const TransactionTabsList = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const selectedTab = useSelector((state) => state.me.selectedItems);

    const changeTab = (tab, index) => {
        dispatch(SET_SELECTED_ITEM(tab.label));
        navigate(tab.path);
    };

    useEffect(() => {
        const currentTab = tabsData.find((tab) => tab.path == location.pathname);
        if (currentTab && currentTab.label !== selectedTab) {
            dispatch(SET_SELECTED_ITEM(currentTab.label));
        }
    }, [location.pathname, dispatch, selectedTab]);

    const getTabButtonStyle = (tabLabel) => ({
        textTransform: 'none',
        fontSize: '1rem',
        fontWeight: selectedTab === tabLabel ? '600' : '500',
        backgroundColor: selectedTab === tabLabel ? '#CEEFDF' : 'transparent',
        borderRadius: selectedTab === tabLabel ? '3px' : 'none',
        color: selectedTab === tabLabel ? '#0A8C4D' : '#6C727F',
    });

    return (
        <Stack
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            gap={1}
            width={{ xs: '100%', md: 'fit-content' }}
            style={{
                background: theme.palette.mode === 'dark' ? 'transparent' : 'rgb(244, 244, 246)',
                minHeight: '60px',
                borderRadius: '5px',
                padding: '1rem 2rem',
            }}
            alignItems="center"
        >
            {tabsData?.map((tab) => (
                <Grid
                    item
                    key={tab.label}
                    style={{
                        borderRadius: '5px',
                    }}
                >
                    <Button
                        onClick={() => changeTab(tab)}
                        style={getTabButtonStyle(tab.label)}
                        variant={selectedTab === tab.label ? 'contained' : 'text'}
                    >
                        {tab.label}
                    </Button>
                </Grid>
            ))}
        </Stack>
    );
};

export default TransactionTabsList;
