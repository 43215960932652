import * as Yup from 'yup';

import { bankOptions } from './qbBankOptions';

export const qbAccountsInitialValues = (accounts) => ({
    accounts: accounts.map((account) => ({
        id: account.id,
        name: account.name,
        account_number: account.customer_chart_of_account?.account_number || '',
        is_coa_selected: account.customer_chart_of_account?.source === 'quickbooks' ? 'yes' : 'no',
        selected_coa:
            account.customer_chart_of_account?.source === 'quickbooks'
                ? account.customer_chart_of_account?.id
                : null,
        bank_type: account.customer_chart_of_account?.account_type_3 || null,
    })),
});

const bankTypeValues = bankOptions.map((option) => option.value);

export const qbAccountsSchema = Yup.object().shape({
    accounts: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required('Account name is required'),
            account_number: Yup.string().required('Account number is required'),
            is_coa_selected: Yup.string().required(),
            selected_coa: Yup.string()
                .nullable()
                .when('is_coa_selected', {
                    is: 'yes',
                    then: (schema) => schema.required('QuickBook account is required'),
                    otherwise: (schema) => schema.nullable(),
                }),
            bank_type: Yup.string()
                .nullable()
                .when('is_coa_selected', {
                    is: 'no',
                    then: (schema) =>
                        schema
                            .required('Account type is required')
                            .oneOf(bankTypeValues, 'Invalid account type'),
                    otherwise: (schema) => schema.nullable(),
                }),
        }),
    ),
});
