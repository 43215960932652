export const uploadTransactionsStyles = () => ({
    inventoryReconciliationWrapper: {
        background: (theme) => (theme.palette.mode == 'dark' ? '#212936' : 'white'),
        padding: '24px',
        borderRadius: '12px',
    },
    buttonsWrapper: {
        display: 'flex',
        gap: '20px',
        marginTop: '20px',
        justifyContent: 'flex-end',
    },
    button: {
        color: 'white',
    },
    clearButton: {
        color: 'white',
        background: (theme) => theme.backgroundColor.primary,
        color: (theme) => theme.primary.dark,
        border: '1px solid #E5E6EB',
        '&:hover': {
            backgroundColor: (theme) => theme.backgroundColor.secondary,
        },
    },
    dropdownsWrapper: {
        display: 'flex',
        gap: '20px',
        width: '100%',
        marginBlock: '10px',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    flex: {
        flex: 1,
    },
});
