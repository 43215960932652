export const handleErrorAlert = (
    setAlertData,
    message = 'Something went wrong, please try again',
) => {
    setAlertData({
        severity: 'error',
        message,
        open: true,
    });
};
