import React from 'react';
import { Typography as Label } from '@mui/material';

const styles = {
    root: {
        fontFamily: (theme) => theme.typography.fontFamily,
        color: (theme) => theme.textColor.dark,
    },
};
export const Typography = ({ text, customStyles, emailLink, fontSize, ...props }) => {
    if (emailLink) {
        const { email } = emailLink;
        const textParts = text.split(email);

        return (
            <Label sx={{ ...styles.root, ...customStyles }} {...props}>
                {textParts.map((part, index) => (
                    <React.Fragment key={index}>
                        {index > 0 && (
                            <a href={`mailto:${email}`} key={index}>
                                {email}
                            </a>
                        )}
                        {part}
                    </React.Fragment>
                ))}
            </Label>
        );
    }

    return (
        <Label sx={{ ...styles.root, ...customStyles }} {...props} fontSize={fontSize || '16px'}>
            {text}
        </Label>
    );
};
