import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Formik, Form } from 'formik';
import { Box, Grid, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { manualBankFormStyles } from '../style/style';
import { handleErrorAlert } from 'utils/handleErrorAlert';
import { createManualBankAccount } from 'store/services/bank.Service';
import { bankAccountTypes, bankAccountSubtypes } from '../utils/bankAccountTypes';
import { Modal, TextField, Typography, Autocomplete, SnackBarAlert } from 'shared';
import { manualBankFormSchema, manualBankFormInitialValues } from '../utils/manualBankFormSchema';

const ManualBankForm = ({ type, open, handleCloseModal, handleManualBankSuccess }) => {
    const classes = manualBankFormStyles();
    const companyData = useSelector((state) => state.company.data);

    const [alertData, setAlertData] = useState({
        severity: '',
        open: false,
        message: '',
    });
    const [buttonLoading, setButtonLoading] = useState(false);

    const handleSubmit = async (values) => {
        try {
            setButtonLoading(true);
            const { type, openingBalanceDate, detailType } = values;
            const data = {
                ...values,
                type: type.value,
                detailType: detailType.value,
                openingBalanceDate: dayjs(openingBalanceDate).format('YYYY-MM-DD'),
                company_id: companyData.id,
            };
            const response = await createManualBankAccount(data);
            handleManualBankSuccess(response?.message);
        } catch (error) {
            handleErrorAlert(setAlertData);
        } finally {
            setButtonLoading(false);
        }
    };

    return (
        <Modal
            fullWidth
            open={open}
            title={`${type} Bank Account`}
            actionBtnTitle={<Typography text={type === 'Add' ? 'Create' : 'Update'} />}
            handleClose={handleCloseModal}
        >
            <Formik
                initialValues={manualBankFormInitialValues()}
                validationSchema={manualBankFormSchema}
                onSubmit={handleSubmit}
            >
                {({ values, setFieldValue, errors, touched }) => (
                    <Form>
                        <Box width="100%" gap={2} mt={1} border="none">
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        name="name"
                                        placeholder="Name"
                                        value={values.name}
                                        onChange={(e) => setFieldValue('name', e.target.value)}
                                        error={touched.name && !!errors.name}
                                        helperText={touched.name && errors.name}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        name="maskNumber"
                                        placeholder="Mask Number"
                                        value={values.maskNumber}
                                        onChange={(e) =>
                                            setFieldValue('maskNumber', e.target.value)
                                        }
                                        error={touched.maskNumber && !!errors.maskNumber}
                                        helperText={touched.maskNumber && errors.maskNumber}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Autocomplete
                                        options={bankAccountTypes}
                                        labelKey="label"
                                        valueKey="value"
                                        placeholder="Type"
                                        name="type"
                                        value={values.type}
                                        onChange={(e, value) => {
                                            setFieldValue('type', value);
                                            setFieldValue('detailType', null);
                                        }}
                                        isSearchField={false}
                                        disableClearable
                                        error={touched.type && !!errors.type}
                                        helperText={touched.type && errors.type}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Autocomplete
                                        disabled={!values.type}
                                        options={bankAccountSubtypes[values.type?.value]}
                                        labelKey="label"
                                        valueKey="value"
                                        placeholder="Detail Type"
                                        name="detailType"
                                        value={values.detailType}
                                        onChange={(e, value) => setFieldValue('detailType', value)}
                                        isSearchField={false}
                                        disableClearable
                                        error={touched.detailType && !!errors.detailType}
                                        helperText={touched.detailType && errors.detailType}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        type="number"
                                        fullWidth
                                        name="openingBalance"
                                        placeholder="Opening Balance"
                                        value={values.openingBalance}
                                        onChange={(e) =>
                                            setFieldValue('openingBalance', e.target.value)
                                        }
                                        error={touched.openingBalance && !!errors.openingBalance}
                                        helperText={touched.openingBalance && errors.openingBalance}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            value={
                                                values.openingBalanceDate &&
                                                dayjs(values.openingBalanceDate)
                                            }
                                            onChange={(date) =>
                                                setFieldValue('openingBalanceDate', date)
                                            }
                                            label="Select Opening Balance Date"
                                            placeholder="Select Opening Balance Date"
                                            format="YYYY-MM-DD"
                                            sx={classes.datePicker}
                                            slotProps={{
                                                textField: {
                                                    error:
                                                        touched.openingBalanceDate &&
                                                        !!errors.openingBalanceDate,
                                                    helperText:
                                                        touched.openingBalanceDate &&
                                                        errors.openingBalanceDate,
                                                    FormHelperTextProps: {
                                                        sx: classes.dateError,
                                                    },
                                                },
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box display="flex" justifyContent="flex-end" marginTop={3} gap={2}>
                            <Button onClick={handleCloseModal}>Cancel</Button>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                loading={buttonLoading}
                            >
                                {type === 'Add' ? 'Create' : 'Update'}
                            </LoadingButton>
                        </Box>
                    </Form>
                )}
            </Formik>

            <SnackBarAlert alertData={alertData} setAlertData={setAlertData} />
        </Modal>
    );
};

export default ManualBankForm;
