import { useSelector } from 'react-redux';
import React, { useState, Fragment, useEffect } from 'react';

import { Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Button, Typography, CircleLoading, SnackBarAlert } from 'shared';

import ExclusionRulesForm from './ExclusionRulesForm';
import ExclusionRulesTable from './ExclusionRulesTable';
import { useCustomMediaQuery } from 'hooks/useMediaQuery';
import { useInventoryService } from 'store/services/inventory.service';
import { exclusionRulesStyles } from '../../../../Inventory/style/style';

const ExclusionRules = () => {
    const { isLoading, getExecutionRules } = useInventoryService();
    const companyData = useSelector((state) => state.company.data);
    const isMobileScreen = useCustomMediaQuery('(max-width: 600px)');

    const classes = exclusionRulesStyles({ isMobileScreen });

    const [page, setPage] = useState(1);
    const [data, setData] = useState(null);
    const [alertData, setAlertData] = useState({
        severity: '',
        open: false,
        message: '',
    });
    const [openExclusionRuleForm, setOpenExclusionRuleForm] = useState(false);

    const handleGetExecutionRules = async (page) => {
        try {
            const response = await getExecutionRules(companyData?.id, page);
            setData(response);
        } catch (error) {
            console.error(error);
        }
    };

    const handleExclusionRuleCreationSuccess = () => {
        setAlertData({
            severity: 'success',
            message: 'Exclusion rule added successfully',
            open: true,
        });
        setOpenExclusionRuleForm(false);
        handleGetExecutionRules(page);
    };

    const handleExclusionRuleDeletionSuccess = (message) => {
        setAlertData({
            severity: 'success',
            message,
            open: true,
        });
        setOpenExclusionRuleForm(false);
        handleGetExecutionRules(page);
    };

    const handlePageChange = (page) => {
        setPage(page);
        handleGetExecutionRules(page);
    };

    useEffect(() => {
        if (companyData) {
            handleGetExecutionRules(page);
        }
    }, [companyData]);

    return (
        <Box sx={classes.root}>
            {companyData ? (
                <Fragment>
                    <Box sx={classes.buttonWrapper}>
                        <Button
                            text={
                                <Typography
                                    text={'Add Exclusion Rule'}
                                    customStyles={classes.addButtonText}
                                />
                            }
                            startIcon={<AddIcon htmlColor={'#FFF'} />}
                            customStyles={classes.exclusionRuleButton}
                            onClick={() => setOpenExclusionRuleForm(true)}
                        />
                    </Box>
                    <Box>
                        <ExclusionRulesTable
                            page={page}
                            data={data}
                            loading={isLoading}
                            companyId={companyData.id}
                            handlePageChange={handlePageChange}
                            handleExclusionRuleDeletionSuccess={
                                handleExclusionRuleDeletionSuccess
                            }
                        />
                    </Box>
                    {openExclusionRuleForm && (
                        <ExclusionRulesForm
                            open={openExclusionRuleForm}
                            companyId={companyData.id}
                            handleCloseModal={() =>
                                setOpenExclusionRuleForm(false)
                            }
                            handleExclusionRuleCreationSuccess={
                                handleExclusionRuleCreationSuccess
                            }
                        />
                    )}

                    <SnackBarAlert
                        alertData={alertData}
                        setAlertData={setAlertData}
                    />
                </Fragment>
            ) : (
                <CircleLoading />
            )}
        </Box>
    );
};

export default ExclusionRules;
