import * as Yup from 'yup';
import { inventoryListHeaders } from './inventoryListHeaders';
import { InventoryRowSchema } from './inventorySchema';

export const classHeaders = inventoryListHeaders.filter((header) =>
    ['Class 1', 'Class 2', 'Class 3'].includes(header.label),
);

export const inventoryPlaceholders = {
    reference_id: 'Reference ID',
    purchase_id: 'Purchase ID',
    sale_reference_id: 'Sales Reference ID ',
    description: 'e.g. Event Name, Venue Name',
    class_1: 'e.g. Section',
    class_2: 'e.g. Row',
    class_3: 'e.g. Seats',
    purchase_price: 'Purchase price',
    payment_last_digits: 'e.g. 4242',
    payment_method: 'MasterCard, Visa, PayPal',
    status: 'Select status',
    expires_at: 'Select expiry date',
    purchased_at: 'Select purchase date',
};

export const statusOptions = [
    { label: 'Active', value: 'active' },
    { label: 'Sold', value: 'sold' },
];

export const InventorySchema = Yup.object().shape({
    rows: Yup.array().of(InventoryRowSchema),
});
