import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
} from '@mui/material';
import dayjs from 'dayjs';
import { useThemeToggle } from 'hooks/useThemeToggle';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import UpgradeToPro from 'shared/UpgradeToPro';
import { store } from 'store';
import { COA_FETCH_WITH_CATEGORY } from 'store/services/bank.Service';
import { useTransactionService } from 'store/services/transactions.service';
import { useCoreService } from 'store/services/core.service';
import { formatAccountCategoriesData, formatDateService, validateTransaction } from 'utils/helpers';
import demo_video from '../assets/video/demo_video.mp4';
import { useCustomMediaQuery } from '../hooks/useMediaQuery';
import { FullWidthTabs, Modal, NonAccountantMode, Typography } from '../shared';
import { SET_CURRENT_USER_SUCCESS } from '../store/actions';
import { useAuthService } from '../store/services/auth.service';
import Chat from './Chat';
import { Reviews } from './Reviews';
import { ReviewsMobile } from './ReviewsMobile';
import { HomeTabs } from 'shared/HomeTabs';
import { SourceModal } from './Transaction/SourceModal';
import useNotification from 'hooks/useNotification';

const makeStyles = ({ isScreenLowerThan1024 }) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        p: isScreenLowerThan1024 ? '20px' : '25px 40px',
    },
    options: {
        borderRadius: '8px',
        border: '1px solid #E5E6EB',
        background: '#FFF',
        height: '52px',
        display: 'flex',
        justifyContent: 'center',
    },
    chatPanel: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '12px',
        background: (theme) => theme.backgroundColor.primary,
    },
    reactPlayer: {
        position: 'absolute',
        top: 0,
        left: 0,
    },
});

export const Home = ({ isEdit, isTransactionsTab }) => {
    const dispatch = useDispatch();
    const { themeMode } = useThemeToggle();
    const { data: company } = useSelector((state) => state?.company);
    const [sendNotification] = useNotification();
    const {
        processReceipts,
        getUnpostedTransactionsByCompany,
        postTransaction,
        editPostTransaction,
        deleteTransaction,
        getTransactionsByCompany,
    } = useTransactionService();
    const [transactions2, setTransactions2] = useState([]);
    const [isTransactionLoading, setIsTransactionLoading] = useState(true);
    const companyNameSaga = useSelector((state) => state?.company?.data);
    const companyNameId = useSelector((state) => state?.company?.data?.id);
    const [tabValue, setTabValue] = useState(0);

    useEffect(() => {
        // Define an async function to handle the async logic
        const fetchUnpostedTransactions = async (companyNameId) => {
            try {
                setIsTransactionLoading(true);
                let result = isEdit
                    ? await getUnpostedTransactionsByCompany(companyNameId)
                    : await getTransactionsByCompany(companyNameId, 1);
                if (!isEdit) {
                    result = result?.results || [];
                }

                setTransactions2(
                    result?.map((item, index) => ({
                        ...item,
                        transaction_items: item?.transaction_items?.map((itemnew, indexNew) => ({
                            ...itemnew,
                            isTaxed: false,
                            taxAmount: 0,
                            isThisTax: false,
                        })),
                    })),
                );
            } catch (error) {
                setTransactions2([]);
                console.error('Error fetching unposted transactions:', error);
            } finally {
                setIsTransactionLoading(false);
            }
        };

        // Call the async function
        if (companyNameId) {
            fetchUnpostedTransactions(companyNameId);
        }
    }, [companyNameId, getTransactionsByCompany, getUnpostedTransactionsByCompany, isEdit]);

    const { updateUserDemo } = useAuthService();
    const is_subscription_active = useSelector(
        (state) => state?.company?.data?.subscription_active,
    );
    const { data: user } = useSelector((state) => state.me);

    const isDemoWatchedByUser = user?.is_demo;

    const isScreenLowerThan1024 = useCustomMediaQuery('(max-width: 1025px)');
    const styles = makeStyles({
        isScreenLowerThan1024,
    });

    const handleCloseModal = () => {
        updateUserDemo({ is_demo: true }).then((res) => {
            //TODO: Need to test this
            dispatch(SET_CURRENT_USER_SUCCESS({ data: res }));
        });
    };
    const reviewsRef = useRef(null);

    const scrollToReviews = useCallback(() => {
        setTimeout(() => {
            reviewsRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, 500);
    }, []);

    const [transactionType, setTransactionType] = useState('expense');
    const [companyName, setCompanyName] = useState('');
    useEffect(() => {
        if (companyNameSaga) {
            setCompanyName(companyNameSaga?.id);
        }
    }, [companyNameSaga]);

    const handleTransactionChange = useCallback((event) => {
        setTransactionType(event.target.value);
    }, []);
    const handleCompanyChange = useCallback((event) => {
        setCompanyName(event.target.value);
    }, []);

    const [checked, setChecked] = useState(true);

    // Handle checkbox state change
    const handleCheckboxChange = useCallback((event) => {
        setChecked(event.target.checked);
    }, []);

    const [files, setFiles] = useState([]);
    const [isFileUploadLoading, setIsFileUploadLoading] = useState(false);

    const handleFileDrop = useCallback(
        (acceptedFiles) => {
            const newFiles = acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                }),
            );

            setFiles([...files, ...newFiles]);
        },
        [files],
    );

    const handleRemoveFile = useCallback((inputFile) => {
        setFiles((files) => files?.filter((file) => file !== inputFile));
    }, []);

    const handleRemoveAllFiles = useCallback(() => {
        setFiles([]);
    }, []);

    const handleFileUpload = useCallback(
        async (files) => {
            try {
                setIsFileUploadLoading(true);
                const response = await processReceipts(
                    transactionType,
                    checked,
                    companyName,
                    files,
                );
                if (response?.length) {
                    setTransactions2((previous) => [
                        ...response?.map((item) => ({
                            ...item,
                            transaction_items: item?.transaction_items?.map((innerItem) => ({
                                ...innerItem,
                                isTaxed: false,
                                taxAmount: 0,
                                isThisTax: false,
                            })),
                        })),
                        ...previous,
                    ]);
                    if (isScreenLowerThan1024) {
                        setTabValue(1);
                    } else {
                        scrollToReviews();
                    }
                    handleRemoveAllFiles();
                } else {
                    setTransactions2([]);
                }
                // console.log(response);
            } catch (error) {
                setTransactions2([]);
            } finally {
                setIsFileUploadLoading(false);
            }
        },
        [
            checked,
            companyName,
            handleRemoveAllFiles,
            isScreenLowerThan1024,
            processReceipts,
            scrollToReviews,
            transactionType,
        ],
    );

    const [page, setPage] = useState(1);
    const isMobileScreen = useCustomMediaQuery('(max-width:600px)');
    const handlePageChange = useCallback((e, pageNumber) => {
        setPage(pageNumber);
    }, []);
    const [description, setDescription] = useState('');
    const [secondTableTransactionDetails, setSecondTableTransactionDetails] = useState('');
    const [secondTableAmount, setSecondTableAmount] = useState('');
    const [secondTableQuantity, setSecondTableQuantity] = useState('');
    const [secondTableCategory, setSecondTableCategory] = useState('');
    const [vendor, setVendor] = useState('');
    const [selectedPaymentStatus, setSelectedPaymentStatus] = useState('');
    const [transactionDate, setTransactionDate] = useState(dayjs(new Date()));
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [amount, setAmount] = useState('');
    const [sourceModal, setSouceModal] = useState(null);
    const [modalItems, setModalItems] = useState([]);
    const [errors, setErrors] = useState({});
    const [errorsSecondTable, setErrorsSecondTable] = useState({});
    const [collapse, setCollapse] = useState(null);
    const [editId, setEditId] = useState(null);
    const [editIdSecondTable, setEditIdSecondTable] = useState(null);
    const { getTransactionStatusOptions, isLoading: isPaymentLoading } = useCoreService();
    const [paymentStatuses, setPaymentStatuses] = useState([
        {
            id: -1000,
            label: 'Not Paid',
        },
    ]);

    const handleDescriptionChange = useCallback((event) => {
        setErrors((previousErrors) => ({
            ...previousErrors,
            description: null,
        }));
        setDescription(event.target.value);
    }, []);

    const handleSecondDescriptionChange = useCallback((event) => {
        setErrorsSecondTable((previousErrors) => ({
            ...previousErrors,
            description: null,
        }));
        setSecondTableTransactionDetails(event.target.value);
    }, []);

    const handleVendorChange = useCallback((event) => {
        setErrors((previousErrors) => ({
            ...previousErrors,
            vendor: null,
        }));
        setVendor(event.target.value);
    }, []);

    const handlePaymentStatusChange = useCallback((event) => {
        setSelectedPaymentStatus(event.target.value);
        setErrors((previousErrors) => ({
            ...previousErrors,
            paymentStatus: null,
        }));
    }, []);

    // const handleAmountChange = useCallback((event) => {
    //     const value = event.target.value;

    //     // Check if the value is a number and is greater than or equal to zero
    //     // if (!isNaN(value || 0) && parseFloat(value || 0) >= 0) {
    //     //     setAmount(value);
    //     //     setErrors((previousErrors) => ({
    //     //         ...previousErrors,
    //     //         amount: null,
    //     //     }));
    //     // }
    //     if (!isNaN(value || 0)) {
    //         setAmount(value);
    //         setErrors((previousErrors) => ({
    //             ...previousErrors,
    //             amount: null,
    //         }));
    //     }
    //     //  else if (parseFloat(value) < 0) {
    //     //     setErrors((previousErrors) => ({
    //     //         ...previousErrors,
    //     //         amount: 'Amount must be a positive number',
    //     //     }));
    //     // }
    //     else {
    //         setErrors((previousErrors) => ({
    //             ...previousErrors,
    //             amount: 'Amount must be a number',
    //         }));
    //     }
    // }, []);

    const handleAmountChange = useCallback((event) => {
        const value = event.target.value.trim();

        // Check if the value matches valid number patterns, including intermediate values like '-' or '-.'
        const isValidAmount = /^-?\d*\.?\d*$/.test(value);

        if (isValidAmount) {
            setAmount(value);
        }
        setErrors((previousErrors) => ({
            ...previousErrors,
            amount: isValidAmount ? null : 'Amount must be a valid number',
        }));
    }, []);

    const handleSecondAmountChange = useCallback((event) => {
        const value = event.target.value.trim();

        // Check if the value matches valid number patterns, including intermediate values like '-' or '-.'
        const isValidAmount = /^-?\d*\.?\d*$/.test(value);

        if (isValidAmount) {
            setSecondTableAmount(value);
        }
        setErrorsSecondTable((previousErrors) => ({
            ...previousErrors,
            amount: isValidAmount ? null : 'Amount must be a valid number',
        }));
    }, []);

    // const handleSecondAmountChange = useCallback((event) => {
    //     const value = event.target.value;

    //     // Check if the value is a number and is greater than or equal to zero
    //     if (!isNaN(value || 0) && parseFloat(value || 0) >= 0) {
    //         setSecondTableAmount(value);
    //         setErrorsSecondTable((previousErrors) => ({
    //             ...previousErrors,
    //             amount: null,
    //         }));
    //     }
    //     //  else if (parseFloat(value) < 0) {
    //     //     setErrorsSecondTable((previousErrors) => ({
    //     //         ...previousErrors,
    //     //         amount: 'Amount must be a positive number',
    //     //     }));
    //     // }
    //     else {
    //         setErrorsSecondTable((previousErrors) => ({
    //             ...previousErrors,
    //             amount: 'Amount must be a number',
    //         }));
    //     }
    // }, []);

    const handleSecondQuantityChange = useCallback((event) => {
        const value = event.target.value;
        if (!isNaN(value)) {
            setSecondTableQuantity(value);
            setErrorsSecondTable((previousErrors) => ({
                ...previousErrors,
                quantity: null,
            }));
        } else {
            setErrorsSecondTable((previousErrors) => ({
                ...previousErrors,
                quantity: 'Quantity must be a number',
            }));
        }
    }, []);

    const handleSourceModalOpen = useCallback((index) => {
        setSouceModal(index);
    }, []);

    const handleSourceModalClose = useCallback((index) => {
        setSouceModal(null);
    }, []);

    useEffect(() => {
        if (sourceModal !== null) {
            setModalItems(transactions2?.[sourceModal]?.files);
        } else {
            setModalItems([]);
        }
    }, [sourceModal, transactions2]);

    const hanldeCloseEditSecondTable = useCallback(() => {
        setSecondTableTransactionDetails('');
        setSecondTableAmount('');
        setSecondTableQuantity('');
        setSecondTableCategory('');
        setEditIdSecondTable(null);
        setErrorsSecondTable({});
    }, []);

    const submitSecondaryTableRow = useCallback(
        async (account, description, price, quantity, index, innerIndex, row, newIndex) => {
            let isError = false;
            setErrorsSecondTable({});
            if (!description) {
                setErrorsSecondTable((previousErrors) => ({
                    ...previousErrors,
                    description: 'Please enter description',
                }));
                isError = true;
            }
            if (!!!account) {
                setErrorsSecondTable((previousErrors) => ({
                    ...previousErrors,
                    account: 'Please select account category',
                }));
                isError = true;
            }

            if (!price) {
                setErrorsSecondTable((previousErrors) => ({
                    ...previousErrors,
                    price: 'Please select price',
                }));
                isError = true;
            }
            // if (parseFloat(price || 0) < 0) {
            //     setErrorsSecondTable((previousErrors) => ({
            //         ...previousErrors,
            //         price: 'Amount must be a positive number',
            //     }));
            //     isError = true;
            // }
            // if (!quantity) {
            //     setErrorsSecondTable((previousErrors) => ({
            //         ...previousErrors,
            //         quantity: 'Please select quantity',
            //     }));
            //     isError = true;
            // }
            if (isError) return;

            try {
                setTransactions2((previousItems) =>
                    previousItems?.map((item, outIndex) =>
                        outIndex === index
                            ? {
                                  ...item,
                                  isEdit: true,
                                  transaction_items: item?.transaction_items?.map(
                                      (itemNew, indexNew) =>
                                          innerIndex === indexNew
                                              ? {
                                                    ...itemNew,
                                                    description,
                                                    // price,
                                                    // quantity,
                                                    // item_total:
                                                    //     price *
                                                    //     quantity,
                                                    item_total: price,
                                                    account: {
                                                        ...account,
                                                    },
                                                }
                                              : itemNew,
                                  ),
                              }
                            : item,
                    ),
                );

                hanldeCloseEditSecondTable();
            } catch (error) {}
        },
        [hanldeCloseEditSecondTable],
    );

    const handleEditClick = useCallback((row, index, previousId) => {
        if (previousId === index) return;
        setTransactionDate(dayjs(new Date(row?.date)));
        setDescription(row?.description);
        setAmount(row?.transaction_total);
        setVendor(row?.counterparty_name);
        setSelectedPaymentStatus(
            row?.is_paid ? row?.payment_method_account?.id || row?.payment_method_account : -1000,
        );
        setEditId(index);
    }, []);

    const handleEditClickSecondTable = useCallback((row, index, previousId, parentRow) => {
        if (previousId === index) return;
        setSecondTableTransactionDetails(row?.description);
        setSecondTableAmount(parentRow?.book_by_each_item ? row?.item_total : row?.item_total);
        setSecondTableQuantity(row?.quantity);
        setSecondTableCategory(parentRow?.book_by_each_item ? row?.account : parentRow?.account);
        setEditIdSecondTable(index);
    }, []);

    const hanldeCloseEdit = useCallback(() => {
        // setPaymentStatuses([]);
        setTransactionDate(dayjs(new Date()));
        setDescription('');
        setAmount('');
        setVendor('');
        setSelectedPaymentStatus('');
        setEditId(null);
    }, []);

    const importPostTransaction = useCallback(
        async (index) => {
            const existingTransaction = transactions2?.[index];
            if (!existingTransaction) return;
            const validationResult = validateTransaction(existingTransaction);
            if (validationResult) {
                sendNotification({
                    msg: validationResult,
                    variant: 'error',
                });
                return;
            }
            try {
                if (existingTransaction?.isEdit) {
                    const item = {
                        date: formatDateService(existingTransaction?.date),
                        description: existingTransaction?.description,
                        transaction_total: existingTransaction?.transaction_total,
                        counterparty_name: existingTransaction?.counterparty_name,
                        is_paid: existingTransaction?.is_paid,
                        payment_method_account: existingTransaction?.payment_method_account,
                        ...(existingTransaction?.book_by_each_item
                            ? {
                                  transaction_items: existingTransaction?.transaction_items?.map(
                                      (item) => ({
                                          description: item?.description,
                                          price: item?.price,
                                          quantity: item?.quantity,
                                          account_id: item?.account?.id,
                                          item_total: item?.isThisTax
                                              ? 0
                                              : parseFloat(item?.item_total || 0) +
                                                (item?.taxAmount || 0),
                                      }),
                                  ),
                              }
                            : {}),
                    };
                    editPostTransaction(existingTransaction?.id, item);
                } else {
                    await postTransaction(existingTransaction?.id);
                }
                setTransactions2((prevTransactions) =>
                    prevTransactions.filter((_, i) => i !== index),
                );
            } catch (errror) {}
        },
        [editPostTransaction, postTransaction, sendNotification, transactions2],
    );

    const handleRemoveTransaction = useCallback(
        async (index) => {
            const existingTransaction = transactions2?.[index];
            if (!existingTransaction) return;
            try {
                await deleteTransaction(existingTransaction?.id);

                setTransactions2((prevTransactions) =>
                    prevTransactions.filter((_, i) => i !== index),
                );
            } catch (errror) {}
        },
        [deleteTransaction, transactions2],
    );

    const handleRemoveTransactionItem = useCallback(
        async (index, innerIndex, isEdit) => {
            const existingTransaction = transactions2?.[index];
            if (!existingTransaction) return;
            setTransactions2((previousItems) =>
                previousItems?.map((item, outIndex) => ({
                    ...item,
                    isEdit: true,
                    transaction_items: item?.transaction_items?.filter(
                        (itemNew, indexNew) => innerIndex !== indexNew,
                    ),
                })),
            );
            if (isEdit) {
                hanldeCloseEditSecondTable();
            }
        },
        [hanldeCloseEditSecondTable, transactions2],
    );

    const handleAddTransactionItem = useCallback(
        (index) => {
            const existingTransaction = transactions2?.[index];
            if (!existingTransaction) return;

            // Define the new item with the same structure as transaction_items but with empty/default values
            const newItem = {
                description: '',
                // price: '',
                // quantity: '',
                item_total: '',
                account: null, // Account key is set to null
                isTaxed: false,
            };

            setTransactions2((previousItems) =>
                previousItems?.map((item, outIndex) =>
                    outIndex === index
                        ? {
                              ...item,
                              isEdit: true,
                              transaction_items: [
                                  ...item.transaction_items, // Add new item to transaction_items array
                                  newItem,
                              ],
                          }
                        : item,
                ),
            );
            setEditIdSecondTable(existingTransaction?.transaction_items?.length);
        },
        [transactions2],
    );

    const addTransactionItem = useCallback(
        async (index) => {
            const existingTransaction = transactions2?.[index];
            if (!existingTransaction) return;
            try {
                await deleteTransaction(existingTransaction?.id);

                setTransactions2((prevTransactions) =>
                    prevTransactions.filter((_, i) => i !== index),
                );
            } catch (errror) {}
        },
        [deleteTransaction, transactions2],
    );

    const submit = useCallback(
        async (description, transactionDate, selectedPaymentStatus, amount, vendor, index) => {
            let isError = false;
            setErrors({});
            if (!description) {
                setErrors((previousErrors) => ({
                    ...previousErrors,
                    description: 'Please enter description',
                }));
                isError = true;
            }
            if (!vendor) {
                setErrors((previousErrors) => ({
                    ...previousErrors,
                    vendor: 'Please enter vendor name',
                }));
                isError = true;
            }

            if (!transactionDate) {
                setErrors((previousErrors) => ({
                    ...previousErrors,
                    transactionDate: 'Please select transaction date',
                }));
                isError = true;
            }
            if (!selectedPaymentStatus) {
                setErrors((previousErrors) => ({
                    ...previousErrors,
                    paymentStatus: 'Please select payment status',
                }));
                isError = true;
            }
            if (!amount) {
                setErrors((previousErrors) => ({
                    ...previousErrors,
                    amount: 'Please enter amount',
                }));
                isError = true;
            }
            // if (parseFloat(amount || 0) < 0) {
            //     setErrors((previousErrors) => ({
            //         ...previousErrors,
            //         amount: 'Amount must be a positive number',
            //     }));
            //     isError = true;
            // }
            if (isError) return;

            try {
                setTransactions2((previousItems) =>
                    previousItems?.map((item, indexInner) =>
                        indexInner === index
                            ? {
                                  ...item,
                                  date: transactionDate,
                                  description: description,
                                  transaction_total: amount,
                                  counterparty_name: vendor,
                                  isEdit: true,
                                  is_paid: selectedPaymentStatus !== -1000 ? true : false,
                                  payment_method_account:
                                      selectedPaymentStatus === -1000
                                          ? null
                                          : selectedPaymentStatus,
                              }
                            : item,
                    ),
                );
                hanldeCloseEdit();
            } catch (error) {}
        },
        [hanldeCloseEdit],
    );

    const handleCollapse = useCallback(
        (index) => {
            setCollapse((previousIndex) => (previousIndex === index ? null : index));
            hanldeCloseEditSecondTable();
        },
        [hanldeCloseEditSecondTable],
    );

    const handlePaymentDropdownOpen = useCallback(async () => {
        try {
            const response = await getTransactionStatusOptions(company?.id, true);
            setPaymentStatuses([
                {
                    id: -1000,
                    label: 'Not Paid',
                },
                ...response?.map((item) => ({
                    id: item?.id,
                    label: `Paid: ${item?.account_name}`,
                })),
            ]);
        } catch (error) {
            console.error('Error fetching options:', error);
        } finally {
        }
    }, [company?.id, getTransactionStatusOptions]);

    useEffect(() => {
        if (!isEdit) return;
        handlePaymentDropdownOpen();
    }, [handlePaymentDropdownOpen, isEdit]);

    const [panelData, setPanelData] = useState({
        title: 'Select',
        children: [],
    });
    const [originalPanelData, setOriginalPanelData] = useState([]);
    const [isPanelDataLoading, setIsPanelDataLoading] = useState(false);
    const [completePanelData, setCompletePanelData] = useState({
        title: 'Select',
        children: [],
    });
    const [openDropDownIndex, setOpenDropDownIndex] = useState(null);

    const fetchCompanyAccountsCategoryData = useCallback(async (companyId) => {
        setIsPanelDataLoading(true);
        setPanelData({
            title: 'Select',
            children: [],
        });
        setOriginalPanelData([]);
        try {
            const { token } = store.getState().auth;
            const response = await fetch(COA_FETCH_WITH_CATEGORY(companyId), {
                method: 'GET',
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = await response.json();
            setPanelData(formatAccountCategoriesData(data, true));
            setOriginalPanelData(data?.active_accounts || []);
            setCompletePanelData(formatAccountCategoriesData(data));
        } catch (error) {
            console.error('Error fetching dropdown options:', error);
            setPanelData({
                title: 'Select',
                children: [],
            });
        } finally {
            setIsPanelDataLoading(false);
        }
    }, []);

    const importAccountCetegories = useCallback(
        (companyId) => {
            fetchCompanyAccountsCategoryData(companyId);
        },
        [fetchCompanyAccountsCategoryData],
    );

    const handleDropDownToggle = useCallback(
        (index, depth, companyId) => {
            if (index !== openDropDownIndex && depth === 1 && companyId) {
                importAccountCetegories(companyId);
            } else if (index !== openDropDownIndex && depth === 1) {
            }
            setOpenDropDownIndex((prev) => (prev === index ? null : index));
        },
        [importAccountCetegories, openDropDownIndex],
    );
    const handleDropDownClose = useCallback(() => {
        setOpenDropDownIndex(null);
    }, []);

    const onPanelListItemClick = useCallback(
        (accountNumber, plaidId, transactionId) => {
            setOpenDropDownIndex(null);
            const inComingAccount = originalPanelData?.find((item) => item?.id === accountNumber);
            setSecondTableCategory(inComingAccount);
        },
        [originalPanelData],
    );

    const changeDropDownLength = useCallback(() => {
        if (panelData?.children?.length === 2) {
            setPanelData({ ...completePanelData });
        } else {
            setPanelData({
                ...completePanelData,
                children: completePanelData?.children?.slice(0, 2),
            });
        }
    }, [completePanelData, panelData?.children?.length]);

    const tabs = [
        {
            label: 'Circler Bookkeeper',
            content: (
                <Chat
                    files={files}
                    handleDrop={handleFileDrop}
                    handleRemoveFile={handleRemoveFile}
                    handleRemoveAllFiles={handleRemoveAllFiles}
                    handleUpload={handleFileUpload}
                    isUploadLoading={isFileUploadLoading}
                    themeMode={themeMode}
                />
            ),
        },
        {
            label: isEdit ? 'Transactions for Review' : null,
            content: (
                <ReviewsMobile
                    isEdit={isEdit}
                    transactions={transactions2}
                    page={page}
                    count={transactions2?.length}
                    handlePageChange={handlePageChange}
                    isMobileScreen={isMobileScreen}
                    themeMode={themeMode}
                    onEditClick={null}
                    collapse={collapse}
                    handleCollapse={handleCollapse}
                    editId={editId}
                    handleEditClick={handleEditClick}
                    onSave={submit}
                    description={description}
                    handleDescriptionChange={handleDescriptionChange}
                    transactionDate={transactionDate}
                    setTransactionDate={setTransactionDate}
                    selectedPaymentStatus={selectedPaymentStatus}
                    handlePaymentStatusChange={handlePaymentStatusChange}
                    amount={amount}
                    handleAmountChange={handleAmountChange}
                    errors={errors}
                    isSubmitLoading={isSubmitLoading}
                    handleVendorChange={handleVendorChange}
                    vendor={vendor}
                    handlePaymentDropdownOpen={handlePaymentDropdownOpen}
                    paymentStatuses={paymentStatuses}
                    isPaymentLoading={isPaymentLoading}
                    panelData={panelData}
                    onListItemClick={onPanelListItemClick}
                    changeDropDownLength={changeDropDownLength}
                    isPanelDataLoading={isPanelDataLoading}
                    openDropDownIndex={openDropDownIndex}
                    handleDropDownToggle={(index, depth, companyId) =>
                        handleDropDownToggle(index, depth, companyNameId)
                    }
                    handleDropDownClose={handleDropDownClose}
                    handleEditClickSecondTable={handleEditClickSecondTable}
                    hanldeCloseEditSecondTable={hanldeCloseEditSecondTable}
                    editIdSecondTable={editIdSecondTable}
                    submitSecondaryTableRow={submitSecondaryTableRow}
                    setSecondaryRows={setTransactions2}
                    isLoading={isTransactionLoading || isFileUploadLoading}
                    importPostTransaction={importPostTransaction}
                    handleRemoveTransaction={handleRemoveTransaction}
                    addTransactionItem={addTransactionItem}
                    secondTableTransactionDetails={secondTableTransactionDetails}
                    secondTableAmount={secondTableAmount}
                    secondTableQuantity={secondTableQuantity}
                    secondTableCategory={secondTableCategory}
                    errorsSecondTable={errorsSecondTable}
                    handleSecondDescriptionChange={handleSecondDescriptionChange}
                    handleSecondAmountChange={handleSecondAmountChange}
                    handleSecondQuantityChange={handleSecondQuantityChange}
                    handleRemoveTransactionItem={handleRemoveTransactionItem}
                    handleAddTransactionItem={handleAddTransactionItem}
                    handleSourceModalOpen={handleSourceModalOpen}
                />
            ),
        },
    ];

    return (
        <>
            <Box
                sx={{
                    ...styles?.root,
                    filter: !isTransactionsTab && !is_subscription_active ? 'blur(2px)' : 'none',
                    pointerEvents: !isTransactionsTab && !is_subscription_active ? 'none' : 'auto',
                    userSelect: !isTransactionsTab && !is_subscription_active ? 'none' : 'auto',
                }}
                tabIndex={!isTransactionsTab && !is_subscription_active ? -1 : 0}
                onKeyDown={(e) => {
                    if (!isTransactionsTab && !is_subscription_active) {
                        e.preventDefault();
                    }
                }}
            >
                <Grid container spacing={2}>
                    {isEdit && (
                        <Grid item container>
                            <Stack
                                width="100%"
                                display="flex"
                                flexDirection={{ xs: 'column', md: 'row' }}
                                alignItems="center"
                                gap={2}
                            >
                                <FormControl
                                    sx={{
                                        width: { xs: '90%', md: 180 },
                                        bgcolor: (theme) =>
                                            theme.palette.mode === 'dark' ? '#394150' : '#fff',
                                        borderRadius: '8px',
                                    }}
                                    size="small"
                                >
                                    <InputLabel>Transaction Type</InputLabel>
                                    <Select
                                        value={transactionType}
                                        label="Transaction Type"
                                        onChange={handleTransactionChange}
                                        sx={{
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                border: 'none',
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                border: 'none',
                                            },
                                        }}
                                    >
                                        <MenuItem value="expense">Expense</MenuItem>
                                        <MenuItem value="revenue">Revenue</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size="small"
                                            checked={checked}
                                            onChange={handleCheckboxChange} // Handle state change
                                        />
                                    }
                                    label="Book by each item on receipt"
                                    sx={{
                                        bgcolor: (theme) =>
                                            theme.palette.mode === 'dark' ? '#394150' : '#fff',
                                        borderRadius: '8px',
                                        padding: '0 8px',
                                        width: { xs: '90%', md: 300 },
                                        margin: 0,
                                        height: 40,
                                        display: 'flex',
                                        alignItems: 'center',
                                        userSelect: 'none',
                                    }}
                                />
                                <FormControl
                                    sx={{
                                        m: 1,
                                        width: { xs: '90%', md: 180 },
                                        bgcolor: (theme) =>
                                            theme.palette.mode === 'dark' ? '#394150' : '#fff',
                                        borderRadius: '8px', // Add border radius here
                                    }}
                                    size="small"
                                >
                                    <InputLabel>Company Name</InputLabel>
                                    <Select
                                        value={companyName}
                                        label="Company Name"
                                        onChange={handleCompanyChange}
                                        sx={{
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                border: 'none',
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                border: 'none',
                                            },
                                        }}
                                    >
                                        <MenuItem value={companyNameSaga?.id}>
                                            {companyNameSaga?.name}
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Stack>
                        </Grid>
                    )}

                    {isScreenLowerThan1024 ? (
                        <>
                            {/* <Grid
                                item
                                display={'flex'}
                                xs={12}
                                alignItems={'center'}
                            >
                                {
                                    <NonAccountantMode
                                        label={'Non-accountant Mode'}
                                    />
                                }
                            </Grid> */}
                            <Grid item xs={12} display={'flex'} alignItems={'center'}>
                                {isEdit ? (
                                    <HomeTabs
                                        tabs={tabs}
                                        tabValue={tabValue}
                                        setTabValue={setTabValue}
                                        variant="fullWidth"
                                        tabCustomStyles={{
                                            fontSize: 14,
                                            fontWeight: 500,
                                            textTransform: 'capitalize',
                                        }}
                                    />
                                ) : (
                                    <ReviewsMobile
                                        isEdit={isEdit}
                                        transactions={transactions2}
                                        page={page}
                                        count={transactions2?.length}
                                        handlePageChange={handlePageChange}
                                        isMobileScreen={isMobileScreen}
                                        themeMode={themeMode}
                                        onEditClick={null}
                                        collapse={collapse}
                                        handleCollapse={handleCollapse}
                                        editId={editId}
                                        handleEditClick={handleEditClick}
                                        onSave={submit}
                                        description={description}
                                        handleDescriptionChange={handleDescriptionChange}
                                        transactionDate={transactionDate}
                                        setTransactionDate={setTransactionDate}
                                        selectedPaymentStatus={selectedPaymentStatus}
                                        handlePaymentStatusChange={handlePaymentStatusChange}
                                        amount={amount}
                                        handleAmountChange={handleAmountChange}
                                        errors={errors}
                                        isSubmitLoading={isSubmitLoading}
                                        handleVendorChange={handleVendorChange}
                                        vendor={vendor}
                                        handlePaymentDropdownOpen={handlePaymentDropdownOpen}
                                        paymentStatuses={paymentStatuses}
                                        isPaymentLoading={isPaymentLoading}
                                        panelData={panelData}
                                        onListItemClick={onPanelListItemClick}
                                        changeDropDownLength={changeDropDownLength}
                                        isPanelDataLoading={isPanelDataLoading}
                                        openDropDownIndex={openDropDownIndex}
                                        handleDropDownToggle={(index, depth, companyId) =>
                                            handleDropDownToggle(index, depth, companyNameId)
                                        }
                                        handleDropDownClose={handleDropDownClose}
                                        handleEditClickSecondTable={handleEditClickSecondTable}
                                        hanldeCloseEditSecondTable={hanldeCloseEditSecondTable}
                                        editIdSecondTable={editIdSecondTable}
                                        submitSecondaryTableRow={submitSecondaryTableRow}
                                        setSecondaryRows={setTransactions2}
                                        isLoading={isTransactionLoading || isFileUploadLoading}
                                        importPostTransaction={importPostTransaction}
                                        handleRemoveTransaction={handleRemoveTransaction}
                                        addTransactionItem={addTransactionItem}
                                        secondTableTransactionDetails={
                                            secondTableTransactionDetails
                                        }
                                        secondTableAmount={secondTableAmount}
                                        secondTableQuantity={secondTableQuantity}
                                        secondTableCategory={secondTableCategory}
                                        errorsSecondTable={errorsSecondTable}
                                        handleSecondDescriptionChange={
                                            handleSecondDescriptionChange
                                        }
                                        handleSecondAmountChange={handleSecondAmountChange}
                                        handleSecondQuantityChange={handleSecondQuantityChange}
                                        handleRemoveTransactionItem={handleRemoveTransactionItem}
                                        handleAddTransactionItem={handleAddTransactionItem}
                                        handleSourceModalOpen={handleSourceModalOpen}
                                    />
                                )}
                            </Grid>
                        </>
                    ) : (
                        <>
                            {isEdit && (
                                <Grid item xs={12} width={'100%'}>
                                    <Chat
                                        files={files}
                                        handleDrop={handleFileDrop}
                                        handleRemoveFile={handleRemoveFile}
                                        handleRemoveAllFiles={handleRemoveAllFiles}
                                        handleUpload={handleFileUpload}
                                        isUploadLoading={isFileUploadLoading}
                                        themeMode={themeMode}
                                    />
                                </Grid>
                            )}

                            <Grid item width={'100%'} xs={12}>
                                <Box sx={styles.chatPanel}>
                                    <Reviews
                                        isEdit={isEdit}
                                        reviewsRef={reviewsRef}
                                        transactions={transactions2}
                                        page={page}
                                        count={transactions2?.length}
                                        handlePageChange={handlePageChange}
                                        isMobileScreen={isMobileScreen}
                                        themeMode={themeMode}
                                        onEditClick={null}
                                        collapse={collapse}
                                        handleCollapse={handleCollapse}
                                        editId={editId}
                                        handleEditClick={handleEditClick}
                                        onSave={submit}
                                        description={description}
                                        handleDescriptionChange={handleDescriptionChange}
                                        transactionDate={transactionDate}
                                        setTransactionDate={setTransactionDate}
                                        selectedPaymentStatus={selectedPaymentStatus}
                                        handlePaymentStatusChange={handlePaymentStatusChange}
                                        amount={amount}
                                        handleAmountChange={handleAmountChange}
                                        errors={errors}
                                        isSubmitLoading={isSubmitLoading}
                                        handleVendorChange={handleVendorChange}
                                        vendor={vendor}
                                        // handlePaymentDropdownOpen={
                                        //     handlePaymentDropdownOpen
                                        // }
                                        paymentStatuses={paymentStatuses}
                                        isPaymentLoading={isPaymentLoading}
                                        panelData={panelData}
                                        onListItemClick={onPanelListItemClick}
                                        changeDropDownLength={changeDropDownLength}
                                        isPanelDataLoading={isPanelDataLoading}
                                        openDropDownIndex={openDropDownIndex}
                                        handleDropDownToggle={(index, depth, companyId) =>
                                            handleDropDownToggle(index, depth, companyNameId)
                                        }
                                        handleDropDownClose={handleDropDownClose}
                                        handleEditClickSecondTable={handleEditClickSecondTable}
                                        hanldeCloseEditSecondTable={hanldeCloseEditSecondTable}
                                        editIdSecondTable={editIdSecondTable}
                                        submitSecondaryTableRow={submitSecondaryTableRow}
                                        setSecondaryRows={setTransactions2}
                                        isLoading={isTransactionLoading || isFileUploadLoading}
                                        importPostTransaction={importPostTransaction}
                                        handleRemoveTransaction={handleRemoveTransaction}
                                        addTransactionItem={addTransactionItem}
                                        secondTableTransactionDetails={
                                            secondTableTransactionDetails
                                        }
                                        secondTableAmount={secondTableAmount}
                                        secondTableQuantity={secondTableQuantity}
                                        secondTableCategory={secondTableCategory}
                                        errorsSecondTable={errorsSecondTable}
                                        handleSecondDescriptionChange={
                                            handleSecondDescriptionChange
                                        }
                                        handleSecondAmountChange={handleSecondAmountChange}
                                        handleSecondQuantityChange={handleSecondQuantityChange}
                                        handleRemoveTransactionItem={handleRemoveTransactionItem}
                                        handleAddTransactionItem={handleAddTransactionItem}
                                        handleSourceModalOpen={handleSourceModalOpen}
                                    />
                                </Box>
                            </Grid>
                        </>
                    )}
                    {/* {isScreenLowerThan1024 ? (
                        isDemoWatchedByUser ? (
                            <>
                                <Grid
                                    item
                                    display={'flex'}
                                    xs={12}
                                    alignItems={'center'}
                                >
                                    {
                                        <NonAccountantMode
                                            label={'Non-accountant Mode'}
                                        />
                                    }
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    display={'flex'}
                                    alignItems={'center'}
                                >
                                    <FullWidthTabs
                                        tabs={tabs}
                                        variant="fullWidth"
                                        tabCustomStyles={{
                                            fontSize: 14,
                                            fontWeight: 500,
                                            textTransform: 'capitalize',
                                        }}
                                    />
                                </Grid>
                            </>
                        ) : (
                            <Grid
                                item
                                xs={12}
                                md={12}
                                lg={12}
                                width={'100%'}
                                height={'calc(100vh - 300px)'}
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                            >
                                <Typography
                                    text={
                                        'Please create a new session by clicking the button on top right'
                                    }
                                    customStyles={{
                                        fontSize: 16,
                                        fontWeight: 600,
                                    }}
                                />
                            </Grid>
                        )
                    ) : isDemoWatchedByUser ? (
                        <>
                            <Grid item xs={12} width={'100%'}>
                                <Chat
                                    files={files}
                                    handleDrop={handleFileDrop}
                                    handleRemoveFile={handleRemoveFile}
                                    handleRemoveAllFiles={handleRemoveAllFiles}
                                    handleUpload={handleFileUpload}
                                    isUploadLoading={isFileUploadLoading}
                                    themeMode={themeMode}
                                />
                            </Grid>

                            <Grid item width={'100%'} xs={12}>
                                <Box sx={styles.chatPanel}>
                                    <Reviews
                                        transactions={transactions2}
                                        page={page}
                                        count={transactions2?.length}
                                        handlePageChange={handlePageChange}
                                        isMobileScreen={isMobileScreen}
                                        themeMode={themeMode}
                                        onEditClick={null}
                                        collapse={collapse}
                                        handleCollapse={handleCollapse}
                                        editId={editId}
                                        handleEditClick={handleEditClick}
                                        onSave={submit}
                                        description={description}
                                        handleDescriptionChange={
                                            handleDescriptionChange
                                        }
                                        transactionDate={transactionDate}
                                        setTransactionDate={setTransactionDate}
                                        selectedPaymentStatus={
                                            selectedPaymentStatus
                                        }
                                        handlePaymentStatusChange={
                                            handlePaymentStatusChange
                                        }
                                        amount={amount}
                                        handleAmountChange={handleAmountChange}
                                        errors={errors}
                                        isSubmitLoading={isSubmitLoading}
                                        handleVendorChange={handleVendorChange}
                                        vendor={vendor}
                                        handlePaymentDropdownOpen={
                                            handlePaymentDropdownOpen
                                        }
                                        paymentStatuses={paymentStatuses}
                                        isPaymentLoading={isPaymentLoading}
                                        panelData={panelData}
                                        onListItemClick={onPanelListItemClick}
                                        changeDropDownLength={
                                            changeDropDownLength
                                        }
                                        isPanelDataLoading={isPanelDataLoading}
                                        openDropDownIndex={openDropDownIndex}
                                        handleDropDownToggle={(
                                            index,
                                            depth,
                                            companyId,
                                        ) =>
                                            handleDropDownToggle(
                                                index,
                                                depth,
                                                companyNameId,
                                            )
                                        }
                                        handleDropDownClose={
                                            handleDropDownClose
                                        }
                                    />
                                </Box>
                            </Grid>
                        </>
                    ) : (
                        <Grid
                            item
                            xs={12}
                            md={12}
                            lg={12}
                            width={'100%'}
                            height={'calc(100vh - 300px)'}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                        >
                            <Typography
                                text={
                                    'Please create a new session by clicking the button on top right'
                                }
                                customStyles={{
                                    fontSize: 16,
                                    fontWeight: 600,
                                }}
                            />
                        </Grid>
                    )} */}
                </Grid>

                {/* <Modal
                    title="Demo"
                    open={!isDemoWatchedByUser}
                    handleClose={handleCloseModal}
                    fullWidth={true}
                    maxWidth={'xl'}
                >
                    <Box
                        p={1}
                        sx={{
                            position: 'relative',
                        }}
                    >
                        <ReactPlayer
                            className={styles.reactPlayer}
                            url={demo_video}
                            width="100%"
                            height="100%"
                            controls={true}
                            playing={!isDemoWatchedByUser}
                        />
                    </Box>
                </Modal> */}
            </Box>

            {!isTransactionsTab && !is_subscription_active && (
                <Stack
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 2,
                        pointerEvents: 'auto',
                    }}
                >
                    <UpgradeToPro onlyIcon />
                </Stack>
            )}
            <SourceModal
                isModalOpen={sourceModal !== null}
                handleCloseModal={handleSourceModalClose}
                files={modalItems}
                themeMode={themeMode}
                companyId={companyNameId}
            />
        </>
    );
};
