import React, { useState } from 'react';

import { Typography, Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { selectedTransactionsInfoStyles } from '../style/style';

const SelectedTransactionsInfo = ({
    data,
    selectedRows,
    handleImportMultiple,
    handleExcludeMultiple,
}) => {
    const classes = selectedTransactionsInfoStyles();

    const [importLoading, setImportLoading] = useState(false);
    const [excludeLoading, setExcludeLoading] = useState(false);

    const getSelectedAmountSum = (data, selectedRows) => {
        return data
            ?.filter((transaction) => selectedRows.includes(transaction.id))
            .reduce((sum, transaction) => sum + Number(transaction.amount), 0)
            .toFixed(2);
    };

    const handleImportClick = async () => {
        setImportLoading(true);
        await handleImportMultiple();
    };

    const handleExcludeClick = async () => {
        setExcludeLoading(true);
        await handleExcludeMultiple();
    };

    return (
        <Box sx={classes.rootWrapper}>
            <Typography variant="body2" sx={classes.category}>
                {selectedRows?.length} transaction
                {selectedRows?.length > 1 && 's'}{' '}
                {selectedRows?.length == 1 ? 'is' : 'are'} selected:
                {data[0]?.currency_code}{' '}
                {getSelectedAmountSum(data, selectedRows)}
            </Typography>

            <LoadingButton
                size="small"
                variant="contained"
                loading={importLoading}
                sx={classes.button}
                onClick={handleImportClick}
            >
                Import
            </LoadingButton>
            <LoadingButton
                size="small"
                variant="contained"
                loading={excludeLoading}
                sx={classes.button}
                onClick={handleExcludeClick}
            >
                Exclude
            </LoadingButton>
        </Box>
    );
};

export default SelectedTransactionsInfo;
