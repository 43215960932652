import DoneIcon from '@mui/icons-material/Done';
import UploadIcon from '@mui/icons-material/Upload';
import { Box, Chip, Typography, IconButton, CircularProgress } from '@mui/material';

export const purchaseActivityTableHeaders = (loadingRowId, handleImportInCirclerClick) => {
    return [
        {
            id: 'purchased_at',
            label: 'Date',
            sort: false,
            verticalAlign: 'top',
        },
        {
            id: 'purchase_id',
            label: 'Purchase',
            sort: false,
            verticalAlign: 'top',
        },
        {
            id: 'description',
            label: 'Description',
            sort: false,
            verticalAlign: 'top',
            render: (row) =>
                `${row.expires_at}, Section: ${row.class_1}, Row: ${row.class_2}, Seats: ${row.seats}`,
        },
        {
            id: 'reference_id',
            label: 'Reference',
            sort: false,
            verticalAlign: 'top',
        },
        {
            id: 'amount',
            label: 'Total',
            sort: false,
            verticalAlign: 'top',
            render: (row) => `$${row.purchase_price}`,
        },
        {
            id: 'plaidTransactionStatus',
            label: 'Status',
            sort: false,
            verticalAlign: 'top',
            render: (row) => (
                <Chip
                    label={row.is_reviewed ? 'Matched' : 'Not matched'}
                    color={row.is_reviewed ? 'success' : 'warning'}
                />
            ),
        },
        // {
        //     id: 'circlerInventoryStatus',
        //     label: '',
        //     sort: false,
        //     verticalAlign: 'top',
        //     render: (row) =>
        //         loadingRowId == row?.id ? (
        //             <CircularProgress size={24} color="inherit" />
        //         ) : (
        //             <Box display="flex" alignItems="center">
        //                 {row?.imported ? (
        //                     <DoneIcon
        //                         sx={{ padding: 0, marginRight: '1px' }}
        //                         fontSize="small"
        //                     />
        //                 ) : (
        //                     <IconButton
        //                         onClick={() =>
        //                             handleImportInCirclerClick(row?.id)
        //                         }
        //                     >
        //                         <UploadIcon
        //                             sx={{ padding: 0 }}
        //                             fontSize="small"
        //                         />
        //                     </IconButton>
        //                 )}
        //                 <Typography fontSize="12px">
        //                     {row?.imported
        //                         ? 'Imported in Circler'
        //                         : 'Import in Circler'}
        //                 </Typography>
        //             </Box>
        //         ),
        // },
    ];
};
