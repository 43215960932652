import React from 'react';

import { Box, Grid, Paper, Typography } from '@mui/material';

import { transactionCardStyles } from 'components/Inventory/style/style';

const TransactionCard = ({ transaction }) => {
    const classes = transactionCardStyles();

    return (
        <Paper sx={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={3}>
                    <Box sx={classes.textBox}>
                        <Typography fontSize="12px" fontWeight="500">
                            DATE
                        </Typography>
                        <Typography fontSize="14px" fontWeight="600">
                            {transaction.date}
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={6} sm={3}>
                    <Box sx={classes.textBox}>
                        <Typography fontSize="12px" fontWeight="500">
                            DESCRIPTION
                        </Typography>
                        <Typography fontSize="14px" fontWeight="600">
                            {transaction.description}
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={6} sm={3}>
                    <Box sx={classes.textBox}>
                        <Typography fontSize="12px" fontWeight="500">
                            ACCOUNT
                        </Typography>
                        <Typography fontSize="14px" fontWeight="600">
                            {transaction.bank_account__name}
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={6} sm={3}>
                    <Box sx={classes.textBox}>
                        <Typography fontSize="12px" fontWeight="500">
                            AMOUNT
                        </Typography>
                        <Typography fontSize="14px" fontWeight="600">
                            {transaction.currency_code} {transaction.amount.toFixed(2)}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default TransactionCard;
