import { useEffect, useRef, useState } from 'react';

export const Typewriter = ({ text, onComplete, delay = 30 }) => {
    const [displayText, setDisplayText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);
    const intervalRef = useRef(null);

    useEffect(() => {
        if (currentIndex < text.length) {
            intervalRef.current = setInterval(() => {
                setDisplayText((prev) => prev + text[currentIndex]);
                setCurrentIndex((prevIndex) => prevIndex + 1);
            }, delay);
        } else if (onComplete) {
            onComplete();
        }

        return () => {
            if (intervalRef.current) clearInterval(intervalRef.current);
        };
    }, [currentIndex, text, delay, onComplete]);

    return <span>{displayText}</span>;
};
