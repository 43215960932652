import { useNavigate } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useMediaQuery } from '@mui/material';

import { CircleLoading } from 'shared';
import { useThemeToggle } from '../hooks/useThemeToggle';
import { useCompanyService } from '../store/services/company.service';

const Subscriptions = () => {
    const navigate = useNavigate();
    const { themeMode } = useThemeToggle();
    const isDarkMode = themeMode === 'dark' ? true : false;
    const publishableKey = process.env.REACT_APP_STRIPE_INTENT;
    const isScreenLowerThan1024 = useMediaQuery('(max-width: 1025px)');
    const pricingTableId = process.env.REACT_APP_STRIPE_PRICING_TABLE_ID;
    const { createStripeSession, getStripeTableSession } = useCompanyService();

    const [stripeSession, setStripeSession] = useState(null);
    const companyData = useSelector((state) => state.company.data);

    useEffect(() => {
        if (companyData && companyData?.stripeStatus === 'active') {
            navigate('/dashboard');
        }
    }, [companyData]);

    const handleCreateStripeSession = useCallback(async () => {
        const response = await createStripeSession();

        if (response.url) {
            window.location.href = response.url;
        }
    }, [createStripeSession]);

    const handleStripeSession = async () => {
        try {
            const response = await getStripeTableSession();
            setStripeSession(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/pricing-table.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        handleStripeSession();
    }, []);

    return stripeSession ? (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                }}
            >
                {/* <button
                onClick={navigateToHome}
                style={{
                    color: 'grey',
                    backgroundColor: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    marginBottom: '10px',
                    marginLeft: '30px',
                }}
            >
                Home Screen
            </button> */}
                <button
                    onClick={handleCreateStripeSession}
                    style={{
                        color: 'grey',
                        backgroundColor: 'transparent',
                        border: 'none',
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        fontSize: '16px',
                        marginBottom: '30px',
                        marginLeft: '30px',
                    }}
                >
                    Manage Billing
                </button>
            </div>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center',
                    margin: '5px 25px',
                }}
            >
                <p
                    style={{
                        fontWeight: '700px',
                        fontSize: isScreenLowerThan1024 ? '20px' : '24px',
                        color: isDarkMode ? '#F9FAFB' : '#121826',
                    }}
                >
                    {/* {companyData?.stripeStatus === 'trialing'
                        ? 'Upgrade now to enjoy uninterrupted access! Pay only after your trial ends.'
                        : 'Your trial has ended. Upgrade today to continue enjoying key benefits'} */}
                    Upgrade now to ensure seamless, uninterrupted access to all
                    features!
                </p>
            </div>

            <stripe-pricing-table
                pricing-table-id={pricingTableId}
                publishable-key={publishableKey}
                customer-session-client-secret={stripeSession?.client_secret}
            ></stripe-pricing-table>
        </div>
    ) : (
        <CircleLoading />
    );
};

export default Subscriptions;
