export const makeStyles = ({ isScreenLowerThan1024 }) => ({
    root: {
        p: isScreenLowerThan1024 ? '20px 20px' : '40px 40px',
        display: 'flex',
        flexDirection: 'column',
        gap: isScreenLowerThan1024 ? '16px' : '32px',
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '300px',
    },
    cancelButton: {
        fontWeight: '500',
        fontSize: '14px',
        color: '#6C727F',
        border: (theme) =>
            theme.palette.mode === 'dark'
                ? '1px solid #394150'
                : '1px solid #E5E6EB',
        textTransform: 'none',
        marginRight: '20px',
    },
    datePicker: {
        '& .MuiInputBase-root': {
            backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#394150' : '#F4F4F6',
            borderRadius: '8px',
            border: 'none',
            height: '48px',
            minHeight: '40px',
            fontSize: 13,
            color: (theme) =>
                theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F',
            '&:hover .MuiOutlinedInput-notchedOutline': {
                border: (theme) =>
                    `1px solid ${
                        theme.palette.mode === 'dark' ? '#E5E6EB' : '#E5E6EB'
                    }`,
            },
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
            },
        },
        '& .MuiSvgIcon-root': {
            color: (theme) =>
                theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F',
        },
        '& .MuiInputBase-input::placeholder': {
            color: (theme) =>
                theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F',
            opacity: 1,
            fontSize: 13,
        },
    },
    errorMessage: {
        fontWeight: 'bold',
    },
    infoIcon: {
        marginRight: '3px',
        paddingTop: '1px',
    },
    alert: { width: '100%' },
});

export const errorMessage = {
    fontWeight: 600,
    marginLeft: 0,
};

export const customLabelStyle = {
    fontSize: 16,
    fontWeight: 500,
    color: (theme) => (theme.palette.mode === 'dark' ? '#D3D5DA' : '#4D5461'),
};

export const customSelectLabelStyle = {
    fontSize: 16,
    fontWeight: 500,
    color: (theme) => (theme.palette.mode === 'dark' ? '#D3D5DA' : '#4D5461'),
};

export const customSelectStyle = {
    color: (theme) => theme.textColor.primary,
};

export const descriptionText = (theme) => {
    return { color: theme === 'dark' ? '#9EA3AE' : '#4D5461' };
};

export const journalButtonText = {
    fontWeight: 500,
    color: '#FFF',
};

export const journalButton = (isMobileScreen) => {
    return {
        padding: '12px 20px',
        height: '48px',
        '& .MuiButton-startIcon': {
            mr: isMobileScreen ? '0px' : '8px',
        },
    };
};
