import dayjs from 'dayjs';
import { Formik, Form, FieldArray } from 'formik';
import React, { Fragment, useEffect, useState } from 'react';

import InfoIcon from '@mui/icons-material/Info';
import LoadingButton from '@mui/lab/LoadingButton';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Box, Grid, Divider, Button, FormHelperText, CircularProgress } from '@mui/material';

import {
    makeStyles,
    errorMessage,
    descriptionText,
    customLabelStyle,
    customSelectStyle,
    customSelectLabelStyle,
} from '../style/style';
import TotalDisplay from './TotalDisplay';
import JournalEntryRow from './JournalEntryRow';
import { useThemeToggle } from 'hooks/useThemeToggle';
import { useCustomMediaQuery } from 'hooks/useMediaQuery';
import { handleErrorAlert } from 'utils/handleErrorAlert';
import { useCoreService } from 'store/services/core.service';
import { handleSuccessAlert } from 'utils/handleSuccessAlert';
import { useBookkeeperService } from 'store/services/bookkeeper.service';
import { journalValidationSchema } from '../utils/journalValidationSchema';
import { journalFormInitialValues } from '../utils/journalFormIntialValues';
import { Modal, TextField, Typography, Autocomplete, SnackBarAlert } from 'shared';

const JournalForm = ({
    type,
    open,
    companyId,
    selectedRow,
    handleCloseModal,
    handleJournalCreationSuccess,
}) => {
    const {
        isLoading,
        addManualJournalEntry,
        updateManualJournalEntry,
        getManualJournalEntryById,
    } = useBookkeeperService();
    const { themeMode } = useThemeToggle();
    const isScreenLowerThan1024 = useCustomMediaQuery('(max-width: 1025px)');
    const { getCurrencyCodes, getCompanyChartOfAccountsList } = useCoreService();

    const classes = makeStyles({ isScreenLowerThan1024 });

    const [loader, setLoader] = useState(true);
    const [alertData, setAlertData] = useState({
        open: false,
        message: '',
        severity: '',
    });
    const [accounts, setAccounts] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [journalEntry, setJournalEntry] = useState(null);

    const handleSubmit = async (values) => {
        const totalDebit = values.journalEntries.reduce(
            (sum, entry) => sum + (Number(entry.debit) || 0),
            0,
        );
        const totalCredit = values.journalEntries.reduce(
            (sum, entry) => sum + (Number(entry.credit) || 0),
            0,
        );

        if (totalDebit !== totalCredit) return;

        const payload = {
            company_id: companyId,
            name: values.name,
            description: values.description,
            currency: values.currency,
            totalAmount: totalCredit.toFixed(2),
            date: values.date ? dayjs(values.date).format('YYYY-MM-DD') : null,
            invoiceNumber: values.invoiceNumber ? values.invoiceNumber : null,
            journalEntries: values.journalEntries.map((entry) => ({
                account: {
                    id: entry?.account?.id,
                    label: entry?.account?.label,
                },
                debit: Number(entry.debit) || 0.0,
                credit: Number(entry.credit) || 0.0,
            })),
        };

        try {
            let response;
            if (type === 'Add') {
                response = await addManualJournalEntry(payload);
            } else if (type === 'Edit') {
                response = await updateManualJournalEntry(
                    companyId,
                    journalEntry.manual_journal_entry.id,
                    payload,
                );
            }

            handleSuccessAlert(setAlertData, response?.message);
            handleJournalCreationSuccess();
        } catch (error) {
            handleErrorAlert(setAlertData);
        }
    };

    useEffect(() => {
        const promises = [getCurrencyCodes(), getCompanyChartOfAccountsList(companyId)];
        if (selectedRow) {
            promises.push(getManualJournalEntryById(companyId, selectedRow.id));
        }
        Promise.all(promises)
            .then(([currencies, accounts, journalEntry]) => {
                setCurrencies(currencies);
                setAccounts(accounts);
                if (journalEntry) {
                    setJournalEntry(journalEntry);
                }
            })
            .catch((error) => {
                handleErrorAlert(setAlertData);
            })
            .finally(() => setLoader(false));
    }, [companyId]);

    if (loader) {
        return (
            <Modal fullWidth open={open} title={`${type} Journal Entry`}>
                <Box sx={classes.loader}>
                    <CircularProgress />
                </Box>
            </Modal>
        );
    }

    return (
        <Fragment>
            <Modal
                fullWidth
                open={open}
                title={`${type} Journal Entry`}
                actionBtnTitle={<Typography text={type === 'Add' ? 'Create' : 'Update'} />}
                handleClose={handleCloseModal}
            >
                <Formik
                    initialValues={journalFormInitialValues(journalEntry)}
                    validationSchema={journalValidationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, setFieldValue, errors, touched }) => {
                        return (
                            <Form>
                                <Box width="100%" gap={2} mt={1} border="none">
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item xs={12}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    value={values.date}
                                                    onChange={(date) => setFieldValue('date', date)}
                                                    sx={classes.datePicker}
                                                    slotProps={{
                                                        textField: {
                                                            error: touched.date && !!errors.date,
                                                            helperText: touched.date && errors.date,
                                                            FormHelperTextProps: {
                                                                sx: errorMessage,
                                                            },
                                                        },
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                name="name"
                                                placeholder="Name"
                                                value={values.name}
                                                onChange={(e) =>
                                                    setFieldValue('name', e.target.value)
                                                }
                                                customLabelStyles={customLabelStyle}
                                                error={touched.name && !!errors.name}
                                                helperText={touched.name && errors.name}
                                            />
                                        </Grid>

                                        <Grid item xs={6} md={3}>
                                            <TextField
                                                fullWidth
                                                name="invoiceNumber"
                                                placeholder="Number"
                                                value={values.invoiceNumber}
                                                onChange={(e) =>
                                                    setFieldValue('invoiceNumber', e.target.value)
                                                }
                                                customLabelStyles={customLabelStyle}
                                                error={
                                                    touched.invoiceNumber && !!errors.invoiceNumber
                                                }
                                                helperText={
                                                    touched.invoiceNumber && errors.invoiceNumber
                                                }
                                            />
                                        </Grid>

                                        <Grid item xs={6} md={3}>
                                            <Autocomplete
                                                options={currencies}
                                                labelKey="label"
                                                valueKey="value"
                                                placeholder="Currency"
                                                name="currency"
                                                value={
                                                    currencies.find(
                                                        (c) => c.value === values.currency?.value,
                                                    ) || null
                                                }
                                                onChange={(e, value) =>
                                                    setFieldValue(
                                                        'currency',
                                                        value
                                                            ? value
                                                            : {
                                                                  value: '',
                                                                  label: '',
                                                              },
                                                    )
                                                }
                                                isSearchField={false}
                                                customLabelStyles={customSelectLabelStyle}
                                                customStyle={customSelectStyle}
                                                disableClearable
                                                error={touched.currency && !!errors.currency}
                                                helperText={touched.currency && errors.currency}
                                                getOptionLabel={(option) =>
                                                    typeof option === 'string'
                                                        ? option
                                                        : option.label
                                                }
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                name="description"
                                                placeholder="Description"
                                                value={values.description}
                                                onChange={(e) =>
                                                    setFieldValue('description', e.target.value)
                                                }
                                                customLabelStyles={customLabelStyle}
                                                multiline
                                                rows={3}
                                                InputProps={{
                                                    style: descriptionText(themeMode),
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Divider
                                                sx={{
                                                    background: (theme) =>
                                                        theme.palette.mode === 'dark'
                                                            ? '#394150'
                                                            : '#E5E6EB',
                                                }}
                                            />
                                        </Grid>

                                        <FieldArray name="journalEntries">
                                            {({ push, remove }) => (
                                                <>
                                                    {values?.journalEntries.map((entry, index) => (
                                                        <JournalEntryRow
                                                            key={index}
                                                            index={index}
                                                            entry={entry}
                                                            setFieldValue={setFieldValue}
                                                            touched={touched}
                                                            errors={errors}
                                                            accounts={accounts}
                                                            onRemove={remove}
                                                            canRemove={
                                                                values.journalEntries?.length > 2
                                                            }
                                                        />
                                                    ))}

                                                    <Grid item xs={12} md={5} />
                                                    <Grid item xs={5} md={3}>
                                                        <TotalDisplay
                                                            currency={values.currency?.value || ''} // Pass string
                                                            entries={values.journalEntries}
                                                            type="debit"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={5} md={3}>
                                                        <TotalDisplay
                                                            currency={values.currency?.value || ''} // Pass string
                                                            entries={values.journalEntries}
                                                            type="credit"
                                                        />
                                                    </Grid>

                                                    {values.journalEntries
                                                        .reduce(
                                                            (sum, entry) =>
                                                                sum + Number(entry.credit || 0),
                                                            0,
                                                        )
                                                        .toFixed(2) !==
                                                        values.journalEntries
                                                            .reduce(
                                                                (sum, entry) =>
                                                                    sum + Number(entry.debit || 0),
                                                                0,
                                                            )
                                                            .toFixed(2) && (
                                                        <Grid item xs={12}>
                                                            <Box sx={classes.buttonWrapper}>
                                                                <FormHelperText
                                                                    error
                                                                    sx={classes.errorMessage}
                                                                >
                                                                    <InfoIcon
                                                                        fontSize="14px"
                                                                        sx={classes.infoIcon}
                                                                    />
                                                                    Check that your debit and credit
                                                                    totals are equal
                                                                </FormHelperText>
                                                            </Box>
                                                        </Grid>
                                                    )}

                                                    <Grid item xs={12}>
                                                        <Button
                                                            fullWidth
                                                            variant="outlined"
                                                            onClick={() =>
                                                                push({
                                                                    account: null,
                                                                    debit: '',
                                                                    credit: '',
                                                                })
                                                            }
                                                            sx={{ mt: 2 }}
                                                        >
                                                            Add a line
                                                        </Button>
                                                    </Grid>
                                                </>
                                            )}
                                        </FieldArray>
                                    </Grid>
                                </Box>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        marginTop: 3,
                                    }}
                                >
                                    <Button sx={classes.cancelButton} onClick={handleCloseModal}>
                                        Cancel
                                    </Button>
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        loading={isLoading}
                                    >
                                        {type === 'Add' ? 'Create' : 'Update'}
                                    </LoadingButton>
                                </Box>
                            </Form>
                        );
                    }}
                </Formik>
            </Modal>
            <SnackBarAlert alertData={alertData} setAlertData={setAlertData} />
        </Fragment>
    );
};

export default JournalForm;
