import React from 'react';

import { Typography } from 'shared';

const TotalDisplay = ({ currency, entries, type }) => {
    const total = entries
        .reduce((sum, entry) => sum + Number(entry[type] || 0), 0)
        .toFixed(2);

    return (
        <Typography
            sx={{ fontSize: '12px' }}
            text={`Total ${
                type === 'debit' ? 'Debit' : 'Credit'
            }: ${currency} ${total}`}
        />
    );
};

export default TotalDisplay;
