import dayjs from 'dayjs';
import { Formik, Form } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Box, FormHelperText, Grid, Paper } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { linearProgressClasses } from '@mui/material/LinearProgress';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { Button, TextField, Typography, DataTable, TablePagination } from '../shared';
import {
    CREATE_COMPANY_ERROR,
    CREATE_COMPANY_PENDING,
    CREATE_COMPANY_SUCCESS,
} from '../store/slices/company.slice';
import { useAuth } from '../hooks/useAuth';
import { Progress } from '../shared/Progress';
import { PhoneNumber } from '../shared/PhoneNumber';
import { useThemeToggle } from '../hooks/useThemeToggle';
import { SET_CURRENT_USER_SUCCESS } from '../store/actions';
import { useCustomMediaQuery } from '../hooks/useMediaQuery';
import { LoaderOnboarding } from '../shared/LoaderOnboarding';
import { useAuthService } from '../store/services/auth.service';
import { CompanyInformation } from './Settings/CompanyInformation';
import { useCompanyService } from '../store/services/company.service';
import { qbAccountsTableHeaders } from 'utils/qbAccountsTableHeaders';
import { ReactComponent as CirclerLogo } from '../assets/icons/Logo.svg';
import { useQuickBooksService } from '../store/services/quickbooks.service';
import { qbTrialBalanceTableHeaders } from 'utils/qbTrialBalanceTableHeaders';
import { ReactComponent as DarkCirclerLogo } from '../assets/icons/DarkLogo.svg';
import {
    StepOneOnboardingInitialValues,
    StepOneOnboardingSchema,
} from './Onboarding/utils/OnboardingFormSchema';

import {
    CompanyInformationSchema,
    StepOneCompnayInformationInitialValues,
} from './Settings/utils/CompanyInfoFormSchema';

const makeStyles = ({ step, isScreenLowerThan1024 }) => ({
    onboardingContainer: {
        p: '28px 0',
        justifyContent: 'center',
        backgroundColor: (theme) => theme.backgroundColor.primary,
    },
    progressStyle: {
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: (theme) => theme.backgroundColor.uploadFileContainer,
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: (theme) => theme.progress.main,
        },
    },
    onboardingContent: {
        display: 'flex',
        m: 'auto',
        p: isScreenLowerThan1024 ? '40px 20px' : '80px 20px',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        maxWidth: '800px',
        height: isScreenLowerThan1024 ? '100%' : step === 1 ? 'auto' : 'auto',
    },
    step: {
        p: '32px',
        boxShadow: '10px 15px 50px 0px rgba(113, 128, 150, 0.24)',
        borderRadius: '12px',
        border: (theme) => `1px solid ${theme.borderColor.inputField}`,
        backgroundColor: (theme) => theme.backgroundColor.primary,
        '& input': {
            backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#394150' : 'inherit'),
            borderRadius: 1,
        },
    },
    tableBox: {
        p: isScreenLowerThan1024 ? '10px 20px' : '10px 40px',
    },
    datePicker: {
        '& .MuiInputBase-root': {
            backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#394150' : '#F4F4F6'),
            borderRadius: '8px',
            border: 'none',
            height: '48px',
            minHeight: '40px',
            fontSize: 13,
            color: (theme) => (theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F'),
            '&:hover .MuiOutlinedInput-notchedOutline': {
                border: (theme) =>
                    `1px solid ${theme.palette.mode === 'dark' ? '#E5E6EB' : '#E5E6EB'}`,
            },
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
            },
        },
        '& .MuiSvgIcon-root': {
            color: (theme) => (theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F'),
        },
        '& .MuiInputBase-input::placeholder': {
            color: (theme) => (theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F'),
            opacity: 1,
            fontSize: 13,
        },
    },
});

const buttonTextStyle = {
    color: '#FFFFFF',
    fontSize: '18px',
    fontWeight: 500,
};

function Onboarding() {
    const today = dayjs();
    const { getQbLoginUrl, addQbAccounts, addTrialBalance, importQbAccounts, getQbUserAndCompany } =
        useQuickBooksService();
    const { user } = useAuth();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { themeMode } = useThemeToggle();
    const { updateUser } = useAuthService();
    const [searchParams] = useSearchParams();
    const { createCompany, isLoading } = useCompanyService();
    const isMobileScreen = useCustomMediaQuery('(max-width:600px)');
    const { data: companyData } = useSelector((state) => state.company);
    const is_onboarded = useSelector((state) => state.me.data.is_onboarded);
    const isScreenLowerThan1024 = useCustomMediaQuery('(max-width: 1025px)');

    const [step, setStep] = useState(0);
    const [page, setPage] = useState(1);
    const [progress, setProgress] = useState(0);
    const [endDate, setEndDate] = useState(dayjs());
    const [qbAccounts, setQbAccounts] = useState(null);
    const [showLoader, setShowLoader] = useState(false);
    const [companyInfo, setCompanyInfo] = useState(null);
    const [onBoardingUser, setOnBoardingUser] = useState({
        firstName: user.data.first_name,
        lastName: user.data.last_name,
        phoneNumber: user.data.phone_number,
    });
    const [loaderMessage, setLoaderMessage] = useState('');
    const [isQbConnected, setIsQbConnected] = useState(false);
    const [qbTrailBalance, setQbTrailBalance] = useState(null);
    const [yesButtonLoading, setYesButtonLoading] = useState(false);
    const [addDataButtonLoading, setAddDataButtonLoading] = useState(false);
    const code = searchParams.get('code');
    const realmId = searchParams.get('realmId');

    const styles = makeStyles({
        step,
        isScreenLowerThan1024,
    });

    useEffect(() => {
        if (is_onboarded && !isQbConnected && step === 0) {
            navigate('/dashboard');
        }
    }, [navigate, is_onboarded, isQbConnected]);

    useEffect(() => {
        if (code && realmId) {
            setShowLoader(true);
            setLoaderMessage('Connecting to the quickbooks.');
            handleUserAndCompanyDetailFromQuickbooks(code, realmId);
        }
    }, [code, realmId]);

    const handleUserChange = (e) => {
        setOnBoardingUser((prevUser) => ({
            ...prevUser,
            [e.target.name]: e.target.value,
        }));
    };

    const handleCompanyChange = (company) => {
        setCompanyInfo((prevInfo) => ({
            ...prevInfo,
            ...company,
        }));
    };

    const handleUserAndCompanyDetailFromQuickbooks = async (code, realmId) => {
        try {
            const response = await getQbUserAndCompany(code, realmId);

            const { user, company } = response.data;
            setOnBoardingUser({
                firstName: user.first_name,
                lastName: user.last_name,
                phoneNumber: user.phone_number,
            });

            // StepOneOnboardingInitialValues(user);
            setCompanyInfo({ name: company.name, ...company.address });

            setStep(1);
            setShowLoader(false);
            // StepOneCompnayInformationInitialValues(company);
            setIsQbConnected(true);
        } catch (error) {
            console.log(error);
        }
    };

    const handleFinishBoardingProcess = async (values) => {
        setCompanyInfo((prev) => ({
            ...prev,
            ...values,
        }));

        if (step === 2) {
            if (!is_onboarded) {
                setShowLoader(true);
                setLoaderMessage('Circler is preparing your chart of accounts');
                try {
                    dispatch(CREATE_COMPANY_PENDING());
                    const [updateUserResponse, companyResponse] = await Promise.all([
                        updateUser({
                            first_name: onBoardingUser.firstName,
                            last_name: onBoardingUser.lastName,
                            phone_number: onBoardingUser.phoneNumber,
                        }),
                        createCompany(
                            values.name,
                            values.industry.value,
                            values.entity.value,
                            values.timezone.value,
                            {
                                line1: values.line1,
                                line2: values.line2,
                                ...(values.line3 && {
                                    line3: values.line3,
                                }),
                                ...(values.line4 && {
                                    line4: values.line4,
                                }),
                                ...(values.line5 && {
                                    line5: values.line5,
                                }),
                                city: values.city,
                                state: values.state,
                                postal_code: values.postalCode,
                                country: values.country,
                            },
                        ),
                    ]);
                    dispatch(
                        CREATE_COMPANY_SUCCESS({
                            data: {
                                ...companyResponse,
                                timezone: {
                                    label: companyResponse.timezone,
                                    value: companyResponse.timezone,
                                },
                                industry: {
                                    label: values.industry.label,
                                    value: values.industry.label,
                                },
                                entity: {
                                    label: companyResponse.entity,
                                    value: companyResponse.entity,
                                },
                            },
                        }),
                    );
                    const updatedUserResponse = {
                        ...updateUserResponse,
                        is_onboarded: true,
                    };
                    dispatch(SET_CURRENT_USER_SUCCESS({ data: updatedUserResponse }));
                    if (isQbConnected) {
                        const accounts = await addQbAccounts(realmId);
                        setQbAccounts(accounts.data);
                    }
                } catch (error) {
                    console.error('Error:', error);
                    dispatch(CREATE_COMPANY_ERROR({ err: error }));
                } finally {
                    setShowLoader(false);
                }
            }

            setStep(3);
            setProgress((prevProgress) => prevProgress + 60);
        }
    };

    const handleQbYesClick = async () => {
        try {
            setYesButtonLoading(true);
            const response = await getQbLoginUrl();
            window.location.href = response.data;
        } catch (error) {
            setYesButtonLoading(false);
            console.log(error);
        }
    };

    const handleQbTrialBalanceClick = async () => {
        try {
            setPage(1);
            setLoaderMessage('Downloading Trial Balance from quickbooks');
            setShowLoader(true);
            const response = await addTrialBalance(
                realmId,
                endDate.format('YYYY-MM-DD'),
                companyData?.id || 107,
            );
            setQbTrailBalance(response.data);
            setStep(5);
            setShowLoader(false);
        } catch (error) {
            console.log(error);
        }
    };

    const handleAddDataButtonClick = async () => {
        setAddDataButtonLoading(true);
        if (step === 3) {
            try {
                await importQbAccounts(realmId);
            } catch (error) {
                console.error(error);
            }
        } else if (step === 5) {
        }
        setStep((prevStep) => prevStep + 1);
        setAddDataButtonLoading(false);
        setPage(1);
    };

    if (showLoader) {
        return <LoaderOnboarding showTextLoader={true} loaderText={loaderMessage} />;
    }

    return (
        <Grid container sx={styles.onboardingContainer}>
            <Grid item>
                {themeMode === 'dark' ? (
                    <DarkCirclerLogo width="180.995px" height="45.22px" />
                ) : (
                    <CirclerLogo width="180.995px" height="45.22px" />
                )}
            </Grid>
            <Grid container p={1}>
                <Grid item xs={12}>
                    <Progress progress={progress} customStyle={styles.progressStyle} />
                </Grid>
            </Grid>
            <Grid container sx={styles.onboardingContent}>
                {([0, 1, 2].includes(step) || (step === 4 && !isQbConnected) || step === 6) && (
                    <Grid item alignContent={'center'} display={'flex'}>
                        <Typography
                            text={'Welcome to Circler!'}
                            customStyles={{ fontSize: 32, fontWeight: 700 }}
                        />
                    </Grid>
                )}
                <Grid item container spacing={2} justifyContent={'space-around'}>
                    {[1, 2].includes(step) && !isQbConnected && (
                        <Grid
                            item
                            alignContent={'center'}
                            display={'flex'}
                            sx={{
                                cursor: 'pointer',
                            }}
                        >
                            <ArrowBackIosIcon
                                onClick={(e) => {
                                    e.preventDefault();
                                    setProgress(0);
                                    setStep((prevStep) => prevStep - 1);
                                }}
                            />
                        </Grid>
                    )}
                    <Grid item textAlign={'center'} display={'flex'}>
                        <Typography
                            text={
                                step === 0
                                    ? 'Do you need to migrate from quickbooks?'
                                    : step === 1
                                    ? 'Tell us about yourself so we can personalize your experience.'
                                    : step === 2
                                    ? 'Tell us about your company so we can personalize your experience.'
                                    : (step === 3 && !isQbConnected) || step === 6
                                    ? `You can start sending emails and receipts to ${companyData?.email} to record transactions.`
                                    : step === 3 && isQbConnected
                                    ? 'Accounts from quickbooks'
                                    : step === 5 && 'Trial Balance from quickbooks'
                            }
                            customStyles={{
                                fontSize: 18,
                                fontWeight: 500,
                                color: '#6C727F',
                            }}
                        />
                    </Grid>
                </Grid>
                {step === 0 && (
                    <Grid item pt={3} marginTop={'30px'} width={'100%'}>
                        <Grid item xs={12} container justifyContent="center" spacing={2}>
                            <Grid item xs={12} md={6} lg={6}>
                                <Button
                                    variant="contained"
                                    text={<Typography text="Yes" customStyles={buttonTextStyle} />}
                                    onClick={handleQbYesClick}
                                    fullWidth
                                    loading={yesButtonLoading}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <Button
                                    variant="contained"
                                    text={<Typography text="No" customStyles={buttonTextStyle} />}
                                    onClick={() => setStep(1)}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {[1, 2].includes(step) && (
                    <Formik
                        initialValues={StepOneOnboardingInitialValues(onBoardingUser)}
                        enableReinitialize={true}
                        validationSchema={
                            step === 1 ? StepOneOnboardingSchema : CompanyInformationSchema
                        }
                        onSubmit={(values, { setSubmitting }) => {
                            if (step === 1) {
                                setOnBoardingUser((prev) => ({
                                    ...prev,
                                    firstName: values.first_name,
                                    lastName: values.last_name,
                                    phoneNumber: values.phone_number,
                                }));
                                handleFinishBoardingProcess();
                                setProgress((prevProgress) => prevProgress + 33);
                                setStep(2);
                            } else {
                                handleFinishBoardingProcess();
                            }

                            setSubmitting(false);
                        }}
                    >
                        {({
                            values,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            errors,
                            touched,
                            setTouched,
                            isSubmitting,
                            handleSubmit,
                        }) => (
                            <Form>
                                <Grid item pt={3} width={'100%'}>
                                    <Paper elevation={2} sx={styles.step}>
                                        {/* Step 1: User Information */}
                                        {step === 1 && (
                                            <Grid item xs={12} container spacing={2}>
                                                <Grid item container>
                                                    <Box width="100%" gap={2} mt={1} border="none">
                                                        <Grid
                                                            container
                                                            alignItems="center"
                                                            spacing={2}
                                                        >
                                                            <Grid item xs={12} md={6}>
                                                                <TextField
                                                                    fullWidth
                                                                    name="first_name"
                                                                    placeholder="First Name"
                                                                    value={values.first_name}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        touched.first_name &&
                                                                        !!errors.first_name
                                                                    }
                                                                    helperText={
                                                                        touched.first_name &&
                                                                        errors.first_name
                                                                    }
                                                                />
                                                            </Grid>

                                                            <Grid item xs={12} md={6}>
                                                                <TextField
                                                                    fullWidth
                                                                    name="last_name"
                                                                    placeholder="Last Name"
                                                                    value={values.last_name}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        touched.last_name &&
                                                                        !!errors.last_name
                                                                    }
                                                                    helperText={
                                                                        touched.last_name &&
                                                                        errors.last_name
                                                                    }
                                                                />
                                                            </Grid>

                                                            {/* Phone Number */}
                                                            <Grid item xs={12} md={12} lg={12}>
                                                                <Box
                                                                    display="flex"
                                                                    flexDirection="column"
                                                                >
                                                                    <PhoneNumber
                                                                        fullWidth
                                                                        name="phone_number"
                                                                        placeholder="Enter Phone Number"
                                                                        value={values.phone_number}
                                                                        onChange={(e) => {
                                                                            setFieldValue(
                                                                                'phone_number',
                                                                                e.target.value,
                                                                            );
                                                                            setTouched({
                                                                                ...touched,
                                                                                phone_number: false,
                                                                            });
                                                                        }}
                                                                    />

                                                                    {/* Error message only below input */}
                                                                    {touched.phone_number &&
                                                                        errors.phone_number && (
                                                                            <FormHelperText
                                                                                error
                                                                                sx={{
                                                                                    fontSize:
                                                                                        '13px',
                                                                                    fontWeight: 600,
                                                                                    mt: 1,
                                                                                    textAlign:
                                                                                        'left',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    errors.phone_number
                                                                                }
                                                                            </FormHelperText>
                                                                        )}
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        )}

                                        {/* Step 2: Company Information */}
                                        {step === 2 && (
                                            <Grid item container spacing={2} xs={12} sx={{ p: 0 }}>
                                                <CompanyInformation
                                                    showIndustry={true}
                                                    companyLabel="Company Name"
                                                    timezoneLabel={'Time Zone'}
                                                    industryLabel={'Industry'}
                                                    typeOfEntityLabel={'Type of Entity'}
                                                    isOnboardingFlow={true}
                                                    onCompanyChange={(updatedCompany) =>
                                                        setFieldValue('company', updatedCompany)
                                                    }
                                                    callback={handleFinishBoardingProcess}
                                                    companyData={companyInfo}
                                                />
                                            </Grid>
                                        )}

                                        {step === 1 && (
                                            <Grid item xs={12} pt={3}>
                                                <Button
                                                    text={
                                                        <Typography
                                                            text="Next"
                                                            customStyles={{
                                                                color: '#FFFFFF',
                                                                fontSize: '18px',
                                                                fontWeight: 500,
                                                            }}
                                                        />
                                                    }
                                                    fullWidth
                                                    loading={isSubmitting}
                                                    onClick={() => {
                                                        setTouched({
                                                            phone_number: true,
                                                        });

                                                        handleSubmit();
                                                    }}
                                                />
                                            </Grid>
                                        )}
                                    </Paper>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                )}

                {((step === 3 && !isQbConnected) || step === 6) && (
                    <Grid item marginTop={'30px'} width={'100%'}>
                        <Grid item xs={12} container justifyContent="center" spacing={2}>
                            <Grid item xs={12} md={6} lg={6}>
                                <Button
                                    variant="contained"
                                    text={
                                        <Typography
                                            text={'Finish'}
                                            customStyles={buttonTextStyle}
                                        />
                                    }
                                    onClick={() => {
                                        navigate('/dashboard');
                                    }}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            {[3, 5].includes(step) && isQbConnected && (qbAccounts || qbTrailBalance) && (
                <Grid container sx={styles.tableBox}>
                    <Grid item xs={12}>
                        <DataTable
                            headCells={
                                step === 3
                                    ? qbAccountsTableHeaders
                                    : step === 5 && qbTrialBalanceTableHeaders
                            }
                            data={
                                step === 3
                                    ? qbAccounts.slice((page - 1) * 10, page * 10)
                                    : step === 5 && qbTrailBalance.slice((page - 1) * 10, page * 10)
                            }
                        />
                    </Grid>
                    <Grid item sx={12} marginTop="10px" width="100%">
                        <TablePagination
                            isMobileScreen={isMobileScreen}
                            page={page}
                            onChange={(e, pageNumber) => {
                                setPage(pageNumber);
                            }}
                            count={
                                step === 3
                                    ? qbAccounts?.length
                                    : step === 5 && qbTrailBalance?.length
                            }
                        />
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent="flex-end" sx={{ mt: 2 }}>
                        <Button
                            variant="contained"
                            text={
                                <Typography
                                    text={step === 3 ? 'Add Accounts' : 'Add Trial Balance'}
                                    customStyles={buttonTextStyle}
                                />
                            }
                            loading={addDataButtonLoading}
                            onClick={handleAddDataButtonClick}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            )}
            {step === 4 && isQbConnected && (
                <Grid container spacing={2} marginTop="30px" width="100%" textAlign="center">
                    <Grid item xs={12}>
                        <Typography text="What is your migration date? We will import balances as of this date." />
                    </Grid>
                    <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                value={endDate}
                                onChange={(newValue) =>
                                    setEndDate(dayjs(newValue.format('YYYY-MM-DD')))
                                }
                                sx={styles.datePicker}
                                disableFuture
                                shouldDisableDate={(date) => {
                                    return date.isSame(today, 'day');
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} container justifyContent="center" spacing={2} sx={{ mt: 2 }}>
                        <Grid item xs={12} md={6} lg={6}>
                            <Button
                                variant="contained"
                                text={
                                    <Typography
                                        text="Import Trial Balance"
                                        customStyles={buttonTextStyle}
                                    />
                                }
                                onClick={handleQbTrialBalanceClick}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}

export default Onboarding;
