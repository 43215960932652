import { Navigate } from 'react-router-dom';

import { Home, Login, Account, History, Dashboard, Transaction, Profile } from '../components';
import Journal from 'components/Journal';
import Invoice from '../components/Invoice';
import Inventory from 'components/Inventory';
import { SignUp } from '../components/Signup';
import Onboarding from '../components/Onboarding';
import Subscriptions from '../components/Subscriptions';
import ForgotPassword from '../components/ForgotPassword';
import InvoiceList from '../components/Invoice/InvoiceList';
import InvoiceSetting from '../components/Invoice/Settings';
import { ProtectedRoute } from '../components/ProtectedRoute';
import ConfirmNewPassword from '../components/ConfirmNewPassword';
import PreviewInvoice from 'components/Invoice/Components/InvoicePreview/PreviewInvoice';
import Reports from 'components/Reports';
import BalanceSheet from 'components/Reports/components/BalanceSheet';
import ProfitLoss from 'components/Reports/components/ProfitLoss';
import CashActivity from 'components/Reports/components/CashActivity';
import JournalEntry from 'components/Reports/components/JournalEntry';

export const PRIVATE_ROUTES = [
    {
        path: '/onboarding',
        element: (
            <ProtectedRoute route="onboarding">
                <Onboarding />
            </ProtectedRoute>
        ),
        label: 'onboarding',
    },
    {
        path: '/home',
        element: (
            <ProtectedRoute>
                <Home />
            </ProtectedRoute>
        ),
        label: 'Home',
    },
    {
        path: '/inventory',
        element: (
            <ProtectedRoute>
                <Inventory label="Inventory" />
            </ProtectedRoute>
        ),
        label: 'Inventory',
    },
    {
        path: '/',
        element: <Navigate replace to="/dashboard" />,
        label: 'Redirect to Home',
    },
    {
        path: '/dashboard',
        element: (
            <ProtectedRoute>
                <Dashboard />
            </ProtectedRoute>
        ),
        label: 'Dashboard',
    },
    {
        path: '/account',
        element: (
            <ProtectedRoute>
                <Account />
            </ProtectedRoute>
        ),
        label: 'Chart of Accounts',
    },
    {
        path: '/transaction',
        element: (
            <ProtectedRoute>
                <Transaction label="Transactions" />
            </ProtectedRoute>
        ),
        label: 'Transactions',
    },
    {
        path: '/import-bank-transactions',
        element: (
            <ProtectedRoute>
                <Transaction label="Import Bank Transactions" />
            </ProtectedRoute>
        ),
        label: 'Import Bank Transactions',
    },
    // {
    //     path: '/email-transactions',
    //     element: (
    //         <ProtectedRoute>
    //             <Transaction label="Email transactions" />
    //         </ProtectedRoute>
    //     ),
    //     label: 'Email transactions',
    // },

    {
        path: '/reports/balance-sheet',
        element: (
            <ProtectedRoute>
                <Reports label="Balance Sheet" />
            </ProtectedRoute>
        ),
        label: 'Balance Sheet',
    },

    {
        path: '/reports/:reportType/journalEntry/:account_id', // Accepts different report types
        element: (
            <ProtectedRoute>
                <JournalEntry />
            </ProtectedRoute>
        ),
        label: 'Journal Entry',
    },

    {
        path: '/reports/profit-loss',
        element: (
            <ProtectedRoute>
                <Reports label="Profit and Loss" />
            </ProtectedRoute>
        ),
        label: 'Profit and Loss',
    },
    {
        path: '/reports/cash-activity',
        element: (
            <ProtectedRoute>
                <Reports label="Cash Activity" />
            </ProtectedRoute>
        ),
        label: 'Cash Activity',
    },
    // {
    //     path: '/scan-receipt',
    //     element: (
    //         <ProtectedRoute>
    //             <Transaction label="Scan Receipt" />
    //         </ProtectedRoute>
    //     ),
    //     label: 'Scan Receipt',
    // },
    {
        path: '/upload-transactions',
        element: (
            <ProtectedRoute>
                <Transaction label="Upload Transactions" />
            </ProtectedRoute>
        ),
        label: 'Upload Transactions',
    },
    // {
    //     path: '/bank-reconciliation',
    //     element: (
    //         <ProtectedRoute>
    //             <Transaction label="Bank Reconciliation" />
    //         </ProtectedRoute>
    //     ),
    //     label: 'Bank Reconciliation',
    // },
    {
        path: '/inventory-reconciliation',
        element: (
            <ProtectedRoute>
                <Inventory label="Inventory Reconciliation" />
            </ProtectedRoute>
        ),
        label: 'Inventory Reconciliation',
    },
    {
        path: '/inventory-emails',
        element: (
            <ProtectedRoute>
                <Inventory label="Emails" />
            </ProtectedRoute>
        ),
        label: 'Emails',
    },
    {
        path: '/history',
        element: (
            <ProtectedRoute>
                <History />
            </ProtectedRoute>
        ),
        label: 'History',
    },
    {
        path: '/invoice',
        element: (
            <ProtectedRoute>
                <Invoice />
            </ProtectedRoute>
        ),
        label: 'Invoice',
    },
    {
        path: '/invoiceList',
        element: (
            <ProtectedRoute>
                <InvoiceList />
            </ProtectedRoute>
        ),
        label: 'Invoice',
    },
    {
        path: '/invoiceSettings',
        element: (
            <ProtectedRoute>
                <InvoiceSetting generalSettings={true} />
            </ProtectedRoute>
        ),
        label: 'Invoice',
    },
    {
        path: '/invoiceSetting',
        element: (
            <ProtectedRoute>
                <InvoiceSetting />
            </ProtectedRoute>
        ),
        label: 'Invoice',
    },
    {
        path: '/profile',
        element: (
            <ProtectedRoute>
                <Profile />
            </ProtectedRoute>
        ),
        label: 'Profile',
    },
    {
        path: '/subscriptions',
        element: (
            <ProtectedRoute route="subscriptions">
                <Subscriptions />
            </ProtectedRoute>
        ),
        label: 'Subscriptions',
    },
    {
        path: '/journal-entries',
        element: (
            <ProtectedRoute>
                <Journal />
            </ProtectedRoute>
        ),
        label: 'Journal Entries',
    },
];

export const PUBLIC_ROUTES = [
    {
        path: '/login',
        element: <Login />,
    },
    {
        path: '/signup',
        element: <SignUp />,
    },
    {
        path: '/forgotPassword',
        element: <ForgotPassword />,
    },
    {
        path: '/password-reset/:uid/:token',
        element: <ConfirmNewPassword />,
    },

    {
        path: '/invoice/:invoiceIds/:companyIds',
        element: <PreviewInvoice />,
    },
];
