import { Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';

export const journalTableHeaders = (handleEdit) => {
    return [
        {
            id: 'name',
            label: 'Name',
            sort: false,
            verticalAlign: 'top',
        },
        {
            id: 'date',
            label: 'Date',
            sort: false,
            verticalAlign: 'top',
        },
        {
            id: 'amount',
            label: 'Total',
            sort: false,
            verticalAlign: 'top',
            render: (row) => `${row.currency} ${row.total_amount}`,
        },
        {
            id: 'action',
            label: '',
            sort: false,
            verticalAlign: 'top',
            render: (row) => (
                <IconButton onClick={() => handleEdit(row)} sx={{ padding: 0 }}>
                    <Edit fontSize="small" />
                </IconButton>
            ),
        },
    ];
};
