export const uploadTransactionsHeaders = [
    'Date *',
    'Description *',
    'Amount *',
    'Merchant Name',
    'Type',
    'Card Name',
    'Card number (or 4 digits)',
    'Transaction ID',
];
