import dayjs from 'dayjs';
import React from 'react';
import { Formik, Form } from 'formik';

import { Box, Grid } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import {
    scheduledPaymentSchema,
    scheduledPaymentInitialValues,
} from 'components/Inventory/utils/scheduledPaymentSchema';
import { Button, TextField, Modal, Autocomplete } from 'shared';
import { paymentPlanFormStyles } from 'components/Inventory/style/style';
import { frequencyOptions } from 'components/Inventory/utils/frequencyOptions';

const ScheduledPaymentModal = ({ open, onClose, onAddPayments }) => {
    const classes = paymentPlanFormStyles();

    const handleFormSubmit = (values) => {
        const { payments, frequency, startDate, amount } = values;
        const newPayments = [];

        let date = dayjs(startDate);
        const originalDay = date.date();

        for (let i = 0; i < payments; i++) {
            if (i > 0) {
                if (frequency === 'daily') date = date.add(1, 'day');
                else if (frequency === 'weekly') date = date.add(1, 'week');
                else if (frequency === 'monthly') {
                    let nextDate = date.add(1, 'month');

                    if (nextDate.date(originalDay).date() !== originalDay) {
                        nextDate = nextDate.endOf('month');
                    } else {
                        nextDate = nextDate.date(originalDay);
                    }

                    date = nextDate;
                }
            }
            newPayments.push({ date: date.format('YYYY-MM-DD'), amount: amount || '0' });
        }

        onAddPayments(newPayments);
        onClose();
    };

    return (
        <Modal fullWidth open={open} title={`Create Scheduled Payments`} handleClose={onClose}>
            <Formik
                initialValues={scheduledPaymentInitialValues}
                validationSchema={scheduledPaymentSchema}
                onSubmit={handleFormSubmit}
            >
                {({ values, setFieldValue, errors, touched }) => (
                    <Form>
                        <Grid container spacing={2} marginTop="5px">
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    name="payments"
                                    inlineLabel="Number of Payments"
                                    type="number"
                                    value={values.payments}
                                    onChange={(e) => setFieldValue('payments', e.target.value)}
                                    error={touched.payments && !!errors.payments}
                                    helperText={touched.payments && errors.payments}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    options={frequencyOptions}
                                    labelKey="label"
                                    valueKey="value"
                                    placeholder="Frequency"
                                    inlineLabel="Frequency"
                                    name="frequency"
                                    value={
                                        frequencyOptions.find(
                                            (options) => options.value == values.frequency,
                                        ) || null
                                    }
                                    onChange={(e, value) => {
                                        setFieldValue('frequency', value ? value.value : null);
                                    }}
                                    isSearchField={false}
                                    disableClearable
                                    error={touched.frequency && !!errors.frequency}
                                    helperText={touched.frequency && errors.frequency}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        value={values.startDate && dayjs(values.startDate)}
                                        onChange={(date) => setFieldValue('startDate', date)}
                                        label="Start Date"
                                        placeholder="Start Date"
                                        format="YYYY-MM-DD"
                                        sx={classes.datePicker}
                                        slotProps={{
                                            textField: {
                                                error: touched.startDate && !!errors.startDate,
                                                helperText: touched.startDate && errors.startDate,
                                                FormHelperTextProps: {
                                                    sx: classes.dateError,
                                                },
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    name="amount"
                                    inlineLabel="Amount"
                                    type="number"
                                    value={values.amount}
                                    onChange={(e) => setFieldValue('amount', e.target.value)}
                                    error={touched.amount && !!errors.amount}
                                    helperText={touched.amount && errors.amount}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="flex-end" gap={1}>
                                    <Button
                                        text="Cancel"
                                        onClick={onClose}
                                        sx={classes.cancelButton}
                                    />
                                    <Button text="Create" type="submit" />
                                </Box>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default ScheduledPaymentModal;
