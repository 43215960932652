import * as Yup from 'yup';

export const journalValidationSchema = Yup.object().shape({
    date: Yup.date().required('Date is required'),
    name: Yup.string().required('Name is required'),
    invoiceNumber: Yup.string().optional(),
    currency: Yup.object()
        .shape({
            value: Yup.string().required('Currency value is required'),
            label: Yup.string().required('Currency label is required'),
        })
        .nullable()
        .required('Currency is required'),
    description: Yup.string().optional(),
    journalEntries: Yup.array().of(
        Yup.object().shape({
            account: Yup.object()
                .shape({
                    id: Yup.string().required('Account id is required'),
                    label: Yup.string().required('Account label is required'),
                })
                .nullable()
                .required('Account is required'),
            debit: Yup.number().min(0, 'Debit must be non-negative').optional(),
            credit: Yup.number()
                .min(0, 'Credit must be non-negative')
                .optional(),
        }),
    ),
});
