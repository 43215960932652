import React from 'react';

import { Alert, Snackbar } from '@mui/material';

export const SnackBarAlert = ({ alertData, setAlertData }) => {
    const handleAlertClose = () => {
        setAlertData((prevState) => ({
            ...prevState,
            open: false,
        }));
    };

    return (
        <Snackbar
            open={alertData.open}
            autoHideDuration={3000}
            onClose={handleAlertClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert
                onClose={handleAlertClose}
                severity={alertData.severity}
                sx={{ width: '100%' }}
            >
                {alertData.message}
            </Alert>
        </Snackbar>
    );
};
