import dayjs from 'dayjs';
import { Formik, Form } from 'formik';
import React, { useState, Fragment } from 'react';

import { Grid } from '@mui/material';
import { Button, DataTable, TablePagination } from 'shared';

import {
    inventoryFiltersSchema,
    inventoryFiltersInitialValues,
} from '../../../utils/inventoryFiltersSchema';
import InventoryFilters from './InventoryFilters';
import InventorySourceModal from './InventorySourceModal';
import FilteredInventoryInfo from './FilteredInventoryInfo';
import { inventoryTableHeaders } from '../../../utils/inventoryTableHeaders';

const InventoryTable = ({
    data,
    page,
    setPage,
    sorting,
    loading,
    sortOrder,
    handleSort,
    downloadLoading,
    handlePageChange,
    handleGetInventory,
    handleDownloadInventory,
}) => {
    const [inventoryId, setInventoryId] = useState(null);
    const [filterValues, setFilterValues] = useState(inventoryFiltersInitialValues);
    const [showInventorySourceModal, setShowInventorySourceModal] = useState(false);

    const handleClearClick = (handleReset) => {
        setPage(1);
        handleReset();
        handleGetInventory(1, {});
        setFilterValues(inventoryFiltersInitialValues);
    };

    const handleSourceClick = (rowData) => {
        setInventoryId(rowData?.id);
        setShowInventorySourceModal(true);
    };

    const sortHandler = (columnId, newSortOrder) => {
        handleSort(filterValues, columnId, newSortOrder);
    };

    return (
        <Fragment>
            <Formik
                initialValues={inventoryFiltersInitialValues}
                validationSchema={inventoryFiltersSchema}
                onSubmit={(values) => {
                    const transformedValues = {
                        ...values,
                        expiryDate: values.expiryDate
                            ? dayjs(values.expiryDate).format('YYYY-MM-DD')
                            : '',
                        purchaseDate: values.purchaseDate
                            ? dayjs(values.purchaseDate).format('YYYY-MM-DD')
                            : '',
                    };

                    setFilterValues(transformedValues);

                    handleGetInventory(1, transformedValues);
                }}
            >
                {({ values, handleReset, handleChange, setFieldValue }) => {
                    return (
                        <Form>
                            <InventoryFilters
                                values={values}
                                handleReset={handleReset}
                                handleChange={handleChange}
                                setFieldValue={setFieldValue}
                                handleClearClick={handleClearClick}
                            />
                        </Form>
                    );
                }}
            </Formik>

            <Grid container>
                <Grid item xs={12} display="flex" marginBottom="10px" justifyContent="flex-end">
                    <Button
                        text="Download CSV"
                        loading={downloadLoading}
                        onClick={() => handleDownloadInventory(filterValues)}
                    />
                </Grid>
                {data?.results?.total_purchase_price > 0 && (
                    <Grid item xs={12}>
                        <FilteredInventoryInfo
                            count={data?.count}
                            amount={data?.results?.total_purchase_price}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <DataTable
                        headCells={inventoryTableHeaders(handleSourceClick)}
                        isLoading={loading}
                        data={data?.results?.results}
                        sorting={sorting}
                        sortOrder={sortOrder}
                        sortHandler={sortHandler}
                    ></DataTable>
                </Grid>
                <Grid item xs={12} p={'10px 0'}>
                    <TablePagination
                        page={page}
                        count={data.count}
                        rowsPerPage={100}
                        onChange={(e, pageNumber) => handlePageChange(pageNumber, filterValues)}
                    />
                </Grid>
            </Grid>

            {showInventorySourceModal && (
                <InventorySourceModal
                    inventoryId={inventoryId}
                    showInventorySourceModal={showInventorySourceModal}
                    setShowInventorySourceModal={setShowInventorySourceModal}
                />
            )}
        </Fragment>
    );
};

export default InventoryTable;
