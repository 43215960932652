import React from 'react';
import dayjs from 'dayjs';

import { Box, Grid } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { Button, TextField } from 'shared';
import { inventoryFiltersStyles } from '../../../style/style';

const InventoryFilters = ({
    values,
    handleReset,
    handleChange,
    setFieldValue,
    handleClearClick,
}) => {
    const classes = inventoryFiltersStyles();

    return (
        <Box>
            <Grid container spacing={2} marginTop="5px">
                <Grid item xs={12} md={3} lg={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            value={values.expiryDate && dayjs(values.expiryDate)}
                            onChange={(date) => setFieldValue('expiryDate', date)}
                            label="Select Expiry Date"
                            placeholder="Select Expiry Date"
                            format="YYYY-MM-DD"
                            sx={classes.datePicker}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            value={values.purchaseDate && dayjs(values.purchaseDate)}
                            onChange={(date) => setFieldValue('purchaseDate', date)}
                            label="Select Purchase Date"
                            placeholder="Select Purchase Date"
                            format="YYYY-MM-DD"
                            sx={classes.datePicker}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <TextField
                        fullWidth
                        name="description"
                        value={values.description}
                        placeholder="Search by description"
                        onChange={handleChange}
                        customStyle={classes.inputField}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={24} marginTop="10px" marginBottom="10px">
                    <Button
                        text="Clear"
                        onClick={() => handleClearClick(handleReset)}
                        customStyles={classes.clearButton}
                    />
                    <Button text="Apply" type="submit" />
                </Grid>
            </Grid>
        </Box>
    );
};

export default InventoryFilters;
