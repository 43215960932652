import { useSelector } from 'react-redux';
import React, { Fragment, useEffect, useState } from 'react';

import { Grid, Typography } from '@mui/material';

import { useCustomMediaQuery } from 'hooks/useMediaQuery';
import { handleErrorAlert } from 'utils/handleErrorAlert';
import { useInventoryService } from 'store/services/inventory.service';
import { excludedTableHeaders } from '../../../utils/excludedTableHeaders';
import { DataTable, CircleLoading, SnackBarAlert, TablePagination } from 'shared';

const Excluded = () => {
    const companyData = useSelector((state) => state.company.data);
    const isMobileScreen = useCustomMediaQuery('(max-width: 600px)');
    const { getExcludedPlaidTransactions, undoExcludedPlaidTransaction } = useInventoryService();

    const [page, setPage] = useState(1);
    const [data, setData] = useState(null);
    const [alertData, setAlertData] = useState({
        severity: '',
        open: false,
        message: '',
    });
    const [loadingRowId, setLoadingRowId] = useState(null);
    const [tableLoading, setTableLoading] = useState(true);

    const handleGetExcludedPlaidTransactions = async (page) => {
        try {
            setTableLoading(true);
            const response = await getExcludedPlaidTransactions(companyData.id, page);
            setData(response);
        } catch (error) {
            handleErrorAlert(setAlertData);
        } finally {
            setTableLoading(false);
        }
    };

    const handleUndoExcludedPlaidTransaction = async (rowData) => {
        setLoadingRowId(rowData?.id);
        try {
            const response = await undoExcludedPlaidTransaction({
                company_id: companyData?.id,
                plaid_transaction_id: rowData?.id,
            });
            setAlertData({
                severity: 'success',
                message: response.message,
                open: true,
            });
            handleGetExcludedPlaidTransactions(page);
        } catch (error) {
            handleErrorAlert(setAlertData);
        }
    };

    const handlePageChange = async (page) => {
        setPage(page);
        await handleGetExcludedPlaidTransactions(page);
    };

    useEffect(() => {
        handleGetExcludedPlaidTransactions(page);
    }, []);

    return (
        <Fragment>
            {companyData?.id && data ? (
                <Grid container>
                    {data?.count > 0 && (
                        <Grid
                            item
                            xs={12}
                            display="flex"
                            marginBottom="5px"
                            justifyContent="flex-end"
                        >
                            <Typography fontSize="18px">{`TOTAL: ${data?.count} ROW${
                                data?.count > 1 ? 'S' : ''
                            }`}</Typography>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <DataTable
                            headCells={excludedTableHeaders(
                                (rowData) => handleUndoExcludedPlaidTransaction(rowData),
                                loadingRowId,
                            )}
                            isLoading={tableLoading}
                            data={data.results}
                        />
                    </Grid>
                    <Grid item xs={12} p={'10px 0'}>
                        <TablePagination
                            isMobileScreen={isMobileScreen}
                            page={page}
                            count={data?.count}
                            onChange={(e, pageNumber) => handlePageChange(pageNumber)}
                        />
                    </Grid>
                </Grid>
            ) : (
                <CircleLoading />
            )}

            <SnackBarAlert alertData={alertData} setAlertData={setAlertData} />
        </Fragment>
    );
};

export default Excluded;
