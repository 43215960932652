import { Chip, IconButton } from '@mui/material';
import SourceIcon from '@mui/icons-material/Source';

import { statusColors } from './statusColors';

export const inventoryTableHeaders = (handleSourceClick) => {
    return [
        {
            id: 'purchase_id',
            label: 'Purchase',
            sort: false,
        },
        {
            id: 'reference_id',
            label: 'Reference',
            sort: true,
        },
        {
            id: 'sale_reference_id',
            label: 'Sale Ref',
            sort: false,
        },
        {
            id: 'description',
            label: 'Description',
            sort: false,
        },
        {
            id: 'class_1',
            label: 'Class 1',
            sort: false,
        },
        {
            id: 'class_2',
            label: 'Class 2',
            sort: false,
        },
        {
            id: 'class_3',
            label: 'Class 3',
            sort: false,
        },
        {
            id: 'purchase_price',
            label: 'Purchase Price',
            sort: false,
        },
        {
            id: 'purchase_payment',
            label: 'Purchase Payment',
            sort: false,
            render: (row) => `${row.payment_method || '-'} ${row.payment_last_digits || '-'}`,
        },
        {
            id: 'expires_at',
            label: 'Expires at',
            sort: true,
        },
        {
            id: 'purchased_at',
            label: 'Purchased At',
            sort: true,
        },
        {
            id: 'status',
            label: 'Status',
            sort: false,
            render: (row) => <Chip label={row.status} color={statusColors[row.status]} />,
        },
        {
            id: 'source',
            label: 'Source',
            sort: false,
            render: (row) => (
                <IconButton onClick={() => handleSourceClick(row)} sx={{ padding: 0 }}>
                    <SourceIcon fontSize="small" />
                </IconButton>
            ),
        },
    ];
};
