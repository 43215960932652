import { Box, Button, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Image from 'mui-image';
import { useDropzone } from 'react-dropzone';
import RejectionFiles from './errors/RejectionFiles';
import MultiFilePreview from './preview/MultiFilePreview';

const StyledDropZone = styled('div')(({ theme }) => ({
    outline: 'none',
    cursor: 'pointer',
    overflow: 'hidden',
    position: 'relative',
    padding: theme.spacing(5),
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create('padding'),
    backgroundColor: 'rgba(0, 97, 219, 0.05)',
    border: `1px dashed #0061DB`,
    '&:hover': {
        opacity: 0.72,
    },
}));

export default function Upload({
    disabled,
    multiple = false,
    error,
    helperText,
    file,
    onDelete,
    files,
    thumbnail,
    onUpload,
    onRemove,
    placeholder = 'Select a JPEG, PNG or PDF file to upload',
    onRemoveAll,
    sx,
    ...other
}) {
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragReject,
        fileRejections,
    } = useDropzone({
        multiple,
        disabled,
        ...other,
    });

    const hasFiles = files && multiple && files.length > 0;

    const isError = isDragReject || !!error;

    return (
        <Box sx={{ width: 1, position: 'relative', ...sx }}>
            <StyledDropZone
                {...getRootProps()}
                sx={{
                    ...(isDragActive && {
                        opacity: 0.72,
                    }),
                    ...(isError && {
                        color: 'error.main',
                        bgcolor: 'error.lighter',
                        borderColor: 'error.light',
                    }),
                    ...(disabled && {
                        opacity: 0.48,
                        pointerEvents: 'none',
                    }),
                }}
            >
                <input {...getInputProps()} />

                <Placeholder title={placeholder} />
            </StyledDropZone>

            {helperText && helperText}

            <RejectionFiles fileRejections={fileRejections} />
            {hasFiles && (
                <>
                    <Box sx={{ my: 3 }}>
                        <MultiFilePreview
                            files={files}
                            thumbnail={thumbnail}
                            onRemove={onRemove}
                            disabled={disabled}
                        />
                    </Box>

                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        spacing={1.5}
                    >
                        {onRemoveAll && (
                            <Button
                                color="inherit"
                                variant="outlined"
                                size="small"
                                onClick={onRemoveAll}
                            >
                                Remove all
                            </Button>
                        )}

                        {onUpload && (
                            <Button
                                size="small"
                                variant="contained"
                                onClick={onUpload}
                            >
                                Upload files
                            </Button>
                        )}
                    </Stack>
                </>
            )}
        </Box>
    );
}

function Placeholder({ title, sx, ...other }) {
    return (
        <Stack
            spacing={5}
            alignItems="center"
            justifyContent="center"
            direction="column"
            sx={{
                width: 1,
                textAlign: {
                    xs: 'center',
                    md: 'left',
                },
                ...sx,
            }}
            {...other}
        >
            <Image
                component="image"
                width={100}
                src="/assets/icons/files/upload_ilustration.svg"
            />

            <Stack direction="column" alignItems="center">
                <Typography
                    variant="h5"
                    color="#0061DB"
                    sx={{ fontWeight: 700 }}
                >
                    Drop or Select your file here to upload
                </Typography>

                <Typography variant="body2" color="#0061DB">
                    {title}
                </Typography>
            </Stack>
        </Stack>
    );
}
