import React, { useState } from 'react';

import {
    Box,
    Grid,
    Accordion,
    Typography,
    AccordionDetails,
    AccordionSummary,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { extractPlainText } from 'components/Inventory/utils/emailUtils';

const classes = {
    accordionWrapper: { mt: 2 },
    accordion: {
        backgroundColor: (theme) =>
            theme.palette.mode === 'dark' ? 'rgb(57, 65, 80)' : 'rgb(244, 244, 246)',
        border: (theme) =>
            `1px solid ${theme.palette.mode === 'dark' ? 'transparent' : '#E5E6EB'} `,
    },
    noBodyText: { whiteSpace: 'pre-wrap' },
    addToNewLine: {
        wordBreak: 'break-all !important',
    },
    accordionDetails: {
        maxHeight: '400px',
        overflow: 'auto',
    },
    accordionSummary: { userSelect: 'text' },
};

export const EmailAccordion = ({ emails }) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = (event) => {
        event.stopPropagation();
        setExpanded(!expanded);
    };

    const handleSummaryClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    return (
        <Box sx={classes.accordionWrapper}>
            {(!emails || emails.length === 0) && <Typography>No emails found.</Typography>}
            {emails && emails.length > 0 && (
                <Box>
                    {emails.map((email, index) => (
                        <Accordion
                            // expanded={expanded}
                            key={email.id || index}
                            sx={classes.accordion}
                            // onChange={() => {}}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <ExpandMoreIcon
                                    // onClick={handleExpandClick}
                                    />
                                }
                                // onClick={handleSummaryClick}
                                // aria-controls="panel-content"
                                // id="panel-header"
                                sx={classes.accordionSummary}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="body1" fontWeight="bold">
                                            Sender:
                                        </Typography>
                                        <Typography variant="body2" sx={classes.addToNewLine}>
                                            {email?.sender || email?.companyEmail?.sender}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="body1" fontWeight="bold">
                                            Subject:
                                        </Typography>
                                        <Typography variant="body2" sx={classes.addToNewLine}>
                                            {email?.subject || email?.companyEmail?.subject}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="body1" fontWeight="bold">
                                            Received At:
                                        </Typography>
                                        <Typography variant="body2">
                                            {email?.timeReceived ||
                                                email?.companyEmail?.timeReceived}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>

                            <AccordionDetails sx={classes.accordionDetails}>
                                {email?.stripped_html || email?.companyEmail?.stripped_html ? (
                                    <Typography
                                        component="div"
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                email?.stripped_html ||
                                                email?.companyEmail?.stripped_html,
                                        }}
                                    />
                                ) : (
                                    <Typography variant="body2" sx={classes.noBodyText}>
                                        {extractPlainText(email?.body || email?.companyEmail?.body)}
                                    </Typography>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>
            )}
        </Box>
    );
};
