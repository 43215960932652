export const paymentPlanTableHeaders = [
    {
        id: 'name',
        label: 'Name',
        sort: false,
    },
    {
        id: 'total',
        label: 'Total',
        sort: false,
    },
    {
        id: 'balance',
        label: 'Balance',
        sort: false,
    },
    {
        id: 'remaining',
        label: 'Remaing',
        sort: false,
    },
    {
        id: 'next_scheduled_amount',
        label: 'Next Payment Amount',
        sort: false,
    },
    {
        id: 'next_payment_date',
        label: 'Next Payment Date',
        sort: false,
    },
];
