import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';

import { Modal, Button, CircleLoading, EmailAccordion } from 'shared';
import { useInventoryService } from 'store/services/inventory.service';

const InventorySourceModal = ({
    inventoryId,
    showInventorySourceModal,
    setShowInventorySourceModal,
    emailId = null,
}) => {
    const companyData = useSelector((state) => state.company.data);
    const { isLoading, getInventorySource, getEmailById } = useInventoryService();

    const [emails, setEmails] = useState(null);

    const handleGetInventorySource = async () => {
        try {
            const response = await getInventorySource(companyData?.id, inventoryId);
            setEmails(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (companyData) {
            if (emailId) {
                getEmailById(companyData?.id, emailId)
                    .then((res) => {
                        console.log('Email by ID:', res.data);
                        // You can handle this data here if needed
                        // e.g., setEmails([res.data]) or another state
                    })
                    .catch((err) => {
                        console.error('Error fetching email by ID:', err);
                    });
            } else {
                handleGetInventorySource();
            }
        }
    }, [companyData, emailId]);

    return (
        <Modal
            fullWidth={true}
            open={showInventorySourceModal}
            title="Inventory Source"
            handleClose={() => setShowInventorySourceModal(false)}
            customWidth="75%"
            showFooter
            footer={
                <Box>
                    <Button text="Cancel" onClick={() => setShowInventorySourceModal(false)} />
                </Box>
            }
        >
            {isLoading && !emails ? (
                <CircleLoading />
            ) : !emails?.length ? (
                <Box display="flex" alignItems="center" marginBottom="10px" justifyContent="center">
                    <Typography>No source email found</Typography>
                </Box>
            ) : (
                <EmailAccordion emails={emails} />
            )}
        </Modal>
    );
};

export default InventorySourceModal;
