import { useCallback } from 'react';
import { useAxios } from '../../hooks/useAxios';

export const useReportService = () => {
    const { get, isLoading } = useAxios();

    const getJournalEntries = useCallback(
        (accountId) => {
            console.log('Fetching Journal Entries for Account ID:', accountId);

            if (!accountId) {
                console.error(
                    'Account ID is required for fetching journal entries',
                );
                return Promise.reject('Missing account ID');
            }

            const url = `/dashboard/journal-entries?account_id=${accountId}`;
            return get(url);
        },
        [get],
    );

    const getBalanceSheetSummary = useCallback(
        (companyId, currency = 'USD', endDate) => {
            let url = '/dashboard/report_bs_summary?';
            if (companyId !== undefined) {
                url += `company_id=${companyId}`;
            }
            if (currency !== undefined) {
                url += `&currency=${currency}`;
            }
            if (endDate !== undefined) {
                url += `&end_date=${endDate}`;
            }
            return get(url);
        },
        [get],
    );
    const getBalanceSheetDetail = useCallback(
        (companyId, currency = 'USD', endDate, section) => {
            console.log(
                'this is api data incoming',
                companyId,
                currency,
                endDate,
                section,
            );
            let url = '/dashboard/report_bs_detail?';
            if (companyId !== undefined) {
                url += `company_id=${companyId}`;
            }
            if (currency !== undefined) {
                url += `&currency=${currency}`;
            }
            if (endDate !== undefined) {
                url += `&end_date=${endDate}`;
            }
            if (section !== undefined) {
                url += `&section=${section}`;
            }
            return get(url);
        },
        [get],
    );

    const getCashActivitySummary = useCallback(
        (companyId, currency = 'USD', endDate, startDate) => {
            let url = '/dashboard/report_cash_summary?';
            if (companyId !== undefined) {
                url += `company_id=${companyId}`;
            }
            if (currency !== undefined) {
                url += `&currency=${currency}`;
            }
            if (startDate !== undefined) {
                url += `&start_date=${startDate}`;
            }
            if (endDate !== undefined) {
                url += `&end_date=${endDate}`;
            }
            return get(url);
        },
        [get],
    );
    const getCashActivityDetail = useCallback(
        (companyId, currency = 'USD', startDate, endDate, section) => {
            let url = '/dashboard/report_cash_detail?';
            if (companyId !== undefined) {
                url += `company_id=${companyId}`;
            }
            if (currency !== undefined) {
                url += `&currency=${currency}`;
            }
            if (startDate !== undefined) {
                url += `&start_date=${startDate}`;
            }
            if (endDate !== undefined) {
                url += `&end_date=${endDate}`;
            }
            if (section !== undefined) {
                url += `&section=${section}`;
            }
            return get(url);
        },
        [get],
    );

    const getProfitLossSummary = useCallback(
        (companyId, currency = 'USD', endDate, startDate) => {
            let url = '/dashboard/report_pl_summary?';
            if (companyId !== undefined) {
                url += `company_id=${companyId}`;
            }
            if (currency !== undefined) {
                url += `&currency=${currency}`;
            }
            if (startDate !== undefined) {
                url += `&start_date=${startDate}`;
            }
            if (endDate !== undefined) {
                url += `&end_date=${endDate}`;
            }
            return get(url);
        },
        [get],
    );
    const getProfitLossDetail = useCallback(
        (companyId, currency = 'USD', startDate, endDate, section) => {
            let url = '/dashboard/report_pl_detail?';
            if (companyId !== undefined) {
                url += `company_id=${companyId}`;
            }
            if (currency !== undefined) {
                url += `&currency=${currency}`;
            }
            if (startDate !== undefined) {
                url += `&start_date=${startDate}`;
            }
            if (endDate !== undefined) {
                url += `&end_date=${endDate}`;
            }
            if (section !== undefined) {
                url += `&section=${section}`;
            }
            return get(url);
        },
        [get],
    );

    const downloadBSReportCSV = useCallback(
        (companyId, currency = 'USD', startDate, endDate) => {
            let url = '/dashboard/report_bs_export_csv?';
            if (companyId !== undefined) {
                url += `company_id=${companyId}`;
            }
            if (currency !== undefined) {
                url += `&currency=${currency}`;
            }
            if (startDate !== undefined) {
                url += `&start_date=${startDate}`;
            }
            if (endDate !== undefined) {
                url += `&end_date=${endDate}`;
            }

            return get(url);
        },
        [get],
    );

    const downloadProfitLossCSV = useCallback(
        (companyId, currency = 'USD', startDate, endDate) => {
            let url = '/dashboard/report_pl_export_csv?';
            if (companyId !== undefined) {
                url += `company_id=${companyId}`;
            }
            if (currency !== undefined) {
                url += `&currency=${currency}`;
            }
            if (startDate !== undefined) {
                url += `&start_date=${startDate}`;
            }
            if (endDate !== undefined) {
                url += `&end_date=${endDate}`;
            }

            return get(url);
        },
        [get],
    );

    const downloadCashReportCSV = useCallback(
        (companyId, currency = 'USD', startDate, endDate) => {
            let url = '/dashboard/report_cash_export_csv?';
            if (companyId !== undefined) {
                url += `company_id=${companyId}`;
            }
            if (currency !== undefined) {
                url += `&currency=${currency}`;
            }
            if (startDate !== undefined) {
                url += `&start_date=${startDate}`;
            }
            if (endDate !== undefined) {
                url += `&end_date=${endDate}`;
            }

            return get(url);
        },
        [get],
    );

    return {
        isLoading,
        getProfitLossDetail,
        getProfitLossSummary,
        downloadBSReportCSV,
        downloadCashReportCSV,
        downloadProfitLossCSV,
        getBalanceSheetDetail,
        getJournalEntries,
        getCashActivityDetail,
        getCashActivitySummary,
        getBalanceSheetSummary,
    };
};
