import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import React, { Fragment, useEffect, useState } from 'react';

import CrownIcon from '@mui/icons-material/Star';
import { Box, Typography, Link } from '@mui/material';

import { useCustomMediaQuery } from 'hooks/useMediaQuery';

const UpgradeBanner = ({ isDarkMode }) => {
    const navigate = useNavigate();
    const isSmallScreen = useCustomMediaQuery('(max-width: 900px)');
    const { data: companyData } = useSelector((state) => state.company);

    const [dayDifference, setDayDifference] = useState();

    useEffect(() => {
        if (companyData && companyData?.stripeTrialExpiry) {
            setDayDifference(
                dayjs(companyData.stripeTrialExpiry)
                    .startOf('day')
                    .diff(dayjs(new Date()).startOf('day'), 'day') + 1,
            );
        }
    }, [companyData]);

    const handleClick = (event) => {
        event.preventDefault();
        navigate('/subscriptions');
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#FFF8E1',
                padding: isSmallScreen ? '4px 8px' : '8px 16px',
                borderRadius: '8px',
                border: '4px',
            }}
        >
            {companyData && (
                <Fragment>
                    <CrownIcon
                        sx={{
                            color: '#FFC107',
                            fontSize: '18px',
                            marginRight: '8px',
                        }}
                    />
                    {isSmallScreen ? (
                        <Link component="button" onClick={handleClick}>
                            Upgrade
                        </Link>
                    ) : companyData.stripeStatus === 'trialing' ? (
                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: '500',
                                flexGrow: 1,
                                color: 'black',
                            }}
                        >
                            There {dayDifference > 1 ? 'are ' : 'is '}
                            <Typography
                                component="span"
                                sx={{
                                    fontWeight: 'bold',
                                    display: 'inline',
                                }}
                            >
                                {dayDifference} day{dayDifference > 1 && 's'}
                            </Typography>{' '}
                            left{' '}
                            <Link component="button" onClick={handleClick}>
                                Upgrade Account
                            </Link>
                        </Typography>
                    ) : (
                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: '500',
                                flexGrow: 1,
                                color: 'black',
                            }}
                        >
                            To enjoy premium features,{' '}
                            <Link component="button" onClick={handleClick}>
                                Upgrade Account
                            </Link>
                        </Typography>
                    )}
                </Fragment>
            )}
        </Box>
    );
};

export default UpgradeBanner;
