import dayjs from 'dayjs';
import { io } from 'socket.io-client';
import { useSelector } from 'react-redux';
import { usePlaidLink } from 'react-plaid-link';
import React, { useEffect, useState } from 'react';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import { LoadingButton } from '@mui/lab';
import SyncIcon from '@mui/icons-material/Sync';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { Animation, Autocomplete, CircleLoading, SnackBarAlert } from 'shared';
import { Box, Grid, Button, useTheme, Typography, useMediaQuery } from '@mui/material';

import {
    setAccessToken,
    getBankAccounts,
    syncTransactions,
    getBankSyncStatus,
    generateLinkToken,
    syncInitialTransactions,
} from 'store/services/bank.Service';
import DivvyModal from './Components/DivvyModal';
import { accountTypes } from './utils/accountTypes';
import ManualBankForm from './Components/ManualBankForm';
import BankCardCarousel from './Components/BanksCarousel';
import { handleErrorAlert } from 'utils/handleErrorAlert';
import { syncBankAccountsMakeStyles } from './style/style';
import { handleSuccessAlert } from 'utils/handleSuccessAlert';
import { useDivvyService } from 'store/services/divvy.service';
import AllBanksUpdateForm from './Components/AllBanksUpdateForm';
import AddBankStartDateModal from './Components/AddBankStartDateModal';
import BankTransactionsStack from './Components/BankTransactionsStack';
import ManageConnectionsModal from './Components/ManageConnectionsModal';

const TOKEN = process.env.REACT_APP_SOCKET_TOKEN;
const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

dayjs.extend(isSameOrAfter);
const todayDate = dayjs(new Date()).format('YYYY-MM-DD');
const yesterdayDate = dayjs(todayDate).subtract(1, 'day');

const ImportBankTransaction = ({}) => {
    const theme = useTheme();
    const classes = syncBankAccountsMakeStyles({});
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { data: companyData } = useSelector((state) => state?.company);
    const { addDivvyCards, addDivvyCardTransactions } = useDivvyService();

    const [alertData, setAlertData] = useState({
        severity: '',
        open: false,
        message: '',
    });
    const [accounts, setAccounts] = useState(null);
    const [bankOption, setBankOption] = useState(null);
    const [linkToken2, setLinkToken2] = useState(null);
    const [selectedBank, setSelectedBank] = useState(null);
    const [syncAllLoading, setSyncAllLoading] = useState(false);
    const [showDivvyModal, setShowDivvyModal] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(yesterdayDate);
    const [isAddDateModalOpen, setAddDateModalOpen] = useState(false);
    const [bankAccountsLoading, setBankAccountsLoading] = useState(false);
    const [isCreateLinkLoading, setIsCreateLinkLoading] = useState(false);
    const [showManualAccountForm, setShowManualAccountForm] = useState(false);
    const [showAllBanksUpdateForm, setShowAllBanksUpdateForm] = useState(false);
    const [showManageConnectionsModal, setShowManageConnectionsModal] = useState(false);

    const handleAddDateModalClose = () => {
        setBankOption(null);
        setAddDateModalOpen(false);
    };

    const handleAddDateModalOpen = () => {
        setAddDateModalOpen(true);
        setSelectedDate(yesterdayDate);
    };

    const handleGetBankSyncStatus = async () => {
        try {
            const response = await getBankSyncStatus(companyData?.id);
            setSyncAllLoading(response.sync_status == 'in-progress');
        } catch (error) {
            handleErrorAlert(setAlertData, 'Something went wrong in syncing, please try again');
            setSyncAllLoading(false);
        }
    };

    const handleBankAccountChange = (type) => {
        setBankOption(type);
        if (type?.value === 'connectBankAccount') {
            handleAddDateModalOpen();
        } else if (type?.value === 'createBankAccount') {
            setShowManualAccountForm(true);
        } else if (type?.value === 'connectDivvy') {
            setShowDivvyModal(true);
        } else if (type?.value === 'manageconnections') {
            setShowManageConnectionsModal(true);
        }
    };

    const onSuccess = async (public_token, metadata) => {
        try {
            setDownloadLoading(true);
            const response = await setAccessToken(
                companyData?.id,
                public_token,
                dayjs(selectedDate).format('YYYY-MM-DD'),
            );
            setSyncAllLoading(true);
            await syncInitialTransactions(null, response.plaid_item_id);
        } catch (error) {
            handleErrorAlert(setAlertData);
        } finally {
            setDownloadLoading(false);
        }
    };

    const { open, ready } = usePlaidLink({
        token: linkToken2,
        onSuccess,
    });

    const createPlaidLinkToken = async (dateIn) => {
        try {
            setIsCreateLinkLoading(true);
            const start_date = dayjs(dateIn).format('YYYY-MM-DD');
            const response = await generateLinkToken(start_date);
            setLinkToken2(response);
        } catch (error) {
            handleErrorAlert(setAlertData);
        } finally {
            setIsCreateLinkLoading(false);
            handleAddDateModalClose(false);
        }
    };

    const sync = async () => {
        try {
            setSyncAllLoading(true);
            await syncTransactions();
        } catch (error) {
            handleErrorAlert(setAlertData);
        }
    };

    const handleDateChange = (newDate) => {
        if (newDate && dayjs(newDate).isValid()) {
            setSelectedDate(dayjs(newDate));
        } else {
            setSelectedDate(null);
        }
    };

    const handleGetAllBanks = async () => {
        try {
            setBankAccountsLoading(true);
            const response = await getBankAccounts(companyData?.id);
            setAccounts(response);
            if (response && response?.length) {
                await handleGetBankSyncStatus();
                setSelectedBank(response[0]);
            }
        } catch (error) {
            handleErrorAlert(setAlertData);
        } finally {
            setBankAccountsLoading(false);
            setDownloadLoading(false);
        }
    };

    const handleManualBankSuccess = (message) => {
        setBankOption(null);
        handleGetAllBanks();
        setShowManualAccountForm(false);
        handleSuccessAlert(setAlertData, message);
    };

    const handleManualBankCloseModal = () => {
        setBankOption(null);
        setShowManualAccountForm(false);
    };

    const handleCloseDivvyModal = () => {
        setBankOption(null);
        setShowDivvyModal(false);
    };

    const handleCloseManageConnectionsModal = () => {
        setBankOption(null);
        setShowManageConnectionsModal(false);
    };

    const handleDivvySuccess = async (message) => {
        try {
            handleCloseDivvyModal();
            handleSuccessAlert(setAlertData, message);
            setDownloadLoading(true);
            const cardsResponse = await addDivvyCards({
                companyId: companyData.id,
            });
            handleSuccessAlert(setAlertData, cardsResponse.message);
            const transactionsResponse = await addDivvyCardTransactions({
                companyId: companyData.id,
            });
            handleSuccessAlert(setAlertData, transactionsResponse.message);
            await handleGetAllBanks();
        } catch (error) {
            handleErrorAlert(setAlertData);
        } finally {
            setDownloadLoading(false);
        }
    };

    const handleManageConnectionsSuccess = () => {
        //this is for manage connection -- need to add code
    };

    const handleBulkAccountUpdateSuccess = async (message) => {
        try {
            handleSuccessAlert(setAlertData, message);
            setShowAllBanksUpdateForm(false);
            handleGetAllBanks();
        } catch (error) {
            handleErrorAlert(setAlertData);
        }
    };

    useEffect(() => {
        if (ready) {
            open();
        }
    }, [ready, open]);

    useEffect(() => {
        if (companyData?.id) {
            handleGetAllBanks();
        }
    }, [companyData]);

    useEffect(() => {
        if (!companyData) return;

        const socket = io(SOCKET_URL, {
            transports: ['websocket'],
            path: '/api/socket/',
            query: {
                token: TOKEN,
            },
        });

        const eventName = `sync-status/${companyData.id}/`;

        socket.off(eventName);
        socket.on(eventName, (data) => {
            if (data.status == 'completed') {
                handleSuccessAlert(setAlertData, 'Sync completed successfully');
                setSyncAllLoading(false);
                handleGetAllBanks();
            }
        });

        socket.on('connect_error', (error) => {
            console.error('Connection error:', error);
        });

        return () => {
            socket.disconnect();
        };
    }, [companyData]);

    return (
        <Grid
            item
            p={2}
            xs={12}
            borderRadius={3}
            sx={{
                bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#212936' : 'white'),
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: isMobile ? 0 : 4,
                    flexDirection: isMobile ? 'column' : 'row',
                    width: '100%',
                }}
            >
                <Typography
                    ml={isMobile ? 1.8 : 0}
                    pb={2}
                    sx={{ fontWeight: '800', fontSize: '20px' }}
                >
                    List of bank accounts
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        gap: isMobile ? '10px' : 0,
                    }}
                >
                    <Autocomplete
                        fullWidth
                        options={accountTypes}
                        labelKey="label"
                        valueKey="value"
                        placeholder="Add a Bank Account"
                        onChange={(e, value) => handleBankAccountChange(value)}
                        value={bankOption}
                        isSearchField={false}
                        customStyle={classes.dropdown}
                    />
                    {accounts && accounts?.length > 0 && (
                        <Button
                            sx={classes.updateButton}
                            onClick={() => setShowAllBanksUpdateForm(true)}
                        >
                            <BorderColorOutlinedIcon fontSize="small" />
                        </Button>
                    )}
                    {accounts && accounts?.length > 0 && (
                        <LoadingButton
                            startIcon={<SyncIcon />}
                            sx={classes.syncAllButton}
                            onClick={sync}
                            loading={syncAllLoading}
                        >
                            Sync All
                        </LoadingButton>
                    )}
                </Box>
            </Box>

            {bankAccountsLoading || !companyData ? (
                <CircleLoading />
            ) : accounts && accounts?.length ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <BankCardCarousel
                        bankCardsData={accounts}
                        selectedBank={selectedBank}
                        setSelectedBank={setSelectedBank}
                    />

                    <BankTransactionsStack selectedBank={selectedBank} />
                </Box>
            ) : (
                <Box display="flex" alignItems="center" marginBottom="10px" justifyContent="center">
                    <Typography>
                        {syncAllLoading
                            ? 'Downloading your bank activities, this typically takes a few minutes.'
                            : 'No bank accounts connected yet.'}
                    </Typography>
                </Box>
            )}

            {downloadLoading && <Animation title={`Downloading your bank activities, `} />}

            <SnackBarAlert alertData={alertData} setAlertData={setAlertData} />

            <AddBankStartDateModal
                open={isAddDateModalOpen}
                selectedDate={selectedDate}
                onClose={handleAddDateModalClose}
                handleDateChange={handleDateChange}
                isCreateLinkLoading={isCreateLinkLoading}
                createPlaidLinkToken={createPlaidLinkToken}
            />

            {showManualAccountForm && (
                <ManualBankForm
                    type="Add"
                    open={showManualAccountForm}
                    handleCloseModal={handleManualBankCloseModal}
                    handleManualBankSuccess={handleManualBankSuccess}
                />
            )}

            {showDivvyModal && (
                <DivvyModal
                    open={showDivvyModal}
                    handleDivvySuccess={handleDivvySuccess}
                    handleCloseModal={handleCloseDivvyModal}
                />
            )}

            {showManageConnectionsModal && (
                <ManageConnectionsModal
                    open={showManageConnectionsModal}
                    handleManageConnectionsSuccess={handleManageConnectionsSuccess}
                    handleCloseModal={handleCloseManageConnectionsModal}
                />
            )}

            {showAllBanksUpdateForm && (
                <AllBanksUpdateForm
                    accounts={accounts}
                    setShowAllBanksUpdateForm={setShowAllBanksUpdateForm}
                    handleBulkAccountUpdateSuccess={handleBulkAccountUpdateSuccess}
                />
            )}
        </Grid>
    );
};

export default ImportBankTransaction;
