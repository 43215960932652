import * as Yup from 'yup';

export const aggInventoryInitialValues = {
    description: '',
    startDate: null,
    endDate: null,
    amount: '',
    purchaseId: '',
};

export const aggInventorySchema = Yup.object({
    startDate: Yup.date().nullable(),
    endDate: Yup.date().nullable(),
    amount: Yup.number().nullable(),
    purchaseId: Yup.string().nullable(),
    description: Yup.string().nullable(),
});
