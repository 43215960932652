import dayjs from 'dayjs';

export const journalFormInitialValues = (initialData) => ({
    date: initialData?.manual_journal_entry?.date
        ? dayjs(initialData.manual_journal_entry.date)
        : null,
    name: initialData?.manual_journal_entry?.name || '',
    invoiceNumber: initialData?.manual_journal_entry?.invoice_number || '',
    currency: {
        value: initialData?.manual_journal_entry?.currency || 'USD',
        label: initialData?.manual_journal_entry?.currency || 'USD',
    },
    description: initialData?.manual_journal_entry?.description || '',
    journalEntries:
        initialData?.journal_entries?.map(({ account, debit, credit }) => ({
            account: account
                ? { id: account.id, label: account.account_name }
                : null,
            debit: debit || 0.0,
            credit: credit || 0.0,
        })) || Array(2).fill({ account: null, debit: '', credit: '' }),
});
