import { Checkbox } from '@mui/material';

export const purchaseTableModalHeaders = (selectedRows, handleCheckboxChange) => {
    return [
        {
            id: 'select',
            label: '',
            sort: false,
            verticalAlign: 'top',
            render: (row) => (
                <Checkbox
                    sx={{ p: 0 }}
                    checked={selectedRows.some((item) => item.id === row.id)}
                    onChange={() => handleCheckboxChange(row)}
                />
            ),
        },
        {
            id: 'purchased_at',
            label: 'Date',
            sort: false,
            verticalAlign: 'top',
        },
        {
            id: 'purchase_id',
            label: 'Purchase',
            sort: false,
            verticalAlign: 'top',
        },
        {
            id: 'description',
            label: 'Description',
            sort: false,
            verticalAlign: 'top',
            render: (row) =>
                `${row.expires_at}, Section: ${row.class_1}, Row: ${row.class_2}, Seats: ${row.seats}`,
        },
        {
            id: 'reference_id',
            label: 'Reference',
            sort: false,
            verticalAlign: 'top',
        },
        {
            id: 'amount',
            label: 'Total',
            sort: false,
            verticalAlign: 'top',
            render: (row) => `$${row.purchase_price}`,
        },
    ];
};

export const purchaseTableHeaders = () => {
    return [
        {
            id: 'purchased_at',
            label: 'Date',
            sort: false,
            verticalAlign: 'top',
        },
        {
            id: 'purchase_id',
            label: 'Purchase',
            sort: false,
            verticalAlign: 'top',
        },
        {
            id: 'description',
            label: 'Description',
            sort: false,
            verticalAlign: 'top',
            render: (row) =>
                `${row.expires_at}, Section: ${row.class_1}, Row: ${row.class_2}, Seats: ${row.seats}`,
        },
        {
            id: 'reference_id',
            label: 'Reference',
            sort: false,
            verticalAlign: 'top',
        },
        {
            id: 'amount',
            label: 'Total',
            sort: false,
            verticalAlign: 'top',
            render: (row) => `$${row.purchase_price}`,
        },
    ];
};
