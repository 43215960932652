import * as React from 'react';
import { TextField } from './TextField';
import { Autocomplete as ComboBox, Paper } from '@mui/material';

const CustomPaper = (props) => {
    return (
        <Paper
            elevation={3}
            sx={{
                boxShadow: (theme) => theme.boxShadow,
                backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#212936' : '#fff'),
                '& li': {
                    color: (theme) => theme.textColor.inputField,
                    fontSize: '16px',
                    fontWeight: 400,
                },
                backgroundImage: 'unset',
                border: (theme) => `1px solid ${theme.borderColor.inputField}`,
            }}
            {...props}
        />
    );
};

export const Autocomplete = (props) => {
    const filterOptions = (options, state) => {
        let newOptions = [];
        options.forEach((element) => {
            if (
                element?.label
                    ?.replace(',', '')
                    ?.toLowerCase()
                    ?.includes(state.inputValue.toLowerCase())
            )
                newOptions.push(element);
        });
        return newOptions;
    };

    const isEqual = (option, value) => option.value === value.value;

    const {
        options,
        labelKey,
        label,
        width,
        placeholder,
        name,
        isSearchField,
        value,
        labelIcon,
        labelIconText,
        customStyle,
        customLabelStyles,
        disabled,
        onChange,
        disableClearable,
        blurOnSelect,
        error = false,
        helperText = '',
        inlineLabel = null,
        showTotal = false,
    } = props;

    return (
        <ComboBox
            disablePortal
            blurOnSelect={blurOnSelect}
            disableClearable={disableClearable}
            options={options}
            value={value}
            getOptionLabel={(option) => {
                return option[labelKey];
            }}
            sx={{
                width,
            }}
            label={label}
            name={name}
            filterOptions={filterOptions}
            PaperComponent={CustomPaper}
            disabled={disabled}
            onChange={(e, value) => onChange(e, value)}
            isOptionEqualToValue={isEqual}
            {...(showTotal && {
                renderOption: (props, option) => (
                    <li
                        {...props}
                        style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
                    >
                        <span>{option[labelKey]}</span>
                        <span style={{ marginLeft: 'auto', fontWeight: 'bold' }}>
                            {option.total}
                        </span>
                    </li>
                ),
            })}
            renderInput={(params) => (
                <TextField
                    label={label}
                    inlineLabel={inlineLabel}
                    labelIcon={labelIcon}
                    placeholder={placeholder}
                    customStyle={customStyle}
                    labelIconText={labelIconText}
                    isSearchField={isSearchField}
                    customLabelStyles={customLabelStyles}
                    error={error}
                    helperText={helperText}
                    {...params}
                />
            )}
        />
    );
};
