export const REPORT_TYPES = {
    BALANCE_SHEET: {
        label: 'Balance Sheet',
        value: 'balance-sheet',
        description:
            'Summarizes assets, liabilities, and equity at a point in time.',
    },
    CASH_ACTIVITY: {
        label: 'Cash Activity',
        value: 'cash-activity',
        description: 'Tracks cash inflows and outflows over a period.',
    },
    PROFIT_LOSS: {
        label: 'Profit and Loss',
        value: 'profit-loss',
        description: 'Shows revenue, expenses, and net profit over a period.',
    },
};
