import { useCallback } from 'react';
import { useAxios } from '../../hooks/useAxios';

export const useCompanyService = () => {
    const { get, post, patch, isLoading } = useAxios();

    const getCurrencyList = useCallback(
        (companyId) => get(`/company/currency_list/${companyId}`),
        [get],
    );

    const getUserAssociatedCompany = useCallback(() => get('/company/user/'), [get]);

    const createCompany = (name, industry, entity, timezone, address) =>
        post('/company/', { name, industry, entity, timezone, address }, '');

    const updateCompany = (id, data) => patch(`/company/${id}/update/`, { ...data }, '');

    const createStripeSession = () => get(`/company/stripe-portal-url/`);

    const getStripeTableSession = () => get(`/company/stripe-customer-session/`);

    const getCompanyEmails = (companyId, amount, date) =>
        get(`/company/get-company-emails/?company_id=${companyId}&amount=${amount}&date=${date}`);

    const getCompanyEmailsWithInventory = (companyId, page, searchText) =>
        get(
            `company/get-company-emails/inventory/?company_id=${companyId}&page=${page}&search=${searchText}`,
        );

    return {
        isLoading,
        createCompany,
        updateCompany,
        getCurrencyList,
        getCompanyEmails,
        createStripeSession,
        getStripeTableSession,
        getUserAssociatedCompany,
        getCompanyEmailsWithInventory,
    };
};
