import React, { useState } from 'react';

import { Grid } from '@mui/material';

import { DataTable, TablePagination } from 'shared';
import { purchaseTableHeaders } from 'components/Inventory/utils/purchaseTableHeaders';

const PurchaseTable = ({ data }) => {
    const [page, setPage] = useState(1);

    return (
        <Grid container>
            <Grid item xs={12}>
                <DataTable
                    data={data.slice((page - 1) * 10, page * 10)}
                    headCells={purchaseTableHeaders()}
                />
            </Grid>
            <Grid item xs={12} marginTop="10px">
                <TablePagination
                    page={page}
                    count={data.length}
                    onChange={(event, pageNumber) => setPage(pageNumber)}
                />
            </Grid>
        </Grid>
    );
};

export default PurchaseTable;
