import { store } from 'store';
import { useSelector } from 'react-redux';
import React, { useState, Fragment, useEffect } from 'react';

import { Grid, Stack, Button } from '@mui/material';
import ExcludedTransactions from './ExcludedTransactions';
import ImportedTransactions from './ImportedTransactions';
import { formatAccountCategoriesData } from 'utils/helpers';
import { bankTransactionsMakeStyles } from '../style/style';
import UnimportedTransactions from './UnimportedTransactions';
import { COA_FETCH_WITH_CATEGORY } from 'store/services/bank.Service';

const tabsData = [{ label: 'Not imported' }, { label: 'Imported' }, { label: 'Excluded' }];

const BankTransactionsStack = ({ selectedBank }) => {
    const classes = bankTransactionsMakeStyles({});
    const { data: companyData } = useSelector((state) => state?.company);

    const [coaCategoryData, setCoaCategoryData] = useState({
        title: 'Select',
        children: [],
        loading: true,
    });
    const [selectedTab, setSelectedTab] = useState('Not imported');

    const getTabButtonStyle = (tabLabel) => ({
        textTransform: 'none',
        fontSize: '1rem',
        fontWeight: selectedTab === tabLabel ? '600' : '500',
        backgroundColor: selectedTab === tabLabel ? '#CEEFDF' : 'transparent',
        borderRadius: selectedTab === tabLabel ? '3px' : 'none',
        color: selectedTab === tabLabel ? '#0A8C4D' : '#6C727F',
    });

    const fetchCompanyAccountsCategoryData = async () => {
        try {
            const { token } = store.getState().auth;
            const response = await fetch(COA_FETCH_WITH_CATEGORY(companyData.id), {
                method: 'GET',
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = await response.json();
            setCoaCategoryData(formatAccountCategoriesData({ ...data, loading: false }));
        } catch (error) {
            setCoaCategoryData({
                title: 'Select',
                children: [],
                loading: false,
            });
        }
    };

    useEffect(() => {
        fetchCompanyAccountsCategoryData();
    }, []);

    return (
        <Fragment>
            <Stack
                display="flex"
                flexDirection={{ xs: 'column', md: 'row' }}
                gap={1}
                width={{ xs: '100%', md: 'fit-content' }}
                sx={classes.tabsStack}
                alignItems="center"
            >
                {tabsData?.map((tab) => (
                    <Grid
                        item
                        key={tab.label}
                        style={{
                            borderRadius: '5px',
                        }}
                    >
                        <Button
                            onClick={() => setSelectedTab(tab.label)}
                            style={getTabButtonStyle(tab.label)}
                            variant={selectedTab === tab.label ? 'contained' : 'text'}
                        >
                            {tab.label}
                        </Button>
                    </Grid>
                ))}
            </Stack>

            {selectedTab === 'Not imported' && (
                <UnimportedTransactions
                    selectedBank={selectedBank}
                    coaCategoryData={coaCategoryData}
                />
            )}

            {selectedTab === 'Imported' && (
                <ImportedTransactions
                    selectedBank={selectedBank}
                    coaCategoryData={coaCategoryData}
                />
            )}

            {selectedTab === 'Excluded' && <ExcludedTransactions />}
        </Fragment>
    );
};

export default BankTransactionsStack;
