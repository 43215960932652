import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    Collapse,
    IconButton,
    CircularProgress,
    Paper,
    useTheme,
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { formatLabel } from '../utils/utils';
import { recieptTableStyles } from '../style/syles';

const RecieptTable = ({ item, level = 0, selectedTab }) => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(true);
    const [children, setChildren] = useState(item.children || []);
    const [loading, setLoading] = useState(false);
    const [isFetched, setIsFetched] = useState(false);

    const classes = recieptTableStyles(level);
    const shouldShowDropdown =
        level === 0 || (level === 1 && children.length > 0);
    useEffect(() => {
        setChildren(item.children || []);
    }, [item.children]);
    const handleClick = async (e) => {
        e.stopPropagation();

        if (level === 2) return;

        setIsOpen(!isOpen);

        if (level === 0 || (children.length === 0 && !isFetched)) {
            setLoading(true);
            setIsFetched(true);
            setLoading(false);
        }
    };

    const handleNavigate = (e, path) => {
        e.stopPropagation();
        navigate(path);
    };

    return (
        <TableContainer component={Paper} sx={classes.tableContainer}>
            <Table>
                <TableBody>
                    <TableRow
                        onClick={shouldShowDropdown ? handleClick : undefined}
                        sx={
                            level === 1 ? classes.categoryRow : classes.tableRow
                        }
                    >
                        <TableCell sx={classes.tableCell}>
                            {shouldShowDropdown ? (
                                <IconButton size="small">
                                    {isOpen ? <ExpandLess /> : <ExpandMore />}
                                </IconButton>
                            ) : null}

                            {level === 2 ? (
                                <Typography
                                    component="span"
                                    onClick={(e) =>
                                        handleNavigate(
                                            e,
                                            `/reports/balance-sheet/journalEntry/${item.account_id}`,
                                        )
                                    }
                                    sx={classes.linkText}
                                >
                                    {formatLabel(item.name)}
                                </Typography>
                            ) : (
                                <Typography
                                    variant="body1"
                                    sx={{ flexGrow: 1 }}
                                >
                                    {formatLabel(item.name)}
                                </Typography>
                            )}
                        </TableCell>

                        <TableCell align="right" sx={classes.totalCell}>
                            {level !== 1 && (
                                <Typography variant="body2" fontWeight="bold">
                                    {item.total !== undefined
                                        ? Number(item.total).toLocaleString(
                                              undefined,
                                              {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                              },
                                          )
                                        : '0.00'}
                                </Typography>
                            )}
                        </TableCell>
                    </TableRow>

                    {loading && (
                        <TableRow>
                            <TableCell
                                colSpan={2}
                                align="center"
                                sx={classes.loaderCell}
                            >
                                <CircularProgress size={24} />
                            </TableCell>
                        </TableRow>
                    )}

                    {level === 1 && children.length === 0 && (
                        <TableRow>
                            <TableCell
                                colSpan={2}
                                align="center"
                                sx={classes.noDataCell}
                            >
                                No data available
                            </TableCell>
                        </TableRow>
                    )}

                    {children.length > 0 && (
                        <TableRow>
                            <TableCell colSpan={2} sx={classes.collapseCell}>
                                <Collapse
                                    in={isOpen}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    {children.map((child) => (
                                        <RecieptTable
                                            key={`${
                                                child.account_id || child.name
                                            }-${level}`}
                                            item={child}
                                            level={level + 1}
                                        />
                                    ))}
                                </Collapse>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default RecieptTable;
