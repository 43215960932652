export const purchaseActivitySearchByFields = [
    {
        name: 'description',
        placeholder: 'Search by description',
        type: 'text',
        gridSize: { xs: 12, md: 12, lg: 6 },
    },
    {
        name: 'amount',
        placeholder: 'Search by amount',
        type: 'number',
        gridSize: { xs: 12, md: 6, lg: 3 },
    },
    {
        name: 'purchaseId',
        placeholder: 'Search by Purchase',
        type: 'text',
        gridSize: { xs: 12, md: 6, lg: 3 },
    },
];

export const purchaseActivityDateFields = [
    {
        name: 'startDate',
        label: 'Start Date',
        placeholder: 'Start Date',
        format: 'YYYY-MM-DD',
        gridSize: { xs: 12, md: 6, lg: 3 },
    },
    {
        name: 'endDate',
        label: 'End Date',
        placeholder: 'End Date',
        format: 'YYYY-MM-DD',
        gridSize: { xs: 12, md: 6, lg: 3 },
    },
];

export const SalesActivitySearchByFields = [
    {
        name: 'description',
        placeholder: 'Search by description',
        type: 'text',
        gridSize: { xs: 12, md: 12, lg: 6 },
    },
    {
        name: 'amountDue',
        placeholder: 'Search by amount',
        type: 'number',
        gridSize: { xs: 12, md: 6, lg: 3 },
    },
    {
        name: 'invoiceId',
        placeholder: 'Search by Invoices',
        type: 'text',
        gridSize: { xs: 12, md: 6, lg: 3 },
    },
];

export const salesActivityDateFields = [
    {
        name: 'saleStartDate',
        label: 'Start Date',
        placeholder: 'Start Date',
        format: 'YYYY-MM-DD',
        gridSize: { xs: 12, md: 6, lg: 3 },
    },
    {
        name: 'saleEndDate',
        label: 'End Date',
        placeholder: 'End Date',
        format: 'YYYY-MM-DD',
        gridSize: { xs: 12, md: 6, lg: 3 },
    },
];
