export const purchaseHeaders = [
    'Purchase id *',
    'Reference id',
    'Description (Event Name) *',
    'Description 2 (Event Venue)',
    'Class 1 (Section, category) *',
    'Class 2 (Row, sub-category) *',
    'Class 3 (Seat, sub-detail) *',
    'Purchase Price *',
    'Expiration date (Event date) *',
    'Purchase date *',
    'Purchaser email',
];

export const requiredPurchaseHeaders = purchaseHeaders.filter((header) => header.includes('*'));

export const saleHeaders = [
    'Invoice id *',
    'Reference id',
    'Purchase id',
    'Sales date *',
    'Purchase date',
    'Expiration date (Event date) *',
    'Description (Event Name) *',
    'Description 2 (Event Venue)',
    'Class 1 (Section, category) *',
    'Class 2 (Row, sub-category) *',
    'Class 3 (Seat, sub-detail) *',
    'Amount due *',
    'Sale price',
    'Comission',
    'Purchaser email',
];

export const requiredSaleHeaders = saleHeaders.filter((header) => header.includes('*'));
