import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
    Grid,
    Link,
    Typography,
    IconButton,
    InputAdornment,
} from '@mui/material';
import Image from 'mui-image';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

import {
    SAVE_TOKEN,
    LOG_IN_ERROR,
    LOG_IN_PENDING,
    LOG_IN_SUCCESS,
} from '../store/actions';
import { TextField, Button } from '../shared';
import useNotification from '../hooks/useNotification';
import { useThemeToggle } from '../hooks/useThemeToggle';
import { useCustomMediaQuery } from '../hooks/useMediaQuery';
import CirclerImage from '../assets/icons/CirclerLeftImage.svg';
import { useAuthService } from '../store/services/auth.service';
import { ReactComponent as DarkCirclerLogo } from '../assets/icons/DarkLogo.svg';
import { ReactComponent as CirclerLogo } from '../assets/icons/circler-logo.svg';

const styles = {
    container: {
        height: '100vh',
        overflow: 'hidden',
    },
    mainHeading: {
        fontSize: '32px',
        fontWeight: '700',
        color: (theme) => theme.textColor.dark,
        lineHeight: '38px',
    },
    subHeading: {
        fontSize: '18px',
        fontWeight: '500',
        color: '#6C727F',
        lineHeight: '24px',
        mt: 0.5,
    },
    forgotPassword: {
        color: (theme) =>
            theme.palette.mode === 'dark' ? '#3381E2' : '#0061DB',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: 500,
    },
    loginBtnStyle: {
        borderRadius: '8px',
        fontSize: '18px',
        fontWeight: 500,
    },
    otherSignInOptionsContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    otherSignInOptionsText: {
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '24px',
        color: (theme) =>
            theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F',
    },
    signInOption: {
        color: '#FFF',
        fontSize: '16px',
        fontWeight: '500',
    },
    googleSignInOption: {
        color: (theme) => theme.textColor.dark,
        fontSize: '16px',
        fontWeight: '500',
    },
    otherSignInOption: {
        color: (theme) => theme.textColor.dark,
        fontSize: '16px',
        fontWeight: '400',
    },
    link: {
        textDecoration: 'none',
        color: (theme) =>
            theme.palette.mode === 'dark' ? '#3381E2' : '#0061DB',
        cursor: 'pointer',
        fontWeight: 600,
        fontSize: '16px',
    },
};

export const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [sendNotification] = useNotification();
    const { login, isLoading } = useAuthService();
    const { themeMode } = useThemeToggle();
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [password, setPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const isScreenLowerThan1024 = useCustomMediaQuery('(max-width: 1025px)');

    const validationSchema = Yup.object({
        email: Yup.string()
            .email('Please enter a valid email')
            .required('Email is required'),
        password: Yup.string().required('Password is required'),
    });

    const initialValues = {
        email: '',
        password: '',
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        dispatch(LOG_IN_PENDING());
        login(values.email.toLowerCase(), values.password)
            .then((res) => {
                if (res.is_active) {
                    dispatch(LOG_IN_SUCCESS());
                    dispatch(SAVE_TOKEN({ token: res.token }));
                    res.is_onboarded
                        ? navigate('/dashboard')
                        : navigate('/onboarding');
                } else {
                    sendNotification({
                        msg: 'Your account is not active yet.',
                        variant: 'error',
                    });
                }
            })
            .catch((err) => {
                dispatch(LOG_IN_ERROR({ error: err.errorMessage }));
                setError(err.errorMessage);
            });
        setSubmitting(false);
    };

    const handleLogin = async () => {
        dispatch(LOG_IN_PENDING());
        login(email.toLowerCase(), password)
            .then((res) => {
                if (res.is_active) {
                    dispatch(LOG_IN_SUCCESS());
                    dispatch(SAVE_TOKEN({ token: res.token }));
                    res.is_onboarded
                        ? navigate('/dashboard')
                        : navigate('/onboarding');
                } else {
                    sendNotification({
                        msg: 'Your account is not active yet.',
                        variant: 'error',
                    });
                }
            })
            .catch((err) => {
                dispatch(LOG_IN_ERROR({ error: err.errorMessage }));
                setError(err.errorMessage);
            });
    };

    return (
        <Grid
            flex={{ xs: '1', sm: '2', md: '3' }}
            container
            sx={styles.container}
        >
            <Grid
                item
                position="relative"
                xs={12}
                md={6}
                lg={6}
                maxWidth={'700px'}
                display={{ xs: 'none', md: 'block', lg: 'block' }}
                overflow={{ xs: 'scroll', md: 'hidden', lg: 'hidden' }}
            >
                <Image alt="Left Content" src={CirclerImage} />
            </Grid>
            <Grid
                container
                item
                xs={12}
                md={6}
                lg={6}
                flex="1"
                alignContent="flex-start"
                justifyContent={'center'}
                px={{ xs: 2, sm: 4, md: 8 }}
                pt="50px"
                sx={{
                    overflow: 'auto',
                    backgroundColor: (theme) => theme.backgroundColor.primary,
                }}
            >
                <Grid
                    item
                    container
                    direction="column"
                    maxWidth={'700px'}
                    padding={isScreenLowerThan1024 ? '24px 0' : '0 10%'}
                    gap={3}
                    textAlign={'start'}
                >
                    <Grid item>
                        {themeMode === 'dark' ? (
                            <DarkCirclerLogo
                                width="180.995px"
                                height="45.22px"
                            />
                        ) : (
                            <CirclerLogo width="180.995px" height="45.22px" />
                        )}
                    </Grid>
                    <Grid item>
                        <Typography sx={styles.mainHeading}>
                            Sign in to your account
                        </Typography>
                        <Typography sx={styles.subHeading}>
                            Please enter your details.
                        </Typography>
                    </Grid>

                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            isSubmitting,
                            submitForm,
                        }) => (
                            <Form>
                                <Grid container spacing={2} direction="column">
                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            name="email"
                                            placeholder="Email *"
                                            type="text"
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.email &&
                                                Boolean(errors.email)
                                            }
                                            helperText={
                                                touched.email && errors.email
                                            }
                                        />
                                    </Grid>

                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            name="password"
                                            label="Password"
                                            placeholder="Password *"
                                            value={values.password}
                                            type={
                                                passwordVisible
                                                    ? 'text'
                                                    : 'password'
                                            }
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.password &&
                                                Boolean(errors.password)
                                            }
                                            helperText={
                                                touched.password &&
                                                errors.password
                                            }
                                            InputProps={{
                                                onKeyDown: (e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault();
                                                        submitForm();
                                                    }
                                                },
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() =>
                                                                setPasswordVisible(
                                                                    (prev) =>
                                                                        !prev,
                                                                )
                                                            }
                                                            edge="end"
                                                        >
                                                            {passwordVisible ? (
                                                                <VisibilityOutlinedIcon fontSize="small" />
                                                            ) : (
                                                                <VisibilityOffOutlinedIcon fontSize="small" />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>

                                    <Grid
                                        container
                                        item
                                        justifyContent="space-between"
                                    >
                                        <Grid item></Grid>
                                        <Grid
                                            item
                                            sx={{
                                                display: 'flex',
                                                alignContent: 'center',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <Typography
                                                sx={styles.forgotPassword}
                                            >
                                                <Link
                                                    href="/forgotpassword"
                                                    sx={styles.link}
                                                >
                                                    Forgot Password?
                                                </Link>
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    {error && (
                                        <Grid item>
                                            <Typography
                                                sx={{
                                                    color: 'red',
                                                    fontSize: 16,
                                                    fontWeight: 500,
                                                }}
                                            >
                                                {error}
                                            </Typography>
                                        </Grid>
                                    )}

                                    <Grid item>
                                        <Button
                                            text={
                                                <Typography
                                                    sx={styles.signInOption}
                                                >
                                                    Sign In
                                                </Typography>
                                            }
                                            fullWidth
                                            loading={isLoading}
                                            customStyles={styles.loginBtnStyle}
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            disabled={isSubmitting}
                                        />
                                    </Grid>

                                    <Grid item textAlign={'center'}>
                                        <Typography
                                            sx={styles.otherSignInOption}
                                        >
                                            Don't have an account?&nbsp;
                                            <Link
                                                href="/signup"
                                                sx={styles.link}
                                            >
                                                Get Started
                                            </Link>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </Grid>
    );
};
