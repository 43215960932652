import React, { useState, Fragment } from 'react';

import { Box, Grid, Stack, Button } from '@mui/material';

import Matched from './Matched';
import Excluded from './Excluded';
import ForReview from './ForReview';
import PaymentPlan from './PaymentPlan';
import UploadFileTab from './UploadFile';
import SalesActivity from './SalesActivity';
import ExclusionRules from './ExclusionRules';
import PurchaseActivity from './PurchaseActivity';
import { inventoryReconciliationStyles } from '../../style/style';
import { inventoryReconciliationTabsData } from '../../utils/inventoryReconciliationTabsData';

const InventoryReconciliation = () => {
    const classes = inventoryReconciliationStyles({});

    const [activeTab, setActiveTab] = useState('forReview');

    return (
        <Fragment>
            <Box padding={1} sx={classes.menuWrapper}>
                <Stack
                    display="flex"
                    flexDirection={{ xs: 'column', md: 'row' }}
                    gap={1}
                    width={{ xs: '100%', md: 'fit-content' }}
                    style={classes.stack}
                    alignItems="center"
                >
                    {inventoryReconciliationTabsData?.map((tab) => (
                        <Grid item key={tab.label} borderRadius={'5px'}>
                            <Button
                                onClick={() => setActiveTab(tab.value)}
                                sx={
                                    tab.value == activeTab ? classes.activeTab : classes.inactiveTab
                                }
                                variant={activeTab === tab.value ? 'contained' : 'text'}
                            >
                                {tab.label}
                            </Button>
                        </Grid>
                    ))}
                </Stack>
            </Box>

            {activeTab === 'fileUpload' && (
                <Box sx={classes.tabContentWrapper}>
                    <UploadFileTab />
                </Box>
            )}

            {activeTab === 'forReview' && (
                <Box sx={classes.tabContentWrapper}>
                    <ForReview />
                </Box>
            )}

            {activeTab === 'matched' && (
                <Box sx={classes.tabContentWrapper}>
                    <Matched />
                </Box>
            )}

            {activeTab === 'exclusionRules' && (
                <Box sx={classes.tabContentWrapper}>
                    <ExclusionRules />
                </Box>
            )}

            {activeTab === 'excluded' && (
                <Box sx={classes.tabContentWrapper}>
                    <Excluded />
                </Box>
            )}

            {activeTab === 'purchaseActivity' && (
                <Box sx={classes.tabContentWrapper}>
                    <PurchaseActivity />
                </Box>
            )}

            {activeTab === 'salesActivity' && (
                <Box sx={classes.tabContentWrapper}>
                    <SalesActivity />
                </Box>
            )}

            {activeTab === 'paymentPlan' && (
                <Box sx={classes.tabContentWrapper}>
                    <PaymentPlan />
                </Box>
            )}
        </Fragment>
    );
};

export default InventoryReconciliation;
