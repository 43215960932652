import * as Yup from 'yup';

export const bankFormInitialValues = (initialValues) => {
    return {
        accountName: initialValues?.name || '',
        accountNumber:
            initialValues?.customer_chart_of_account?.account_number || '',
        isSubAccount: initialValues?.customer_chart_of_account?.parent_account
            ? true
            : false,
        parentAccount:
            initialValues?.customer_chart_of_account?.parent_account || null,
        description:
            initialValues?.customer_chart_of_account?.description || '',
        connectToQB: false,
        quickBooksAccount: null,
    };
};

export const bankFormSchema = Yup.object({
    accountName: Yup.string().required(),
    accountNumber: Yup.string().required(),
    isSubAccount: Yup.bool().nullable().optional(),
    parentAccount: Yup.string().nullable().optional(),
    description: Yup.string().optional().nullable(),
    description: Yup.string().optional().nullable(),
    quickBooksAccount: Yup.string()
        .nullable()
        .when('connectToQB', {
            is: true,
            then: (schema) => schema.required('QuickBooks account is required'),
            otherwise: (schema) => schema.nullable(),
        }),
});
