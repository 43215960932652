import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import React, { Fragment } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Divider, Grid } from '@mui/material';

import { TextField, Autocomplete } from 'shared';
import { bankOptions } from '../utils/qbBankOptions';
import { yesNoOptions } from '../utils/yesNoOptions';
import { useCustomMediaQuery } from 'hooks/useMediaQuery';
import { allBanksUpdateFormStyles } from '../style/style';
import { qbAccountsInitialValues, qbAccountsSchema } from '../utils/qbBankAccountsSchema';

const QbAccountsForm = ({ coas, accounts, loading, handleBlukAccountsUpdate }) => {
    const isMobileScreen = useCustomMediaQuery('(max-width:600px)');
    const classes = allBanksUpdateFormStyles({ isMobileScreen });

    const filterChangedAccounts = (updatedAccounts) => {
        return updatedAccounts.filter((updatedAccount, index) => {
            const initialAccount = qbAccountsInitialValues(accounts).accounts[index];
            return (
                updatedAccount.name !== initialAccount.name ||
                updatedAccount.account_number !== initialAccount.account_number ||
                updatedAccount.is_coa_selected !== initialAccount.is_coa_selected ||
                updatedAccount.selected_coa !== initialAccount.selected_coa ||
                updatedAccount.bank_type !== initialAccount.bank_type
            );
        });
    };

    const handleFormSubmit = (values) => {
        const modifiedAccounts = filterChangedAccounts(values.accounts);
        handleBlukAccountsUpdate(modifiedAccounts);
    };

    return (
        <Formik
            onSubmit={handleFormSubmit}
            validationSchema={qbAccountsSchema}
            initialValues={qbAccountsInitialValues(accounts)}
        >
            {({ values, setFieldValue, errors, touched }) => (
                <Form>
                    <Box sx={classes.formWrapper}>
                        <Grid container spacing={2}>
                            {values.accounts.map((account, index) => (
                                <Fragment key={account.id}>
                                    <Grid item xs={6} md={2}>
                                        <TextField
                                            fullWidth
                                            name={`accounts[${index}].account_number`}
                                            placeholder="Number"
                                            inlineLabel="Number"
                                            value={values.accounts[index].account_number}
                                            onChange={(e) =>
                                                setFieldValue(
                                                    `accounts[${index}].account_number`,
                                                    e.target.value,
                                                )
                                            }
                                            error={
                                                touched.accounts?.[index]?.account_number &&
                                                !!errors.accounts?.[index]?.account_number
                                            }
                                            helperText={
                                                touched.accounts?.[index]?.account_number &&
                                                errors.accounts?.[index]?.account_number
                                            }
                                        />
                                    </Grid>

                                    <Grid item xs={6} md={3}>
                                        <TextField
                                            fullWidth
                                            name={`accounts[${index}].name`}
                                            placeholder="Name"
                                            inlineLabel="Name"
                                            value={values.accounts[index].name}
                                            onChange={(e) =>
                                                setFieldValue(
                                                    `accounts[${index}].name`,
                                                    e.target.value,
                                                )
                                            }
                                            error={
                                                touched.accounts?.[index]?.name &&
                                                !!errors.accounts?.[index]?.name
                                            }
                                            helperText={
                                                touched.accounts?.[index]?.name &&
                                                errors.accounts?.[index]?.name
                                            }
                                        />
                                    </Grid>

                                    <Grid item xs={4} md={2}>
                                        <Autocomplete
                                            options={yesNoOptions}
                                            labelKey="label"
                                            valueKey="value"
                                            value={yesNoOptions.find(
                                                (option) =>
                                                    option.value ===
                                                    values.accounts[index].is_coa_selected,
                                            )}
                                            onChange={(event, value) => {
                                                setFieldValue(
                                                    `accounts[${index}].is_coa_selected`,
                                                    value ? value?.value : null,
                                                );
                                                setFieldValue(
                                                    `accounts[${index}].selected_coa`,
                                                    null,
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Connect to QuickBooks"
                                                />
                                            )}
                                            inlineLabel="Connect to QuickBooks"
                                            placeholder="Connect to QuickBooks"
                                            disableClearable
                                            error={
                                                touched.accounts?.[index]?.is_coa_selected &&
                                                !!errors.accounts?.[index]?.is_coa_selected
                                            }
                                            helperText={
                                                touched.accounts?.[index]?.is_coa_selected &&
                                                errors.accounts?.[index]?.is_coa_selected
                                            }
                                        />
                                    </Grid>

                                    {values.accounts[index].is_coa_selected == 'yes' ? (
                                        <Grid item xs={8} md={5}>
                                            <Autocomplete
                                                options={coas}
                                                labelKey="label"
                                                valueKey="id"
                                                value={
                                                    account.selected_coa
                                                        ? coas.find(
                                                              (coa) =>
                                                                  coa.id === account.selected_coa,
                                                          )
                                                        : null
                                                }
                                                onChange={(e, value) => {
                                                    setFieldValue(
                                                        `accounts[${index}].selected_coa`,
                                                        value ? value?.id : null,
                                                    );
                                                }}
                                                inlineLabel="Select QuickBook Account"
                                                placeholder="Select QuickBook Account"
                                                disableClearable
                                                error={
                                                    touched.accounts?.[index]?.selected_coa &&
                                                    !!errors.accounts?.[index]?.selected_coa
                                                }
                                                helperText={
                                                    touched.accounts?.[index]?.selected_coa &&
                                                    errors.accounts?.[index]?.selected_coa
                                                }
                                            />
                                        </Grid>
                                    ) : (
                                        <Grid item xs={8} md={5}>
                                            <Autocomplete
                                                options={bankOptions}
                                                labelKey="label"
                                                valueKey="value"
                                                value={
                                                    bankOptions.find(
                                                        (option) =>
                                                            option.value ===
                                                            values.accounts[index].bank_type,
                                                    ) || null
                                                }
                                                onChange={(event, value) => {
                                                    setFieldValue(
                                                        `accounts[${index}].bank_type`,
                                                        value ? value.value : null,
                                                    );
                                                }}
                                                inlineLabel="Select Type"
                                                disableClearable
                                                placeholder="Select Type"
                                                error={
                                                    touched.accounts?.[index]?.bank_type &&
                                                    !!errors.accounts?.[index]?.bank_type
                                                }
                                                helperText={
                                                    touched.accounts?.[index]?.bank_type &&
                                                    errors.accounts?.[index]?.bank_type
                                                }
                                            />
                                        </Grid>
                                    )}

                                    {index !== accounts?.length - 1 && (
                                        <Grid item xs={12}>
                                            <Divider sx={classes.dividerBackground} />
                                        </Grid>
                                    )}
                                </Fragment>
                            ))}
                        </Grid>
                    </Box>

                    <Box
                        width="100%"
                        display="flex"
                        justifyContent="flex-end"
                        marginTop={isMobileScreen ? 1 : 3}
                    >
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            loading={loading}
                        >
                            Update
                        </LoadingButton>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

export default QbAccountsForm;
