import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';

import AJAX from 'services/api.service';
import { DataTable } from 'shared';
import BulkEditInventory from './BulkEditInventory';
import { handleErrorAlert } from 'utils/handleErrorAlert';
import { handleSuccessAlert } from 'utils/handleSuccessAlert';
import { inventoryListHeaders } from '../../../utils/inventoryListHeaders';

const InventoryList = ({ data, company_email_id, refreshData, setAlertData }) => {
    const [bulkEditOpen, setBulkEditOpen] = useState(false);
    const [editedRows, setEditedRows] = useState([]);

    const { id: company_id } = useSelector((state) => state.company.data);

    const handleBulkEditOpen = () => {
        setEditedRows([...data]);
        setBulkEditOpen(true);
    };

    const handleBulkEditClose = () => {
        refreshData();
        setBulkEditOpen(false);
    };

    const handleBulkEditSubmit = async (updatedRows) => {
        handleSuccessAlert(setAlertData, 'Processing');
        const newRows = updatedRows.filter(
            (row) => typeof row.id === 'string' && row.id.startsWith('temp-'),
        );
        const existingRows = updatedRows.filter((row) => typeof row.id !== 'string');

        try {
            let createdRows = [];
            if (newRows.length > 0) {
                const createPayload = {
                    creates: newRows.map(({ id, ...rest }) => ({
                        ...rest,
                        company_id,
                        company_email_id,
                    })),
                };

                const createResponse = await AJAX.request(
                    'POST',
                    'api/inventory/create_inventory/',
                    createPayload,
                );

                if (createResponse?.error_code === 0) {
                    handleSuccessAlert(setAlertData, 'Data Successfully Added');
                }
                if (createResponse?.error_code !== 0) {
                    handleErrorAlert(
                        setAlertData,
                        'Error! Cannot fulfill your request. Please try again later',
                    );
                    throw new Error(`Create API Error: ${createResponse.message}`);
                }

                createdRows = createResponse?.data?.created || [];
            }

            let updatedRowsFromApi = [];
            if (existingRows.length > 0) {
                const updatePayload = {
                    updates: existingRows.map((row) => ({
                        ...row,
                        company_id,
                        company_email_id,
                    })),
                };

                const updateResponse = await AJAX.request(
                    'PUT',
                    'api/inventory/update/',
                    updatePayload,
                );

                if (updateResponse?.error_code === 0) {
                    handleSuccessAlert(setAlertData, 'Data Successfully Updated');
                }

                if (updateResponse?.error_code !== 0) {
                    handleErrorAlert(
                        setAlertData,
                        'Error! Cannot fulfill your request. Please try again later',
                    );

                    throw new Error(`Update API Error: ${updateResponse.message}`);
                }

                updatedRowsFromApi = updateResponse?.data?.updated || [];
            }

            const finalRows = [
                ...(Array.isArray(updatedRowsFromApi) ? updatedRowsFromApi : []),
                ...(Array.isArray(createdRows) ? createdRows : []),
            ];

            refreshData();
            setBulkEditOpen(false);
        } catch (error) {
            console.error('Error saving inventory:', error);
            handleErrorAlert(setAlertData, 'Error saving inventory');
        }
    };

    return (
        <>
            <Grid item xs={12}>
                <DataTable
                    headCells={inventoryListHeaders}
                    bulkEdit={handleBulkEditOpen}
                    data={data}
                />
            </Grid>

            <BulkEditInventory
                open={bulkEditOpen}
                data={editedRows}
                onClose={handleBulkEditClose}
                onSubmit={handleBulkEditSubmit}
            />
        </>
    );
};

export default InventoryList;
