import * as Yup from 'yup';

export const StepOneOnboardingInitialValues = (data) => {
    return {
        first_name: data?.firstName || '',
        last_name: data?.lastName || '',
        phone_number: data?.phoneNumber || '',
    };
};

export const StepOneOnboardingSchema = Yup.object({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    phone_number: Yup.string()
        .required('Phone Number is required')
        .matches(/^\+?[\d\s()-]{8,20}$/, 'Phone number must be valid and 8-15 digits long'),
});
