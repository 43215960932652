import React from 'react';
import { omit } from 'lodash';

import {
    Table,
    TableRow,
    Checkbox,
    TableHead,
    TableCell,
    TableBody,
    IconButton,
    TableSortLabel,
    TableContainer,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';

import { Typography } from './Typography';
import TableSkeleton from './table-skeleton';

const styles = {
    tableContainer: {
        width: '100%',
        background: (theme) => theme.backgroundColor.primary,
        overflow: 'auto',
        maxHeight: '800px',
        padding: '4px 24px 0px 24px',
        borderRadius: '12px',
        '& .MuiTableCell-root': {
            borderColor: (theme) => (theme.palette.mode === 'dark' ? '#394150' : '#E5E6EB'),
            fontSize: '12px',
        },
        '&::-webkit-scrollbar': {
            width: '0.4rem',
            height: '0.4rem',
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            margin: 1,
            borderRadius: '8px',
            backgroundColor: (theme) => theme.primary.dark,
        },
    },
    headerTableCell: {
        p: '12px',
        background: (theme) => theme.backgroundColor.primary,
        borderBottom: (theme) =>
            theme.palette.mode === 'dark' ? '1px solid #394150' : '1px solid #E5E6EB',
        fontWeight: 500,
        fontSize: 14,
        color: '#6C727F',
        '&:last-child': {
            borderRadius: '0px 0px 0px 0px',
        },
    },
    nonSelectableHeaderTableCell: {
        py: 0,
        background: (theme) => theme.backgroundColor.primary,
        fontWeight: 600,
        padding: '10px',
        borderRadius: '0px 0px 0px 0px',
    },
    headerDividerCell: {
        padding: '6px 0',
        backgroundColor: 'transparent',
        borderBottom: 0,
    },
    headerCellLabel: {
        whiteSpace: 'nowrap',
    },
    headerCheckbox: {
        paddingLeft: 2,
        color: (theme) => theme.palette.primary.main,
    },
    checkbox: {
        // color: (theme) => theme.palette.primary.main,
    },
    tableRow: {
        cursor: 'pointer',
    },
};

export const DataTable = ({
    headCells = [],
    data = [],
    sorting = {},
    sortOrder = 'asc',
    sortHandler = () => {},
    selected = {},
    setSelected = () => {},
    setAnchorEl = () => {},
    isSelectable = false,
    canEdit,
    handleRowClick = () => {},
    isRowClickable = false,
    visibleColumns = headCells.map((headCell) => headCell.id),
    actionIconPlacement,
    isLoading = false,
    bulkEdit = false,
    showHeaderCheckBox = true,
}) => {
    const handleSelectAllRows = (event) => {
        if (event.target.checked) {
            const selectedRows = {};
            data.forEach((cellData) => {
                selectedRows[cellData.id] = true;
            });
            setSelected(selectedRows);
        } else setSelected({});
    };

    const handleSelectRow = (event, id) => {
        event.stopPropagation();
        if (event.target.checked) {
            setSelected({ ...selected, [id]: true });
        } else {
            setSelected(omit(selected, id));
        }
    };

    const handleSort = (columnId) => {
        const isAsc = sorting[columnId] && sortOrder === 'asc';
        const newSortOrder = isAsc ? 'desc' : 'asc';
        sortHandler(columnId, newSortOrder);
    };

    return (
        <TableContainer sx={styles.tableContainer}>
            <Table>
                <TableHead>
                    <TableRow>
                        {isSelectable && (
                            <TableCell
                                padding="checkbox"
                                sx={{
                                    ...styles.headerTableCell,
                                    borderRadius: '8px 0 0 8px',
                                }}
                            >
                                {showHeaderCheckBox && (
                                    <Checkbox
                                        color="primary"
                                        indeterminate={
                                            Object.keys(selected).length > 0 &&
                                            data.length > Object.keys(selected).length
                                        }
                                        checked={data.length === Object.keys(selected).length}
                                        onChange={handleSelectAllRows}
                                        sx={styles.headerCheckbox}
                                    />
                                )}
                            </TableCell>
                        )}
                        {headCells.map(
                            (headCell, index) =>
                                visibleColumns.includes(headCell.id) && (
                                    <TableCell
                                        key={headCell.id}
                                        sx={
                                            !isSelectable && index === 0
                                                ? {
                                                      ...styles.nonSelectableHeaderTableCell,
                                                      maxWidth: headCell.maxWidth || 'unset',
                                                      backgroundColor: headCell?.changed
                                                          ? '#0061DB'
                                                          : '',
                                                  }
                                                : !canEdit && index === headCells.length - 1
                                                ? {
                                                      ...styles.headerTableCell,
                                                      textAlign: headCell.textAlign,
                                                      borderRadius: '0 8px 8px 0',
                                                      backgroundColor: headCell?.changed
                                                          ? '#0061DB'
                                                          : '',
                                                  }
                                                : {
                                                      ...styles.headerTableCell,
                                                      maxWidth: headCell.maxWidth || 'unset',
                                                      textAlign: headCell.textAlign,
                                                      backgroundColor: headCell?.changed
                                                          ? '#0061DB'
                                                          : '',
                                                  }
                                        }
                                    >
                                        <TableSortLabel
                                            active={sorting[headCell.id]}
                                            direction={sorting[headCell.id] ? sortOrder : 'asc'}
                                            onClick={() => handleSort(headCell.id)}
                                            sx={styles.headerCellLabel}
                                            hideSortIcon={!headCell.sort}
                                            disabled={!headCell.sort || isLoading}
                                        >
                                            <Typography
                                                text={headCell.label}
                                                customStyles={{
                                                    fontSize: 14,
                                                    fontWeight: 500,
                                                    color: (theme) =>
                                                        headCell?.changed
                                                            ? 'white'
                                                            : theme.palette.mode === 'dark'
                                                            ? '#6C727F'
                                                            : '#4D5461',
                                                }}
                                            />
                                        </TableSortLabel>
                                    </TableCell>
                                ),
                        )}
                        {canEdit && (
                            <TableCell
                                sx={{
                                    ...styles.headerTableCell,
                                    borderRadius: '0 8px 8px 0',
                                }}
                            >
                                Action
                            </TableCell>
                        )}

                        {bulkEdit && (
                            <TableCell
                                sx={{
                                    ...styles.headerTableCell,
                                    borderRadius: '0 8px 8px 0',
                                    textAlign: 'center',
                                }}
                            >
                                <IconButton onClick={bulkEdit}>
                                    <EditIcon />
                                </IconButton>
                            </TableCell>
                        )}
                    </TableRow>
                    <TableRow>
                        <TableCell sx={styles.headerDividerCell} />
                        {headCells.map((cell) => (
                            <TableCell
                                key={`divider-cell-${cell.id}`}
                                sx={styles.headerDividerCell}
                            />
                        ))}
                        <TableCell sx={styles.headerDividerCell} />
                    </TableRow>
                </TableHead>
                {isLoading ? (
                    <TableBody>
                        {[...Array(8)].map((i, index) => (
                            <TableSkeleton key={index} numberOfColumns={8} />
                        ))}
                    </TableBody>
                ) : (
                    <TableBody>
                        {data.length == 0 ? (
                            <TableRow>
                                <TableCell colSpan={headCells.length} align="center">
                                    <Typography
                                        variant="body1"
                                        color="textSecondary"
                                        text="No data available"
                                    />
                                </TableCell>
                            </TableRow>
                        ) : (
                            data.map((row, index) => (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    key={`table-row-${row.id || index}`}
                                    sx={isRowClickable ? styles.tableRow : {}}
                                    selected={!!selected?.[row.id]}
                                    onClick={() => isRowClickable && handleRowClick(index)}
                                >
                                    {isSelectable && (
                                        <TableCell padding="none" sx={{ padding: '10px' }}>
                                            <Checkbox
                                                sx={styles.checkbox}
                                                checked={!!selected[row.id]}
                                                onClick={(event) => handleSelectRow(event, row.id)}
                                            />
                                        </TableCell>
                                    )}

                                    {headCells.map((headCell) => (
                                        <TableCell
                                            key={`table-cell-${headCell.id}`}
                                            sx={{
                                                maxWidth: headCell.maxWidth || 'unset',
                                                textAlign: headCell.textAlign,
                                                verticalAlign: headCell.verticalAlign,
                                            }}
                                        >
                                            {headCell.render
                                                ? headCell.render(row)
                                                : row[headCell.id] || '-'}
                                        </TableCell>
                                    ))}

                                    {canEdit && (
                                        <TableCell padding="checkbox">
                                            <IconButton
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    setAnchorEl(event, index);
                                                }}
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                )}
            </Table>
        </TableContainer>
    );
};
