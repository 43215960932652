import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';

import { CircleLoading, Modal } from 'shared';
import LinkedAccountsAccordion from './LinkedAccountsAccordion';
import { getAllBankAccounts } from 'store/services/bank.Service';

const ManageConnectionsModal = ({ open, handleCloseModal, handleManageConnectionsSuccess }) => {
    const [banksData, setBanksData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const { id: companyId } = useSelector((state) => state.company.data);

    useEffect(() => {
        if (open && companyId) {
            fetchBankAccounts();
        }
    }, [open, companyId]);

    const fetchBankAccounts = async () => {
        setLoading(true);
        try {
            const response = await getAllBankAccounts({ companyId });
            if (Array.isArray(response)) {
                setBanksData(transformBankAccounts(response));
                setError(null);
            }
        } catch (err) {
            setError('Failed to fetch bank accounts. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const transformBankAccounts = (bankAccounts) => {
        return bankAccounts.map((bank) => ({
            bankName: bank.bank_accounts[0]?.official_name || 'Unknown Bank',
            addedOn: new Date().toLocaleString(),
            accounts: bank.bank_accounts.map((acc) => ({
                name: `${acc.name})`,
                balance: parseFloat(acc.current_balance) || 0,
            })),
        }));
    };

    return (
        <Modal
            fullWidth
            open={open}
            customWidth="60%"
            title="Manage Connections"
            handleClose={handleCloseModal}
            actionBtnTitle="Update"
        >
            <Typography variant="h5" fontWeight="bold" gutterBottom>
                Your linked accounts
            </Typography>
            <Typography variant="body2" gutterBottom>
                One place to manage your linked accounts and the data we get from them.
            </Typography>

            {loading ? (
                <CircleLoading />
            ) : error ? (
                <Typography variant="body1" color="error">
                    {error}
                </Typography>
            ) : banksData.length === 0 ? (
                <Typography variant="body1">No linked accounts found</Typography>
            ) : (
                <Box>
                    {banksData.map((bank, index) => (
                        <Box key={index} mb={1.5}>
                            <LinkedAccountsAccordion data={bank} />
                        </Box>
                    ))}
                </Box>
            )}
        </Modal>
    );
};

export default ManageConnectionsModal;
