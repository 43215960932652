import { useSelector } from 'react-redux';
import React, { Fragment, useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import { useCustomMediaQuery } from 'hooks/useMediaQuery';
import { handleErrorAlert } from 'utils/handleErrorAlert';
import SalesActivityFilters from './SalesActivityFilters';
import { useInventoryService } from 'store/services/inventory.service';
import { DataTable, SnackBarAlert, CircleLoading, TablePagination } from 'shared';
import { salesActivityTableHeaders } from 'components/Inventory/utils/salesActivityTableHeaders';

const SalesActivity = ({}) => {
    const companyData = useSelector((state) => state.company.data);
    const isMobileScreen = useCustomMediaQuery('(max-width: 600px)');
    const { getAggregatedFileInventories } = useInventoryService();

    const [page, setPage] = useState(1);
    const [data, setData] = useState(null);
    const [alertData, setAlertData] = useState({
        severity: '',
        open: false,
        message: '',
    });
    const [filterValues, setFilterValues] = useState({});
    const [tableLoading, setTableLoading] = useState(true);
    const [downloadLoading, setDownloadLoading] = useState(false);

    const handleGetAggregatedFileInventories = async (page, filters = {}) => {
        try {
            setTableLoading(true);
            const response = await getAggregatedFileInventories({
                companyId: companyData.id,
                page,
                isReviewed: 'all',
                activityType: 'sales',
                ...filters,
            });
            setData(response);
        } catch (error) {
            handleErrorAlert(setAlertData);
        } finally {
            setTableLoading(false);
        }
    };

    const handlePageChange = async (page) => {
        setPage(page);
        await handleGetAggregatedFileInventories(page, filterValues);
    };

    const downloadPurchaseActivityCsv = (data) => {
        const headers = salesActivityTableHeaders;

        const csvHeaders = headers.map((col) => col.label);
        const csvRows = [csvHeaders.join(',')];

        data.forEach((item) => {
            const rowValues = headers.map((col) => {
                switch (col.id) {
                    case 'description':
                        return `${item.expires_at}, Section: ${item.class_1}, Row: ${item.class_2}, Seats: ${item.seats}`;

                    case 'plaidTransactionStatus':
                        return item.is_reviewed ? 'Matched' : 'Not matched';

                    case 'amount':
                        return item.amount_due;

                    default:
                        return item[col.id] ?? '';
                }
            });

            const csvLine = rowValues.map((val) => `"${val}"`).join(',');

            csvRows.push(csvLine);
        });

        const csvString = csvRows.join('\n');

        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `purcahse_activity_${new Date()}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleDownloadPurcahseActivity = async () => {
        try {
            setDownloadLoading(true);
            const response = await getAggregatedFileInventories({
                companyId: companyData.id,
                isReviewed: 'all',
                activityType: 'sales',
                ...filterValues,
            });
            downloadPurchaseActivityCsv(response.data);
        } catch (error) {
            handleErrorAlert(setAlertData);
        } finally {
            setDownloadLoading(false);
        }
    };

    const handleGetInitialData = async () => {
        try {
            setPage(1);
            setFilterValues({});
            handleGetAggregatedFileInventories(1);
        } catch (error) {
            handleErrorAlert(setAlertData);
        }
    };

    const handleFiltersSubmit = async (values) => {
        try {
            setPage(1);
            setFilterValues(values);
            handleGetAggregatedFileInventories(1, values);
        } catch (error) {
            handleErrorAlert(setAlertData);
        }
    };

    useEffect(() => {
        handleGetAggregatedFileInventories(page);
    }, []);

    return (
        <Fragment>
            {companyData?.id && data ? (
                <Grid container>
                    <Grid item xs={12}>
                        <SalesActivityFilters
                            downloadLoading={downloadLoading}
                            handleFiltersSubmit={handleFiltersSubmit}
                            handleGetInitialData={handleGetInitialData}
                            handleDownloadPurcahseActivity={handleDownloadPurcahseActivity}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DataTable
                            headCells={salesActivityTableHeaders}
                            data={data.results}
                            isLoading={tableLoading}
                        />
                    </Grid>
                    <Grid item xs={12} p={'10px 0'}>
                        <TablePagination
                            isMobileScreen={isMobileScreen}
                            page={page}
                            count={data?.count}
                            onChange={(e, pageNumber) => handlePageChange(pageNumber)}
                        />
                    </Grid>
                </Grid>
            ) : (
                <CircleLoading />
            )}

            <SnackBarAlert alertData={alertData} setAlertData={setAlertData} />
        </Fragment>
    );
};

export default SalesActivity;
