// Define language constants (for now, single language)
const language = {
    reports: 'Reports',
    no_data_available: 'No Data Available',
};

export const getLang = (key) => {
    if (key in language) {
        return language[key];
    } else {
        //show error
        console.error(`Language key "${key}" is missing from the dictionary.`);
        return key;
    }
};
