import * as Yup from 'yup';

export const manualBankFormInitialValues = () => {
    return {
        name: '',
        maskNumber: '',
        type: null,
        detailType: null,
        openingBalance: null,
        openingBalanceDate: null,
    };
};

export const manualBankFormSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    maskNumber: Yup.string().required('Mask Number is required'),
    type: Yup.object()
        .shape({
            value: Yup.string().required('Type value is required'),
            label: Yup.string().required('Type label is required'),
        })
        .required('Type is required'),
    detailType: Yup.object()
        .shape({
            value: Yup.string().required('Detail type value is required'),
            label: Yup.string().required('Detail type label is required'),
        })
        .required('Detail type is required'),
    openingBalance: Yup.number()
        .typeError('Opening balance must be a number')
        .required('Opening balance is required'),
    openingBalanceDate: Yup.date()
        .required('Opening balance date is required')
        .typeError('Opening balance date must be a valid date'),
});
