import React from 'react';

import { Grid, Typography, Box, Radio } from '@mui/material';

import { matchRowStyles } from '../../../style/style';

const MatchedRow = ({ data, isSelected, onSelect }) => {
    const {
        id,
        seats,
        class_1,
        class_2,
        order_id,
        expires_at,
        description,
        reference_id,
        purchased_at,
        purchase_price,
        venue,
        purchaser_email,
    } = data;

    const classes = matchRowStyles();

    return (
        <Box sx={classes.rootWrapper}>
            <Grid container spacing={2} alignItems="center" wrap="nowrap">
                <Grid item xs={1} sm={1}>
                    <Radio
                        fontSize="12px"
                        checked={isSelected}
                        onChange={() => onSelect(id)}
                        value={id}
                        name="row-selection"
                    />
                </Grid>
                <Grid item xs={2} sm={2}>
                    <Typography fontSize="12px">{purchased_at}</Typography>
                </Grid>
                <Grid item xs={2} sm={2}>
                    <Typography fontSize="12px">{order_id}</Typography>
                    <Typography fontSize="12px">{reference_id}</Typography>
                </Grid>
                <Grid item xs={3} sm={3}>
                    <Typography fontSize="12px">
                        {expires_at}, Section: {class_1}, Row: {class_2}, Seat: {seats}
                    </Typography>
                    <Typography fontSize="14px">
                        {venue ? `${description} - ${venue}` : description}
                    </Typography>
                </Grid>
                <Grid item xs={2} sm={2}>
                    <Typography fontSize="12px">{purchase_price}</Typography>
                </Grid>
                <Grid item xs={2} sm={2}>
                    <Typography fontSize="12px">
                        {purchaser_email ?? 'Purchaser Email Not Provided'}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default MatchedRow;
