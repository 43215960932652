export const inventoryTabStyles = ({ isScreenLowerThan1024 }) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: isScreenLowerThan1024 ? '16px' : '32px',
        width: '100%',
    },
    loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '300px',
    },
});

export const journalEntryStyles = () => ({
    paperContainer: {
        width: '95%',
        maxWidth: '1200px',
        margin: '40px auto',
        padding: { xs: 2, sm: 3, md: 4 },
        borderRadius: '12px',
        boxShadow: 2,
        bgcolor: 'background.paper',
    },
    backButton: {
        mb: 3,
        textTransform: 'none',
        fontSize: '1rem',
        fontWeight: 'bold',
    },
    titleBox: {
        mb: 3,
        textAlign: { xs: 'center', md: 'left' },
    },
    tableContainer: {
        borderRadius: '8px',
        overflow: 'hidden',
    },
    table: {
        minWidth: 600,
    },
    tableHeadRow: {
        bgcolor: 'primary.main',
    },
    tableHeadCell: {
        color: 'white',
        fontWeight: 'bold',
    },
    noDataText: {
        mt: 3,
        color: 'gray',
        textAlign: 'center',
    },
});

export const balanceSheetStyles = ({ isMobileScreen }) => ({
    paperContainer: {
        width: '90%',
        maxWidth: '100%',
        margin: '20px auto',
        padding: 2,
        minWidth: '300px',
        borderRadius: '16px',
        background: (theme) =>
            theme.palette.mode === 'dark' ? '#1E252F' : 'white',
    },
    title: {
        fontWeight: 'bold',
        mb: 1,
    },
});

// #1E252F

export const recieptTableStyles = (level) => ({
    tableContainer: {
        width: '100%',
    },
    tableRow: {
        cursor: level === 0 ? 'pointer' : 'default',
        backgroundColor: (theme) =>
            theme.palette.mode === 'dark'
                ? level === 1
                    ? '#1E252F'
                    : '#2A3342'
                : level === 1
                ? '#f1f1f1'
                : '#fff',
        transition: 'background-color 0.2s ease-in-out',
        '&:hover': {
            backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#444' : '#e6f7ff',
        },
    },
    categoryRow: {
        backgroundColor: (theme) =>
            theme.palette.mode === 'dark' ? '#1E252F' : '#f5f5f5', // Background for Level 1 categories
        fontWeight: 'bold',
    },
    tableCell: {
        pl: level === 0 ? 0 : level * 4, // Apply indentation only for Level 1 and Level 2
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        paddingY: '8px',
        color: (theme) => (theme.palette.mode === 'dark' ? '#ddd' : '#000'),
    },
    linkText: {
        textDecoration: 'underline',
        color: (theme) =>
            theme.palette.mode === 'dark' ? 'lightblue' : 'primary.main',
        cursor: 'pointer',
        '&:hover': {
            color: (theme) =>
                theme.palette.mode === 'dark' ? 'primary.dark' : 'primary.dark',
        },
    },
    totalCell: {
        whiteSpace: 'nowrap',
        paddingY: '8px',
        fontWeight: 'bold',
        color: (theme) => (theme.palette.mode === 'dark' ? '#ddd' : '#000'),
    },
    noDataCell: {
        paddingY: '8px',
        color: '#999',
        pointerEvents: 'none',
    },
    collapseCell: {
        padding: 0,
    },
    loaderCell: {
        paddingY: '8px',
    },
});

export const recieptTableLevelOneStyles = (level) => ({
    tableContainer: {
        width: '100%',
    },
    tableRow: {
        cursor: level === 0 ? 'pointer' : 'default',
        backgroundColor: (theme) =>
            theme.palette.mode === 'dark' ? '#2A3342' : 'white',
        // backgroundColor:
        //     level === 0 ? (isDarkMode ? '#2A3342' : '#fff') : 'transparent',
        transition: 'background-color 0.2s ease-in-out',
        '&:hover': {
            backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#444' : '#e6f7ff',
        },
    },
    tableCell: {
        pl: level === 0 ? 0 : level * 4,
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        paddingY: '6px',

        color: (theme) => (theme.palette.mode === 'dark' ? '#ddd' : '#000'),
    },
    linkText: {
        textDecoration: 'underline',
        color: (theme) =>
            theme.palette.mode === 'dark' ? 'lightblue' : 'primary.main',
        cursor: 'pointer',
        '&:hover': {
            color: (theme) =>
                theme.palette.mode === 'dark' ? 'primary.dark' : 'primary.dark',
        },
    },
    totalCell: {
        whiteSpace: 'nowrap',
        paddingY: '6px',
        // backgroundColor: isDarkMode ? '#2A3342' : 'white',
        color: (theme) => (theme.palette.mode === 'dark' ? '#ddd' : '#000'),
        fontWeight: level === 0 || level === 1 ? 'bold' : 'normal',
    },
    collapseCell: {
        padding: 0,
    },
    secondRow: {
        backgroundColor: (theme) =>
            theme.palette.mode === 'dark' ? '#2A3342' : 'white',
    },
    noDataCell: {
        paddingY: '6px',
        color: '#999',
        pointerEvents: 'none',
    },
});

export const reportStyles = ({ isMobileScreen }) => ({
    menuWrapper: {
        width: '100%',
        background: (theme) =>
            theme.palette.mode === 'dark' ? '#1E252F' : 'white',
        borderBottom: (theme) =>
            `1px solid ${
                theme.palette.mode === 'dark' ? '#2A2F3A' : '#D1D5DB'
            }`,
    },
    stack: {
        background: (theme) =>
            theme.palette.mode === 'dark' ? '#1E252F' : 'white',
        minHeight: '60px',
        borderRadius: '5px',
        padding: '1rem 2rem',
    },
    tabContentWrapper: {
        p: isMobileScreen ? '20px' : '40px',
    },
    paperContainer: {
        width: '90%',
        maxWidth: '100%',
        margin: '20px auto',
        padding: 2,
        minWidth: '300px',
        borderRadius: '16px',

        background: (theme) =>
            theme.palette.mode === 'dark' ? '#1E252F' : 'white',
    },
    filterBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 2,
    },
    datePickerGrid: {
        container: {
            spacing: 4,
            alignItems: 'center',
        },
        item: {
            marginRight: '15px',
        },
    },
    buttonGroup: {
        display: 'flex',
        alignItems: 'center',
    },
    smallButton: {
        height: '48px',
        fontWeight: 'bold',
        marginRight: '10px',
        backgroundColor: '#0061DB;',
        color: 'white',
    },
    exportButton: {
        height: '48px',
        fontWeight: 'bold',
        backgroundColor: '#0061DB;',
        color: 'white',
    },
    tabButton: (selected) => ({
        textTransform: 'none',
        fontSize: '1rem',
        fontWeight: selected ? '600' : '500',
        backgroundColor: selected ? '#CEEFDF' : 'transparent',

        borderRadius: selected ? '3px' : 'none',
        color: selected ? '#0A8C4D' : '#A1A8B3',
        '&:hover': {
            backgroundColor: selected ? '#0A8C4D26' : 'transparent',
            color: selected ? '#0A8C4D' : '#A1A8B3',
        },
    }),
});
