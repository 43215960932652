import * as Yup from 'yup';

export const forReviewInitialValues = {
    description: '',
    startDate: null,
    endDate: null,
    amount: '',
    hasMatches: { value: 'all', label: 'All' },
    bankAccount: { value: 'all', label: 'All accounts' },
    type: { value: 'all', label: 'All' },
};

export const forReviewSchema = Yup.object({
    endDate: Yup.date().nullable(),
    amount: Yup.number().nullable(),
    startDate: Yup.date().nullable(),
    description: Yup.string().nullable(),
    hasMatches: Yup.object()
        .shape({
            value: Yup.string().required('Matches value is required'),
            label: Yup.string().required('Matches label is required'),
        })
        .nullable(),
    bankAccount: Yup.object()
        .shape({
            value: Yup.string().required('Bank Account value is required'),
            label: Yup.string().required('Bank Account label is required'),
        })
        .nullable(),
    type: Yup.object()
        .shape({
            value: Yup.string().required('Type value is required'),
            label: Yup.string().required('Type label is required'),
        })
        .nullable(),
});
