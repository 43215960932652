import { faker } from '@faker-js/faker';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, Grid, IconButton, Skeleton } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { ReactComponent as ArrowDown } from '../assets/icons/ArrowDown.svg';
import { ReactComponent as ArrowUp } from '../assets/icons/ArrowUp.svg';
import { ReactComponent as CardBalanceIcon } from '../assets/icons/CardBalance.svg';
import { ReactComponent as CashBalanceIcon } from '../assets/icons/CashBalance.svg';
import { ReactComponent as CSV } from '../assets/icons/CSV.svg';
import { ReactComponent as DownArrow } from '../assets/icons/DownArrow.svg';
import { ReactComponent as Export } from '../assets/icons/Export.svg';
import { ReactComponent as LeftArrow } from '../assets/icons/LeftArrow.svg';
import { ReactComponent as LeftArrowDarkMode } from '../assets/icons/LeftArrowDarkMode.svg';
import { ReactComponent as NetIncome } from '../assets/icons/NetIncome.svg';
import { ReactComponent as RightArrow } from '../assets/icons/RightArrow.svg';
import { ReactComponent as RightArrowDarkMode } from '../assets/icons/RightArrowDarkMode.svg';
import { Button, DateRangePicker, DropDown, Menu, Typography } from '../shared';
import { downloadFile, formatCurrencyAmount, formateDate, isObjectEmpty } from '../utils/helpers';
// import { Switch } from '../shared/Switch';
import { useCustomMediaQuery } from '../hooks/useMediaQuery';
import { useThemeToggle } from '../hooks/useThemeToggle';
import GenericAccordion from '../shared/Accordion';
import { useCompanyService } from '../store/services/company.service';
import { useDashboardService } from '../store/services/dashboard.service';
import { useReportService } from '../store/services/report.service';
import { CURRENCIES } from '../utils/data';
import CashInCashOutBar from './CashInCashOutChart';
import CashPositionChart from './CashPositionChart';
import { OperatingExpensesChart } from './OperatingExpensesChart';
import RevenueMonthChart from './RevenueMonthChart';
import TopExpensesChart from './TopExpensesChart';

const makeStyles = ({ isScreenLowerThan1024, balanceOption }) => ({
    root: {
        p: isScreenLowerThan1024 ? '40px 0 20px 20px' : '40px',
        overflow: 'auto',
    },
    cards: {
        p: isScreenLowerThan1024 ? '0px' : '56px 0 0 0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '12px',
        height: isScreenLowerThan1024 ? '326px' : '406px',
        backgroundColor: (theme) => theme.backgroundColor.primary,
    },
    chartCard: {
        borderRadius: '12px',
        // filter: 'blur(3px)',
        backgroundColor: (theme) => theme.backgroundColor.primary,
    },
    reportContainer: {
        p: '20px',
        m: isScreenLowerThan1024 ? '0' : '0 35px 0 25px',
        overflow: 'auto',
        borderRadius: '12px',
        justifyContent: 'space-between',
        backgroundColor: (theme) => theme.backgroundColor.primary,
    },
    reportDetailsContainer: {
        display: 'flex',
        padding: '0 12px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
        minHeight: '200px',
        borderRadius:
            balanceOption === 1
                ? '0px 12px 12px 12px'
                : balanceOption === 3
                ? '12px 0 12px 12px'
                : '12px 12px 12px 12px',
        background: (theme) => theme.backgroundColor.secondary,
    },
});

const labels = ['Jan 23', 'Feb 23', 'Mar 23', 'Apr 23'];

// const cashInData = {
//     labels,
//     datasets: [
//         {
//             label: 'Dataset 1',
//             data: labels.map(() =>
//                 faker.datatype.number({ min: -9000000, max: 0 }),
//             ),
//             backgroundColor: '#00C791',
//             barThickness: 12,
//             maxBarThickness: 12,
//             borderRadius: 5,
//         },
//         {
//             label: 'Dataset 2',
//             data: labels.map(() =>
//                 faker.datatype.number({ min: -9000000, max: 9000000 }),
//             ),
//             backgroundColor: '#8633FF',
//             barThickness: 12,
//             maxBarThickness: 12,
//             borderRadius: 5,
//         },
//         {
//             label: 'Dataset 3',
//             data: labels.map(() =>
//                 faker.datatype.number({ min: -9000000, max: 9000000 }),
//             ),
//             backgroundColor: '#D3D5DA',
//             barThickness: 12,
//             maxBarThickness: 12,
//             borderRadius: 5,
//         },
//     ],
// };

// const cashPositionBarData = {
//     labels: [...labels, 'Mar 23'],
//     datasets: [
//         {
//             label: 'Dataset 1',
//             data: [...labels, 'Mar 23'].map(() =>
//                 faker.datatype.number({ min: 0, max: 90000000 }),
//             ),
//             backgroundColor: '#66A0E9',
//             borderRadius: 4,
//             barThickness: 30,
//             maxBarThickness: 40,
//         },
//     ],
// };

const stackBarData = {
    labels,
    datasets: [
        {
            label: 'Dataset 1',
            data: labels.map(() => faker.datatype.number({ min: 0, max: 5000 })),
            backgroundColor: '#8633FF',
            borderRadius: 40,
            barThickness: 30,
            maxBarThickness: 40,
        },
        {
            label: 'Dataset 2',
            data: labels.map(() => faker.datatype.number({ min: 0, max: 50000 })),
            backgroundColor: '#FF1F6A',
            borderRadius: 4,
            barThickness: 30,
            maxBarThickness: 40,
        },
        {
            label: 'Dataset 3',
            data: labels.map(() => faker.datatype.number({ min: 0, max: 50000 })),
            backgroundColor: '#00C791',
            borderRadius: 4,
            barThickness: 30,
            maxBarThickness: 40,
        },
        {
            label: 'Dataset 4',
            data: labels.map(() => faker.datatype.number({ min: 0, max: 50000 })),
            backgroundColor: '#3381E2',
            borderRadius: 2,
            barThickness: 30,
            maxBarThickness: 40,
        },
    ],
};

// const revenueMonthData = {
//     labels,
//     datasets: [
//         {
//             label: 'Dataset 1',
//             data: labels.map(() =>
//                 faker.datatype.number({ min: -100000, max: 5000000 }),
//             ),
//             backgroundColor: '#0061DB',
//             barThickness: 12,
//             maxBarThickness: 12,
//         },
//         {
//             label: 'Dataset 2',
//             data: labels.map(() =>
//                 faker.datatype.number({ min: -500000, max: 5000000 }),
//             ),
//             backgroundColor: '#6DCFA0',
//             barThickness: 12,
//             maxBarThickness: 12,
//         },
//         {
//             label: 'Dataset 3',
//             data: labels.map(() =>
//                 faker.datatype.number({ min: -500000, max: 5000000 }),
//             ),
//             backgroundColor: '#8633FF',
//             barThickness: 12,
//             maxBarThickness: 12,
//         },
//     ],
// };

// loading data
const cashInsightItems = [
    {
        id: 1,
        title: 'vs. Apr 23',
        content: '$184,234',
    },
    {
        id: 2,
        title: 'vs. Mar 23',
        content: '$244,734',
    },
];

// TODO: Need to break this component into smaller components
export const Dashboard = () => {
    const {
        getInsightsView,
        getDashboardView,
        getNetIncomeView,
        getDashboardSecondView,
        getOperatingExpenseMonthsView,
    } = useDashboardService();

    const {
        getBalanceSheetDetail,
        getCashActivityDetail,
        getProfitLossDetail,
        getProfitLossSummary,
        getBalanceSheetSummary,
        getCashActivitySummary,
        downloadBSReportCSV,
        downloadCashReportCSV,
        downloadProfitLossCSV,
        isLoading: isReportLoading,
    } = useReportService();

    const { getCurrencyList } = useCompanyService();

    const [cashDetail, setCashDetail] = useState({
        card_balance: 0,
        cash_balance: 0,
        cash_in_out: [],
        cash_position: [],
    });

    const [revenueDetail, setRevenueDetail] = useState({
        revenue_months: [],
        top_expenses_this_month: [],
    });
    const [isLoading, setIsLoading] = useState(true);
    const [cashInsightItems, setCashInsightItems] = useState([]);
    const [operatingExpenses, setOperatingExpenses] = useState({
        operating_expenses_months: [],
    });

    const [netIncome, setNetIncome] = useState(0);
    const { data: company } = useSelector((state) => state.company);
    const [expanded, setExpanded] = useState([]);
    const [dateRange, setDateRange] = useState({
        startDate: `${formateDate(new Date(), 'yyyy-MM-dd')}`,
        endDate: `${formateDate(new Date(), 'yyyy-MM-dd')}`,
    });
    const [summary, setSummary] = useState();
    const [selectedPanel, setSelectedPanel] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    // const [isSwitchOn, setIsSwitchOn] = useState(false);
    const [balanceOption, setBalanceOption] = useState(1);
    const [currencies, setCurrencies] = useState([]);
    const [currency, setCurrency] = useState('USD');
    const [isDateRangeOpen, setIsDateRangeOpen] = useState(false);
    const isScreenLowerThan1024 = useCustomMediaQuery('(max-width: 1025px)');
    const isScreenLowerThan1230 = useCustomMediaQuery('(max-width: 1230px)');
    const isMobileScreen = useCustomMediaQuery('(max-width:600px)');
    const styles = makeStyles({ isScreenLowerThan1024, balanceOption });
    const { themeMode } = useThemeToggle();
    const toggle = () => setIsDateRangeOpen(!isDateRangeOpen);
    const isDarkMode = themeMode === 'dark';

    const onChangeDateRange = (dateRange) => {
        setDateRange({
            startDate: `${formateDate(dateRange.startDate, 'yyyy-MM-dd')}`,
            endDate: `${formateDate(dateRange.endDate, 'yyyy-MM-dd')}`,
        });
        setIsDateRangeOpen(!isDateRangeOpen);
    };

    //TODO: Need to ask Yun if we need DD
    // const handleChange = (event) => {
    //     setSelectedCashValue(event.target.value);
    // };

    const handleBalanceOptionChange = (selectedBalanceOption) => {
        setExpanded([]);
        // setIsSwitchOn(false);
        setBalanceOption(selectedBalanceOption);
    };

    // const handleSwitchToggle = (isExpanded) => {
    //     setIsSwitchOn(!isSwitchOn);

    //     setExpanded(
    //         isExpanded ? Object.keys(summary).map((key) => `panel${key}`) : [],
    //     );
    // };

    const handlePrevClick = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : cashInsightItems.length - 1,
        );
    };

    const handleNextClick = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex < cashInsightItems.length - 1 ? prevIndex + 1 : 0,
        );
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleCurrencyChange = (e, value) => {
        setCurrency(e.target.value);
    };

    const handleAccordionChange = (selectedPanel) => {
        setSelectedPanel(selectedPanel);
        switch (balanceOption) {
            case 1:
                getCashActivityDetail(
                    company.id,
                    currency,
                    dateRange.startDate,
                    dateRange.endDate,
                    selectedPanel.heading,
                ).then((res) => {
                    setSummary((prevSummary) => {
                        return {
                            ...prevSummary,
                            [selectedPanel.heading]: {
                                ...prevSummary[selectedPanel.heading],
                                additionalProps: res,
                            },
                        };
                    });
                });
                break;
            case 2:
                getProfitLossDetail(
                    company.id,
                    currency,
                    dateRange.startDate,
                    dateRange.endDate,
                    selectedPanel.heading,
                ).then((res) => {
                    setSummary((prevSummary) => {
                        return {
                            ...prevSummary,
                            [selectedPanel.heading]: {
                                ...prevSummary[selectedPanel.heading],
                                additionalProps: res[selectedPanel.heading.toLowerCase()],
                            },
                        };
                    });
                });
                break;

            case 3:
                getBalanceSheetDetail(
                    company.id,
                    currency,
                    dateRange.endDate,
                    selectedPanel.heading,
                ).then((res) => {
                    setSummary((prevSummary) => {
                        return {
                            ...prevSummary,
                            [selectedPanel.heading]: {
                                ...prevSummary[selectedPanel.heading],
                                additionalProps: {
                                    ...res[selectedPanel.heading],
                                },
                            },
                        };
                    });
                });
                break;

            default:
                break;
        }
    };

    const transformSummary = (originalData) => {
        const transformedData = {};

        Object.keys(originalData).forEach((key) => {
            transformedData[key] = {
                amount: +originalData[key],
                content: <></>,
            };
        });
        return transformedData;
    };

    const getSummaryReport = useCallback(
        async (balanceOption) => {
            if (company) {
                switch (balanceOption) {
                    case 1: {
                        const result = await getCashActivitySummary(
                            company.id,
                            currency,
                            dateRange.endDate,
                            dateRange.startDate,
                        );

                        const transformedData = transformSummary(result);
                        setSummary(transformedData);
                        break;
                    }
                    case 2: {
                        const result = await getProfitLossSummary(
                            company.id,
                            currency,
                            dateRange.endDate,
                            dateRange.startDate,
                        );

                        const transformedData = transformSummary(result);
                        setSummary(transformedData);
                        break;
                    }
                    case 3: {
                        const result = await getBalanceSheetSummary(
                            company.id,
                            currency,
                            dateRange.endDate,
                        );
                        const transformedData = transformSummary(result);
                        setSummary(transformedData);
                        break;
                    }
                    default:
                        break;
                }
            }
        },
        [
            currency,
            company,
            dateRange.startDate,
            dateRange.endDate,
            getProfitLossSummary,
            getBalanceSheetSummary,
            getCashActivitySummary,
        ],
    );

    const handleDownLoadCSV = () => {
        switch (balanceOption) {
            case 1:
                downloadCashReportCSV(
                    company.id,
                    currency,
                    dateRange.startDate,
                    dateRange.endDate,
                ).then((res) => {
                    downloadFile(res, 'CashActivityReport');
                });
                break;
            case 2:
                downloadProfitLossCSV(
                    company.id,
                    currency,
                    dateRange.startDate,
                    dateRange.endDate,
                ).then((res) => {
                    downloadFile(res, 'ProfilLoss');
                });
                break;

            case 3:
                downloadBSReportCSV(
                    company.id,
                    currency,
                    dateRange.startDate,
                    dateRange.endDate,
                ).then((res) => {
                    downloadFile(res, 'BalanceSheet');
                });
                break;

            default:
                break;
        }
    };

    useEffect(() => {
        setIsLoading(true);
        if (company?.id)
            Promise.all([
                getInsightsView(),
                getDashboardView(company.id, currency),
                getDashboardSecondView(company.id, currency),
                getNetIncomeView(company.id, currency),
                getCurrencyList(company.id),
                getOperatingExpenseMonthsView(company.id, currency),
            ])
                .then(
                    ([
                        insights,
                        dashboard,
                        secondDashboardView,
                        netIncome,
                        currencies,
                        operatingExpenses,
                    ]) => {
                        setCashInsightItems(insights);
                        setCashDetail(dashboard);
                        setRevenueDetail(secondDashboardView);
                        setNetIncome(netIncome.net_income);
                        const matchedCurrencies = CURRENCIES.filter((currency) =>
                            currencies.code.includes(currency.value),
                        );
                        setCurrencies(matchedCurrencies);
                        // console.log(
                        //     'Received operating expenses data: ',
                        //     operatingExpenses,
                        // );
                        // const transformedData = stackBarDatas(
                        //     operatingExpenses['operating_expenses_months'],
                        // );

                        setOperatingExpenses(operatingExpenses);
                        // setOperatingExpenses({
                        //     operating_expenses_months: [
                        //         {
                        //             month: 'Oct 24',
                        //             dataset: [
                        //                 { account: 'airline', amount: 500 },
                        //                 { account: 'airline2', amount: -500 },
                        //             ],
                        //         },
                        //         {
                        //             month: 'Sep 24',
                        //             dataset: [
                        //                 { account: 'airline', amount: 500 },
                        //                 { account: 'airline2', amount: -11.73 },
                        //                 { account: 'airline3', amount: -16.33 },
                        //             ],
                        //         },
                        //         {
                        //             month: 'Aug 24',
                        //             dataset: [
                        //                 { account: 'airline2', amount: -500 },
                        //                 { account: 'airline3', amount: 700 },
                        //             ],
                        //         },
                        //         {
                        //             month: 'Jul 24',
                        //             dataset: [
                        //                 { account: 'airline', amount: 500 },
                        //                 { account: 'airline2', amount: -510 },
                        //                 { account: 'airline3', amount: 700 },
                        //             ],
                        //         },
                        //     ],
                        // });
                        setIsLoading(false);
                    },
                )
                .catch((err) => {
                    setIsLoading(false);
                    console.error(err);
                });
    }, [
        currency,
        company?.id,
        getCurrencyList,
        getDashboardView,
        getNetIncomeView,
        getDashboardSecondView,
        getOperatingExpenseMonthsView,
    ]);

    useEffect(() => {
        getSummaryReport(balanceOption);
    }, [balanceOption, getSummaryReport]);

    // useEffect(() => {
    //     getInsightsView().then((insights) => setCashInsightItems(insights));
    // }, [getInsightsView]);

    return (
        <Grid container p={2}>
            <Grid item container sx={styles.root} spacing={4}>
                <Grid
                    container
                    item
                    xs={12}
                    p={'0 !important'}
                    display={'flex'}
                    justifyContent={'flex-end'}
                >
                    <Grid item display={'flex'} justifyContent={'flex-end'}>
                        <DropDown
                            value={currency}
                            options={currencies}
                            label={'Currency'}
                            onChange={handleCurrencyChange}
                            placeHolder="Select an option"
                            customStyles={{}}
                        />
                    </Grid>
                    {/* <Grid item>
                        <Button
                            text=""
                            customStyles={{
                                backgroundColor: '#0061DB',
                                color: (theme) => theme.textColor.white,
                                p: '12px 20px',
                                fontSize: '16px',
                                fontWeight: 500,
                                height: '48px',
                                '& .MuiButton-startIcon': {
                                    mr: '0px',
                                },
                            }}
                            startIcon={<Refresh />}
                        />
                    </Grid> */}
                </Grid>

                {isMobileScreen ? (
                    <>
                        <SwipeableViews enableMouseEvents>
                            <Grid item sm={6} xs={12} md={4} lg={4} sx={{ m: '24px 0 24px 30px' }}>
                                <Grid container item sx={{ ...styles.cards }} spacing={2}>
                                    <Grid item>
                                        <CashBalanceIcon
                                            width={isScreenLowerThan1024 ? '120px' : '140px'}
                                            height={isScreenLowerThan1024 ? '120px' : '140px'}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            text="Cash Balance"
                                            customStyles={{
                                                fontWeight: 500,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        {isLoading ? (
                                            <Skeleton width={140} height={50} />
                                        ) : (
                                            <Typography
                                                text={formatCurrencyAmount(
                                                    cashDetail?.cash_balance || 0,
                                                )}
                                                customStyles={{
                                                    fontWeight: 700,
                                                    fontSize: 32,
                                                }}
                                            />
                                        )}
                                    </Grid>
                                    {/* <Grid
                                        item
                                        container
                                        spacing={2}
                                        justifyContent={'space-around'}
                                    >
                                        <Grid
                                            item
                                            display={'flex'}
                                            justifyContent={'center'}
                                        >
                                            <ArrowUp /> &nbsp;
                                            <Typography
                                                text="23% compared to May 2023"
                                                customStyles={{
                                                    fontWeight: 400,
                                                    color: (theme) =>
                                                        theme.textColor.primary,
                                                }}
                                            />
                                        </Grid>
                                    </Grid> */}
                                </Grid>
                            </Grid>
                            <Grid item sm={6} xs={12} md={4} lg={4} sx={{ m: '24px 0 24px 30px' }}>
                                <Grid container item sx={styles.cards} spacing={2}>
                                    <Grid item>
                                        <CardBalanceIcon
                                            width={isScreenLowerThan1024 ? '120px' : '140px'}
                                            height={isScreenLowerThan1024 ? '120px' : '140px'}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            text="Card Balance"
                                            customStyles={{
                                                fontWeight: 500,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        {isLoading ? (
                                            <Skeleton width={140} height={50} />
                                        ) : (
                                            <Typography
                                                text={formatCurrencyAmount(
                                                    cashDetail?.card_balance || 0,
                                                )}
                                                customStyles={{
                                                    fontWeight: 700,
                                                    fontSize: 32,
                                                }}
                                            />
                                        )}
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        justifyContent={'space-around'}
                                    >
                                        <Grid item display={'flex'} justifyContent={'center'}>
                                            {/* <ArrowDown /> &nbsp;
                                            <Typography
                                                text="-12% compared to May 2023"
                                                customStyles={{
                                                    fontWeight: 400,
                                                    color: (theme) =>
                                                        theme.textColor.primary,
                                                }}
                                            /> */}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={6} xs={12} md={4} lg={4} sx={{ m: '24px 0 24px 30px' }}>
                                <Grid
                                    container
                                    item
                                    sx={styles.chartCard}
                                    spacing={2}
                                    justifyContent={'center'}
                                    px={2.5}
                                    height={isScreenLowerThan1024 ? '326px' : '406px'}
                                >
                                    <Grid item>
                                        <Typography
                                            text={'Cash In, Cash Out'}
                                            customStyles={{
                                                fontWeight: 500,
                                            }}
                                        />
                                    </Grid>
                                    {isLoading ? (
                                        <>
                                            <Grid item xs={12}>
                                                <Skeleton width={'100%'} height={10} />
                                                <Skeleton width={'100%'} height={10} />
                                                <Skeleton width={'100%'} height={10} />
                                                <Skeleton width={'100%'} height={10} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Skeleton width={'100%'} height={10} />
                                                <Skeleton width={'100%'} height={10} />
                                                <Skeleton width={'100%'} height={10} />
                                                <Skeleton width={'100%'} height={10} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Skeleton width={'100%'} height={10} />
                                                <Skeleton width={'100%'} height={10} />
                                                <Skeleton width={'100%'} height={10} />
                                                <Skeleton width={'100%'} height={10} />
                                            </Grid>
                                        </>
                                    ) : (
                                        <CashInCashOutBar
                                            data={{
                                                labels: cashDetail.cash_in_out.map(
                                                    (cash) => cash.month,
                                                ),
                                                datasets: cashDetail.cash_in_out.map(
                                                    (cashInOut) => {
                                                        return {
                                                            label: '',
                                                            data: [
                                                                cashInOut.cash_in,
                                                                cashInOut.cash_out,
                                                            ],
                                                            backgroundColor: '#00C791',
                                                            barThickness: 12,
                                                            maxBarThickness: 12,
                                                            borderRadius: 5,
                                                        };
                                                    },
                                                ),
                                            }}
                                            isDarkMode={themeMode === 'dark'}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </SwipeableViews>
                        <SwipeableViews enableMouseEvents>
                            <Grid item sm={6} xs={12} md={4} lg={4} sx={{ m: '24px 0 24px 30px' }}>
                                <Grid
                                    container
                                    item
                                    sx={styles.chartCard}
                                    spacing={2}
                                    px={2.5}
                                    height={isScreenLowerThan1024 ? '326px' : '406px'}
                                    justifyContent={'center'}
                                >
                                    <Grid item>
                                        <Typography
                                            text={'Revenue'}
                                            customStyles={{
                                                fontWeight: 500,
                                            }}
                                        />
                                    </Grid>
                                    {isLoading ? (
                                        <>
                                            <Grid item xs={12}>
                                                <Skeleton width={'100%'} height={10} />
                                                <Skeleton width={'100%'} height={10} />
                                                <Skeleton width={'100%'} height={10} />
                                                <Skeleton width={'100%'} height={10} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Skeleton width={'100%'} height={10} />
                                                <Skeleton width={'100%'} height={10} />
                                                <Skeleton width={'100%'} height={10} />
                                                <Skeleton width={'100%'} height={10} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Skeleton width={'100%'} height={10} />
                                                <Skeleton width={'100%'} height={10} />
                                                <Skeleton width={'100%'} height={10} />
                                                <Skeleton width={'100%'} height={10} />
                                            </Grid>
                                        </>
                                    ) : (
                                        <RevenueMonthChart
                                            data={{
                                                labels: revenueDetail.revenue_months.map(
                                                    (cash) => cash.month,
                                                ),
                                                datasets: revenueDetail.revenue_months.map(
                                                    (revenue) => {
                                                        return {
                                                            label: '',
                                                            data: [
                                                                revenue.revenue,
                                                                revenue.cost_of_revenue,
                                                            ],
                                                            backgroundColor: '#0061DB',
                                                            barThickness: 12,
                                                            maxBarThickness: 12,
                                                        };
                                                    },
                                                ),
                                            }}
                                            isDarkMode={themeMode === 'dark'}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item sm={6} xs={12} md={4} lg={4} sx={{ m: '24px 0 24px 30px' }}>
                                <Grid
                                    container
                                    item
                                    sx={styles.cards}
                                    spacing={2}
                                    justifyContent={'center'}
                                >
                                    <Grid item>
                                        <NetIncome
                                            width={isScreenLowerThan1024 ? '120px' : '200px'}
                                            height={isScreenLowerThan1024 ? '120px' : '200px'}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            p={1}
                                            text="Net Income for This Year"
                                            customStyles={{
                                                fontWeight: 1000,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        {isLoading ? (
                                            <Skeleton height={50} width={250} />
                                        ) : (
                                            <Typography
                                                text={formatCurrencyAmount(netIncome || 0)}
                                                customStyles={{
                                                    fontWeight: 700,
                                                    fontSize: 32,
                                                }}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={6} xs={12} md={4} lg={4} sx={{ m: '24px 0 24px 30px' }}>
                                <Grid
                                    container
                                    item
                                    sx={styles.chartCard}
                                    spacing={2}
                                    px={2.5}
                                    height={isScreenLowerThan1024 ? '326px' : '406px'}
                                    justifyContent={'center'}
                                >
                                    <Grid item>
                                        <Typography
                                            text={'Operating Expenses'}
                                            customStyles={{
                                                fontWeight: 500,
                                            }}
                                        />
                                    </Grid>
                                    {isLoading ? (
                                        <>
                                            <Grid item xs={12}>
                                                <Skeleton width={'100%'} height={10} />
                                                <Skeleton width={'100%'} height={10} />
                                                <Skeleton width={'100%'} height={10} />
                                                <Skeleton width={'100%'} height={10} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Skeleton width={'100%'} height={10} />
                                                <Skeleton width={'100%'} height={10} />
                                                <Skeleton width={'100%'} height={10} />
                                                <Skeleton width={'100%'} height={10} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Skeleton width={'100%'} height={10} />
                                                <Skeleton width={'100%'} height={10} />
                                                <Skeleton width={'100%'} height={10} />
                                                <Skeleton width={'100%'} height={10} />
                                            </Grid>
                                        </>
                                    ) : (
                                        <OperatingExpensesChart
                                            data={operatingExpenses}
                                            isDarkMode={themeMode === 'dark'}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </SwipeableViews>
                        <SwipeableViews enableMouseEvents>
                            <Grid item sm={6} xs={12} md={4} lg={4} sx={{ m: '24px 0 24px 30px' }}>
                                <Grid
                                    container
                                    item
                                    sx={styles.chartCard}
                                    spacing={2}
                                    height={isScreenLowerThan1024 ? '326px' : '406px'}
                                    justifyContent={'center'}
                                    px={2.5}
                                    py={'0 !important'}
                                >
                                    <Grid item>
                                        <Typography
                                            text={'Top Expenses This Month'}
                                            customStyles={{ fontWeight: 500 }}
                                        />
                                    </Grid>
                                    <Grid item width={'100%'} p={'0 !important'}>
                                        {isLoading ? (
                                            <Grid container item spacing={2}>
                                                <Grid item xs={12}>
                                                    <Skeleton width={'100%'} height={10} />
                                                    <Skeleton width={'100%'} height={10} />
                                                    <Skeleton width={'100%'} height={10} />
                                                    <Skeleton width={'100%'} height={10} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Skeleton width={'100%'} height={10} />
                                                    <Skeleton width={'100%'} height={10} />
                                                    <Skeleton width={'100%'} height={10} />
                                                    <Skeleton width={'100%'} height={10} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Skeleton width={'100%'} height={10} />
                                                    <Skeleton width={'100%'} height={10} />
                                                    <Skeleton width={'100%'} height={10} />
                                                    <Skeleton width={'100%'} height={10} />
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <TopExpensesChart
                                                topExpensesData={
                                                    revenueDetail.top_expenses_this_month
                                                }
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={6} xs={12} md={4} lg={4} sx={{ m: '24px 0 24px 30px' }}>
                                <Grid
                                    container
                                    item
                                    sx={styles.chartCard}
                                    spacing={2}
                                    height={isScreenLowerThan1024 ? '326px' : '406px'}
                                    justifyContent={'center'}
                                    px={2.5}
                                    // alignItems={'center'}
                                    py={'0 !important'}
                                >
                                    <Grid item alignItems={'start'} p={'15px !important'}>
                                        <Typography
                                            text={'Insights'}
                                            customStyles={{ fontWeight: 500 }}
                                        />
                                    </Grid>
                                    <Grid item container p={'0 !important'}>
                                        <Grid
                                            item
                                            xs={1}
                                            display={'flex'}
                                            justifyContent={'flex-start'}
                                            alignItems={'center'}
                                        >
                                            <Box
                                                sx={{
                                                    cursor: 'pointer',
                                                    display: 'flex',
                                                    width: 'var(--spacing-4, 32px)',
                                                    height: 'var(--spacing-4, 32px)',
                                                    padding: 'var(--spacing-none, 8px)',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 'var(--spacing-none, 0px)',
                                                    borderRadius: '666.667px',
                                                    border: '1px solid var(--Icon-Icon-Border, #E5E6EB)',
                                                    backgroundColor: (theme) =>
                                                        theme.backgroundColor.primary,
                                                }}
                                            >
                                                {isDarkMode ? (
                                                    <LeftArrowDarkMode
                                                        onClick={handlePrevClick}
                                                        width={16}
                                                        height={16}
                                                    />
                                                ) : (
                                                    <LeftArrow
                                                        onClick={handlePrevClick}
                                                        width={16}
                                                        height={16}
                                                    />
                                                )}
                                            </Box>
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            xs={10}
                                            display={'flex'}
                                            justifyContent={'center'}
                                            alignItems={'center'}
                                            p={'16px 0px'}
                                            flexDirection={'column'}
                                        >
                                            <Grid item>
                                                {isLoading ? (
                                                    <Skeleton width={150} height={50} />
                                                ) : (
                                                    cashInsightItems[currentIndex] && (
                                                        <Typography
                                                            text={
                                                                cashInsightItems[currentIndex].title
                                                            }
                                                            customStyles={{
                                                                fontSize: isScreenLowerThan1230
                                                                    ? 24
                                                                    : 32,
                                                                fontWeight: 700,
                                                            }}
                                                        />
                                                    )
                                                )}
                                            </Grid>
                                            <Grid item>
                                                {cashInsightItems[currentIndex] && (
                                                    <Typography
                                                        text={
                                                            cashInsightItems[currentIndex].content
                                                        }
                                                        customStyles={{
                                                            fontSize: 16,
                                                            fontWeight: 400,
                                                            color: (theme) =>
                                                                theme.textColor.primary,
                                                        }}
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                            display={'flex'}
                                            justifyContent={'flex-end'}
                                            alignItems={'center'}
                                        >
                                            <Box
                                                sx={{
                                                    cursor: 'pointer',
                                                    display: 'flex',
                                                    width: 'var(--spacing-4, 32px)',
                                                    height: 'var(--spacing-4, 32px)',
                                                    padding: 'var(--spacing-none, 8px)',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 'var(--spacing-none, 0px)',
                                                    borderRadius: '666.667px',
                                                    border: '1px solid var(--Icon-Icon-Border, #E5E6EB)',
                                                    backgroundColor: (theme) =>
                                                        theme.backgroundColor.primary,
                                                }}
                                            >
                                                {isDarkMode ? (
                                                    <RightArrowDarkMode
                                                        onClick={handleNextClick}
                                                        width={16}
                                                        height={16}
                                                    />
                                                ) : (
                                                    <RightArrow
                                                        onClick={handleNextClick}
                                                        width={16}
                                                        height={16}
                                                    />
                                                )}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid item width={'100%'}>
                                        <Divider
                                            sx={{
                                                background: (theme) =>
                                                    theme.palette.mode === 'dark'
                                                        ? '#394150'
                                                        : '#E5E6EB',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item pt={isScreenLowerThan1024 ? 'unset' : '48px '}>
                                        {isLoading ? (
                                            <Box
                                                display={'flex'}
                                                flexDirection={'column'}
                                                alignItems={'center'}
                                            >
                                                <Skeleton width={300} height={50} />
                                                <Skeleton width={150} height={50} />
                                            </Box>
                                        ) : (
                                            cashInsightItems[currentIndex] && (
                                                <Typography
                                                    text={cashInsightItems[currentIndex].text}
                                                    customStyles={{
                                                        fontSize: 14,
                                                        fontWeight: 400,
                                                        color: (theme) => theme.textColor.primary,
                                                        textAlign: 'center',
                                                    }}
                                                />
                                            )
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={6} xs={12} md={4} lg={4} sx={{ m: '24px 0 24px 30px' }}>
                                <Grid
                                    container
                                    item
                                    sx={styles.chartCard}
                                    spacing={2}
                                    px={2.5}
                                    py={0}
                                    height={isScreenLowerThan1024 ? '326px' : '406px'}
                                    justifyContent={'center'}
                                >
                                    <Grid item>
                                        <Typography
                                            text={'Cash Positions'}
                                            customStyles={{ fontWeight: 500 }}
                                        />
                                    </Grid>
                                    <Grid item width={'100%'} p={'0 !important'}>
                                        {isLoading ? (
                                            <>
                                                <Grid item xs={12}>
                                                    <Skeleton width={'100%'} height={10} />
                                                    <Skeleton width={'100%'} height={10} />
                                                    <Skeleton width={'100%'} height={10} />
                                                    <Skeleton width={'100%'} height={10} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Skeleton width={'100%'} height={10} />
                                                    <Skeleton width={'100%'} height={10} />
                                                    <Skeleton width={'100%'} height={10} />
                                                    <Skeleton width={'100%'} height={10} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Skeleton width={'100%'} height={10} />
                                                    <Skeleton width={'100%'} height={10} />
                                                    <Skeleton width={'100%'} height={10} />
                                                    <Skeleton width={'100%'} height={10} />
                                                </Grid>
                                            </>
                                        ) : (
                                            <CashPositionChart
                                                data={{
                                                    labels: cashDetail.cash_position.map(
                                                        (cash) => cash.month,
                                                    ),
                                                    datasets: cashDetail.cash_position.map(
                                                        (cashPosition) => {
                                                            return {
                                                                label: '',
                                                                data: [cashPosition.cash_balance],
                                                                backgroundColor: '#00C791',
                                                                barThickness: 12,
                                                                maxBarThickness: 12,
                                                                borderRadius: 5,
                                                            };
                                                        },
                                                    ),
                                                }}
                                                isDarkMode={themeMode === 'dark'}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </SwipeableViews>
                    </>
                ) : (
                    <>
                        <Grid item sm={6} xs={12} md={4} lg={4}>
                            <Grid container item sx={{ ...styles.cards }} spacing={2}>
                                <Grid item>
                                    <CashBalanceIcon
                                        width={isScreenLowerThan1024 ? '120px' : '140px'}
                                        height={isScreenLowerThan1024 ? '120px' : '140px'}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        text="Cash Balance"
                                        customStyles={{
                                            fontWeight: 500,
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    {isLoading ? (
                                        <Skeleton width={140} height={50} />
                                    ) : (
                                        <Typography
                                            text={formatCurrencyAmount(
                                                cashDetail?.cash_balance || 0,
                                            )}
                                            customStyles={{
                                                fontWeight: 700,
                                                fontSize: 32,
                                            }}
                                        />
                                    )}
                                </Grid>
                                <Grid item container spacing={2} justifyContent={'space-around'}>
                                    <Grid
                                        item
                                        display={'flex'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                    >
                                        {/* {isLoading ? (
                                            <Skeleton
                                                variant="circular"
                                                width={25}
                                                height={25}
                                            />
                                        ) : (
                                            <ArrowUp />
                                        )}
                                        &nbsp;
                                        {isLoading ? (
                                            <Skeleton width={250} height={50} />
                                        ) : (
                                            <Typography
                                                text="23% compared to May 2023"
                                                customStyles={{
                                                    fontWeight: 400,
                                                    color: (theme) =>
                                                        theme.textColor.primary,
                                                }}
                                            />
                                        )} */}
                                    </Grid>
                                </Grid>
                                {/* </>
                                )} */}
                            </Grid>
                        </Grid>
                        <Grid item sm={6} xs={12} md={4} lg={4}>
                            <Grid container item sx={styles.cards} spacing={2}>
                                <Grid item>
                                    <CardBalanceIcon
                                        width={isScreenLowerThan1024 ? '120px' : '140px'}
                                        height={isScreenLowerThan1024 ? '120px' : '140px'}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        text="Card Balance"
                                        customStyles={{ fontWeight: 500 }}
                                    />
                                </Grid>
                                <Grid item>
                                    {isLoading ? (
                                        <Skeleton width={140} height={50} />
                                    ) : (
                                        <Typography
                                            text={formatCurrencyAmount(
                                                Math.abs(cashDetail?.card_balance) || 0,
                                            )}
                                            customStyles={{
                                                fontWeight: 700,
                                                fontSize: 32,
                                            }}
                                        />
                                    )}
                                </Grid>
                                {/* <Grid
                                    item
                                    container
                                    spacing={2}
                                    justifyContent={'space-around'}
                                >
                                    <Grid
                                        item
                                        display={'flex'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                    >
                                        {isLoading ? (
                                            <Skeleton
                                                variant="circular"
                                                width={25}
                                                height={25}
                                            />
                                        ) : (
                                            <ArrowDown />
                                        )}{' '}
                                        &nbsp;
                                        {isLoading ? (
                                            <Skeleton width={250} height={50} />
                                        ) : (
                                            <Typography
                                                text="-12% compared to May 2023"
                                                customStyles={{
                                                    fontWeight: 400,
                                                    color: (theme) =>
                                                        theme.textColor.primary,
                                                }}
                                            />
                                        )}
                                    </Grid>
                                </Grid> */}
                            </Grid>
                        </Grid>
                        <Grid item sm={6} xs={12} md={4} lg={4}>
                            <Grid
                                container
                                item
                                sx={styles.chartCard}
                                spacing={2}
                                justifyContent={'center'}
                                px={2.5}
                                height={isScreenLowerThan1024 ? '326px' : '406px'}
                            >
                                <Grid item>
                                    <Typography
                                        text={'Cash In, Cash Out'}
                                        customStyles={{ fontWeight: 500 }}
                                    />
                                </Grid>
                                {isLoading ? (
                                    <>
                                        <Grid item xs={12}>
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                        </Grid>
                                    </>
                                ) : (
                                    <CashInCashOutBar
                                        data={{
                                            labels: cashDetail.cash_in_out.map(
                                                (cash) => cash.month,
                                            ),
                                            datasets: cashDetail.cash_in_out.map((cashInOut) => {
                                                return {
                                                    label: '',
                                                    data: [cashInOut.cash_in, cashInOut.cash_out],
                                                    backgroundColor: '#00C791',
                                                    barThickness: 12,
                                                    maxBarThickness: 12,
                                                    borderRadius: 5,
                                                };
                                            }),
                                        }}
                                        isDarkMode={themeMode === 'dark'}
                                    />
                                )}
                            </Grid>
                        </Grid>

                        <Grid item sm={6} xs={12} md={4} lg={4}>
                            <Grid
                                container
                                item
                                sx={styles.chartCard}
                                spacing={2}
                                px={2.5}
                                height={isScreenLowerThan1024 ? '326px' : '406px'}
                                justifyContent={'center'}
                            >
                                <Grid item>
                                    <Typography
                                        text={'Revenue'}
                                        customStyles={{ fontWeight: 500 }}
                                    />
                                </Grid>
                                {isLoading ? (
                                    <>
                                        <Grid item xs={12}>
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                        </Grid>
                                    </>
                                ) : (
                                    <RevenueMonthChart
                                        data={{
                                            labels: revenueDetail.revenue_months.map(
                                                (cash) => cash.month,
                                            ),
                                            datasets: revenueDetail.revenue_months.map(
                                                (revenue) => {
                                                    return {
                                                        label: '',
                                                        data: [
                                                            revenue.revenue,
                                                            revenue.cost_of_revenue,
                                                        ],
                                                        backgroundColor: '#0061DB',
                                                        barThickness: 12,
                                                        maxBarThickness: 12,
                                                    };
                                                },
                                            ),
                                        }}
                                        isDarkMode={themeMode === 'dark'}
                                    />
                                )}
                            </Grid>
                        </Grid>
                        <Grid item sm={6} xs={12} md={4} lg={4}>
                            <Grid
                                container
                                item
                                sx={{
                                    ...styles.cards,
                                    p: isScreenLowerThan1024 ? '0' : '20px 0 0 0',
                                }}
                                spacing={2}
                                justifyContent={'center'}
                            >
                                <Grid item>
                                    <NetIncome
                                        width={isScreenLowerThan1024 ? '120px' : '200px'}
                                        height={isScreenLowerThan1024 ? '120px' : '200px'}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        p={1}
                                        text="Net Income for This Year"
                                        customStyles={{ fontWeight: 500 }}
                                    />
                                </Grid>
                                <Grid item>
                                    {isLoading ? (
                                        <Skeleton height={50} width={250} />
                                    ) : (
                                        <Typography
                                            text={formatCurrencyAmount(netIncome || 0)}
                                            customStyles={{
                                                fontWeight: 700,
                                                fontSize: 32,
                                            }}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={6} xs={12} md={4} lg={4}>
                            <Grid
                                container
                                item
                                sx={styles.chartCard}
                                spacing={2}
                                px={2.5}
                                height={isScreenLowerThan1024 ? '326px' : '406px'}
                                justifyContent={'center'}
                            >
                                <Grid item>
                                    <Typography
                                        text={'Operating Expenses'}
                                        customStyles={{ fontWeight: 500 }}
                                    />
                                </Grid>
                                {isLoading ? (
                                    <>
                                        <Grid item xs={12}>
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                        </Grid>
                                    </>
                                ) : (
                                    <OperatingExpensesChart
                                        data={operatingExpenses}
                                        isDarkMode={themeMode === 'dark'}
                                    />
                                )}
                            </Grid>
                        </Grid>

                        <Grid item sm={6} xs={12} md={4} lg={4}>
                            <Grid
                                container
                                item
                                sx={styles.chartCard}
                                spacing={2}
                                height={isScreenLowerThan1024 ? '326px' : '406px'}
                                justifyContent={'center'}
                                px={2.5}
                                py={'0 !important'}
                            >
                                <Grid item>
                                    <Typography
                                        text={'Top Expenses This Month'}
                                        customStyles={{ fontWeight: 500 }}
                                    />
                                </Grid>
                                {isLoading ? (
                                    <>
                                        <Grid item xs={12}>
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                        </Grid>
                                    </>
                                ) : (
                                    <Grid item width={'100%'} p={'0 !important'}>
                                        <TopExpensesChart
                                            topExpensesData={revenueDetail.top_expenses_this_month}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item sm={6} xs={12} md={4} lg={4}>
                            <Grid
                                container
                                item
                                sx={styles.chartCard}
                                spacing={2}
                                height={isScreenLowerThan1024 ? '326px' : '406px'}
                                justifyContent={'center'}
                                px={2.5}
                                // alignItems={'center'}
                                py={'0 !important'}
                            >
                                <Grid item alignItems={'start'} p={'15px !important'}>
                                    <Typography
                                        text={'Insights'}
                                        customStyles={{ fontWeight: 500 }}
                                    />
                                </Grid>
                                <Grid item container>
                                    <Grid
                                        item
                                        xs={1}
                                        display={'flex'}
                                        justifyContent={'flex-start'}
                                        alignItems={'center'}
                                    >
                                        <Box
                                            sx={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                width: 'var(--spacing-4, 32px)',
                                                height: 'var(--spacing-4, 32px)',
                                                padding: 'var(--spacing-none, 8px)',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                gap: 'var(--spacing-none, 0px)',
                                                borderRadius: '666.667px',
                                                border: '1px solid var(--Icon-Icon-Border, #E5E6EB)',
                                                backgroundColor: (theme) =>
                                                    theme.backgroundColor.primary,
                                            }}
                                        >
                                            {isDarkMode ? (
                                                <LeftArrowDarkMode
                                                    onClick={handlePrevClick}
                                                    width={16}
                                                    height={16}
                                                />
                                            ) : (
                                                <LeftArrow
                                                    onClick={handlePrevClick}
                                                    width={16}
                                                    height={16}
                                                />
                                            )}
                                        </Box>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        xs={10}
                                        display={'flex'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        p={'16px 10px'}
                                        flexDirection={'column'}
                                        textAlign={'center'}
                                    >
                                        <Grid item>
                                            {isLoading ? (
                                                <Skeleton width={150} height={50} />
                                            ) : (
                                                cashInsightItems[currentIndex] && (
                                                    <Typography
                                                        text={cashInsightItems[currentIndex].title}
                                                        customStyles={{
                                                            fontSize: isScreenLowerThan1230
                                                                ? 24
                                                                : 32,
                                                            fontWeight: 700,
                                                        }}
                                                    />
                                                )
                                            )}
                                        </Grid>
                                        {isLoading ? (
                                            <Skeleton width={150} height={50} />
                                        ) : (
                                            cashInsightItems[currentIndex] && (
                                                <Grid item>
                                                    <Typography
                                                        text={
                                                            cashInsightItems[currentIndex].content
                                                        }
                                                        customStyles={{
                                                            fontSize: 16,
                                                            fontWeight: 400,
                                                            color: (theme) =>
                                                                theme.textColor.primary,
                                                        }}
                                                    />
                                                </Grid>
                                            )
                                        )}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={1}
                                        display={'flex'}
                                        justifyContent={'flex-end'}
                                        alignItems={'center'}
                                    >
                                        <Box
                                            sx={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                width: 'var(--spacing-4, 32px)',
                                                height: 'var(--spacing-4, 32px)',
                                                padding: 'var(--spacing-none, 8px)',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                gap: 'var(--spacing-none, 0px)',
                                                borderRadius: '666.667px',
                                                border: '1px solid var(--Icon-Icon-Border, #E5E6EB)',
                                                backgroundColor: (theme) =>
                                                    theme.backgroundColor.primary,
                                            }}
                                        >
                                            {isDarkMode ? (
                                                <RightArrowDarkMode
                                                    onClick={handleNextClick}
                                                    width={16}
                                                    height={16}
                                                />
                                            ) : (
                                                <RightArrow
                                                    onClick={handleNextClick}
                                                    width={16}
                                                    height={16}
                                                />
                                            )}
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item width={'100%'}>
                                    <Divider
                                        sx={{
                                            background: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#394150'
                                                    : '#E5E6EB',
                                        }}
                                    />
                                </Grid>
                                <Grid item pt={isScreenLowerThan1024 ? 'unset' : '48px '}>
                                    {isLoading ? (
                                        <Box
                                            display={'flex'}
                                            flexDirection={'column'}
                                            alignItems={'center'}
                                        >
                                            <Skeleton width={300} height={50} />
                                            <Skeleton width={150} height={50} />
                                        </Box>
                                    ) : (
                                        cashInsightItems[currentIndex] && (
                                            <Typography
                                                text={cashInsightItems[currentIndex].text}
                                                customStyles={{
                                                    fontSize: 14,
                                                    fontWeight: 400,
                                                    color: (theme) => theme.textColor.primary,
                                                    textAlign: 'center',
                                                }}
                                            />
                                        )
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={6} xs={12} md={4} lg={4}>
                            <Grid
                                container
                                item
                                sx={styles.chartCard}
                                spacing={2}
                                px={2.5}
                                py={0}
                                height={isScreenLowerThan1024 ? '326px' : '406px'}
                                justifyContent={'center'}
                            >
                                <Grid item>
                                    <Typography
                                        text={'Cash Positions'}
                                        customStyles={{ fontWeight: 500 }}
                                    />
                                </Grid>
                                {isLoading ? (
                                    <>
                                        <Grid item xs={12}>
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                            <Skeleton width={'100%'} height={10} />
                                        </Grid>
                                    </>
                                ) : (
                                    <Grid item width={'100%'} p={'0 !important'}>
                                        <CashPositionChart
                                            data={{
                                                labels: cashDetail.cash_position.map(
                                                    (cash) => cash.month,
                                                ),
                                                datasets: cashDetail.cash_position.map(
                                                    (cashPosition) => {
                                                        return {
                                                            label: '',
                                                            data: [cashPosition.cash_balance],
                                                            backgroundColor: '#00C791',
                                                            barThickness: 12,
                                                            maxBarThickness: 12,
                                                            borderRadius: 5,
                                                        };
                                                    },
                                                ),
                                            }}
                                            isDarkMode={themeMode === 'dark'}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>

            {/* <p>remove</p> */}
        </Grid>
    );
};
