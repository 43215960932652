import React, { useState } from 'react';

import { Grid } from '@mui/material';
import { DataTable, TablePagination } from 'shared';

import { useCustomMediaQuery } from 'hooks/useMediaQuery';
import { useInventoryService } from 'store/services/inventory.service';
import { exclusionRuleTableHeaders } from 'components/Inventory/utils/exclusionRuleTableHeaders';

const ExclusionRulesTable = ({
    page,
    data,
    loading,
    companyId,
    handlePageChange,
    handleExclusionRuleDeletionSuccess,
}) => {
    const { deleteExecutionRule } = useInventoryService();
    const isMobileScreen = useCustomMediaQuery('(max-width: 600px)');

    const [loadingRowId, setLoadingRowId] = useState(null);

    const handleDeleteExecutionRule = async (rowId) => {
        setLoadingRowId(rowId);
        try {
            await deleteExecutionRule(rowId, companyId);
            handleExclusionRuleDeletionSuccess(
                'Exclusion Rule deleted successfully',
            );
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <DataTable
                    isLoading={loading}
                    data={data?.results}
                    headCells={exclusionRuleTableHeaders(
                        (rowId) => handleDeleteExecutionRule(rowId),
                        loadingRowId,
                    )}
                ></DataTable>
            </Grid>
            <Grid item xs={12} p={'10px 0'}>
                <TablePagination
                    page={page}
                    count={data?.count}
                    isMobileScreen={isMobileScreen}
                    onChange={(e, pageNumber) => handlePageChange(pageNumber)}
                />
            </Grid>
        </Grid>
    );
};

export default ExclusionRulesTable;
