import Papa from 'papaparse';
import { useSelector } from 'react-redux';
import React, { useCallback, Fragment, useState } from 'react';

import { Box, Grid, Select, MenuItem, Typography, FormControl } from '@mui/material';

import {
    Button,
    DataTable,
    Animation,
    Autocomplete,
    CircleLoading,
    SnackBarAlert,
    TablePagination,
} from 'shared';
import {
    saleHeaders,
    purchaseHeaders,
    requiredSaleHeaders,
    requiredPurchaseHeaders,
} from '../../../utils/customHeaders';
import { Upload } from 'components/upload';
import { handleErrorAlert } from 'utils/handleErrorAlert';
import { uploadFileTabStyles } from '../../../style/style';
import { handleSuccessAlert } from 'utils/handleSuccessAlert';
import { useInventoryService } from 'store/services/inventory.service';
import { inventoryTypes } from 'components/Inventory/utils/inventoryTypes';

const UploadFileTab = () => {
    const classes = uploadFileTabStyles();
    const { isLoading, addFileInventory } = useInventoryService();
    const companyData = useSelector((state) => state.company.data);

    const [page, setPage] = useState(1);
    const [rows, setRows] = useState([]);
    const [files, setFiles] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [alertData, setAlertData] = useState({
        severity: '',
        message: '',
        open: false,
    });
    const [selectedValues, setSelectedValues] = useState({});
    const [initialHeaders, setInitialHeaders] = useState(null);
    const [customHeaders, setCustomHeaders] = useState(purchaseHeaders);
    const [inventoryType, setInventoryType] = useState('purcahseActivity');
    const [requiredHeaders, setRequiredHeaders] = useState(requiredPurchaseHeaders);

    const handleFileDrop = useCallback(
        (acceptedFiles) => {
            const newFiles = acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                }),
            );

            setFiles([newFiles[0]]);
            if (newFiles) {
                Papa.parse(newFiles[0], {
                    header: true,
                    skipEmptyLines: true,
                    complete: (results) => {
                        if (results.data.length > 0) {
                            const firstRow = results.data[0];
                            const formatted = Object.keys(firstRow).map((header) => ({
                                id: header,
                                label: header,
                                sort: false,
                                verticalAlign: 'top',
                                changed: false,
                                labelForSelect: header,
                            }));
                            setInitialHeaders(formatted);
                            setHeaders(formatted);
                            setRows(results.data);
                        }
                    },
                    error: (error) => {
                        handleErrorAlert(setAlertData, 'Error parsing CSV, please try again');
                    },
                });
            }
        },
        [files],
    );

    const handleHeaderChange = (event, ch) => {
        const selectedValue = event.target.value;

        setHeaders((prevHeaders) =>
            prevHeaders.map((header) => {
                if (header.id === selectedValues[ch]) {
                    return {
                        ...header,
                        changed: false,
                        label: header.label.replace(` (${ch.replace(' *', '')})`, ''),
                    };
                }
                if (header.id === selectedValue) {
                    return {
                        ...header,
                        changed: true,
                        label: `${header.label} (${ch.replace(' *', '')})`,
                    };
                }
                return header;
            }),
        );

        setSelectedValues((prevSelectedValues) => ({
            ...prevSelectedValues,
            [ch]: selectedValue,
        }));
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleClear = () => {
        setFiles([]);
        setHeaders([]);
        setRows([]);
        setSelectedValues({});
        setPage(1);
        setInitialHeaders([]);
    };

    const formatData = () => {
        const camelCaseKey = (str) => {
            return str
                .replace(/\([^)]*\)/g, '') // Remove anything inside parentheses, including the parentheses
                .replace(/\*/g, '') // Remove asterisks
                .replace(/(?:^|\s)(\w)/g, (_, c) => (c ? c.toUpperCase() : '')) // Capitalize letters after spaces
                .replace(/\s+/g, '') // Remove spaces
                .replace(/^(\w)/, (_, c) => c.toLowerCase()); // Make the first letter lowercase
        };

        return rows.map((row) => {
            const formattedRow = {};

            Object.keys(selectedValues).forEach((key) => {
                const mappedKey = camelCaseKey(key);
                const mappedValue = selectedValues[key];

                formattedRow[mappedKey] = row[mappedValue] || null;
            });

            return formattedRow;
        });
    };

    const handleImport = async () => {
        const data = formatData();
        try {
            const response = await addFileInventory({
                data,
                companyId: companyData.id,
                inventoryType,
            });
            handleSuccessAlert(setAlertData, response.message);
            handleClear();
        } catch (error) {
            handleErrorAlert(setAlertData);
        }
    };

    const handleInventoryTypeChange = (e, inventory) => {
        setInventoryType(inventory.value);
        if (inventory.value == 'salesActivity') {
            setCustomHeaders(saleHeaders);
            setRequiredHeaders(requiredSaleHeaders);
        } else {
            setCustomHeaders(purchaseHeaders);
            setRequiredHeaders(requiredPurchaseHeaders);
        }
        setSelectedValues({});
        setHeaders(initialHeaders);
    };

    if (!companyData) {
        return <CircleLoading />;
    }

    return (
        <Fragment>
            <Grid container sx={classes.inventoryReconciliationWrapper}>
                <Fragment>
                    <Grid item xs={12} width={'100%'}>
                        <Upload
                            multiple
                            accept={{
                                'text/csv': [],
                                'application/vnd.ms-excel': [],
                            }}
                            placeholder={'Drag and drop your files here (CSV)'}
                            files={files}
                            onDrop={handleFileDrop}
                            onRemove={handleClear}
                        />
                        {headers.length > 0 && (
                            <Box sx={classes.buttonsWrapper}>
                                <Button
                                    text="Import"
                                    variant="outlined"
                                    disabled={requiredHeaders.some((req) => !selectedValues[req])}
                                    customStyles={classes.button}
                                    onClick={handleImport}
                                    loading={isLoading}
                                />
                                <Button
                                    text="Clear"
                                    onClick={handleClear}
                                    customStyles={classes.clearButton}
                                />
                            </Box>
                        )}
                    </Grid>
                    {files.length > 0 && (
                        <Grid container marginTop={5} spacing={2}>
                            <Grid item sm={12} md={6}>
                                <Typography marginBottom="20px">
                                    Total Rows: {rows.length}
                                </Typography>
                                {/* <Autocomplete
                                    options={inventoryTypes}
                                    labelKey="label"
                                    valueKey="value"
                                    inlineLabel="Select Activity"
                                    placeholder="Select Activity"
                                    value={inventoryTypes.find(
                                        (type) => type.value == inventoryType,
                                    )}
                                    onChange={handleInventoryTypeChange}
                                    disableClearable
                                /> */}
                            </Grid>
                            {headers.length > 0 && (
                                <Grid item sm={12} md={6}>
                                    {customHeaders.map((ch, i) => (
                                        <Box key={i} sx={classes.dropdownsWrapper}>
                                            <Typography sx={classes.flex}>{ch}</Typography>
                                            <FormControl sx={classes.flex}>
                                                <Select
                                                    placeholder="Select Column"
                                                    value={selectedValues[ch] || ''}
                                                    onChange={(e) => handleHeaderChange(e, ch)}
                                                >
                                                    {headers
                                                        .filter(
                                                            (h) =>
                                                                !h.changed ||
                                                                selectedValues[ch] === h.id,
                                                        )
                                                        .map((h) => (
                                                            <MenuItem
                                                                disabled={h.changed}
                                                                key={h.id}
                                                                value={h.id}
                                                            >
                                                                {h.labelForSelect}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    ))}
                                </Grid>
                            )}
                        </Grid>
                    )}
                </Fragment>
            </Grid>

            {headers.length > 0 && (
                <Grid container spacing={2} marginTop="10px">
                    <Grid item xs={12}>
                        <DataTable
                            headCells={headers}
                            data={rows.slice((page - 1) * 10, page * 10)}
                        ></DataTable>
                    </Grid>
                    <Grid item xs={12} p={'10px 0'}>
                        <TablePagination
                            page={page}
                            count={rows.length}
                            onChange={handleChangePage}
                        />
                    </Grid>
                </Grid>
            )}

            {isLoading && <Animation title="Uploading your inventory data" />}

            <SnackBarAlert alertData={alertData} setAlertData={setAlertData} />
        </Fragment>
    );
};

export default UploadFileTab;
