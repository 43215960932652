import { Typography } from '@mui/material';

import { Button, CoaNestedDropdown } from 'shared';

export const importedTransactionsTableHeaders = (
    selectedOption,
    coaCategoryData,
    handleCoaUpdate,
    handleUnimportClick,
) => {
    return [
        {
            id: 'date',
            label: 'Date',
            sort: false,
            verticalAlign: 'top',
        },
        {
            id: 'description',
            label: 'Description',
            sort: false,
            verticalAlign: 'top',
        },
        {
            id: 'amount',
            label: 'Amount',
            sort: false,
            verticalAlign: 'top',
            render: (row) => `${row.currency_code} ${row.amount}`,
        },
        {
            id: 'category',
            label: 'Category',
            sort: false,
            verticalAlign: 'top',
            render: (row) => (
                <CoaNestedDropdown
                    coaCategoryData={coaCategoryData}
                    selectedOption={{
                        title: `${row?.coa_account?.account_number}, ${row?.coa_account?.account_name}`,
                    }}
                    setSelectedOption={(data) => handleCoaUpdate(data, row)}
                />
            ),
        },
        {
            id: 'type',
            label: 'Type',
            sort: false,
            verticalAlign: 'top',
            render: (row) => (
                <Typography fontSize="12px">
                    {row?.status.replace(/^\w/, (c) => c.toUpperCase())} to{' '}
                    {row?.transaction_type
                        .replace(/_/g, ' ')
                        .replace(/\b\w/g, (char) => char.toUpperCase())}
                </Typography>
            ),
        },
        {
            id: 'import',
            label: 'Action',
            sort: false,
            verticalAlign: 'top',
            render: (row) => (
                <Button
                    sx={{ padding: '5px', margin: '0', fontSize: '12px' }}
                    loading={selectedOption?.id == row.id}
                    size="small"
                    text="Unimport"
                    onClick={() => handleUnimportClick(row)}
                />
            ),
        },
    ];
};
