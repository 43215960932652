import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Link, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { handleErrorAlert } from 'utils/handleErrorAlert';
import { useDivvyService } from 'store/services/divvy.service';
import { Modal, TextField, SnackBarAlert, CircleLoading } from 'shared';

const DivvyModal = ({ open, handleCloseModal, handleDivvySuccess }) => {
    const { isLoading, addDivvyToken, getDivvyToken, updateDivvyToken } = useDivvyService();
    const companyData = useSelector((state) => state.company.data);

    const [alertData, setAlertData] = useState({
        message: '',
        open: false,
        severity: '',
    });

    const [apiToken, setApiToken] = useState('');
    const [selectedDate, setSelectedDate] = useState(null);
    const [buttonMode, setButtonMode] = useState('add');
    const [originalToken, setOriginalToken] = useState('');

    useEffect(() => {
        if (open && companyData?.id) {
            fetchToken();
        }
    }, [open, companyData?.id]);

    const fetchToken = async () => {
        try {
            const response = await getDivvyToken(companyData.id);
            if (response?.token) {
                setApiToken(response.token);
                setOriginalToken(response.token);
                setSelectedDate(dayjs(response.date));
                setButtonMode('update');
                console.log('this is use effect call for update button');
            } else {
                console.log('this is use effect call for add button');
                setButtonMode('add');
            }
        } catch (error) {
            setButtonMode('add');
        }
    };
    const handleSubmit = async () => {
        try {
            const data = {
                apiToken,
                companyId: companyData.id,
                date: selectedDate ? selectedDate.format('YYYY-MM-DD') : null,
            };

            const response =
                buttonMode === 'add' ? await addDivvyToken(data) : await updateDivvyToken(data);

            handleDivvySuccess(response.message);
        } catch (error) {
            handleErrorAlert(setAlertData, error?.response?.data?.message);
        }
    };

    return (
        <Modal
            fullWidth
            open={open}
            title={`${buttonMode === 'update' ? 'Update' : 'Add'} Divvy Token`}
            handleClose={handleCloseModal}
            actionBtnTitle={<Typography text={buttonMode === 'update' ? 'Update' : 'Add'} />}
        >
            <Typography variant="body2" marginBottom={2} fontWeight="bold">
                To generate an API token, please follow the guide
                <Link
                    href="https://help.bill.com/direct/s/article/6633616"
                    target="_blank"
                    rel="noopener noreferrer"
                    fontWeight="bold"
                    ml={0.5}
                >
                    here
                </Link>
                .
            </Typography>

            {isLoading ? (
                <CircleLoading />
            ) : (
                <>
                    {buttonMode === 'update' && (
                        <Typography variant="body2" color="error" marginBottom={2}>
                            Warning: Updating your Divvy token or date will prevent fetching
                            transactions from your previously connected token and cards.
                        </Typography>
                    )}

                    <TextField
                        label="Enter Divvy API Token"
                        fullWidth
                        value={apiToken}
                        onChange={(e) => setApiToken(e.target.value)}
                        margin="normal"
                    />

                    <Typography variant="body2" marginBottom={1} marginTop={2} fontWeight="medium">
                        Select Opening Balance Date
                    </Typography>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            value={selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                            disabled={buttonMode === 'update' && apiToken === originalToken}
                            label="Select Opening Balance Date"
                            fullWidth
                            margin="normal"
                        />
                    </LocalizationProvider>

                    <Box mt={2} display="flex" justifyContent="flex-end">
                        <LoadingButton
                            onClick={handleSubmit}
                            variant="contained"
                            loading={isLoading}
                            disabled={!apiToken || !selectedDate}
                        >
                            {buttonMode === 'update' ? 'Update Token' : 'Add Token'}
                        </LoadingButton>
                    </Box>
                </>
            )}

            <SnackBarAlert alertData={alertData} setAlertData={setAlertData} />
        </Modal>
    );
};

export default DivvyModal;
