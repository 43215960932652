import LinkOffIcon from '@mui/icons-material/LinkOff';
import { Button, Typography, CircularProgress, Box } from '@mui/material';

export const matchedTableHeaders = (
    loadingRowId,
    handleUnmatchAggFileInventoryAndPlaidTransaction,
) => {
    return [
        {
            id: 'date',
            label: 'Date',
            sort: false,
            verticalAlign: 'top',
        },
        {
            id: 'account_name',
            label: 'Account Name',
            sort: false,
            verticalAlign: 'top',
        },
        {
            id: 'description',
            label: 'Description',
            sort: false,
            verticalAlign: 'top',
        },
        {
            id: 'inventoryDescription',
            label: 'Inventory Description',
            sort: false,
            verticalAlign: 'top',
            render: (row) => (
                <Box>
                    {row.aggregated_file_inventories.length ? (
                        row.aggregated_file_inventories.map((inventory) => (
                            <Typography fontSize="12px">{`${inventory.expires_at}, Section: ${inventory.class_1}, Row: ${inventory.class_2}, Seats: ${inventory.seats}`}</Typography>
                        ))
                    ) : (
                        <Typography>-</Typography>
                    )}
                </Box>
            ),
        },
        {
            id: 'matchedPaymentPlan',
            label: 'Payment Plan',
            sort: false,
            verticalAlign: 'top',
            render: (row) => `${row?.matched_payment_plan?.name || '-'}`,
        },
        {
            id: 'amount',
            label: 'Total',
            sort: false,
            verticalAlign: 'top',
            render: (row) => `${row.currency_code} ${row.amount}`,
        },
        {
            id: 'action',
            label: '',
            sort: false,
            verticalAlign: 'top',
            render: (row) =>
                loadingRowId === row?.id ? (
                    <CircularProgress size={24} color="inherit" />
                ) : (
                    <Button
                        sx={{ padding: 0, color: 'gray' }}
                        onClick={() => handleUnmatchAggFileInventoryAndPlaidTransaction(row)}
                    >
                        {<LinkOffIcon fontSize="small" />}
                        <Typography fontSize="12px"> Unmatch</Typography>
                    </Button>
                ),
        },
    ];
};
