import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, Grid, Stack, Box } from '@mui/material';

import { tabsData } from './utils/tabsData';
import { inventoryStyles } from './style/style';
import { SET_SELECTED_ITEM } from 'store/actions';
import InventoryTab from './components/InventoryTab';
import { useCustomMediaQuery } from 'hooks/useMediaQuery';
import InventoryEmails from './components/InventoryEmails';
import InventoryReconciliation from './components/InventoryReconciliation';

const Inventory = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const isMobileScreen = useCustomMediaQuery('(max-width: 600px)');
    const selectedTab = useSelector((state) => state.me.selectedItems);

    const classes = inventoryStyles({ isMobileScreen });

    const changeTab = (tab, index) => {
        dispatch(SET_SELECTED_ITEM(tab.label));
        navigate(tab.path);
    };

    useEffect(() => {
        const currentTab = tabsData.find((tab) => tab.path == location.pathname);
        if (currentTab && currentTab.label !== selectedTab) {
            dispatch(SET_SELECTED_ITEM(currentTab.label));
        }
    }, [location.pathname, dispatch, selectedTab]);

    const getTabButtonStyle = (tabLabel) => ({
        textTransform: 'none',
        fontSize: '1rem',
        fontWeight: selectedTab === tabLabel ? '600' : '500',
        backgroundColor: selectedTab === tabLabel ? '#CEEFDF' : 'transparent',
        borderRadius: selectedTab === tabLabel ? '3px' : 'none',
        color: selectedTab === tabLabel ? '#0A8C4D' : '#6C727F',
    });

    return (
        <Box>
            <Box padding={1} sx={classes.menuWrapper}>
                <Stack
                    display="flex"
                    flexDirection={{ xs: 'column', md: 'row' }}
                    gap={1}
                    width={{ xs: '100%', md: 'fit-content' }}
                    style={classes.stack}
                    alignItems="center"
                >
                    {tabsData?.map((tab) => (
                        <Grid item key={tab.label} borderRadius={'5px'}>
                            <Button
                                onClick={() => changeTab(tab)}
                                style={getTabButtonStyle(tab.label)}
                                variant={selectedTab === tab.label ? 'contained' : 'text'}
                            >
                                {tab.label}
                            </Button>
                        </Grid>
                    ))}
                </Stack>
            </Box>

            {selectedTab === 'Inventory' && (
                <Grid sx={classes.tabContentWrapper}>
                    <InventoryTab />
                </Grid>
            )}

            {selectedTab === 'Inventory Reconciliation' && (
                <Grid sx={classes.tabContentWrapper}>
                    <InventoryReconciliation />
                </Grid>
            )}

            {selectedTab === 'Emails' && (
                <Grid sx={classes.tabContentWrapper}>
                    <InventoryEmails />
                </Grid>
            )}
        </Box>
    );
};

export default Inventory;
