import { Formik, Form } from 'formik';
import React, { Fragment } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Divider, Grid } from '@mui/material';

import { TextField, Autocomplete } from 'shared';
import { useCustomMediaQuery } from 'hooks/useMediaQuery';
import { allBanksUpdateFormStyles } from '../style/style';
import { ACCOUNT_TYPES_1, ACCOUNT_TYPES_2 } from 'utils/helpers';
import { nonQbAccountsInitialValues, nonQbAccountsSchema } from '../utils/nonQbBankAccountSchema';

const NonQbAccountsForm = ({ coas, accounts, loading, handleBlukAccountsUpdate }) => {
    const isMobileScreen = useCustomMediaQuery('(max-width:600px)');
    const classes = allBanksUpdateFormStyles({ isMobileScreen });

    const filterChangedAccounts = (updatedAccounts) => {
        return updatedAccounts.filter((updatedAccount, index) => {
            const initialAccount = nonQbAccountsInitialValues(accounts).accounts[index];
            return (
                updatedAccount.name !== initialAccount.name ||
                updatedAccount.account_number !== initialAccount.account_number ||
                updatedAccount.parent_account !== initialAccount.parent_account ||
                updatedAccount.account_type_1 !== initialAccount.account_type_1 ||
                updatedAccount.account_type_2 !== initialAccount.account_type_2
            );
        });
    };

    const handleFormSubmit = (values) => {
        const modifiedAccounts = filterChangedAccounts(values.accounts);
        handleBlukAccountsUpdate(modifiedAccounts);
    };

    return (
        <Formik
            onSubmit={handleFormSubmit}
            validationSchema={nonQbAccountsSchema}
            initialValues={nonQbAccountsInitialValues(accounts)}
        >
            {({ values, setFieldValue, errors, touched }) => (
                <Form>
                    <Box sx={classes.formWrapper}>
                        <Grid container spacing={{ xs: 1, md: 2 }}>
                            {values.accounts.map((account, index) => (
                                <Fragment key={account.id}>
                                    <Grid item xs={4} md={2}>
                                        <TextField
                                            fullWidth
                                            name={`accounts[${index}].account_number`}
                                            placeholder="Account Number"
                                            inlineLabel="Account Number"
                                            value={values.accounts[index].account_number}
                                            onChange={(e) =>
                                                setFieldValue(
                                                    `accounts[${index}].account_number`,
                                                    e.target.value,
                                                )
                                            }
                                            error={
                                                touched.accounts?.[index]?.account_number &&
                                                !!errors.accounts?.[index]?.account_number
                                            }
                                            helperText={
                                                touched.accounts?.[index]?.account_number &&
                                                errors.accounts?.[index]?.account_number
                                            }
                                        />
                                    </Grid>

                                    <Grid item xs={8} md={3}>
                                        <TextField
                                            fullWidth
                                            name={`accounts[${index}].name`}
                                            placeholder="Account Name"
                                            inlineLabel="Account Name"
                                            value={values.accounts[index].name}
                                            onChange={(e) =>
                                                setFieldValue(
                                                    `accounts[${index}].name`,
                                                    e.target.value,
                                                )
                                            }
                                            error={
                                                touched.accounts?.[index]?.name &&
                                                !!errors.accounts?.[index]?.name
                                            }
                                            helperText={
                                                touched.accounts?.[index]?.name &&
                                                errors.accounts?.[index]?.name
                                            }
                                        />
                                    </Grid>

                                    <Grid item xs={6} md={3}>
                                        <Autocomplete
                                            options={coas}
                                            labelKey="label"
                                            valueKey="id"
                                            value={
                                                account.parent_account
                                                    ? coas.find(
                                                          (coa) =>
                                                              coa.id === account.parent_account,
                                                      )
                                                    : null
                                            }
                                            onChange={(e, value) => {
                                                setFieldValue(
                                                    `accounts[${index}].parent_account`,
                                                    value ? value?.id : null,
                                                );
                                            }}
                                            inlineLabel="Parent Account"
                                            placeholder="Select Parent Account"
                                            disableClearable
                                        />
                                    </Grid>

                                    <Grid item xs={3} md={2}>
                                        {!account?.parent_account && (
                                            <Autocomplete
                                                options={ACCOUNT_TYPES_1}
                                                labelKey="label"
                                                valueKey="value"
                                                value={
                                                    ACCOUNT_TYPES_1.find(
                                                        (option) =>
                                                            option.value ===
                                                            values.accounts[index].account_type_1,
                                                    ) || null
                                                }
                                                onChange={(event, value) => {
                                                    setFieldValue(
                                                        `accounts[${index}].account_type_1`,
                                                        value ? value.value : null,
                                                    );
                                                    setFieldValue(
                                                        `accounts[${index}].account_type_2`,
                                                        null,
                                                    );
                                                }}
                                                inlineLabel="Account Type 1"
                                                placeholder="Select Account Type 1"
                                                disableClearable
                                            />
                                        )}
                                    </Grid>

                                    <Grid item xs={3} md={2}>
                                        {!account?.parent_account && (
                                            <Autocomplete
                                                options={
                                                    ACCOUNT_TYPES_2[account.account_type_1] || []
                                                }
                                                labelKey="label"
                                                valueKey="value"
                                                value={
                                                    (
                                                        ACCOUNT_TYPES_2[account.account_type_1] ||
                                                        []
                                                    ).find(
                                                        (option) =>
                                                            option.value ===
                                                            values.accounts[index].account_type_2,
                                                    ) || null
                                                }
                                                onChange={(event, value) => {
                                                    setFieldValue(
                                                        `accounts[${index}].account_type_2`,
                                                        value ? value.value : null,
                                                    );
                                                }}
                                                inlineLabel="Account Type 2"
                                                placeholder="Select Account Type 2"
                                                disableClearable
                                            />
                                        )}
                                    </Grid>

                                    {index !== accounts?.length - 1 && (
                                        <Grid item xs={12}>
                                            <Divider sx={classes.dividerBackground} />
                                        </Grid>
                                    )}
                                </Fragment>
                            ))}
                        </Grid>
                    </Box>

                    <Box
                        width="100%"
                        display="flex"
                        justifyContent="flex-end"
                        marginTop={isMobileScreen ? 1 : 3}
                        gap={2}
                    >
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            loading={loading}
                        >
                            Update
                        </LoadingButton>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

export default NonQbAccountsForm;
