import { useSelector } from 'react-redux';
import React, { useState, Fragment, useEffect } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Box, CircularProgress } from '@mui/material';
import { Button, SnackBarAlert, Typography } from 'shared';

import JournalForm from './components/JournalForm';
import JournalTable from './components/JournalTable';
import { useCustomMediaQuery } from 'hooks/useMediaQuery';
import { useBookkeeperService } from 'store/services/bookkeeper.service';
import { journalButton, journalButtonText, makeStyles } from './style/style';

const Journal = () => {
    const companyData = useSelector((state) => state.company.data);
    const isMobileScreen = useCustomMediaQuery('(max-width: 600px)');
    const { getManualJournalEntry, isLoading } = useBookkeeperService();
    const isScreenLowerThan1024 = useCustomMediaQuery('(max-width: 1025px)');

    const classes = makeStyles({ isScreenLowerThan1024 });

    const [page, setPage] = useState(1);
    const [alertData, setAlertData] = useState({
        open: false,
        message: '',
        severity: '',
    });
    const [selectedRow, setSelectedRow] = useState(null);
    const [openJournalForm, setOpenJournalForm] = useState(false);
    const [manualJournalEntries, setManualJournalEntries] = useState([]);

    const handleJournalCreationSuccess = () => {
        setOpenJournalForm(false);
        handleManualJournalEntry(page);
    };

    const handleManualJournalEntry = async (page) => {
        const response = await getManualJournalEntry(companyData.id, page);
        setManualJournalEntries(response);
    };

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
        handleManualJournalEntry(pageNumber);
    };

    const handleEdit = (data) => {
        setSelectedRow(data);
        setOpenJournalForm(true);
    };

    const handleCloseModal = () => {
        setSelectedRow(null);
        setOpenJournalForm(false);
    };

    useEffect(() => {
        if (companyData) {
            handleManualJournalEntry(page);
        }
    }, [companyData]);

    return (
        <Box sx={classes.root}>
            {companyData ? (
                <Fragment>
                    <Box sx={classes.buttonWrapper}>
                        <Button
                            text={
                                <Typography
                                    text={'Add Journal Entry'}
                                    customStyles={journalButtonText}
                                />
                            }
                            startIcon={<AddIcon htmlColor={'#FFF'} />}
                            customStyles={journalButton(isMobileScreen)}
                            onClick={() => setOpenJournalForm(true)}
                        />
                    </Box>
                    <Box>
                        <JournalTable
                            page={page}
                            loading={isLoading}
                            handleEdit={handleEdit}
                            data={manualJournalEntries}
                            handlePageChange={handlePageChange}
                        />
                    </Box>
                    {openJournalForm && (
                        <JournalForm
                            type={selectedRow ? 'Edit' : 'Add'}
                            open={openJournalForm}
                            companyId={companyData.id}
                            selectedRow={selectedRow}
                            handleCloseModal={handleCloseModal}
                            handleJournalCreationSuccess={
                                handleJournalCreationSuccess
                            }
                        />
                    )}
                    <SnackBarAlert
                        alertData={alertData}
                        setAlertData={setAlertData}
                    />
                </Fragment>
            ) : (
                <Box sx={classes.loader}>
                    <CircularProgress />
                </Box>
            )}
        </Box>
    );
};

export default Journal;
