import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { Formik, Form, FieldArray } from 'formik';
import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { DatePicker } from '@mui/x-date-pickers';
import { Grid, IconButton, Divider, Box } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import {
    Modal,
    Button,
    Typography,
    Autocomplete,
    TextField,
    CircleLoading,
    SnackBarAlert,
} from 'shared';
import AJAX from 'services/api.service';
import {
    classHeaders,
    inventoryPlaceholders,
    InventorySchema,
    statusOptions,
} from 'components/Inventory/utils/bulkEditHelpers';
import { handleSuccessAlert } from 'utils/handleSuccessAlert';
import { BulkEditForm } from 'components/Inventory/style/style';
import { inventoryListHeaders } from '../../../utils/inventoryListHeaders';

const BulkEditInventory = ({ open, data, onClose, onSubmit }) => {
    const classes = BulkEditForm();

    const [initialRows, setInitialRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [alertData, setAlertData] = useState({
        message: '',
        open: false,
        severity: '',
    });
    const { id: companyId } = useSelector((state) => state.company.data);

    const handleDeleteInventory = async (inventoryId, rowIndex, remove) => {
        if (!inventoryId || (typeof inventoryId === 'string' && inventoryId.startsWith('temp-'))) {
            remove(rowIndex);
            return;
        }

        try {
            const response = await AJAX.request(
                'DELETE',
                `api/inventory/${inventoryId}/delete/?company_id=${companyId}`,
            );
            if (response?.error_code === 0) {
                handleSuccessAlert(setAlertData, 'Inventory deleted successfully.');
                remove(rowIndex);
            } else {
                throw new Error(response?.message || 'Error deleting inventory.');
            }
        } catch (error) {
            setAlertData({
                message: 'Error deleting inventory. Please try again.',
                open: true,
                severity: 'error',
            });
        }
    };

    useEffect(() => {
        if (open && data) {
            const updatedRows = data.map((row) => ({
                ...row,
                status: row.status || 'active',
                purchased_at: row.purchased_at
                    ? dayjs(row.purchased_at).toISOString()
                    : new Date().toISOString(),
                purchase_price: row.purchase_price
                    ? parseFloat(row.purchase_price).toFixed(2)
                    : null,
            }));
            setInitialRows(updatedRows);
            setLoading(false);
        }
    }, [open, data]);

    if (!open) return null;

    if (loading) {
        return <CircleLoading />;
    }
    return (
        <>
            <Modal
                fullWidth
                open={open}
                title="Bulk Edit Inventory"
                handleClose={onClose}
                customWidth="60%"
                showFooter={false}
            >
                <Formik
                    enableReinitialize
                    initialValues={{ rows: initialRows }}
                    validationSchema={InventorySchema}
                    onSubmit={(values) => {
                        const processedRows = values.rows
                            .filter((row) => row.id)
                            .map((row) => ({
                                ...row,
                                status: row.status || 'active',
                                purchased_at: row.purchased_at || new Date().toISOString(),
                                purchase_price: row.purchase_price
                                    ? parseFloat(row.purchase_price).toFixed(2)
                                    : null,
                            }));
                        onSubmit(processedRows);
                        onClose();
                    }}
                >
                    {({ values, setFieldValue, errors, touched }) => (
                        <Form style={classes.formContainer}>
                            <FieldArray name="rows">
                                {({ push, remove }) => (
                                    <>
                                        {values.rows && values.rows.length > 0 ? (
                                            values.rows.map((row, rowIndex) => (
                                                <Grid
                                                    container
                                                    key={row.id || rowIndex}
                                                    spacing={2}
                                                    sx={classes.modalBack}
                                                >
                                                    <Grid item xs={12}>
                                                        <Grid
                                                            container
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                        >
                                                            <Grid item>
                                                                <Typography
                                                                    text={`Reference Id: ${
                                                                        row.reference_id ?? 'N/A'
                                                                    }`}
                                                                    variant="h6"
                                                                />
                                                            </Grid>
                                                            <Grid item>
                                                                <IconButton
                                                                    onClick={() =>
                                                                        handleDeleteInventory(
                                                                            row.id,
                                                                            rowIndex,
                                                                            remove,
                                                                        )
                                                                    }
                                                                >
                                                                    <DeleteOutlineIcon color="error" />
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                        <Divider sx={classes.firsrDivider} />
                                                    </Grid>

                                                    {classHeaders.map((header) => (
                                                        <Grid key={header.id} item xs={12} sm={4}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                variant="outlined"
                                                                inlineLabel={header.label}
                                                                placeholder={
                                                                    inventoryPlaceholders[
                                                                        header.id
                                                                    ] || `Enter ${header.label}`
                                                                }
                                                                name={`rows[${rowIndex}].${header.id}`}
                                                                value={row[header.id] || ''}
                                                                onChange={(e) =>
                                                                    setFieldValue(
                                                                        `rows[${rowIndex}].${header.id}`,
                                                                        e.target.value,
                                                                    )
                                                                }
                                                                error={
                                                                    touched?.rows &&
                                                                    touched.rows[rowIndex] &&
                                                                    Boolean(
                                                                        errors?.rows &&
                                                                            errors.rows[rowIndex]?.[
                                                                                header.id
                                                                            ],
                                                                    )
                                                                }
                                                                helperText={
                                                                    touched?.rows &&
                                                                    touched.rows[rowIndex] &&
                                                                    errors?.rows &&
                                                                    errors.rows[rowIndex]?.[
                                                                        header.id
                                                                    ]
                                                                }
                                                            />
                                                        </Grid>
                                                    ))}

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            variant="outlined"
                                                            inlineLabel="Description"
                                                            name={`rows[${rowIndex}].description`}
                                                            placeholder={
                                                                inventoryPlaceholders.description
                                                            }
                                                            value={row.description || ''}
                                                            onChange={(e) =>
                                                                setFieldValue(
                                                                    `rows[${rowIndex}].description`,
                                                                    e.target.value,
                                                                )
                                                            }
                                                            error={
                                                                touched?.rows &&
                                                                touched.rows[rowIndex] &&
                                                                Boolean(
                                                                    errors?.rows &&
                                                                        errors.rows[rowIndex]
                                                                            ?.description,
                                                                )
                                                            }
                                                            helperText={
                                                                touched?.rows &&
                                                                touched.rows[rowIndex] &&
                                                                errors?.rows &&
                                                                errors.rows[rowIndex]?.description
                                                            }
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} sm={4}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            variant="outlined"
                                                            inlineLabel="Reference"
                                                            name={`rows[${rowIndex}].reference_id`}
                                                            value={row.reference_id || ''}
                                                            placeholder={
                                                                inventoryPlaceholders.reference_id
                                                            }
                                                            onChange={(e) =>
                                                                setFieldValue(
                                                                    `rows[${rowIndex}].reference_id`,
                                                                    e.target.value,
                                                                )
                                                            }
                                                            error={
                                                                touched?.rows &&
                                                                touched.rows[rowIndex] &&
                                                                Boolean(
                                                                    errors?.rows &&
                                                                        errors.rows[rowIndex]
                                                                            ?.reference_id,
                                                                )
                                                            }
                                                            helperText={
                                                                touched?.rows &&
                                                                touched.rows[rowIndex] &&
                                                                errors?.rows &&
                                                                errors.rows[rowIndex]?.reference_id
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            variant="outlined"
                                                            inlineLabel="Sale Ref"
                                                            name={`rows[${rowIndex}].sale_reference_id`}
                                                            placeholder={
                                                                inventoryPlaceholders.sale_reference_id
                                                            }
                                                            value={row.sale_reference_id || ''}
                                                            onChange={(e) =>
                                                                setFieldValue(
                                                                    `rows[${rowIndex}].sale_reference_id`,
                                                                    e.target.value,
                                                                )
                                                            }
                                                            error={
                                                                touched?.rows &&
                                                                touched.rows[rowIndex] &&
                                                                Boolean(
                                                                    errors?.rows &&
                                                                        errors.rows[rowIndex]
                                                                            ?.sale_reference_id,
                                                                )
                                                            }
                                                            helperText={
                                                                touched?.rows &&
                                                                touched.rows[rowIndex] &&
                                                                errors?.rows &&
                                                                errors.rows[rowIndex]
                                                                    ?.sale_reference_id
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            variant="outlined"
                                                            inlineLabel="Purchase ID"
                                                            name={`rows[${rowIndex}].purchase_id`}
                                                            value={row.purchase_id || ''}
                                                            placeholder={
                                                                inventoryPlaceholders.purchase_id
                                                            }
                                                            onChange={(e) =>
                                                                setFieldValue(
                                                                    `rows[${rowIndex}].purchase_id`,
                                                                    e.target.value,
                                                                )
                                                            }
                                                            error={
                                                                touched?.rows &&
                                                                touched.rows[rowIndex] &&
                                                                Boolean(
                                                                    errors?.rows &&
                                                                        errors.rows[rowIndex]
                                                                            ?.purchase_id,
                                                                )
                                                            }
                                                            helperText={
                                                                touched?.rows &&
                                                                touched.rows[rowIndex] &&
                                                                errors?.rows &&
                                                                errors.rows[rowIndex]?.purchase_id
                                                            }
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} sm={4}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            variant="outlined"
                                                            inlineLabel="Purchase Price"
                                                            name={`rows[${rowIndex}].purchase_price`}
                                                            placeholder={
                                                                inventoryPlaceholders.purchase_price
                                                            }
                                                            value={row.purchase_price || ''}
                                                            onChange={(e) =>
                                                                setFieldValue(
                                                                    `rows[${rowIndex}].purchase_price`,
                                                                    e.target.value,
                                                                )
                                                            }
                                                            error={
                                                                touched?.rows &&
                                                                touched.rows[rowIndex] &&
                                                                Boolean(
                                                                    errors?.rows &&
                                                                        errors.rows[rowIndex]
                                                                            ?.purchase_price,
                                                                )
                                                            }
                                                            helperText={
                                                                touched?.rows &&
                                                                touched.rows[rowIndex] &&
                                                                errors?.rows &&
                                                                errors.rows[rowIndex]
                                                                    ?.purchase_price
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            variant="outlined"
                                                            inlineLabel="Payment Method"
                                                            name={`rows[${rowIndex}].payment_method`}
                                                            placeholder={
                                                                inventoryPlaceholders.payment_method ||
                                                                'Enter Payment Method'
                                                            }
                                                            value={row.payment_method || ''}
                                                            onChange={(e) =>
                                                                setFieldValue(
                                                                    `rows[${rowIndex}].payment_method`,
                                                                    e.target.value,
                                                                )
                                                            }
                                                            error={
                                                                touched?.rows &&
                                                                touched.rows[rowIndex] &&
                                                                Boolean(
                                                                    errors?.rows &&
                                                                        errors.rows[rowIndex]
                                                                            ?.payment_method,
                                                                )
                                                            }
                                                            helperText={
                                                                touched?.rows &&
                                                                touched.rows[rowIndex] &&
                                                                errors?.rows &&
                                                                errors.rows[rowIndex]
                                                                    ?.payment_method
                                                            }
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} sm={4}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            variant="outlined"
                                                            inlineLabel="Payment Last Digits"
                                                            name={`rows[${rowIndex}].payment_last_digits`}
                                                            placeholder={
                                                                inventoryPlaceholders.payment_last_digits ||
                                                                'Enter Last 4 Digits'
                                                            }
                                                            value={row.payment_last_digits || ''}
                                                            onChange={(e) =>
                                                                setFieldValue(
                                                                    `rows[${rowIndex}].payment_last_digits`,
                                                                    e.target.value,
                                                                )
                                                            }
                                                            error={
                                                                touched?.rows &&
                                                                touched.rows[rowIndex] &&
                                                                Boolean(
                                                                    errors?.rows &&
                                                                        errors.rows[rowIndex]
                                                                            ?.payment_last_digits,
                                                                )
                                                            }
                                                            helperText={
                                                                touched?.rows &&
                                                                touched.rows[rowIndex] &&
                                                                errors?.rows &&
                                                                errors.rows[rowIndex]
                                                                    ?.payment_last_digits
                                                            }
                                                        />
                                                    </Grid>

                                                    <Grid container item xs={12}>
                                                        <Grid item xs={12} sm={4} paddingRight={1}>
                                                            <Autocomplete
                                                                fullWidth
                                                                options={statusOptions}
                                                                inlineLabel="Status"
                                                                labelKey="label"
                                                                valueKey="value"
                                                                placeholder={
                                                                    inventoryPlaceholders.status
                                                                }
                                                                onChange={(e, value) =>
                                                                    setFieldValue(
                                                                        `rows[${rowIndex}].status`,
                                                                        value ? value.value : '',
                                                                    )
                                                                }
                                                                value={
                                                                    statusOptions.find(
                                                                        (option) =>
                                                                            option.value ===
                                                                            (row.status ||
                                                                                'active'),
                                                                    ) || null
                                                                }
                                                                isSearchField={false}
                                                                customStyle={{}}
                                                                error={
                                                                    touched?.rows &&
                                                                    touched.rows[rowIndex] &&
                                                                    Boolean(
                                                                        errors?.rows &&
                                                                            errors.rows[rowIndex]
                                                                                ?.status,
                                                                    )
                                                                }
                                                                helperText={
                                                                    touched?.rows &&
                                                                    touched.rows[rowIndex] &&
                                                                    errors?.rows &&
                                                                    errors.rows[rowIndex]?.status
                                                                }
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12} paddingLeft={1} sm={4}>
                                                            <LocalizationProvider
                                                                dateAdapter={AdapterDayjs}
                                                            >
                                                                <DatePicker
                                                                    label="Expires At"
                                                                    value={
                                                                        row.expires_at
                                                                            ? dayjs(row.expires_at)
                                                                            : null
                                                                    }
                                                                    onChange={(date) => {
                                                                        const formattedDate = date
                                                                            ? dayjs(date).format(
                                                                                  'YYYY-MM-DD',
                                                                              )
                                                                            : '';
                                                                        setFieldValue(
                                                                            `rows[${rowIndex}].expires_at`,
                                                                            formattedDate,
                                                                        );
                                                                    }}
                                                                    sx={classes.datePicker}
                                                                    slotProps={{
                                                                        textField: {
                                                                            fullWidth: true,
                                                                            variant: 'outlined',
                                                                            error:
                                                                                touched?.rows?.[
                                                                                    rowIndex
                                                                                ]?.expires_at &&
                                                                                Boolean(
                                                                                    errors?.rows?.[
                                                                                        rowIndex
                                                                                    ]?.expires_at,
                                                                                ),
                                                                            helperText:
                                                                                touched?.rows?.[
                                                                                    rowIndex
                                                                                ]?.expires_at &&
                                                                                errors?.rows?.[
                                                                                    rowIndex
                                                                                ]?.expires_at,
                                                                        },
                                                                    }}
                                                                />
                                                            </LocalizationProvider>
                                                        </Grid>

                                                        <Grid item xs={12} sm={4} paddingLeft={2}>
                                                            <LocalizationProvider
                                                                dateAdapter={AdapterDayjs}
                                                            >
                                                                <DatePicker
                                                                    label="Purchased At"
                                                                    value={
                                                                        row.purchased_at
                                                                            ? dayjs(
                                                                                  row.purchased_at,
                                                                              )
                                                                            : dayjs()
                                                                    }
                                                                    onChange={(date) => {
                                                                        const formattedDate = date
                                                                            ? dayjs(date).format(
                                                                                  'YYYY-MM-DD',
                                                                              )
                                                                            : '';
                                                                        setFieldValue(
                                                                            `rows[${rowIndex}].purchased_at`,
                                                                            formattedDate,
                                                                        );
                                                                    }}
                                                                    sx={classes.datePicker}
                                                                    slotProps={{
                                                                        textField: {
                                                                            fullWidth: true,
                                                                            variant: 'outlined',
                                                                            error:
                                                                                touched?.rows?.[
                                                                                    rowIndex
                                                                                ]?.purchased_at &&
                                                                                Boolean(
                                                                                    errors?.rows?.[
                                                                                        rowIndex
                                                                                    ]?.purchased_at,
                                                                                ),
                                                                            helperText:
                                                                                touched?.rows?.[
                                                                                    rowIndex
                                                                                ]?.purchased_at &&
                                                                                errors?.rows?.[
                                                                                    rowIndex
                                                                                ]?.purchased_at,
                                                                        },
                                                                    }}
                                                                />
                                                            </LocalizationProvider>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            ))
                                        ) : (
                                            <Typography text="No data available" />
                                        )}

                                        <Box display="flex" justifyContent="center" mt={2}>
                                            <IconButton
                                                onClick={() => {
                                                    const newRow = {};
                                                    inventoryListHeaders.forEach((header) => {
                                                        newRow[header.id] = '';
                                                    });
                                                    newRow.id = `temp-${Date.now()}`;
                                                    newRow.reference_id = `${Date.now()}`;
                                                    push(newRow);
                                                }}
                                                size="large"
                                                sx={classes.newRowIcon}
                                            >
                                                <AddIcon
                                                    fontSize="large"
                                                    sx={classes.addIconColor}
                                                />
                                            </IconButton>
                                        </Box>
                                    </>
                                )}
                            </FieldArray>

                            <Grid container justifyContent="flex-end" spacing={2} mt={2}>
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        text="Cancel"
                                        onClick={onClose}
                                        style={classes.footerSetup}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button type="submit" text="Save Changes" />
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Modal>
            <SnackBarAlert alertData={alertData} setAlertData={setAlertData} />
        </>
    );
};

export default BulkEditInventory;
