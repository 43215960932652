export const extractPlainText = (html) => {
    const toMatch = html.match(/(To:\s*<[^>]+>)/i);
    let toLine = '';
    if (toMatch) {
        toLine = toMatch[1];

        html = html.replace(toMatch[1], 'TO_PLACEHOLDER');
    }

    // Process the rest of the text
    let result = html
        .replace(/<[^>]*>/g, '') // Remove HTML tags
        .replace(/\[\s*[\s\S]*?\s*\]/g, '') // Remove text in square brackets
        .replace(/\n\s*\n\s*\n+/g, '\n\n') // Collapse multiple newlines
        .trim();

    // Restore the To: line if it was replaced
    if (toLine) {
        result = result.replace('TO_PLACEHOLDER', toLine);
    }
    return result;
};

export const extractPlainTextV2 = (html) => {
    return html
        .replace(/<[^>]*>/g, '') // Replace HTML tags with spaces
        .replace(/\[\s*[\s\S]*?\s*\]/g, '') // Remove everything inside square brackets and the brackets themselves
        .replace(/\n\s*\n\s*\n+/g, '\n\n') // Replace more than two consecutive newlines with exactly two
        .trim(); // Trim leading/trailing spaces
};
