import dayjs from 'dayjs';
import { Formik, Form } from 'formik';
import React from 'react';

import { Grid } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { Autocomplete, Button, TextField } from 'shared';
import {
    salesActivityFiltersSchema,
    salesActivityFiltersInitialValues,
} from 'components/Inventory/utils/salesActivityFiltersSchema';
import { activityFiltersStyles } from 'components/Inventory/style/style';
import { activityStatuses } from 'components/Inventory/utils/activityStatuses';

const SalesActivityFilters = ({
    downloadLoading,
    handleFiltersSubmit,
    handleGetInitialData,
    handleDownloadPurcahseActivity,
}) => {
    const classes = activityFiltersStyles();

    const handleClearClick = (handleReset) => {
        handleReset();
        handleGetInitialData();
    };

    const handleFormSubmit = (values) => {
        const filters = {
            ...values,
            saleStartDate: values.saleStartDate
                ? dayjs(values.saleStartDate).format('YYYY-MM-DD')
                : null,
            saleEndDate: values.saleEndDate ? dayjs(values.saleEndDate).format('YYYY-MM-DD') : null,
            status: values.status?.value,
        };
        handleFiltersSubmit(filters);
    };

    return (
        <Formik
            initialValues={salesActivityFiltersInitialValues}
            validationSchema={salesActivityFiltersSchema}
            onSubmit={handleFormSubmit}
        >
            {({ values, handleReset, handleChange, setFieldValue }) => {
                return (
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} lg={3}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        value={values.saleStartDate && dayjs(values.saleStartDate)}
                                        onChange={(date) => setFieldValue('saleStartDate', date)}
                                        label="Start Date"
                                        placeholder="Start Date"
                                        format="YYYY-MM-DD"
                                        sx={classes.datePicker}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        value={values.saleEndDate && dayjs(values.saleEndDate)}
                                        onChange={(date) => setFieldValue('saleEndDate', date)}
                                        label="End Date"
                                        placeholder="End Date"
                                        format="YYYY-MM-DD"
                                        sx={classes.datePicker}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <TextField
                                    fullWidth
                                    name="amountDue"
                                    inlineLabel="Amount"
                                    type="number"
                                    value={values.amountDue}
                                    placeholder="Search by amount"
                                    onChange={handleChange}
                                    customStyle={classes.inputField}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <Autocomplete
                                    options={activityStatuses}
                                    labelKey="label"
                                    valueKey="value"
                                    placeholder="Select Status"
                                    name="status"
                                    inlineLabel="Status"
                                    value={values.status}
                                    onChange={(e, value) => setFieldValue('status', value)}
                                    isSearchField={false}
                                    disableClearable
                                    customStyle={classes.dropdown}
                                    showTotal={true}
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Grid item>
                                <Button
                                    text="Download CSV"
                                    loading={downloadLoading}
                                    onClick={handleDownloadPurcahseActivity}
                                />
                            </Grid>

                            <Grid item marginTop="10px" marginBottom="10px" marginLeft="auto">
                                <Button
                                    text="Clear"
                                    onClick={() => handleClearClick(handleReset)}
                                    customStyles={classes.clearButton}
                                />
                                <Button text="Apply" type="submit" />
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default SalesActivityFilters;
