export const formatTransaction = (transaction) => {
    return `$${Math.abs(transaction.amount).toFixed(2)} on ${new Date(
        transaction.date,
    ).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })} from ${
        transaction.vendor || transaction.description
    } (x${transaction.card_last4})`;
};

export const formatInventory = (inventory) => {
    return `${inventory.description}, ${inventory.class_1}, ${inventory.class_2}, Seats ${
        inventory.seats
    } | ${new Date(inventory.purchased_at).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    })} | $${inventory.purchase_price.toFixed(2)} | ${inventory.purchase_email || 'No email'}`;
};

export const getGeneratedReasoning = (cardData) => {
    const last4 =
        cardData.transaction?.card_last4 ?? cardData.transactions?.[0]?.card_last4 ?? 'XXXX';

    return `Matched based on event, date, ticket details, and card last 4 digits found in email`;
};
