import dayjs from 'dayjs';
import { Formik, Form } from 'formik';
import { useSelector } from 'react-redux';
import React, { Fragment, useEffect, useState } from 'react';

import {
    importTransactions,
    excludeTransactions,
    updateTransactionCoa,
    fetchBankTransactions,
} from 'store/services/bank.Service';
import RowAccordion from './RowAccordion';
import {
    unimportedTransactionsSchema,
    unimportedTransactionsInitialValues,
} from '../utils/unimportedTransactionsFiltersSchema';
import { handleErrorAlert } from 'utils/handleErrorAlert';
import { useCustomMediaQuery } from 'hooks/useMediaQuery';
import { handleSuccessAlert } from 'utils/handleSuccessAlert';
import SelectedTransactionsInfo from './SelectedTransactionsInfo';
import UnimportedTransactionsFilters from './UnimportedTransactionsFilters';
import { SnackBarAlert, TablePagination, DataTableWithAccordion } from 'shared';
import { unimportedTransactionsTableHeaders } from '../utils/unimportedTransactionsTableHeaders';

const UnimportedTransactions = ({ selectedBank, coaCategoryData }) => {
    const companyData = useSelector((state) => state.company.data);
    const isMobileScreen = useCustomMediaQuery('(max-width: 600px)');

    const [page, setPage] = useState(1);
    const [data, setData] = useState(null);
    const [alertData, setAlertData] = useState({
        severity: '',
        open: false,
        message: '',
    });
    const [loading, setLoading] = useState(true);
    const [filterValues, setFilterValues] = useState(unimportedTransactionsInitialValues);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);

    const handleGetTransactions = async (pageNumber, filters = {}) => {
        try {
            setLoading(true);
            const response = await fetchBankTransactions(
                selectedBank?.id,
                pageNumber,
                'unimported',
                filters?.description,
                filters?.date,
            );
            setData(response);
        } catch (error) {
            handleErrorAlert(setAlertData);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (pageNumber, filters) => {
        setPage(pageNumber);
        setSelectedRows([]);
        handleGetTransactions(pageNumber, filters);
    };

    const showAccordion = (rowData, onClose) => {
        const handleBankTransactions = (message) => {
            onClose();
            handleGetTransactions(
                data?.plaid_transactions?.length == 1 && page != 1 ? page - 1 : page,
                filterValues,
            );
            if (data?.plaid_transactions?.length == 1 && page != 1) {
                setPage(page - 1);
            }
            handleSuccessAlert(setAlertData, message);
        };

        return (
            <RowAccordion
                rowData={rowData}
                onClose={onClose}
                coaCategoryData={coaCategoryData}
                handleBankTransactions={handleBankTransactions}
            />
        );
    };

    const getInitialTransactions = async () => {
        setPage(1);
        setSelectedRows([]);
        setSelectedOption(null);
        await handleGetTransactions(1, {});
    };

    const handleImportClick = async (plaidTransactionIds, filters) => {
        try {
            setLoading(true);
            setSelectedOption(plaidTransactionIds[0]);
            const response = await importTransactions(companyData?.id, plaidTransactionIds);
            handleSuccessAlert(setAlertData, response?.detail);
            await handleGetTransactions(
                data?.plaid_transactions?.length == 1 && page != 1 ? page - 1 : page,
                filters,
            );
            if (data?.plaid_transactions?.length == 1 && page != 1) {
                setPage(page - 1);
            }
        } catch (error) {
            handleErrorAlert(setAlertData);
        } finally {
            setLoading(false);
        }
    };

    const handleCoaUpdate = async (coa, plaidTransaction, filters) => {
        try {
            setLoading(true);
            await updateTransactionCoa(plaidTransaction?.id, coa?.id);
            await handleGetTransactions(page, filters);
        } catch (error) {
            handleErrorAlert(setAlertData);
        } finally {
            setLoading(false);
        }
    };

    const handleClearClick = (handleReset) => {
        setPage(1);
        handleReset();
        getInitialTransactions();
        setFilterValues(unimportedTransactionsInitialValues);
    };

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            setSelectedRows(data?.plaid_transactions?.map((transaction) => transaction.id));
        } else {
            setSelectedRows([]);
        }
    };

    const handleImportMultiple = async () => {
        try {
            const response = await importTransactions(companyData?.id, selectedRows);
            handleSuccessAlert(setAlertData, response?.detail);
            getInitialTransactions();
        } catch (error) {
            handleErrorAlert(setAlertData);
        }
    };

    const handleExcludeMultiple = async () => {
        try {
            const response = await excludeTransactions(companyData?.id, selectedRows);
            handleSuccessAlert(setAlertData, response?.message);
            getInitialTransactions();
        } catch (error) {
            handleErrorAlert(setAlertData);
        }
    };

    useEffect(() => {
        handleGetTransactions(1, {});
    }, [selectedBank]);

    return (
        <Fragment>
            <Formik
                initialValues={unimportedTransactionsInitialValues}
                validationSchema={unimportedTransactionsSchema}
                onSubmit={(values) => {
                    const transformedValues = {
                        ...values,
                        date: values.date ? dayjs(values.date).format('YYYY-MM-DD') : '',
                    };

                    setFilterValues(transformedValues);

                    handleGetTransactions(1, transformedValues);
                }}
            >
                {({ values, handleReset, handleChange, setFieldValue }) => {
                    return (
                        <Form>
                            <UnimportedTransactionsFilters
                                values={values}
                                handleReset={handleReset}
                                handleChange={handleChange}
                                setFieldValue={setFieldValue}
                                handleClearClick={handleClearClick}
                            />
                        </Form>
                    );
                }}
            </Formik>

            {selectedRows?.length > 0 && (
                <SelectedTransactionsInfo
                    selectedRows={selectedRows}
                    data={data?.plaid_transactions}
                    handleImportMultiple={handleImportMultiple}
                    handleExcludeMultiple={handleExcludeMultiple}
                />
            )}

            <DataTableWithAccordion
                data={{
                    results: data?.plaid_transactions || [],
                }}
                isLoading={loading}
                headers={unimportedTransactionsTableHeaders(
                    selectedRows,
                    selectedOption,
                    setSelectedRows,
                    coaCategoryData,
                    (coaData, plaidTransaction) =>
                        handleCoaUpdate(coaData, plaidTransaction, filterValues),
                    handleSelectAll,
                    (row) => handleImportClick([row?.id], filterValues),
                    data?.plaid_transactions?.length || 0,
                )}
                showAccordion={showAccordion}
            />

            <TablePagination
                page={page}
                count={data?.total_count || 0}
                isMobileScreen={isMobileScreen}
                onChange={(e, pageNumber) => handlePageChange(pageNumber, filterValues)}
            />

            <SnackBarAlert alertData={alertData} setAlertData={setAlertData} />
        </Fragment>
    );
};

export default UnimportedTransactions;
