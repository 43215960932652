import ReplayIcon from '@mui/icons-material/Replay';
import { CircularProgress, IconButton } from '@mui/material';

export const excludedTransactionsTableHeaders = (
    loadingRowId,
    handleUndoExcludedTransaction,
) => {
    return [
        {
            id: 'date',
            label: 'Date',
            sort: false,
            verticalAlign: 'top',
        },
        {
            id: 'description',
            label: 'Description',
            sort: false,
            verticalAlign: 'top',
        },
        {
            id: 'amount',
            label: 'Amount',
            sort: false,
            verticalAlign: 'top',
            render: (row) => `${row.currency_code} ${row.amount}`,
        },
        {
            id: 'action',
            label: '',
            sort: false,
            verticalAlign: 'top',
            render: (row) => (
                <IconButton
                    onClick={() => handleUndoExcludedTransaction(row?.id)}
                    disabled={loadingRowId === row?.id}
                    sx={{ padding: 0 }}
                >
                    {loadingRowId === row?.id ? (
                        <CircularProgress size={24} color="inherit" />
                    ) : (
                        <ReplayIcon fontSize="small" />
                    )}
                </IconButton>
            ),
        },
    ];
};
