import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { Modal, Button, CircleLoading, EmailAccordion } from 'shared';
import { useInventoryService } from 'store/services/inventory.service';

const EmailSourceModal = ({ emailId, showEmailSourceModal, setShowEmailSourceModal }) => {
    const companyData = useSelector((state) => state.company.data);
    const { getEmailById, isLoading } = useInventoryService();
    const [emails, setEmails] = useState(null);

    useEffect(() => {
        if (companyData?.id && emailId) {
            getEmailById(companyData.id, emailId)
                .then((res) => {
                    // wrap single email in array so EmailAccordion works properly
                    setEmails([res.data?.[0]]);
                })
                .catch((err) => console.error('Error fetching email by ID:', err));
        }
    }, [companyData, emailId]);

    return (
        <Modal
            fullWidth
            open={showEmailSourceModal}
            title="Email Source"
            handleClose={() => setShowEmailSourceModal(false)}
            customWidth="75%"
            showFooter
            footer={
                <Box>
                    <Button text="Close" onClick={() => setShowEmailSourceModal(false)} />
                </Box>
            }
        >
            {isLoading && !emails ? (
                <CircleLoading />
            ) : !emails?.length ? (
                <Typography align="center">No email data found</Typography>
            ) : (
                <EmailAccordion emails={emails} />
            )}
        </Modal>
    );
};

export default EmailSourceModal;
